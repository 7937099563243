import React, { useState, useEffect } from 'react';
import axios from 'axios';
import encryptionModule from '../../components/common/localStorageUtils';

const SkeletonLoader = () => {
  return (
    <tr className="border-b animate-pulse">
      <td className="p-4">
        <div className="h-4 bg-gray-200 rounded w-3/4"></div>
      </td>
      <td className="p-4">
        <div className="h-4 bg-gray-200 rounded w-1/2"></div>
      </td>
      <td className="p-4">
        <div className="h-4 bg-gray-200 rounded w-1/4"></div>
      </td>
      <td className="p-4">
        <div className="h-4 bg-gray-200 rounded w-1/4"></div>
      </td>
      <td className="p-4">
        <div className="h-4 bg-gray-200 rounded w-1/4"></div>
      </td>
    </tr>
  );
};

const Issues = () => {
  const [issues, setIssues] = useState([]);
  const [selectedIssue, setSelectedIssue] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [adminResponse, setAdminResponse] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const User = encryptionModule.decryptData('adminInfo')?.user;

  useEffect(() => {
    const fetchIssues = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_KEY}/issues/get-issues-user/${User?._id}`);
        setIssues(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchIssues();
  }, []);

  const handleStatusChange = async (id, newStatus) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_KEY}/issues/update-issues/${User?._id}/${id}`, { status: newStatus, response: adminResponse });
      setIssues((prevIssues) =>
        prevIssues.map((issue) => (issue._id === id ? { ...issue, status: newStatus } : issue))
      );
      setAdminResponse('');
      alert('Status updated successfully!');
    } catch (error) {
      console.error(error);
      alert('Failed to update status');
    }
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "open":
        return "bg-orange-300 text-orange-800";
      case "in_progress":
        return "bg-blue-300 text-blue-800";
      case "resolved":
        return "bg-green-300 text-green-800";
      case "closed":
        return "bg-red-300 text-red-800";
      default:
        return "bg-gray-200 text-gray-800";
    }
  };

  const openModal = (issue) => {
    setSelectedIssue(issue);
    setModalOpen(true);
    setAdminResponse('');
    setSelectedStatus(issue.status);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedIssue(null);
    setAdminResponse('');
    setSelectedStatus('');
  };

  // Filter issues based on status and search query
  const filteredIssues = issues.filter((issue) => {
    const matchesStatus = statusFilter ? issue.status === statusFilter : true;
    const matchesSearch = issue.title.toLowerCase().includes(searchQuery.toLowerCase()) || 
                          issue.user.name.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesStatus && matchesSearch;
  });

  return (
    <div className="p-4">
      <h1 className="text-xl font-bold mb-4">Issues</h1>
      
      {/* Responsive Flex Container for Search and Filter */}
      <div className='flex flex-col md:flex-row items-center gap-4'>
        {/* Search Bar */}
        <div className="mb-4 w-full md:w-1/2">
          <label className="mr-2">Search:</label>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search by User or Title"
            className="border-2 rounded p-2 border-customPurple w-full"
          />
        </div>

        {/* Status Filter */}
        <div className="mb-4 w-full md:w-1/2">
          <label className="mr-2">Filter by Status:</label>
          <select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            className="border-2 rounded p-2 border-customPurple w-full"
          >
            <option value="">All</option>
            <option value="open">Open</option>
            <option value="in_progress">In Progress</option>
            <option value="resolved">Resolved</option>
            <option value="closed">Closed</option>
          </select>
        </div>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-4">
        <table className="min-w-full bg-white shadow-md rounded-lg">
          <thead>
            <tr className="bg-customPurple text-xs uppercase text-white text-left">
              <th className="p-4">User</th>
              <th className="p-4">Issue Message</th>
              <th className="p-4">Date/Time</th>
              <th className="p-4">Status</th>
              <th className="p-4">Action</th>
            </tr>
          </thead>
          <tbody className='bg-white border'>
            {loading
              ? (
                <>
                  {/* Display multiple skeleton loaders while loading */}
                  {Array.from({ length: 5 }).map((_, index) => (
                    <SkeletonLoader key={index} />
                  ))}
                </>
              )
              : filteredIssues.map((issue) => (
                  <tr key={issue._id} className="border-b">
                    <td className="p-4">{issue.user.name}</td>
                    <td className="p-4">{issue.title.substring(0, 20)}...</td>
                    <td className="p-4">{new Date(issue.createdAt).toLocaleString()}</td>
                    <td className={`p-4`}>
                      <p className={`${getStatusClass(issue.status)} text-center py-2 rounded-lg cursor-not-allowed`}>{issue.status==='in_progress'?"in progress":issue.status}</p>
                    </td>
                    <td className="p-4">
                      <button
                        onClick={() => openModal(issue)}
                        className="text-blue-500 underline"
                      >
                        View More
                      </button>
                    </td>
                  </tr>
                ))
            }
          </tbody>
        </table>
      </div>

      {modalOpen && selectedIssue && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6 w-11/12 max-w-lg">
            <h2 className="text-lg font-bold mb-2">{selectedIssue.title}</h2>
            <p className="mb-4">{selectedIssue.description}</p>
            <textarea
              rows="3"
              placeholder="Admin response..."
              value={adminResponse}
              onChange={(e) => setAdminResponse(e.target.value)}
              className="border rounded w-full p-2 mb-4"
            />
            <select
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
              className="border rounded w-full p-2 mb-4"
            >
              <option value="open">Open</option>
              <option value="in_progress">In Progress</option>
              <option value="resolved">Resolved</option>
              <option value="closed">Closed</option>
            </select>
            <div className="flex justify-end">
              <button onClick={closeModal} className="bg-gray-500 text-white rounded px-4 py-2 mr-2">
                Close
              </button>
              <button
                onClick={() => handleStatusChange(selectedIssue._id, selectedStatus)}
                className="bg-customPurple text-white rounded px-4 py-2"
              >
                Update Status
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Issues;
