import React, { useEffect, useState } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import axios from "axios";
import toast from "react-hot-toast";
const EditMockPlannModal = ({ setEditPlanModal, currentPlan }) => {
  const [Mock, setMocks] = useState({
    packageName: "",
    size: "",
    actual_Price: "",
    discounted_Price: "",
  });

  const [currentItem, setCurrentItem] = useState(currentPlan);
  const [load, setLoad] = useState(false);
  const handleInputChange = (name, value) => {
    setCurrentItem((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleUpdatePlan = (e) => {
    e.preventDefault();
    console.log(Mock);
    setLoad(true);
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/mock-interview-plans/update/${currentPlan._id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        ...currentItem,
        discounted_price: currentItem.discounted_price || 0,
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        toast.success("Mock Updated SuccessFully");
        setEditPlanModal(false);
        setLoad(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Mock Couldn't be Updated...!");
        setLoad(false);
      });
  };
  console.log(currentPlan);
  useEffect(() => {}, []);
  return (
    <div className="fixed inset-0 z-50 bg-[#0000005d] h-screen w-screen flex justify-center items-center">
      <div className="w-[30rem] bg-customWhite rounded-lg relative ">
        <IoIosCloseCircle
          onClick={() => {
            setEditPlanModal(false);
          }}
          className="cursor-pointer absolute top-2 right-2"
          size={25}
          color="#846cf9"
        />
        <h1 className="text-center font-semibold tracking-wide my-4">
          Update Mock Plan
        </h1>
        <div>
          <form onSubmit={handleUpdatePlan} class="w-full max-w-lg mx-auto p-4">
            <div class="mb-4">
              <label
                class="block text-gray-700 text-sm tracking-wide  mb-2"
                for="size"
              >
                Package name
              </label>
              <input
                className="shadow appearance-none border rounded tracking-wide w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="packageName"
                type="text"
                value={currentItem.packageName}
                onChange={(e) => {
                  handleInputChange("packageName", e.target.value);
                }}
                placeholder="Enter package name"
                required
              />
            </div>

            <div class="mb-4">
              <label
                class="block text-gray-700 text-sm tracking-wide  mb-2"
                for="size"
              >
                No. of mocks
              </label>
              <input
                className="shadow appearance-none border rounded tracking-wide w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="size"
                type="number"
                value={currentItem.size}
                onChange={(e) => {
                  handleInputChange("size", e.target.value);
                }}
                placeholder="Enter number of mocks"
                required
              />
            </div>

            <div class="mb-4">
              <label
                class="block text-gray-700 text-sm tracking-wide  mb-2"
                for="actual_price"
              >
                Actual Price
              </label>
              <input
                class="shadow appearance-none border rounded tracking-wide w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="actual_price"
                type="number"
                value={currentItem?.actual_price}
                onChange={(e) => {
                  handleInputChange("actual_price", e.target.value);
                }}
                placeholder="Enter actual price"
              />
            </div>

            <div class="mb-4">
              <label
                class="block text-gray-700 text-sm tracking-wide  mb-2"
                for="discounted_price"
              >
                Discounted Price
              </label>
              <input
                class="shadow appearance-none tracking-wide border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="discounted_price"
                type="number"
                value={currentItem?.discounted_price}
                onChange={(e) => {
                  handleInputChange("discounted_price", e.target.value);
                }}
                placeholder="Enter discounted price"
              />
            </div>

            <div class="flex items-center justify-between">
              <button
                className={`${
                  load ? "bg-blue-200" : "bg-[#846CF9]"
                }  hover:scale-110 w-[7rem] duration-300 text-white font-bold py-2  rounded focus:outline-none focus:shadow-outline`}
                type="submit"
              >
                {load ? "Updating..." : "Update"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditMockPlannModal;
