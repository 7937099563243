import React, { useEffect, useState } from "react";
import axios from "axios";
import { MdDelete } from "react-icons/md";
import encryptionModule from "../../components/common/localStorageUtils";
import mockImage from "../../Assets/MockInterview/9384988_41713441.png";
import PayPalButton from "../../components/common/PayPalButton";
import PlanSelection from "../../components/MockInterview/PlanSelection";

const API_BASE_URL = `${process.env.REACT_APP_API_KEY}`;

const CourseCard = ({ item, onRemove, isRemoving }) => (
  <div className="bg-white shadow-md rounded-lg p-4 flex flex-col border-primaryColor border-2">
    <h3 className="text-lg font-semibold mb-2">
      {item?.courses?.course_reference?.title}
    </h3>
    <p className="text-lg font-medium mb-2">
      Price: ${item?.courses?.course_plan_reference?.price}
    </p>
    <div className="flex items-center justify-between">
      <p className="text-sm">
        Plan: {item?.courses.course_plan_reference?.name}
      </p>
      <button
        onClick={() => onRemove(item.id, "course")}
        disabled={isRemoving}
        className={`self-end text-red-600 hover:text-red-700 transition ${
          isRemoving ? "opacity-50 cursor-not-allowed" : ""
        }`}
      >
        <MdDelete size={20} />
      </button>
    </div>
  </div>
);

const ResumeReviewCard = ({ item, onRemove, isRemoving }) => (
  <div className="bg-white shadow-md rounded-lg p-4 flex flex-col border-primaryColor border-2">
    <h3 className="text-lg font-semibold mb-2">Resume Review</h3>
    <p className="text-lg font-medium mb-2">
      Price: ${item?.resumeReviews?.resume_review_plan?.price}
    </p>
    <div className="flex items-center justify-between">
      <p className="text-sm">
        Type: {item.resumeReviews?.resume_review_plan?.type}
      </p>
      <button
        onClick={() => onRemove(item.id, "resumeReview")}
        disabled={isRemoving}
        className={`text-red-600 hover:text-red-700 transition ${
          isRemoving ? "opacity-50 cursor-not-allowed" : ""
        }`}
      >
        <MdDelete size={20} />
      </button>
    </div>
  </div>
);

const MockInterviewCard = ({ item, onRemove, isRemoving }) => (
  <div className="bg-white shadow-md rounded-lg p-4 flex flex-col border-primaryColor border-2">
    <h3 className="text-lg font-semibold mb-2">Mock Interview</h3>
    <p className="text-lg font-medium mb-2">
      Price: ${item?.packages?.packageId?.discounted_price}
    </p>
    <div className="">
      <div className="flex flex-col gap-2">
        <p className="font-medium">
          Number of Mocks: {item?.packages?.packageId?.size}
        </p>
        <p>
          Nmber of Technical Grooming Sessions:{" "}
          {item?.packages?.technicalGrooming}
        </p>
        <p>Number of Mock Interviews: {item?.packages?.mockInterview}</p>
        <p>
          Number of Behavioural Grooming: {item?.packages?.behaviouralGrooming}
        </p>
      </div>

      <button
        onClick={() => onRemove(item.id, "mockInterview")}
        disabled={isRemoving}
        className={`float-right text-red-600 hover:text-red-700 transition ${
          isRemoving ? "opacity-50 cursor-not-allowed" : ""
        }`}
      >
        <MdDelete size={20} />
      </button>
    </div>
  </div>
);

const Cart = () => {
  const [cartData, setCartData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingItemId, setLoadingItemId] = useState(null);
  const User = encryptionModule.decryptData("userInfo")?.user;
  const [mockPlans, setMockPlans] = useState([]);
  const [resumePlans, setResumePlans] = useState([]);

  const getMockPlans = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_KEY}/mock-interview-plans/get-all-plans`
      )
      .then((response) => {
        setMockPlans(response.data?.plans);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getResumePlan = () => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/resume-plans/get-all-plans`)
      .then((response) => {
        setResumePlans(response.data?.plans);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getMockPlans();
    getResumePlan();
  }, []);

  const handleMockAddToCart = (plan) => {
    let data = JSON.stringify({
      user_reference: User?._id,
      type: "mockInterview",
      courses: null,
      packages: {
        mockInterview_plan_reference: plan?._id,
      },
      resumeReviews: null,
      amount: plan?.discounted_price,
      tax: 15,
      totalAmount: plan?.discounted_price,
    });

    axios
      .post(`${process.env.REACT_APP_API_KEY}/cart/create`, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        console.log(response.data);
        fetchCartData();
        alert("This Item is added in your cart");
      })
      .catch((error) => {
        console.log(error);
        alert(error?.response?.data?.message);
      });
  };

  const handleResumeAddToCart = (plan) => {
    let data = JSON.stringify({
      user_reference: User?._id,
      type: "resumeReview",
      courses: null,
      packages: null,
      resumeReviews: {
        resume_review_plan: plan?._id,
      },
      amount: plan?.price,
      tax: 15,
      totalAmount: plan?.price,
    });

    axios
      .post(`${process.env.REACT_APP_API_KEY}/cart/create`, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        console.log(response.data);
        fetchCartData();
        alert("This Item is added in your cart");
      })
      .catch((error) => {
        console.log(error);
        alert(error?.response?.data?.message);
      });
  };

  const fetchCartData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${API_BASE_URL}/cart/get-user-carts/${User?._id}`
      );
      console.log("Response of fetchCartData: ", response.data);
      setCartData(response.data);
    } catch (err) {
      console.log(err);
      setCartData({
        courses: [],
        packages: [],
        resumeReviews: [],
        subtotal: 0,
        tax: 0,
        totalAmount: 0,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (User?._id) {
      fetchCartData();
    }
  }, [User?._id]);

  const calculateTotals = (updatedCart) => {
    const subtotal = [
      ...updatedCart.courses,
      ...updatedCart.packages,
      ...updatedCart.resumeReviews,
    ].reduce((total, item) => {
      const price =
        item.courses?.course_plan_reference?.price ||
        item.packages?.mockInterview_plan_reference?.discounted_price ||
        item.resumeReviews?.resume_review_plan?.price ||
        0;
      return total + price;
    }, 0);

    const tax = subtotal * 0.1; // Assuming 10% tax rate
    const totalAmount = subtotal + tax;

    return { subtotal, tax, totalAmount };
  };

  const handleRemoveItem = async (itemId, type) => {
    setLoadingItemId(itemId);
    try {
      await axios.delete(`${API_BASE_URL}/cart/delete/${User?._id}/${itemId}`);
      fetchCartData();

      // setCartData((prevData) => {
      //   console.log("Type: ", type);
      //   const updatedCart = {
      //     ...prevData,
      //     [type + "s"]: prevData[type + "s"].filter(
      //       (item) => item.id !== itemId
      //     ),
      //   };

      //   console.log("Updated Cart: ", updatedCart);
      //   const { subtotal, tax, totalAmount } = calculateTotals(updatedCart);
      //   return {
      //     ...updatedCart,
      //     subtotal,
      //     tax,
      //     totalAmount,
      //   };
      // });
    } catch (err) {
      console.error("Error removing cart item:", err);
    } finally {
      setLoadingItemId(null);
    }
  };

  const handlePurchased = () => {
    const totals = calculateTotals(cartData);
    let data = JSON.stringify({
      userId: User?._id,
      items: {
        courses: cartData.courses,
        packages: cartData.packages,
        resumeReviews: cartData.resumeReviews,
      },
      totalAmount: totals.subtotal,
      tax: totals.tax,
      grandTotal: totals.totalAmount,
    });

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/purchased/purchased-items`,
        data,
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((response) => {
        console.log(JSON.stringify(response.data));
        clearAllItem();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const clearAllItem = async () => {
    await axios.delete(`${API_BASE_URL}/cart/clear-all-data/${User?._id}`);
    fetchCartData();
  };

  if (isLoading) {
    return (
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold mb-6">Your Cart</h1>
        <div className="animate-pulse">
          <div className="h-8 bg-gray-200 rounded w-1/4 mb-4"></div>
          <div className="h-64 bg-gray-200 rounded mb-4"></div>
          <div className="h-8 bg-gray-200 rounded w-1/4 mb-4"></div>
          <div className="h-64 bg-gray-200 rounded mb-4"></div>
        </div>
      </div>
    );
  }

  if (!cartData) {
    return null;
  }

  const { courses, packages, resumeReviews, subtotal, tax, totalAmount } =
    cartData;

  const isCartEmpty =
    courses.length === 0 && packages.length === 0 && resumeReviews.length === 0;

  console.log("-------bghi--->", courses, packages, resumeReviews);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6">Your Cart</h1>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div className="lg:col-span-2">
          {isCartEmpty ? (
            <div
              className="bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3"
              role="alert"
            >
              <p className="font-bold">Your cart is empty</p>
              <p className="text-sm">
                Start shopping to add items to your cart.
              </p>
            </div>
          ) : (
            <>
              {courses.length > 0 && (
                <section className="mb-8">
                  <h2 className="text-2xl font-semibold mb-4">Courses</h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {courses?.map((item) => (
                      <CourseCard
                        key={item.id}
                        item={item}
                        onRemove={handleRemoveItem}
                        isRemoving={loadingItemId === item.id}
                      />
                    ))}
                  </div>
                </section>
              )}

              {packages.length > 0 && (
                <section className="mb-8">
                  <h2 className="text-2xl font-semibold mb-4">Packages</h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {packages?.map((item) => (
                      <MockInterviewCard
                        key={item.id}
                        item={item}
                        onRemove={handleRemoveItem}
                        isRemoving={loadingItemId === item.id}
                      />
                    ))}
                  </div>
                </section>
              )}

              {resumeReviews.length > 0 && (
                <section className="mb-8">
                  <h2 className="text-2xl font-semibold mb-4">
                    Resume Reviews
                  </h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {resumeReviews.map((item) => (
                      <ResumeReviewCard
                        key={item.id}
                        item={item}
                        onRemove={handleRemoveItem}
                        isRemoving={loadingItemId === item.id}
                      />
                    ))}
                  </div>
                </section>
              )}
            </>
          )}
        </div>

        <div className="lg:col-span-1">
          <div className="sticky top-4">
            <div className="bg-white shadow-md rounded-lg p-6 border-primaryColor border-2">
              <h2 className="text-2xl font-bold mb-4">Order Summary</h2>
              <div className="flex justify-between mb-2">
                <span>Subtotal:</span>
                <span>${subtotal.toFixed(2)}</span>
              </div>
              <div className="flex justify-between mb-2">
                <span>Tax:</span>
                <span>${tax.toFixed(2)}</span>
              </div>
              <div className="flex justify-between font-bold text-xl mb-4">
                <span>Total Amount:</span>
                <span>${totalAmount.toFixed(2)}</span>
              </div>
              {/* <button
                onClick={handlePurchased}
                className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full transition duration-300"
                disabled={isCartEmpty}
              >
                Proceed to Checkout
              </button> */}
              <PayPalButton
                totalAmount={100}
                handlePurchased={handlePurchased}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-12">
        <h2 className="text-2xl font-bold mb-6">Suggested Items</h2>

        <div className="mb-8">
          <h3 className="text-xl font-semibold mb-4">Resume Review Plans</h3>
          <div className="flex overflow-x-auto pb-4 space-x-6">
            {resumePlans?.map((plan) => (
              <div
                key={plan._id}
                className={`flex-shrink-0 w-64 p-6 border rounded-lg shadow-lg transition-transform transform hover:scale-105 ${
                  plan.highlighted ? "bg-yellow-500 text-white" : "bg-white"
                }`}
              >
                <div className="mb-4">
                  <span className="text-2xl font-bold">${plan.price}</span>
                  <span className="text-lg font-medium">
                    /
                    {plan.type === "Review over Mai"
                      ? "per review"
                      : "per session"}
                  </span>
                </div>
                <h3
                  className={`text-sm font-semibold mb-4 rounded-lg ${
                    plan.highlighted
                      ? "text-white bg-[#DFA72E] inline-block px-4 py-2"
                      : "text-white bg-primaryColor inline-block px-4 py-2"
                  }`}
                >
                  {plan.type}
                </h3>
                <ul
                  className={`mb-6 ${
                    plan.highlighted ? "text-white" : "text-gray-600"
                  }`}
                >
                  {plan.key_points.map((feature, index) => (
                    <li key={index} className="flex space-x-2 mb-2 text-sm">
                      <span>✔️</span>
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
                <button
                  onClick={() => handleResumeAddToCart(plan)}
                  className={`py-2 px-6 text-sm rounded-lg font-semibold transition w-full ${
                    plan.highlighted
                      ? "bg-white text-primaryColor hover:bg-yellow-100"
                      : "bg-primaryColor text-white hover:bg-yellow-600"
                  }`}
                >
                  Add to Cart
                </button>
              </div>
            ))}
          </div>
        </div>

        <PlanSelection />

        {/* <div className="mt-8">
          <h3 className="text-xl font-semibold mb-4">Mock Interview Plans</h3>
          <div className="flex overflow-x-auto pb-4 space-x-6">
            {mockPlans.map((plan) => (
              <div
                key={plan._id}
                className="flex-shrink-0 w-64 border rounded-lg p-4 shadow-md relative transition-transform transform hover:scale-105 cursor-pointer bg-white"
              >
                {plan.discount && (
                  <div className="absolute top-2 right-2 bg-yellow-500 text-white text-xs px-2 py-1 rounded">
                    20% OFF
                  </div>
                )}

                <div className="mb-4 h-32 flex items-center justify-center">
                  <img
                    src={mockImage}
                    alt={`Package of ${plan?.number_of_mocks} Mock Interviews`}
                    className="max-h-full max-w-full object-contain"
                  />
                </div>

                <h3 className=" mb-2 h-14 overflow-hidden">
                  Package of {plan?.number_of_mocks} Mock Interview
                  {plan?.number_of_mocks > 1 ? "s" : ""}
                </h3>

                <div className="flex items-center justify-between mt-auto">
                  <div>
                    <div className="text-sm text-gray-500 line-through">
                      ${plan.actual_price}
                    </div>
                    <div className="text-xl font-bold text-primaryColor">
                      ${plan?.discounted_price}
                    </div>
                  </div>
                  <button
                    onClick={() => handleMockAddToCart(plan)}
                    className="bg-primaryColor text-white hover:bg-yellow-600 px-4 py-2 rounded transition duration-300"
                  >
                    Add
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Cart;
