import React, { useEffect } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import { IoMdAddCircle } from "react-icons/io";
import { useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import axios from "axios";
import { useForm } from "react-hook-form";
import encryptionModule from "../../common/localStorageUtils";

const EditCourseModal = ({
  setEditModal,
  currentCourse,
  refresh,
  setRefresh,
}) => {
  const cloudName = "dnw1ttr02";
  const upload_preset = "wjs1zyo5";

  const adminData = encryptionModule.decryptData("adminInfo");
  const AdminId = adminData?.user?._id;

  const { register, handleSubmit } = useForm();

  const [formErrors, setFormErrors] = useState({});
  const [keyPoints, setKeyPoints] = useState("");
  const [keyPointArr, setKeyPointArr] = useState([]);
  const [chooseCourse, setChooseCourse] = useState("");
  const [chooseCourseArr, setChooseCourseArr] = useState([]);
  const [courseImg, setCourseImg] = useState([]);
  const [arrOfCourseImg, setArrOfCourseImg] = useState([]);
  const [category, setCategory] = useState([]);
  const [currentCourseData, setCurrentCourseData] = useState({});
  const [load, setload] = useState(false);
  const [addImgLload, setAddImgLoad] = useState(false);

  console.log("i am the test current data---->", currentCourseData);

  const handleKeyPointsChange = () => {
    let arr = [...keyPointArr];
    arr.push(keyPoints);
    setKeyPointArr(arr);
    setKeyPoints("");
  };
  const handleChooseCourse = () => {
    let arr = [...chooseCourseArr];
    arr.push(chooseCourse);
    setChooseCourseArr(arr);
    setChooseCourse("");
  };

  console.log(arrOfCourseImg);
  const handleCourseImg = () => {
    if (arrOfCourseImg?.length == 2) {
      alert("Already 2 images exist. Please remove images to add a new ones.");
    } else {
      // setArrOfCourseImg([...arrOfCourseImg, courseImg]);
      // setCourseImg([]);
      setAddImgLoad(true);
      const file = courseImg[0];
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", upload_preset);
      formData.append("public_id", `KGS/${courseImg[0].name}`);
      axios
        .post(
          `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
          formData
        )
        .then((response) => {
          console.log(
            response?.data?.secure_url,
            "fjdfhkjdhgjfdghjghjkghdkfjghdfjkgh"
          );
          setArrOfCourseImg([...arrOfCourseImg, response?.data?.secure_url]);
          setCourseImg([]);
          setAddImgLoad(false);
        })

        .catch((error) => {
          console.log(error);
          setAddImgLoad(false);
        });
    }
  };

  const removeKeyPoint = (each, itemIndex) => {
    const newArr = keyPointArr.filter((each, index) => {
      return index != itemIndex;
    });
    setKeyPointArr(newArr);
  };
  const removeCourseArr = (each, itemIndex) => {
    const newArr = chooseCourseArr.filter((each, index) => {
      return index != itemIndex;
    });
    setChooseCourseArr(newArr);
  };

  const removeCourseImg = (each, itemIndex) => {
    const newArr = arrOfCourseImg.filter((each, index) => {
      return index != itemIndex;
    });
    setArrOfCourseImg(newArr);
  };

  const getAllCatagory = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/categories/get-all-categories`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        setRefresh(!refresh);
        setCategory(response?.data?.categories);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleInputChange = (name, value) => {
    console.log(name, value);

    if (name == "about_course") {
      setCurrentCourseData((prev) => {
        return {
          ...prev,
          about_course: { ...prev.about_course, [value.field]: value.data },
        };
      });
      return;
    }

    setCurrentCourseData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    const formFields = [
      "title",
      "aboutCourseTitle",
      "mydescription",
      "ratings",
      "reviews",
      "published_by",
      "hours_of_course",
      "total_assignments",
      "video_lessons",
      "students_enrolled",
      // "key_points",
      // "images",
      // "why_choose_this_course",
      "least_details",
      "detail",
    ];

    console.log(document.getElementsByName("description")[0]);
    formFields.forEach((field) => {
      const element = document.getElementsByName(field)[0];
      if (!element || !element.value) {
        formIsValid = false;
        errors[field] = "This field is required";
      }
    });

    setFormErrors(errors);
    return formIsValid;
  };

  const handleCourseUpdate = (e) => {
    e.preventDefault();

    if (handleValidation()) {
      // if (currentCourseData?.category?.name) {
      //   setFormErrors((prev) => {
      //     return { ...prev, category: "This field is required" };
      //   });
      //   alert("Please select catagory");
      //   return;
      // }

      const filterCat = category.filter((each) => {
        return each.name == currentCourseData?.category;
      });
      console.log(category, "--------------------------->catagory");
      console.log(
        filterCat,
        "-----------------CVDFVVDFJVJFKDV-------------CAT"
      );
      setload(true);

      const myData = {
        ...currentCourseData,
        category: filterCat[0]?._id,
        published_by: currentCourseData?.published_by?._id,
        about_course: {
          ...currentCourseData.about_course,
          key_points: keyPointArr,
          images: arrOfCourseImg,
          why_choose_this_course: chooseCourseArr,
        },
      };
      console.log(myData);

      console.log(
        `${process.env.REACT_APP_API_KEY}/courses/update/${currentCourseData._id}`
      );

      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_KEY}/courses/update/${currentCourseData._id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: myData,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response);
          document.getElementById("course").reset();
          setEditModal(false);
          setRefresh(!refresh);
          alert("Course has been updated successfully ..");
          setload(false);
        })
        .catch((error) => {
          console.log(error);
          alert("Course Couldn't be updated..!");
          setload(false);
        });
    } else {
      console.log("form has errors");
    }
  };

  console.log("current course data----->", currentCourseData);

  useEffect(() => {
    getAllCatagory();
    const newdata = {
      ...currentCourse,
      category: currentCourse?.category?.name,
    };
    setCurrentCourseData(newdata);
    setKeyPointArr(currentCourse?.about_course?.key_points);
    setChooseCourseArr(currentCourse?.about_course?.why_choose_this_course);
    setArrOfCourseImg(currentCourse?.about_course?.images);
  }, []);
  return (
    <div className="fixed inset-0 z-50 bg-[#0000005d] h-screen w-screen flex justify-center items-center">
      <div className="w-[60rem] h-[30rem] bg-customWhite rounded-lg relative ">
        <IoIosCloseCircle
          onClick={() => {
            setEditModal(false);
          }}
          className="cursor-pointer absolute top-2 right-2"
          size={25}
          color="#846cf9"
        />
        <h1 className="text-center font-semibold my-4">Edit Course</h1>
        <div className="h-[30rem]  mx-auto p-6 bg-white shadow-md rounded-md overflow-y-scroll">
          <form
            id="course"
            onSubmit={handleCourseUpdate}
            className="grid grid-cols-1 sm:grid-cols-2 gap-4"
          >
            {/* Title */}
            <div>
              <label
                htmlFor="title"
                className={`block text-sm font-medium ${
                  formErrors.title ? "text-red-600" : "text-gray-700"
                }`}
              >
                Title
              </label>
              <input
                type="text"
                id="title"
                name="title"
                value={currentCourseData?.title}
                onChange={(e) => {
                  handleInputChange("title", e.target.value);
                }}
                //   {...register("title")}
                className={`mt-1 block w-full px-3 py-2 border ${
                  formErrors.title ? "border-red-600" : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                placeholder="Enter course title"
              />
            </div>

            <div>
              <label
                htmlFor="title"
                className={`block text-sm font-medium ${
                  formErrors.mydescription ? "text-red-600" : "text-gray-700"
                }`}
              >
                Description
              </label>
              <input
                type="text"
                id="mydescription"
                name="mydescription"
                //   {...register("mydescription")}
                value={currentCourseData?.description}
                onChange={(e) => {
                  handleInputChange("description", e.target.value);
                }}
                className={`mt-1 block w-full px-3 py-2 border ${
                  formErrors.mydescription
                    ? "border-red-600"
                    : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                placeholder="Enter course description"
              />
            </div>

            {/* Category */}
            <div>
              <label
                htmlFor="category"
                className={`block text-sm font-medium ${
                  formErrors.category ? "text-red-600" : "text-gray-700"
                }`}
              >
                Category
              </label>
              <select
                id="category"
                name="category"
                value={currentCourseData?.category}
                onChange={(e) => {
                  handleInputChange("category", e.target.value);
                }}
                className={`mt-1 block w-full px-3 py-2 border ${
                  formErrors.category ? "border-red-600" : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
              >
                <option value="Select Ctegory" selected>
                  Select Category
                </option>
                {category.map((each, index) => {
                  return (
                    <option key={index} value={each.name}>
                      {each?.name}
                    </option>
                  );
                })}
                {/* Add options here */}
              </select>
            </div>

            {/* Ratings */}
            <div>
              <label
                htmlFor="ratings"
                className={`block text-sm font-medium ${
                  formErrors.ratings ? "text-red-600" : "text-gray-700"
                }`}
              >
                Ratings
              </label>

              <select
                id="ratings"
                name="ratings"
                value={currentCourseData?.ratings}
                onChange={(e) => {
                  handleInputChange("ratings", e.target.value);
                }}
                className={`mt-1 block w-full px-3 py-2 border ${
                  formErrors.ratings ? "border-red-600" : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
              >
                {/* <option value="Select Ctegory" selected>
                  Select Category
                </option> */}

                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                {/* {category.map((each, index) => {
                  return (
                    <option key={index} value={each.name}>
                      {each?.name}
                    </option>
                  );
                })} */}
                {/* Add options here */}
              </select>
            </div>

            {/* Reviews */}
            <div>
              <label
                htmlFor="reviews"
                className={`block text-sm font-medium ${
                  formErrors.reviews ? "text-red-600" : "text-gray-700"
                }`}
              >
                Reviews
              </label>
              <input
                type="number"
                id="reviews"
                name="reviews"
                value={currentCourseData?.reviews}
                onChange={(e) => {
                  handleInputChange("reviews", e.target.value);
                }}
                //   {...register("reviews")}
                className={`mt-1 block w-full px-3 py-2 border ${
                  formErrors.reviews ? "border-red-600" : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                placeholder="Enter number of reviews"
              />
            </div>

            {/* Published By */}
            <div>
              <label
                htmlFor="published_by"
                className={`block text-sm font-medium ${
                  formErrors.published_by ? "text-red-600" : "text-gray-700"
                }`}
              >
                Published By
              </label>
              <select
                id="published_by"
                name="published_by"
                value={currentCourseData?.published_by}
                onChange={(e) => {
                  handleInputChange("published_by", e.target.value);
                }}
                //   {...register("published_by")}
                className={`mt-1 block w-full px-3 py-2 border ${
                  formErrors.published_by ? "border-red-600" : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
              >
                <option value="Admin">Admin</option>
                {/* Add options here */}
              </select>
            </div>

            {/* Hours of Course */}
            <div>
              <label
                htmlFor="hours_of_course"
                className={`block text-sm font-medium ${
                  formErrors.hours_of_course ? "text-red-600" : "text-gray-700"
                }`}
              >
                Hours of Course
              </label>
              <input
                type="number"
                id="hours_of_course"
                name="hours_of_course"
                value={currentCourseData?.hours_of_course}
                onChange={(e) => {
                  handleInputChange("hours_of_course", e.target.value);
                }}
                //   {...register("hours_of_course")}
                className={`mt-1 block w-full px-3 py-2 border ${
                  formErrors.hours_of_course
                    ? "border-red-600"
                    : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                placeholder="Enter hours of course"
              />
            </div>

            {/* Total Assignments */}
            <div>
              <label
                htmlFor="total_assignments"
                className={`block text-sm font-medium ${
                  formErrors.total_assignments
                    ? "text-red-600"
                    : "text-gray-700"
                }`}
              >
                Total Assignments
              </label>
              <input
                type="number"
                id="total_assignments"
                name="total_assignments"
                value={currentCourseData?.total_assignments}
                onChange={(e) => {
                  handleInputChange("total_assignments", e.target.value);
                }}
                //   {...register("total_assignments")}
                className={`mt-1 block w-full px-3 py-2 border ${
                  formErrors.total_assignments
                    ? "border-red-600"
                    : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                placeholder="Enter total assignments"
              />
            </div>

            {/* Video Lessons */}
            <div>
              <label
                htmlFor="video_lessons"
                className={`block text-sm font-medium ${
                  formErrors.video_lessons ? "text-red-600" : "text-gray-700"
                }`}
              >
                Video Lessons
              </label>
              <input
                type="number"
                id="video_lessons"
                name="video_lessons"
                value={currentCourseData?.video_lessons}
                onChange={(e) => {
                  handleInputChange("video_lessons", e.target.value);
                }}
                //   {...register("video_lessons")}
                className={`mt-1 block w-full px-3 py-2 border ${
                  formErrors.video_lessons
                    ? "border-red-600"
                    : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                placeholder="Enter video lessons"
              />
            </div>

            {/* Students Enrolled */}
            <div>
              <label
                htmlFor="students_enrolled"
                className={`block text-sm font-medium ${
                  formErrors.students_enrolled
                    ? "text-red-600"
                    : "text-gray-700"
                }`}
              >
                Students Enrolled
              </label>
              <input
                type="number"
                id="students_enrolled"
                name="students_enrolled"
                value={currentCourseData?.students_enrolled}
                onChange={(e) => {
                  handleInputChange("students_enrolled", e.target.value);
                }}
                //   {...register("students_enrolled")}
                className={`mt-1 block w-full px-3 py-2 border ${
                  formErrors.students_enrolled
                    ? "border-red-600"
                    : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                placeholder="Enter students enrolled"
              />
            </div>

            {/* ----------------------------------------------- */}

            <div>
              <label
                htmlFor="About Course Title"
                className={`block text-sm font-medium ${
                  formErrors.aboutCourseTitle ? "text-red-600" : "text-gray-700"
                }`}
              >
                About Course Title
              </label>
              <input
                type="text"
                id="aboutCourseTitle"
                name="aboutCourseTitle"
                value={currentCourseData?.about_course?.title}
                onChange={(e) => {
                  handleInputChange("about_course", {
                    field: "title",
                    data: e.target.value,
                  });
                }}
                //   {...register("aboutCourseTitle")}
                className={`mt-1 block w-full px-3 py-2 border ${
                  formErrors.aboutCourseTitle
                    ? "border-red-600"
                    : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                placeholder="course title"
              />
            </div>
            <div>
              <label
                htmlFor="detail"
                className={`block text-sm font-medium ${
                  formErrors.detail ? "text-red-600" : "text-gray-700"
                }`}
              >
                About Course Details
              </label>
              <input
                type="text"
                id="detail"
                name="detail"
                value={currentCourseData?.about_course?.details}
                onChange={(e) => {
                  handleInputChange("about_course", {
                    field: "details",
                    data: e.target.value,
                  });
                }}
                //   {...register("detail")}
                className={`mt-1 block w-full px-3 py-2 border ${
                  formErrors.detail ? "border-red-600" : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                placeholder="Enter more details about the course"
              />
            </div>

            {/* Least Details */}
            <div className="col-span-2">
              <label
                htmlFor="least_details"
                className={`block text-sm font-medium ${
                  formErrors.least_details ? "text-red-600" : "text-gray-700"
                }`}
              >
                Last Details
              </label>
              <input
                type="text"
                id="least_details"
                name="least_details"
                //   {...register("least_details")}
                value={currentCourseData?.about_course?.last_details}
                onChange={(e) => {
                  handleInputChange("about_course", {
                    field: "last_details",
                    data: e.target.value,
                  });
                }}
                className={`mt-1 block w-full px-3 py-2 border ${
                  formErrors.least_details
                    ? "border-red-600"
                    : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                placeholder="Enter last details"
              />
            </div>

            {/* Key Points */}
            <div className="col-span-2">
              <label
                htmlFor="key_points"
                className={`block text-sm font-medium ${
                  formErrors.keyPoint ? "text-red-600" : "text-gray-700"
                }`}
              >
                Key Points
              </label>
              <div className="flex items-center gap-2">
                <input
                  type="text"
                  id="key_points"
                  name="key_points"
                  // {...register("key_points")}
                  value={keyPoints}
                  onChange={(e) => {
                    setKeyPoints(e.target.value);
                  }}
                  className={`mt-1 flex-grow block w-full px-3 py-2 border ${
                    formErrors.keyPoint ? "border-red-600" : "border-gray-300"
                  } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  placeholder="Enter key points, separated by commas"
                />
                <IoMdAddCircle
                  onClick={() => {
                    handleKeyPointsChange();
                  }}
                  className="cursor-pointer"
                  size={25}
                  color="#846cf9"
                />
              </div>
              <div className="mt-2 rounded-lg bg-gray-50 px-2 py-2 ">
                <ul className="flex flex-wrap items-center gap-1">
                  {keyPointArr?.map((each, index) => {
                    return (
                      <li
                        key={index}
                        className="flex items-center gap-1 rounded-full bg-customPurple px-2 py-1 text-sm text-white"
                      >
                        {each.substring(0, 30)}
                        <IoCloseSharp
                          onClick={() => {
                            removeKeyPoint(each, index);
                          }}
                          size={20}
                          className="font-semibold cursor-pointer"
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            {/* --------------------------------------------------------- */}
            {/* Images */}
            <div className="col-span-2">
              <label
                htmlFor="images"
                className={`block text-sm font-medium ${
                  formErrors.courseImg ? "text-red-600" : "text-gray-700"
                }`}
              >
                Images
              </label>
              <div className="flex items-center gap-2">
                <input
                  type="file"
                  id="images"
                  name="images"
                  filename={courseImg[0]?.name}
                  onChange={(e) => {
                    const images = e.target.files[0];
                    setCourseImg([...courseImg, images]);
                  }}
                  className={`mt-1 block w-full px-3 py-2 border ${
                    formErrors.courseImg ? "border-red-600" : "border-gray-300"
                  } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                />

                {addImgLload ? (
                  <div class="loaderAdd"></div>
                ) : (
                  <IoMdAddCircle
                    onClick={handleCourseImg}
                    className="cursor-pointer"
                    size={25}
                    color="#846cf9"
                  />
                )}
              </div>
              <div className="mt-2 rounded-lg bg-gray-50 px-2 py-2 ">
                <ul className="flex flex-wrap items-center gap-3">
                  {arrOfCourseImg?.map((each, index) => {
                    return (
                      <li className="flex items-start gap-1 rounded-full text-sm text-white">
                        {/* {each[0]?.name} */}
                        <img
                          src={each}
                          className="h-[60px] w-[60px] rounded-lg object-cover"
                          alt="No image"
                        />
                        <IoCloseSharp
                          color="#fff"
                          onClick={() => {
                            removeCourseImg(each, index);
                          }}
                          size={25}
                          className="font-semibold bg-customPurple p-1 rounded-full cursor-pointer"
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            {/* Why Choose This Course */}
            <div className="col-span-2">
              <label
                htmlFor="why_choose_this_course"
                className={`block text-sm font-medium ${
                  formErrors.chooseCourse ? "text-red-600" : "text-gray-700"
                }`}
              >
                Why Choose This Course
              </label>
              <div className="flex items-center gap-2">
                <input
                  type="text"
                  id="why_choose_this_course"
                  name="why_choose_this_course"
                  value={chooseCourse}
                  onChange={(e) => {
                    setChooseCourse(e.target.value);
                  }}
                  className={`mt-1 block w-full px-3 py-2 border ${
                    formErrors.chooseCourse
                      ? "border-red-600"
                      : "border-gray-300"
                  } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  placeholder="Enter reasons, separated by commas"
                />
                <IoMdAddCircle
                  onClick={() => {
                    handleChooseCourse();
                  }}
                  className="cursor-pointer"
                  size={25}
                  color="#846cf9"
                />
              </div>

              <div className="mt-2 rounded-lg bg-gray-50 px-2 py-2 ">
                <ul className="flex flex-wrap items-center gap-1">
                  {chooseCourseArr?.map((each, index) => {
                    return (
                      <li
                        key={index}
                        className="flex items-center gap-1 rounded-full bg-customPurple px-2 py-1 text-sm text-white"
                      >
                        {each.substring(0, 30)}
                        <IoCloseSharp
                          onClick={() => {
                            removeCourseArr(each, index);
                          }}
                          size={20}
                          className="font-semibold cursor-pointer"
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            {/* Submit Button */}
            <div className="col-span-2 flex justify-center">
              <button
                type="submit"
                className={`w-[7rem] px-4 py-2 ${
                  load ? "bg-gray-400" : "bg-customPurple"
                }  text-white font-semibold rounded-md shadow-sm hover:bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
              >
                {load ? "Submiting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditCourseModal;
