// import React from "react";
// import HeadingWithDot from "../common/HeadingWithDot";
// import { BiSolidRightArrow } from "react-icons/bi";
// import data from "../../CourseData.json";
// import { Link } from "react-router-dom";

// const OurServices = () => {

//   const formatTitleForUrl = (title) => {
//     return title
//       .toLowerCase()
//       .replace(/\s+/g, "-") // Replace spaces with hyphens
//       .replace(/&/g, "and") // Replace & with "and"
//       .replace(/_/g, "-");  // Replace underscores with hyphens
//   };

//   return (
//     <div className="mt-20">
//       <div className="flex justify-center">
//         <HeadingWithDot name="Our Services" />
//       </div>
//       <h1 className="md:text-4xl text-2xl tracking-wider text-center mt-2">
//         Services We Provide
//       </h1>
//       <div className="mt-10 grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8">
//         {data?.slice(0,6).map((ele) => (
//           <Link to={ele?.path} className="border border-gray-300 p-3 rounded-xl">
//             <img
//               src={ele?.image}
//               alt="Services Image"
//               className="rounded-md w-full h-[250px]"
//             />
//             <h1 className="font-bold mt-4 tracking-wide text-TextPrimaryColor">
//               {ele?.title}
//             </h1>
//             <p className="mt-3 tracking-wide text-sm leading-7 text-TextSecondaryColor truncate">
//               {ele?.description}
//             </p>

//             <div className="flex items-center justify-end gap-2 mt-4 cursor-pointer">
//               <button className="text-[#034D8E]">View Details </button>
//               <BiSolidRightArrow color="#034D8E" />
//             </div>
//           </Link>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default OurServices;

import React, { useEffect } from "react";
import HeadingWithDot from "../common/HeadingWithDot";
import { BiSolidRightArrow } from "react-icons/bi";
import applicationDev from "../../Assets/Home/Application-development.png";
import buisnessImg from "../../Assets/Home/Business-analysis.png";
import microsoftImg from "../../Assets/Home/Microsoft-services.png";
import businessIntelligence from "../../Assets/Home/Business-intelligence.png";
import itStaffing from "../../Assets/Home/IT-staffing.png";
import qaTesting from "../../Assets/Home/QA-testing.png";
import dataVisualization from "../../Assets/Home/Datavisualization.png";
import ethicalHacking from "../../Assets/Home/Ethical-hacking.png";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const OurServices = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const formatTitleForUrl = (title) => {
    return title
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/&/g, "and") // Replace & with "and"
      .replace(/_/g, "-"); // Replace underscores with hyphens
  };

  // Course data
  let data = [
    {
      id: 1,
      title: "APPLICATION DEVELOPMENT",
      description:
        "Benefit from the latest innovations by modernizing your legacy apps into agile systems and thus drive your business's growth.",
      rating: 4.8,
      reviews: 2500,
      publishedBy: "DesignPro Academy",
      hoursOfCourse: 25,
      totalAssignments: 10,
      videoLessons: 150,
      studentsEnrolled: 12000,
      category: "UI/UX Design",
      image: applicationDev,
      path: "/services/application-development",
    },
    {
      id: 2,
      title: "BUSINESS ANALYSIS",
      description:
        "Drive growth by analyzing and optimizing your business processes with cutting-edge strategies and tools.",
      rating: 4.7,
      reviews: 3400,
      publishedBy: "CodeMasters",
      hoursOfCourse: 45,
      totalAssignments: 20,
      videoLessons: 200,
      studentsEnrolled: 50000,
      category: "Web Development",
      image: buisnessImg,
      path: "/services/business-analysis-service",
    },
    {
      id: 3,
      title: "MICROSOFT SERVICES",
      description:
        "Accelerate your business's growth by leveraging Microsoft technologies to modernize and optimize your legacy systems.",
      rating: 4.9,
      reviews: 5200,
      publishedBy: "DataScience Hub",
      hoursOfCourse: 40,
      totalAssignments: 12,
      videoLessons: 180,
      studentsEnrolled: 35000,
      category: "Data Science",
      image: microsoftImg,
      path: "/services/microsoft-services",
    },
    {
      id: 4,
      title: "IT STAFFING & DELIVERY MODEL",
      description:
        "Optimize your business operations with Techmentee's flexible IT staffing and delivery models. We provide the right talent at the right time.",
      rating: 4.6,
      reviews: 2200,
      publishedBy: "SecurityPro",
      hoursOfCourse: 30,
      totalAssignments: 8,
      videoLessons: 130,
      studentsEnrolled: 15000,
      category: "Cyber Security",
      image: itStaffing,
      path: "/services/it-staffing-delivery-model",
    },
    {
      id: 5,
      title: "BUSINESS INTELLIGENCE",
      description:
        "Leverage the power of data analytics to drive informed decisions and accelerate your business growth.",
      rating: 4.9,
      reviews: 4500,
      publishedBy: "CreativeDesign",
      hoursOfCourse: 20,
      totalAssignments: 6,
      videoLessons: 90,
      studentsEnrolled: 10000,
      category: "business",
      image: businessIntelligence,
      path: "/services/business-intelligence-and-data-analytics",
    },
    {
      id: 6,
      title: "QUALITY ASSURANCE & TESTING",
      description:
        "Ensure the highest quality and performance of your software with our comprehensive QA & Testing services.",
      rating: 4.8,
      reviews: 6000,
      publishedBy: "WebDev Academy",
      hoursOfCourse: 60,
      totalAssignments: 25,
      videoLessons: 250,
      studentsEnrolled: 45000,
      category: "Web Development",
      image: qaTesting,
      path: "/services/qa-testing",
    },
    {
      id: 7,
      title: "Mastering Data Visualization with Python",
      description:
        "Learn how to visualize complex data sets with Python using libraries like Matplotlib and Seaborn.",
      rating: 4.7,
      reviews: 2100,
      publishedBy: "DataViz Academy",
      hoursOfCourse: 35,
      totalAssignments: 15,
      videoLessons: 160,
      studentsEnrolled: 12000,
      category: "Data Science",
      image: dataVisualization,
    },
    {
      id: 8,
      title: "Ethical Hacking and Cyber Security Training",
      description:
        "Become an ethical hacker by learning penetration testing, risk assessment, and more.",
      rating: 4.5,
      reviews: 3100,
      publishedBy: "HackPro",
      hoursOfCourse: 50,
      totalAssignments: 18,
      videoLessons: 220,
      studentsEnrolled: 20000,
      category: "Cyber Security",
      image: ethicalHacking,
    },
  ];

  // Array of AOS animations to cycle through
  const animations = [
    "fade-up",
    "zoom-in",
    "flip-left",
    "fade-right",
    "fade-down",
    "zoom-out",
  ];

  return (
    <div className="mt-20">
      <div className="flex justify-center">
        <HeadingWithDot name="Our Services" />
      </div>
      <h1 className="md:text-4xl text-2xl tracking-wider text-center mt-2 opensans font-bold">
        Services We Provide
      </h1>
      <div className="mt-10 grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-3">
        {data?.slice(0, 8).map((ele, index) => (
          <Link
            to={ele?.path}
            className="border-2 border-[#E7E5EA] p-3 rounded-xl bg-[#fff] relative"
            data-aos={animations[index % animations.length]} // Different AOS animation for each card
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            key={ele?.id}
          >
            <div className="h-44">
              <img
                src={ele?.image}
                alt="Services Image"
                className="rounded-md h-full w-full object-cover"
                data-aos="zoom-in" // Optional separate animation for images
                data-aos-duration="800"
              />
            </div>
            <div>
              <div className="h-36">
                <h2 className="text-lg text-[#212529] mt-2 font-semibold uppercase opensans">
                  {ele?.title}
                </h2>
                <p className="text-sm text-[#7B7E86] montserrat text-justify mt-2">
                  {ele?.description.length > 100
                    ? ele.description.substring(0, 100) + "..."
                    : ele.description}
                </p>
              </div>
              <div className="mt-4 flex items-center justify-end gap-2 text-[#034D8E] absolute right-2 bottom-4">
                <p className="montserrat font-semibold text-sm md:text-base">
                  View Details{" "}
                </p>
                <BiSolidRightArrow />
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default OurServices;
