import React, { useEffect, useState } from "react";
import HeadingWithDot from "../common/HeadingWithDot";
import TechTypeImageHome from "../../Assets/Home/TechTypeImageHome.png";
import aboutUsGif from "../../Assets/Home/aboutusGif.gif";
import aboutUsImage from "../../Assets/Home/aboutusImage.jpg";
import { IoCheckmarkCircle } from "react-icons/io5";
import AOS from "aos";
import "aos/dist/aos.css";

const AboutUs = () => {
  // State to keep track of active tab
  const [activeTab, setActiveTab] = useState("aboutKgs");

  // Initialize AOS
  useEffect(() => {
    AOS.init();
  }, []);

  // Tab content
  const renderTabContent = () => {
    switch (activeTab) {
      case "aboutKgs":
        return (
          <div data-aos="fade-up" data-aos-duration="1000">
            <p className="text-TextSecondaryColor text-sm tracking-wide leading-8">
              KGS TECHNOLOGY GROUP, headquartered in Alpharetta, Georgia, is a
              leading multinational IT Consulting company that has successfully
              served the business community for over five years in onshore and
              over ten years offshore. Since its incorporation, KGS TECHNOLOGY
              GROUP has provided a wide variety of excellent customized IT
              solutions to its customers on time and on budget. KGS TECHNOLOGY
              GROUP has proven itself as an industry leader.
            </p>
            <div className="mt-4 flex flex-col gap-2">
              <div className="flex items-center gap-2">
                <IoCheckmarkCircle className="text-primaryColor" size={20} />
                <p className="text-TextPrimaryColor tracking-wider">
                  Comprehensive UI/UX Assessment
                </p>
              </div>
              <div className="flex items-center gap-2">
                <IoCheckmarkCircle className="text-primaryColor" size={20} />
                <p className="text-TextPrimaryColor tracking-wider">
                  Deep Contextual Research and 360° Planning
                </p>
              </div>
              <div className="flex items-center gap-2">
                <IoCheckmarkCircle className="text-primaryColor" size={20} />
                <p className="text-TextPrimaryColor tracking-wider">
                  Wireframing & Prototyping
                </p>
              </div>
            </div>
          </div>
        );
      case "workForKgs":
        return (
          <div data-aos="fade-left" data-aos-duration="1000">
            <p className="text-TextSecondaryColor text-sm tracking-wide leading-8 ">
              If you're asking whether the phrasing implies that KGS Technology
              Group is working directly with the U.S. market, the wording
              "onshore" and "offshore" suggests that KGS is working both within
              the U.S. (onshore) and internationally (offshore). The company
              serves clients in both regions, implying a global presence with
              operations that likely include the U.S. market.
            </p>
            <div className="mt-4 flex flex-col gap-2">
              <div className="flex items-center gap-2">
                <IoCheckmarkCircle className="text-primaryColor" size={20} />
                <p className="text-TextPrimaryColor tracking-wider">
                  Onshore: Refers to projects and clients based in the U.S.,
                  where the company is headquartered.
                </p>
              </div>
              <div className="flex items-center gap-2">
                <IoCheckmarkCircle className="text-primaryColor" size={20} />
                <p className="text-TextPrimaryColor tracking-wider">
                  Offshore: Refers to projects managed outside the U.S., showing
                  their global reach and ability to work with international
                  clients or outsourcing.
                </p>
              </div>
            </div>
          </div>
        );
      case "companyHistory":
        return (
          <div data-aos="fade-right" data-aos-duration="1000">
            <p className="text-TextSecondaryColor text-sm tracking-wide leading-8">
              Georgia, KGS Technology Group started by providing tailored IT
              solutions to U.S. clients. Within five years, it successfully
              expanded its onshore operations while establishing a strong
              offshore presence in India.
            </p>
            <p className="text-TextSecondaryColor text-sm tracking-wide leading-8 ">
              KGS quickly grew by delivering projects on time and within budget,
              gaining recognition across diverse industries. The company
              embraced cutting-edge technologies and broadened its offerings,
              including UI/UX design, cloud services, and software development.
              With global training and recruitment centers, KGS continues to
              focus on innovation, quality, and empowering its highly skilled
              workforce to meet evolving client needs.
            </p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="mt-10 grid lg:grid-cols-2 grid-cols-1 gap-8">
      <div
        className="xl:pr-20 relative md:h-[510px] "
        data-aos="zoom-in"
        data-aos-duration="1000"
      >
        <img
          src={aboutUsGif}
          alt="About_us_image"
          className="h-[90%] lg:h-[80%] xl:h-[90%] z-0"
        />
        <img
          src={aboutUsImage}
          alt="About_us_image"
          className="z-10 absolute bottom-0 left-[30%] lg:left-[20%] xl:left-[40%] h-[50%] rounded-lg"
        />
      </div>
      <div className="" data-aos="fade-up" data-aos-duration="1000">
        <HeadingWithDot name="About Us" />
        <h1 className="md:text-4xl text-2xl tracking-wider font-semibold md:leading-[60px] leading-[40px] mt-2">
          Best Talent, Competitive Cost,
          <span className="text-primaryColor"> Incredible</span> IT Service
        </h1>
        <div className="mt-4">
          <button
            className={`${
              activeTab === "aboutKgs"
                ? "text-primaryColor border-b-TextPrimaryColor border-t-TextPrimaryColor border-r-0 border-l-0 border py-2"
                : ""
            }`}
            onClick={() => setActiveTab("aboutKgs")}
          >
            About KGS
          </button>
          <button
            className={`${
              activeTab === "workForKgs"
                ? "text-primaryColor border-b-TextPrimaryColor border-t-TextPrimaryColor border-r-0 border-l-0 border py-2"
                : ""
            } ml-4`}
            onClick={() => setActiveTab("workForKgs")}
          >
            Work for KGS
          </button>
          <button
            className={`${
              activeTab === "companyHistory"
                ? "text-primaryColor border-b-TextPrimaryColor border-t-TextPrimaryColor border-r-0 border-l-0 border py-2"
                : ""
            } ml-4`}
            onClick={() => setActiveTab("companyHistory")}
          >
            Company History
          </button>
        </div>
        <div className="mt-4 text-justify">
          {renderTabContent()}
          {/* <img
            src={TechTypeImageHome}
            alt="TechType"
            className="absolute mt-[-220px] right-[0px] md:flex hidden opacity-30"
            data-aos="fade-up"
          /> */}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
