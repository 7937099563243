import React from 'react';

import img1 from '../../Assets/ApplicationDevelopment/Mask group.png';
import img2 from '../../Assets/ApplicationDevelopment/Mask group (2).png';
import img3 from '../../Assets/ApplicationDevelopment/Mask group (3).png';

const DataEfficiency = () => {
  return (
    <div className='flex flex-wrap w-[90%] mx-auto justify-between py-6'>
      <div className='w-full sm:w-[45%] md:w-[22%] p-4 text-center'>
        <img src={img1} alt='Data Efficiency' className='mx-auto mb-4'/>
        <h1 className='text-xl font-semibold'>Data Efficiency</h1>
      </div>
      <div className='w-full sm:w-[45%] md:w-[22%] p-4 text-center'>
        <img src={img2} alt='Scalability & Flexibility' className='mx-auto mb-4'/>
        <h1 className='text-xl font-semibold'>Scalability & Flexibility</h1>
      </div>
      <div className='w-full sm:w-[45%] md:w-[22%] p-4 text-center'>
        <img src={img3} alt='Data Security & Compliance' className='mx-auto mb-4'/>
        <h1 className='text-xl font-semibold'>Data Security & Compliance</h1>
      </div>
      <div className='w-full sm:w-[45%] md:w-[22%] p-4 text-center'>
        <img src={img1} alt='Data Integration & Customization' className='mx-auto mb-4'/>
        <h1 className='text-xl font-semibold'>Data Integration & Customization</h1>
      </div>
    </div>
  );
}

export default DataEfficiency;
