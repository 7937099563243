import React from "react";
import img1 from "../../Assets/ResumeReview/Frame1.png";
import img2 from "../../Assets/ResumeReview/Frame2.png";
import img3 from "../../Assets/ResumeReview/Frame3.png";

const Highlights = () => {
  const features = [
    {
      id: 1,
      title: "Internships with Purpose",
      description: "Shaped around your career goals and interests.",
      icon: img1,
    },
    {
      id: 2,
      title: "Remote Opportunities",
      description:
        " Participate from anywhere, offering flexibility and convenience",
      icon: img2,
    },
    {
      id: 3,
      title: "Diverse Fields",
      description:
        "Explore opportunities in various industries, including IT, marketing, finance, and more.",
      icon: img3,
    },
  ];

  return (
    <div className="bg-white pt-10 px-5 md:px-20">
      {/* Section Title */}
      <h2 className="text-center text-2xl md:text-3xl font-medium text-gray-800 ">
        Highlights
      </h2>

      {/* Features */}
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
        {features.map((feature) => (
          <div
            key={feature.id}
            className="flex flex-col items-center text-center w-full px-4 py-6 tracking-wide"
          >
            <img
              src={feature.icon}
              alt={feature.title}
              className="w-12 h-12 mb-4"
            />
            <h3 className="text-xl font-medium text-gray-800 mb-2">
              {feature.title}
            </h3>
            <p className="text-[#737588]">{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Highlights;
