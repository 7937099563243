import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import PartnerBackground from "../../Assets/AboutUs/PartnerBackground.png";
import Duragas from "../../Assets/AboutUs/Duragas.png";
import Turbologo from "../../Assets/AboutUs/Turbologo.png";
import Logotype from "../../Assets/AboutUs/Logotype.png";
import Safeguard from "../../Assets/AboutUs/Safeguard.png";
import Terreno from "../../Assets/AboutUs/Terreno.png";

const Clients = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with custom duration
  }, []);

  return (
    <div
      className="text-white text-center md:py-20 py-10"
      style={{
        backgroundImage: `url(${PartnerBackground})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left",
      }}
      data-aos="fade-up" // AOS effect for the parent container
    >
      <p className="tracking-wide" data-aos="fade-down">
        Clients
      </p>
      <h1 className="text-2xl tracking-wider mt-3" data-aos="fade-down">
        Trusted Partners
      </h1>
      <div
        className="flex flex-wrap justify-center mt-6 gap-6"
        data-aos="fade-up"
      >
        <img src={Duragas} alt="Duragas" className="h-16" data-aos="zoom-in" />
        <img src={Turbologo} alt="Turbologo" className="h-16" data-aos="zoom-in" />
        <img src={Logotype} alt="Logotype" className="h-16" data-aos="zoom-in" />
        <img src={Safeguard} alt="Safeguard" className="h-16" data-aos="zoom-in" />
        <img src={Terreno} alt="Terreno" className="h-16" data-aos="zoom-in" />
      </div>
    </div>
  );
};

export default Clients;
