import React from "react";
import SimpleBanner from "../../../components/common/SimpleBanner";
import AboutUs_banner from "../../../Assets/AboutUs/AboutUs_banner.png";
import IndependentInternship from "../../../components/Internship/IndependentInternship";
import ContactUsForm from "../../../components/home/ContactUsForm";
import IndependentInternshipPlans from "../../../components/programs/IndependentInternshipPlans";
import Highlights from "../../../components/Internship/Highlights";
const Internship = () => {
  return (
    <div className="overflow-x-hidden overflow-y-hidden">
      <SimpleBanner
        image={AboutUs_banner}
        name="Independent Internship"
        path="Home / Programs"
      />
      <IndependentInternship />
      <Highlights />
      <IndependentInternshipPlans />
      <div className="mx-4 lg:mx-16">
        <ContactUsForm />
      </div>
    </div>
  );
};

export default Internship;
