import React from 'react';
import { Document, Packer, Paragraph, TextRun } from 'docx';

const CoverLetterModal = ({ isOpen, onClose, coverLetter,selectedJob }) => {
  const handleDownloadWord = async () => {
    // Create a new Document
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: selectedJob?.cover_letter,
                  font: "Arial",
                }),
              ],
            }),
          ],
        },
      ],
    });

    // Generate the Word document
    const blob = await Packer.toBlob(doc);
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${selectedJob?.candidate_name}_cover-letter.docx`;
    a.click();
    URL.revokeObjectURL(url);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
      <div className="bg-white p-6 rounded shadow-lg w-[90vw] max-w-[800px] relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
        >
          &times;
        </button>
        <h2 className="text-xl font-semibold mb-4">Cover Letter</h2>
        <p className="mb-4">{selectedJob?.cover_letter}</p>
        <button
          onClick={handleDownloadWord}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Download Cover Letter as Word
        </button>
      </div>
    </div>
  );
};

export default CoverLetterModal;
