import React from "react";
import TopImg from "../../Assets/picimgs/TopImg.png";
function TopSection() {
  return (
    <div className="mx-6 md:mx-16 my-6 md:my-16">
      <div className="grid grid-cols-1 lg:grid-cols-2 items-center">
        <div className="flex flex-col">
          <h1 className="text-3xl md:text-4xl font-bold text-[#101A29] capitalize tracking-wide mb-8">
            "From search to <span className="text-[#0632D0]">Success</span>"
          </h1>
          <p className="text-[#737588] w-full md:w-[80%] tracking-wide leading-relaxed mb-4">
            Dive into new career opportunities with Full-time Job Assistance
            Programme, offering personalized job search strategies, expert
            feedback, and exclusive connections to top employers
          </p>

          <p className="text-[#737588] w-full md:w-[80%] tracking-wide leading-relaxed">
            This is an innovative strategy that empowers job seekers to
            dramatically increase their chances of landing their dream job. As a
            team at KGS, we will utilize the best technology, strategy,
            resources, and services to help you optimize and navigate your job
            search. The purpose of this program is to transform the job hunt
            process for job seekers which empowers individuals to find
            fulfilling careers that align with their passions, skills, and
            aspirations.
          </p>
        </div>
        <img src={TopImg} alt="Banner Image" className="object-cover w-full" />
      </div>
    </div>
  );
}

export default TopSection;
