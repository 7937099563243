import React from "react";
import img1 from "../../Assets/ApplicationDevelopment/image.png";
import img2 from "../../Assets/ApplicationDevelopment/image (1).png";
import img3 from "../../Assets/ApplicationDevelopment/image (2).png";
const Development = () => {
  return (
    <div className="bg-white py-10 px-5 md:px-20">
      <div className="text-center">
        <h1 className="text-3xl md:text-5xl font-bold">
          APPLICATION <span className="text-orange-500">DEVELOPMENT</span> &amp;
          MODERNIZATION
        </h1>
        <p className="mt-5 text-lg md:text-xl text-gray-600">
          Benefit from the latest innovations by modernizing your legacy apps
          into agile systems and thus drive your business's growth.
        </p>
      </div>

      <div className="mt-8 text-gray-600 text-center mx-auto">
        <p>
          Agile app architecture is one of the key enablers of a business's
          success. As budgets shrink and app environments become more
          sophisticated, you wouldn't want your customers to disconnect because
          of unexpected downtime; that's why having the best software company in
          Atlanta, GA, USA, onboard pays off. Techmentee recognizes that
          leveraging present systems while shifting to advanced technologies
          demands substantial planning and deep understanding. That's why our
          experts extensively optimize your current systems to help you minimize
          your investments while you seamlessly migrate to advanced
          technologies.
        </p>
      </div>

      <div className="mt-10 flex flex-col md:flex-row items-center justify-center gap-5">
        <img
          src={img1}
          alt="HTML and Laptop"
          className="rounded-lg w-full md:w-1/3 object-cover"
        />
        <img
          src={img2}
          alt="Mobile with Apps"
          className="rounded-lg w-full md:w-1/3 object-cover"
        />
        <img
          src={img3}
          alt="Code and Screen"
          className="rounded-lg w-full md:w-1/3 object-cover"
        />
      </div>
    </div>
  );
};

export default Development;
