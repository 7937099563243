import React, { useEffect, useRef, useState } from "react";
import {
  Link,
  useLoaderData,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
import { motion, AnimatePresence } from "framer-motion";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { GiHamburgerMenu } from "react-icons/gi";
import Logo from "../../Assets/Kgs_Logo.png";
import { useAuth } from "../../context/AuthContext";
import { FaCartPlus } from "react-icons/fa";
import { FaSpinner } from "react-icons/fa";
import { HiUserCircle } from "react-icons/hi2";
import axios from "axios";
import encryptionModule from "../../components/common/localStorageUtils";
import aboutIcon from "../../Assets/picimgs/about-icon.svg";
import hoverAboutIcon from "../../Assets/picimgs/aboutHover-icon.svg";
import coursesIcon from "../../Assets/picimgs/courses-icon.svg";
import hoverCoursesIcon from "../../Assets/picimgs/coursesHover-icon.svg";
import programIcon from "../../Assets/picimgs/program-icon.svg";
import hoverProgramIcon from "../../Assets/picimgs/programHover-icon.svg";
import servicesIcon from "../../Assets/picimgs/services-icon.svg";
import hoverServicesIcon from "../../Assets/picimgs/servicesHover-icon.svg";
import industryIcon from "../../Assets/picimgs/industry-icon.svg";
import hoverIndustryIcon from "../../Assets/picimgs/industryHover-icon.svg";
import contactIcon from "../../Assets/picimgs/contact-icon.svg";
import hoverContactIcon from "../../Assets/picimgs/contactHover-icon.svg";
import careerIcon from "../../Assets/picimgs/career-icon.svg";
import hoverCareerIcon from "../../Assets/picimgs/careerHover-icon.svg";

const BottomNavbar = () => {
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const [courseData, setCourseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cartLength, setCartLength] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const location = useLocation();
  const { isUserLogin, logout } = useAuth();
  const [cartData, setCartData] = useState({
    courses: [],
    packages: [],
    resumeReviews: [],
  });
  const [isProfileClicked, setIsProfileClicked] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);

  const User = encryptionModule.decryptData("userInfo")?.user;

  const fetchCartData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_KEY}/cart/get-user-carts/${User?._id}`
      );
      setCartData(response.data);
      console.log("Response of fetchCartData", response.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (User?._id) {
      fetchCartData();
    }
  }, []);

  useEffect(() => {
    setCartLength(
      cartData.courses.length +
        cartData.packages.length +
        cartData.resumeReviews.length
    );
  }, [cartData]);

  console.log("Length of cartData: ", cartLength);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
        setActiveMenuItem(null);
        setIsProfileClicked(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [activeMenuItem]);

  const toggleProfileOptions = () => {
    setIsProfileClicked(!isProfileClicked);
  };

  const toggleDropdownOptions = (menuItem) => {
    setActiveMenuItem(activeMenuItem === menuItem ? null : menuItem);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleItemClick = (path) => {
    navigate(path);
    setMenuOpen(false);
  };

  const formatTitleForUrl = (title) => {
    return title
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/&/g, "and") // Replace & with "and"
      .replace(/_/g, "-") // Replace underscores with hyphens
      .replace(/\//g, "-");
  };

  const getAllCourse = () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/courses/get-all-course`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Courses on navbar: ", response?.data?.courses);
        const simplifiedCourses = response?.data?.courses.map((course) => ({
          title: course.title,
          path: `/course/${formatTitleForUrl(course?.title)}/${course._id}`,
        }));
        setCourseData(simplifiedCourses);
        console.log(simplifiedCourses);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllCourse();
  }, []);

  const menuItems = [
    {
      title: "Home",
      path: "/",
    },
    {
      title: "About Us",
      path: "/about-us",
      icon: <img src={aboutIcon} alt="home" />,
      hoverIcon: <img src={hoverAboutIcon} alt="home" />,
    },
    {
      title: "Courses",
      icon: <img src={coursesIcon} alt="courses" />,
      hoverIcon: <img src={hoverCoursesIcon} alt="courses" />,
      options: courseData,
    },
    {
      title: "Programs",
      icon: <img src={programIcon} alt="program" />,
      hoverIcon: <img src={hoverProgramIcon} alt="program" />,
      options: [
        {
          title: "Full time job assistance program",
          path: "/program/full-time-job-assistance-program",
        },
        { title: "Remote Boot Camp", path: "/program/remote-boot-camp" },
        {
          title: "Mock Interview And Grooming Hub",
          path: "/program/mock-interview-coaching",
        },
        { title: "Resume Workshop", path: "/program/ResumeReview" },
        {
          title: "Independent Internship",
          path: "/program/internship",
        },
      ],
    },
    {
      title: "Services",
      icon: <img src={servicesIcon} alt="services" />,
      hoverIcon: <img src={hoverServicesIcon} alt="services" />,
      options: [
        {
          title: "Application Development",
          path: "/services/application-development",
        },
        { title: "Microsoft Services", path: "/services/microsoft-services" },
        {
          title: "Business Analysis Service",
          path: "/services/business-analysis-service",
        },
        {
          title: "Business Intelligence and Data Analytics",
          path: "/services/business-intelligence-and-data-analytics",
        },
        { title: "QA & Testing", path: "/services/qa-testing" },
        {
          title: "IT Staffing & Delivery Model",
          path: "/services/it-staffing-delivery-model",
        },
        {
          title: "SCOD-Specialty Consultant on Demand",
          path: "/services/scod-specialty-consultant-on-demand",
        },
        { title: "KGS’s Product Suite", path: "/services/kgss-product-suite" },
      ],
    },
    // {
    //   title: "Knowledge Center",
    //   path: "/knowledge-center",
    //   icon: <PiBrainLight size={20} />,
    // },
    // {
    //   title: "Support",
    //   path: "/support",
    //   icon: <TbSettingsSearch size={20} />,
    // },
    {
      title: "Industry",
      icon: <img src={industryIcon} alt="industry" />,
      hoverIcon: <img src={hoverIndustryIcon} alt="industry" />,
      options: [
        { title: "Health Care", path: "/industry/health-care" },
        { title: "Automotive", path: "/industry/automotive" },
        { title: "Banking", path: "/industry/banking" },
        { title: "E-commerce", path: "/industry/e-commerce" },
        { title: "Government", path: "/industry/government" },
        { title: "Infrastructure", path: "/industry/infrastructure" },
        { title: "Real Estate", path: "/industry/realEstate" },
        { title: "Telecom", path: "/industry/telecom" },
      ],
    },
    ,
    {
      title: "Contact",
      path: "/contact",
      icon: <img src={contactIcon} alt="contact" />,
      hoverIcon: <img src={hoverContactIcon} alt="contact" />,
    },
    {
      title: "Career",
      path: "/program/evaluate-your-career/view-job-openings",
      icon: <img src={contactIcon} alt="contact" />,
    },
  ];

  const isActive = (itemPath) => {
    if (itemPath === "/") {
      return location.pathname === "/";
    }
    return location.pathname.startsWith(itemPath);
  };

  const isMenuItemActive = (item) => {
    if (item.options) {
      return item.options.some((option) => isActive(option.path));
    }
    return isActive(item.path);
  };

  return (
    <div>
      <div className="px-20 py-3 xl:flex hidden items-center justify-between bg-white w-full">
        <Link to="/">
          <img src={Logo} alt="Logo" className="w-[120px] h-auto" />
        </Link>
        <div className="flex gap-8 items-center montserrat font-medium">
          {menuItems.map((item, index) => (
            <div
              key={index}
              className={`flex hover:text-primaryColor items-center gap-1 cursor-pointer relative ${
                isMenuItemActive(item) ? "text-primaryColor" : ""
              }`}
              onClick={() =>
                item.options
                  ? toggleDropdownOptions(item.title)
                  : handleItemClick(item.path)
              }
              onMouseEnter={() => setHoveredItem(item.title)}
              onMouseLeave={() => setHoveredItem(null)}
            >
              {(hoveredItem === item.title || isMenuItemActive(item)) &&
              item.hoverIcon
                ? item.hoverIcon
                : item.icon}
              {item.title}
              {item.options &&
                (activeMenuItem === item.title ? (
                  <IoIosArrowUp size={20} className="mt-1" />
                ) : (
                  <IoIosArrowDown size={20} className="mt-1" />
                ))}
              <AnimatePresence>
                {activeMenuItem === item.title && item.options && (
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    ref={menuRef}
                    className="absolute bg-white text-black z-40 top-[40px] left-0 shadow-lg rounded-b-xl overflow-hidden w-[250px] max-h-[360px] overflow-y-scroll decrease-scrollbar-width"
                  >
                    {loading ? (
                      <FaSpinner
                        className="animate-spin text-primaryColor text-center"
                        size={24}
                      />
                    ) : (
                      item.options.map((option, index) => (
                        <motion.div
                          key={index}
                          className={`px-8 py-2 cursor-pointer hover:bg-[#CC8302] hover:text-white ${
                            isActive(option.path)
                              ? "bg-primaryColor text-white"
                              : ""
                          }`}
                          onClick={() => handleItemClick(option.path)}
                        >
                          {option.title}
                        </motion.div>
                      ))
                    )}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          ))}
          {isUserLogin ? (
            <>
              <button onClick={toggleProfileOptions}>
                <HiUserCircle size={24} />
              </button>
              {isProfileClicked && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  ref={menuRef}
                  className="absolute bg-white text-black z-40 top-[70px] right-0 shadow-lg rounded-b-xl overflow-hidden w-[300px] max-h-[360px]"
                >
                  <div className="p-4 flex flex-col items-center gap-4 bg-gray-200 rounded-lg">
                    <h1 className="text-center">
                      Hey <span className="text-primaryColor">{User.name}</span>
                    </h1>{" "}
                    <div className="flex justify-between items-end gap-4 w-full">
                      <button
                        onClick={() => navigate("/profile")}
                        className="border-primaryColor border px-4 py-2 rounded-lg"
                      >
                        Profile
                      </button>{" "}
                      <button onClick={logout} className="text-red-500">
                        Logout
                      </button>
                    </div>
                  </div>
                </motion.div>
              )}
              <button
                className="relative p-4"
                onClick={() => navigate("/cart")}
              >
                {/* <p className="absolute top-0 righ">{cartLength}</p> */}
                <FaCartPlus size={24} />
                <h1 className="absolute top-0 right-0 text-primaryColor rounded-full border-2 w-5 h-5 border-primaryColor items-center text-center text-[12px] ">
                  {cartLength}
                </h1>
              </button>
            </>
          ) : (
            <button
              onClick={() => {
                navigate("/login");
                window.scrollTo(0, 0);
              }}
              className="bg-[#ECAB06] hover:bg-[#CC8302] active:bg-[#A35C05] text-[#161616] font-semibold px-8 py-3 rounded-md text-sm"
            >
              Sign in
            </button>
          )}
        </div>
      </div>

      {/* Mobile Menu */}
      <div className="bg-white px-10 py-3 xl:hidden flex items-center justify-between shadow-lg">
        <div>
          <img src={Logo} alt="Logo" className="w-[100px] h-auto" />
        </div>
        <div onClick={toggleMenu} className="cursor-pointer">
          <GiHamburgerMenu size={30} color="#F8B003" />
        </div>
      </div>
      <AnimatePresence>
        {menuOpen && (
          <div className="fixed inset-0 bg-transparent flex justify-end z-50">
            <motion.div
              initial={{ opacity: 0, scale: 0.9, x: 100 }}
              animate={{ opacity: 1, scale: 1, x: 0 }}
              exit={{ opacity: 0, scale: 0.9, x: 100 }}
              transition={{ duration: 0.2 }}
              ref={menuRef}
              className="bg-white shadow-lg text-black p-5 w-[60%] flex flex-col justify-between"
            >
              <div>
                {menuItems.map((item, index) => (
                  <div
                    key={index}
                    className={`flex flex-col items-start gap-1 relative ${
                      isMenuItemActive(item) ? "text-yellow-500" : ""
                    }`}
                    onClick={() =>
                      item.options
                        ? toggleDropdownOptions(item.title)
                        : handleItemClick(item.path)
                    }
                  >
                    <div className="flex items-center mb-3 cursor-pointer text-[20px]">
                      {item.title}
                      {item.options &&
                        (activeMenuItem === item.title ? (
                          <RiArrowUpSFill size={20} />
                        ) : (
                          <RiArrowDownSFill size={20} />
                        ))}
                    </div>
                    {activeMenuItem === item.title && item.options && (
                      <motion.div
                        initial={{ opacity: 0, scale: 0.9, y: -10 }}
                        animate={{ opacity: 1, scale: 1, y: 0 }}
                        exit={{ opacity: 0, scale: 0.9, y: -10 }}
                        transition={{ duration: 0.2 }}
                        className="bg-gray-100 text-black shadow-lg overflow-hidden w-full mb-4 max-h-96 overflow-y-auto"
                        style={{ zIndex: 9999, position: "relative" }}
                      >
                        {item.options.map((option, index) => (
                          <motion.div
                            key={index}
                            whileHover={{
                              backgroundColor: "#F8B003",
                              color: "white",
                            }}
                            className={`px-8 py-2 text-[18px] transition duration-200 ease-linear cursor-pointer ${
                              isActive(option.path) ? "text-yellow-500" : ""
                            }`}
                            onClick={() => handleItemClick(option.path)}
                          >
                            {option.title}
                          </motion.div>
                        ))}
                      </motion.div>
                    )}
                  </div>
                ))}

                <ul className="">
                  <li
                    className="text-xl mb-2 cursor-pointer"
                    onClick={() => navigate("/profile")}
                  >
                    Profile
                  </li>
                  <li
                    className="text-xl mb-4 cursor-pointer"
                    onClick={() => navigate("/cart")}
                  >
                    Cart
                  </li>
                </ul>
              </div>

              <div className="flex justify-between items-center">
                {isUserLogin ? (
                  <button
                    onClick={logout}
                    className="bg-red-500 text-white font-medium px-4 py-2 rounded-md"
                  >
                    Log out
                  </button>
                ) : (
                  <button
                    className="bg-yellow-500 text-white px-4 py-2 rounded-md"
                    onClick={() => navigate("/login")}
                  >
                    Sign in
                  </button>
                )}

                <button
                  className="bg-gray-400 text-white px-4 py-2 rounded-md"
                  onClick={() => setMenuOpen(false)}
                >
                  Close
                </button>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default BottomNavbar;
