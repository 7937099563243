import React from 'react'
import { PiDotFill } from "react-icons/pi";

const HeadingWithDot = ({name}) => {
  return (
    <div className='flex items-center gap-2'>
        <PiDotFill color='blue'/>
        <p className="text-sm tracking-wide uppercase">{name}</p>
        <PiDotFill color='blue'/>
    </div>
  )
}

export default HeadingWithDot;