"use client";

import { Truck } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

const ApplyJobModal = ({
  setApplyJobModal,
  JobApply,
  currentJob,
  loading,
  resumeFile,
  setResumeFile,
  userData,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const [error, setError] = useState("");
  const [candidateDetails, setCandidateDetails] = useState({});
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (!file) {
      setError("File is required");
      return;
    }

    const validExtensions = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    if (!validExtensions.includes(file.type)) {
      setError("Invalid file type. Only PDF and DOC allowed.");
      return;
    }

    setError(""); // Clear error if validation passes
    setResumeFile(file); // Store file in state
  };

  // console.log("currentJob---->", currentJob);
  const onSubmit = async (data) => {
    console.log("Form submitted:", data);
    await JobApply(currentJob._id, currentJob?.jobCreatedBy, data);
  };
  useEffect(() => {
    if (userData) {
      setValue("fullName", userData?.user?.name);
      setValue("email", userData?.user?.email);
      setValue("phoneNumber", userData?.user?.phone);
    }
  }, [userData]);

  useEffect(() => {
    if (loading == true) {
      reset({
        fullName: "",
        email: "",
        phoneNumber: "",
      });
    }
  }, [loading]);
  return (
    <div className="fixed z-50 inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-md">
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-xl font-semibold text-gray-900">
            User Information
          </h2>
          <button
            onClick={() => setApplyJobModal(false)}
            className="text-gray-400 hover:text-gray-500"
          >
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="p-6">
          <div className="space-y-4">
            <div>
              <label
                htmlFor="fullName"
                className="block text-sm font-medium text-gray-700"
              >
                Full Name
              </label>
              <input
                id="fullName"
                {...register("fullName", {
                  required: "Full name is required",
                })}
                className="mt-1 block w-full h-12 border p-2 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
              {errors.fullName && (
                <span className="text-red-500 text-xs">
                  {errors.fullName.message}
                </span>
              )}
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <input
                id="email"
                type="email"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Invalid email address",
                  },
                })}
                className="mt-1 block w-full h-12 border p-2 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
              {errors.email && (
                <span className="text-red-500 text-xs">
                  {errors.email.message}
                </span>
              )}
            </div>
            <div>
              <label
                htmlFor="phoneNumber"
                className="block text-sm font-medium text-gray-700"
              >
                Phone Number
              </label>
              <input
                id="phoneNumber"
                type="tel"
                {...register("phoneNumber", {
                  required: "Phone number is required",
                  pattern: {
                    value: /^\d{10}$/,
                    message: "Invalid phone number, should be 10 digits",
                  },
                })}
                className="mt-1 block w-full h-12 border p-2 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
              {errors.phoneNumber && (
                <span className="text-red-500 text-xs">
                  {errors.phoneNumber.message}
                </span>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Upload Resume (PDF or DOC)
              </label>
              <input
                type="file"
                accept=".doc,.docx,.pdf"
                onChange={handleFileChange}
                className="mt-1 block w-full h-12 border p-2 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
              {error && <span className="text-red-500 text-xs">{error}</span>}
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end space-x-3">
            <button
              type="button"
              onClick={() => setApplyJobModal(false)}
              className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Close
            </button>
            <button
              type="submit"
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              {loading ? "Please Wait..." : "Apply"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ApplyJobModal;
