import { Check, X } from "lucide-react";
import visa from "../../Assets/imgs/visa.svg";
import payPal from "../../Assets/imgs/paypal.svg";
import mastercard from "../../Assets/imgs/mastercard.svg";
import maestro from "../../Assets/imgs/maestro.svg";

export default function ResumeWorkShop() {
  return (
    <div className="max-w-7xl mx-auto p-6 items-center flex flex-col">
      <h3 className="text-sm text-yellow-500 font-semibold mb-2">OUR PLAN</h3>
      <h2 className="text-2xl md:text-3xl font-bold mb-8">
        Choose a <span className="text-yellow-500">Plan</span> That Works For
        You
      </h2>
      {/* Grid container for the cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Card 1 */}
        <div
          className="w-full max-w-sm mx-auto overflow-hidden rounded-t-lg"
          data-aos="fade-up"
        >
          {/* Header */}
          <div className="bg-[#D9EAFD]  py-4">
            <h2 className="text-[black] text-2xl font-bold text-center">
              CORE
            </h2>
          </div>

          {/* Price Section */}
          <div className="bg-[#eee129fd] px-6 py-4 font">
            {/* Features List */}
            <div className="mt-4 space-y-4 capitalize">
              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>
                  Personalized one-on-one consultation( Phone Consultation)
                </span>
              </div>

              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Professionally formatted resume</span>
              </div>

              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Keyword optimization for ATS</span>
              </div>
              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>ATS compliant resume writing</span>
              </div>
              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Industry-specific resume designs(Standard)</span>
              </div>

              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Professional summary and career highlights</span>
              </div>

              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>1 Revisions</span>
              </div>
              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Delivery in 5 business days</span>
              </div>
              <div className="flex items-center text-[black] opacity-50">
                <X className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Priority Customer support</span>
              </div>

              <div className="flex items-center text-[black] opacity-50">
                <X className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Thank you letter template</span>
              </div>

              <div className="flex items-center text-[black] opacity-50">
                <X className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Follow Up email template</span>
              </div>

              <div className="flex items-center text-[black] opacity-50">
                <X className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Cover letter template included</span>
              </div>
              <div className="flex items-center text-[black] opacity-50">
                <X className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>LinkedIn profile optimization</span>
              </div>
              <div className="flex items-center text-[black] opacity-50">
                <X className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Expert advice on career gap</span>
              </div>
              <div className="flex items-center text-[black] opacity-50">
                <X className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Interview coaching</span>
              </div>
              <div className="flex items-center text-[black] opacity-50">
                <X className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Job board posting</span>
              </div>
            </div>
            <div className="flex items-center justify-center mt-4">
              <span className="text-white text-6xl font-bold">
                <span className="text-3xl align-top">$</span>
                149
              </span>
            </div>
            {/* Button */}
            <button
              className="w-full mt-4 px-4 py-3 bg-white text-[black] rounded-md font-medium hover:bg-opacity-90 transition-colors"
              onClick={() => {
                document
                  .getElementById("resume-plans")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Select Package
            </button>

            {/* Payment Methods */}
            <div className="flex justify-center items-center mt-8 space-x-4">
              <img src={visa} alt="Visa" className="h-8" />
              <img src={payPal} alt="PayPal" className="h-8" />
              <img src={mastercard} alt="Mastercard" className="h-8" />
              <img src={maestro} alt="Maestro" className="h-8" />
            </div>
          </div>
        </div>

        {/* Duplicate the same card twice */}
        <div
          className="w-full max-w-sm mx-auto overflow-hidden rounded-t-lg"
          data-aos="fade-right"
        >
          {/* The same design as Card 1 */}
          {/* Header */}
          <div className="bg-[#D9EAFD] py-4">
            <h2 className="text-[black] text-2xl font-bold text-center">
              PREMIUM
            </h2>
          </div>

          {/* Price Section */}
          <div className="bg-[#eee129fd] px-6 py-4 font">
            {/* Features List */}
            <div className="mt-4 space-y-4 capitalize">
              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>
                  Personalized one-on-one consultation ( 30 minutes of video
                  conference)
                </span>
              </div>

              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Professionally formatted resume</span>
              </div>

              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Keyword optimization for ATS</span>
              </div>

              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>ATS compliant resume writing</span>
              </div>

              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Industry-specific resume designs (Tailor-made)</span>
              </div>

              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Professional summary and career highlights</span>
              </div>

              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>3 Revision</span>
              </div>

              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Delivery in 3 business days</span>
              </div>

              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Priority Customer support</span>
              </div>

              <div className="flex items-center text-[black] ">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Thank you letter template</span>
              </div>

              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Follow-up email template</span>
              </div>

              <div className="flex items-center text-[black] ">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Cover letter template included</span>
              </div>

              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Basic LinkedIn profile Review</span>
              </div>

              <div className="flex items-center text-[black] ">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Expert advice on career gap</span>
              </div>

              <div className="flex items-center text-[black] opacity-50">
                <X className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Interview coaching</span>
              </div>

              <div className="flex items-center text-[black] opacity-50">
                <X className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Job board posting</span>
              </div>
            </div>
            <div className="flex items-center justify-center mt-4">
              <span className="text-white text-6xl font-bold">
                <span className="text-3xl align-top">$</span>
                199
              </span>
            </div>
            {/* Button */}
            <button
              className="w-full mt-4 px-4 py-3 bg-white text-[black] rounded-md font-medium hover:bg-opacity-90 transition-colors"
              onClick={() => {
                document
                  .getElementById("resume-plans")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Select Package
            </button>

            {/* Payment Methods */}
            <div className="flex justify-center items-center mt-8 space-x-4">
              <img src={visa} alt="Visa" className="h-8" />
              <img src={payPal} alt="PayPal" className="h-8" />
              <img src={mastercard} alt="Mastercard" className="h-8" />
              <img src={maestro} alt="Maestro" className="h-8" />
            </div>
          </div>
        </div>

        <div
          className="w-full max-w-sm mx-auto overflow-hidden rounded-t-lg"
          data-aos="fade-down"
        >
          {/* The same design as Card 1 */}
          <div className="bg-[#D9EAFD] py-4">
            <h2 className="text-[black] text-2xl font-bold text-center">
              PREMIUM PLUS
            </h2>
          </div>

          {/* Price Section */}
          <div className="bg-[#eee129fd] px-6 py-4 font">
            {/* Features List */}
            <div className="mt-4 space-y-4 capitalize">
              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>
                  Personalized one-on-one consultation (60 minutes of video
                  conference)
                </span>
              </div>

              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Professionally formatted resume</span>
              </div>

              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Keyword optimization for ATS</span>
              </div>

              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>ATS compliant resume writing</span>
              </div>

              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Industry-specific resume designs (custom creative)</span>
              </div>

              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Professional summary and career highlights</span>
              </div>

              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Unlimited revisions for 15 days</span>
              </div>

              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Delivery in 2 business days</span>
              </div>

              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Priority Customer support</span>
              </div>

              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Thank you letter template</span>
              </div>

              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Follow-up email template</span>
              </div>

              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Cover letter template included</span>
              </div>

              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>LinkedIn profile optimization</span>
              </div>

              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Expert advice on career gap</span>
              </div>

              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Interview coaching</span>
              </div>

              <div className="flex items-center text-[black]">
                <Check className="h-5 w-5 mr-3 flex-shrink-0" />
                <span>Job board posting</span>
              </div>
            </div>
            <div className="flex items-center justify-center mt-4">
              <span className="text-white text-6xl font-bold">
                <span className="text-3xl align-top">$</span>
                299
              </span>
            </div>
            {/* Button */}
            <button
              className="w-full mt-4 px-4 py-3 bg-white text-[black] rounded-md font-medium hover:bg-opacity-90 transition-colors"
              onClick={() => {
                document
                  .getElementById("resume-plans")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Select Package
            </button>

            {/* Payment Methods */}
            <div className="flex justify-center items-center mt-8 space-x-4">
              <img src={visa} alt="Visa" className="h-8" />
              <img src={payPal} alt="PayPal" className="h-8" />
              <img src={mastercard} alt="Mastercard" className="h-8" />
              <img src={maestro} alt="Maestro" className="h-8" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
