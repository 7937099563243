import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import encryptionModule from '../../components/common/localStorageUtils';

const NonAuthLayout = (props) => {
  if (encryptionModule.decryptData('adminInfo')) {
    return (
      <Navigate to={{ pathname: '/dashboard' }} />
    );
  }
  if (encryptionModule.decryptData('userInfo')) {
    return (
      <Navigate to={{ pathname: '/' }} />
    );
  }

  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  )
}

export default NonAuthLayout