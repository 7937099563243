import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import HeadingWithDot from "../common/HeadingWithDot";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import IndustryImage1 from "../../Assets/Home/IndustryImage1.png";
import IndustryImage2 from "../../Assets/Home/IndustryImage2.png";
import IndustryImage3 from "../../Assets/Home/IndustryImage3.png";

// Sample data array
const industryData = [
  {
    title: "Artificial Intelligence",
    location: "Las Vegas",
    image: IndustryImage1,
  },
  {
    title: "Manufacturing & Production",
    location: "New York",
    image: IndustryImage2,
  },
  {
    title: "Pharmaceuticals & Biotech",
    location: "San Francisco",
    image: IndustryImage3,
  },
];

// Array of AOS animations for images
const aosAnimations = ["fade-right", "fade-up", "fade-left"];

const DigitalRevolution = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div>
      <div className="flex items-center justify-center" data-aos="fade-up">
        <HeadingWithDot name="Our Industry" />
      </div>
      <h1
        className="md:text-4xl text-2xl tracking-wider md:leading-[70px] leading-[30px] mt-2 text-center"
        data-aos="zoom-in"
      >
        Leading The
        <span className="text-primaryColor"> Digital </span>
        Revolution
      </h1>

      <div
        className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 md:gap-8 mt-6"
        data-aos="fade-up"
      >
        {industryData.map((industry, index) => (
          <div key={index} data-aos={aosAnimations[index % aosAnimations.length]}>
            <img
              src={industry.image}
              alt={industry.title}
              className="w-full rounded-md"
            />
            <div
              className="flex items-center justify-between bg-white p-4 rounded-xl relative left-10 bottom-12 w-[90%] shadow-md"
              data-aos="slide-up"
            >
              <div>
                <h1>{industry.title}</h1>
                <p className="text-primaryColor mt-2">{industry.location}</p>
              </div>
              <div>
                <MdOutlineKeyboardDoubleArrowRight className="text-primaryColor size-6" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DigitalRevolution;
