import React, { useEffect } from "react";
import img1 from "../../Assets/Home/leftimgs.png";
import img2 from "../../Assets/Home/rightimgs.png";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const NextBigStep = () => {
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with a default duration
  }, []);

  const handleButtonClick = () => {
    navigate("/program/evaluate-your-career/view-job-openings");
  };

  return (
    <div
      className="bg-blue-50 flex flex-col items-center justify-center relative"
      data-aos="fade-up"
    >
      <div className="text-center py-20" data-aos="fade-down">
        <h1
          className="md:text-4xl text-2xl font-bold text-gray-800"
          data-aos="zoom-in"
        >
          TAKE YOUR <span className="text-primaryColor">NEXT BIG STEP</span>{" "}
          TOWARDS
          <br />A REWARDING CAREER.
        </h1>
        <p
          className="text-TextSecondaryColor mt-6 mx-2 md:mx-4 xl:mx-auto max-w-5xl"
          data-aos="fade-up"
        >
          This exclusive placement program targeting fresh graduates,
          experienced professionals, and anyone who wants to start a career in
          IT. No matter who you are or what you do, we have got you covered. You
          can get extensive job-oriented pre-placement preparation from industry
          experts and fetch your dream technology job in a leading IT company.
        </p>
        <button
          className="mt-8 bg-[#ECAB06] hover:bg-[#CC8302] active:bg-[#A35C05] text-white py-3 px-6 rounded-full text-lg font-semibold outline-none"
          onClick={handleButtonClick}
          data-aos="flip-up"
        >
          View Openings
        </button>
      </div>
      <img
        src={img2}
        alt="climbing"
        className="hidden md:block absolute bottom-0 right-0"
        data-aos="fade-left"
      />
      <img
        src={img1}
        alt="climbing"
        className="hidden md:block absolute bottom-0 left-0"
        data-aos="fade-right"
      />
    </div>
  );
};

export default NextBigStep;
