import React from "react";
import img1 from "../../Assets/ApplicationDevelopment/image.png";
import img2 from "../../Assets/ApplicationDevelopment/image (1).png";
import img3 from "../../Assets/ApplicationDevelopment/image (2).png";
const Development = () => {
  return (
    <div className="bg-white py-10 px-5 md:px-20">
      <div className="text-center">
        <h1 className="text-3xl md:text-5xl font-bold">
          MICROSOFT <span className="text-blue-500">SERVICES</span> &amp;
          MODERNIZATION
        </h1>
        <p className="mt-5 text-lg md:text-xl text-gray-600">
          Accelerate your business's growth by leveraging Microsoft technologies
          to modernize and optimize your legacy systems.
        </p>
      </div>

      <div className="mt-8 text-gray-600 text-center mx-auto">
        <p>
          Modernizing your IT infrastructure with Microsoft's ecosystem is
          essential for businesses aiming to stay competitive. As budgets
          tighten and tech environments grow more complex, ensuring your systems
          are resilient and future-proof is critical. Partnering with a leading
          Microsoft services provider in Atlanta, GA, USA, can make all the
          difference. At Techmentee, we specialize in helping businesses
          seamlessly transition to Microsoft’s cloud, productivity, and
          enterprise solutions, enabling you to minimize investments while
          maximizing performance.
        </p>
      </div>

      <div className="mt-10 flex flex-col md:flex-row items-center justify-center gap-5">
        <img
          src={img1}
          alt="Azure Cloud Services"
          className="rounded-lg w-full md:w-1/3 object-cover"
        />
        <img
          src={img2}
          alt="Microsoft 365 Apps"
          className="rounded-lg w-full md:w-1/3 object-cover"
        />
        <img
          src={img3}
          alt="Power Platform Solutions"
          className="rounded-lg w-full md:w-1/3 object-cover"
        />
      </div>
    </div>
  );
};

export default Development;
