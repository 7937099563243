import axios from "axios";
import React, { useState } from "react";
import encryptionModule from "../common/localStorageUtils";

const CreateIssueModal = ({ isOpen, onClose,fetchIssues }) => {
  const [issueTitle, setIssueTitle] = useState("");
  const [issueDescription, setIssueDescription] = useState("");
  const [priority, setPriority] = useState("low");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const User = encryptionModule.decryptData("userInfo")?.user;
  console.log(User);

  // Handles the form submission with validation
  const handleSubmit = () => {
    // Reset any previous errors
    setLoading(true);
    setError("");

    // Basic validation
    if (!issueTitle || !issueDescription || !phoneNumber) {
      setError("Please fill out all fields.");
      setLoading(false);
      return;
    }

    let data = JSON.stringify({
      title: issueTitle,
      description: issueDescription,
      priority: priority,
      phoneNumber: phoneNumber,
      user: User?._id,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/issues/create-issues`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        fetchIssues()
        alert("Your Issues is successfully created");

        setIssueTitle("");
        setIssueDescription("");
        setPriority("low");
        setPhoneNumber("");
        setLoading(false);
        // Close the modal
        onClose();
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg w-full max-w-md mx-auto">
        <h2 className="text-2xl font-semibold mb-4">Create New Issue</h2>

        {/* Error message display */}
        {error && <div className="text-red-500 mb-4">{error}</div>}

        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Issue Title</label>
          <input
            type="text"
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
            value={issueTitle}
            onChange={(e) => setIssueTitle(e.target.value)}
            placeholder="Enter issue title"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Description</label>
          <textarea
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
            value={issueDescription}
            onChange={(e) => setIssueDescription(e.target.value)}
            placeholder="Enter issue description"
            rows={4}
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Priority</label>
          <select
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
            value={priority}
            onChange={(e) => setPriority(e.target.value)}
          >
            <option value="low">Low</option>
            <option value="medium">Medium</option>
            <option value="high">High</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Phone Number</label>
          <input
            type="tel"
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Enter phone number"
            pattern="[0-9]{10}" // Example pattern for 10-digit numbers
            required
          />
        </div>

        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded mr-2"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            disabled={loading}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
          >
            {loading ? "Loading..." : "Create Issue"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateIssueModal;
