import React from "react";
import CourseBanner_image from "../../Assets/Programs/CourseBanner.png";
import { MdStart } from "react-icons/md";
import { FaCircleCheck, FaStar } from "react-icons/fa6";
import { GoDotFill } from "react-icons/go";

const CourseBanner = ({
  title,
  description,
  rating,
  reviews,
  category,
  BannerImg,
  published,
}) => {
  // console.log(BannerImg);
  return (
    <div
      className="relative h-auto py-20"
      style={{
        backgroundImage: `url(${BannerImg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
      }}
    >
      {/* Overlay for transparent black background */}
      <div className="absolute inset-0 bg-black opacity-50 z-10"></div>

      <div className=" relative tracking-wider text-white lg:w-[60%] lg:ml-20 md:ml-10 ml-5 z-20">
        <button className="bg-primaryColor text-white px-4 py-1 rounded-md text-sm ">
          {category}
        </button>
        <h1 className=" text-3xl mt-8 tracking-wide">{title}</h1>
        <p className="font-thin mt-6 tracking-wide max-w-[90%] md:max-w-[60%]">
          {description?.length > 200
            ? description.slice(0, 200) + "..."
            : description}
        </p>
        <div className="flex items-center gap-4 mt-8">
          <div className="flex items-center gap-2">
            <FaStar color="orange" />
            <p>{rating}</p>
            <p className="font-thin tracking-wide">({reviews} Reviews)</p>
          </div>
          <GoDotFill />
          <div className="flex items-center gap-2">
            <p className="font-thin tracking-wide">Published By</p>
            <p>{published}</p>
            <FaCircleCheck color="green" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseBanner;
