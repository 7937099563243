// import React from 'react';
// import img1 from '../../Assets/MockInterview/Frame 1000003253.png'
// import img2 from '../../Assets/MockInterview/Frame 1000003254.png'
// import img3 from '../../Assets/MockInterview/Frame 1000003255.png'
// import img4 from '../../Assets/MockInterview/Frame 1000003256.png'
// const steps = [
//   {
//     image:img1,
//     title: 'Provide Preferences',
//     description: 'You select your target company category, role, interview round type and book a slot',
//   },
//   {
//     image:img2,
//     title: 'Connect With Interviewer',
//     description: 'You select your target company category, role, interview round type and book a slot',
//   },
//   {
//     image:img3,
//     title: 'Give Mock Interview',
//     description: 'You select your target company category, role, interview round type and book a slot',
//   },
//   {
//     image: img4,
//     title: 'Get Feedback',
//     description: 'You select your target company category, role, interview round type and book a slot',
//   },
// ];

// const ProcessSteps = () => {
//   return (
//     <div className="py-16 bg-gray-100">
//       <div className="max-w-6xl mx-auto text-center">
//         <p className="text-sm font-semibold text-orange-500 mb-2">OUR PLAN</p>
//         <h2 className="text-4xl font-bold text-gray-900 mb-12">How will Online Mock Interview Work</h2>
//         <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
//         {steps.map((step) => (
//           <div
//             key={step.id}
//             className="bg-white border rounded-lg shadow-md p-6 flex-1 max-w-sm transition-transform transform hover:scale-105"
//           >
//             <img src={step.image} alt={step.title} className="w-[100%]" />
//             <h3 className="text-lg font-semibold mb-2">{step.title}</h3>
//             <p className="text-gray-600 mb-6">{step.description}</p>
//             <div className={`w-full h-1 ${step.borderColor}`}></div>
//           </div>
//         ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProcessSteps;

import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import img1 from "../../Assets/MockInterview/Frame 1000003253.png";
import img2 from "../../Assets/MockInterview/Frame 1000003254.png";
import img3 from "../../Assets/MockInterview/Frame 1000003255.png";
import img4 from "../../Assets/MockInterview/Frame 1000003256.png";

const steps = [
  {
    image: img1,
    title: "Provide Preferences",
    description:
      "Share your career aspirations, target roles, and industry of interest to customize the mock interview session specifically for your goals. ",
  },
  {
    image: img2,
    title: "Connect With Interviewer",
    description:
      "Get matched with experienced interviewers who specialize in your chosen field. They bring real-world expertise to simulate an authentic interview environment.",
  },
  {
    image: img3,
    title: "Give Mock Interview",
    description:
      "Participate in a live, interactive mock interview that mirrors actual scenarios, helping you practice answering questions and presenting yourself effectively.",
  },
  {
    image: img4,
    title: "Get Feedback",
    description:
      "Receive detailed, constructive feedback on your strengths and areas for improvement. Use these insights to polish your skills and prepare for success in real interviews.",
  },
];

const ProcessSteps = () => {
  // Initialize AOS
  useEffect(() => {
    AOS.init({ duration: 1000 }); // You can adjust the duration as needed
  }, []);

  return (
    <div className="py-16 bg-gray-100">
      <div className="max-w-7xl mx-auto text-center">
        {/* <p className="text-sm font-semibold text-orange-500 mb-2">OUR PLAN</p> */}
        <h2 className="text-4xl font-bold text-gray-900 mb-12">
          How will Online Mock Interview Work
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 md:gap-4 lg:gap-8 mx-2 ">
          {steps.map((step, index) => (
            <div
              key={index}
              data-aos="fade-up" // You can change the animation type (e.g., fade-in, slide-up, etc.)
              data-aos-delay={index * 200} // Adding delay based on the index
              className="bg-white border rounded-lg shadow-md p-6 flex-1 max-w-sm transition-transform transform hover:scale-105"
            >
              <img src={step.image} alt={step.title} className="w-[100%]" />
              <h3 className="text-lg font-semibold mb-2">{step.title}</h3>
              <p className="text-gray-600 mb-6">{step.description}</p>
              <div className={`w-full h-1 ${step.borderColor}`}></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProcessSteps;
