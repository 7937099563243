import React from "react";
import SimpleBanner from "../../../components/common/SimpleBanner";
import AboutUs_banner from "../../../Assets/AboutUs/AboutUs_banner.png";
import ResumeReviewSection from "../../../components/ResumeReview/ResumeReviewSection";
import KeyFeaturesSection from "../../../components/ResumeReview/KeyFeaturesSection";
import PlanSection from "../../../components/ResumeReview/PlanSection";
import Qna from "../../../components/ResumeReview/Qna";
import ResumeWorkShop from "../../../components/ResumeReview/ResumeWorkShop";

const ResumeReview = () => {
  return (
    <div className="overflow-hidden">
      <SimpleBanner
        image={AboutUs_banner}
        name="Resume Workshop"
        path="Home / Program / Resume Workshop"
      />
      <ResumeReviewSection />
      <ResumeWorkShop />
      <KeyFeaturesSection />
      <PlanSection />
      <div className="mx-5 lg:mx-20">
        <Qna />
      </div>
    </div>
  );
};

export default ResumeReview;
