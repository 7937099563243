import React from "react";
import kgslogo from "../../Assets/picimgs/kgslogo.png";
import skillimg1 from "../../Assets/picimgs/skills1.png";
import skillimg2 from "../../Assets/picimgs/skills2.png";
import skillimg3 from "../../Assets/picimgs/skills3.png";
import skillimg4 from "../../Assets/picimgs/skills4.png";
import skillimg5 from "../../Assets/picimgs/skills5.png";
import arrows from "../../Assets/picimgs/arrows.svg";
import line from "../../Assets/picimgs/AbstractLine.png";
import line2 from "../../Assets/picimgs/AbstractLine2.png";
import { useNavigate } from "react-router-dom";
const SkillsFrom = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="mt-16">
        {/* Header Section */}
        <div
          className="relative flex flex-col md:flex-row items-center w-[85%] md:w-3/4 lg:w-[70%] mx-auto justify-center space-y-4 md:space-y-0 md:space-x-4 border-2 border-[#DEE7EC] rounded-xl px-6 py-2"
          data-aos="zoom-in"
        >
          <img src={line} alt="img" className="absolute -top-10 -left-10" />
          <h1 className="text-2xl md:text-4xl font-bold text-center md:text-left">
            Master <span className="text-blue-600">In-Demand</span> Skills From
            Anywhere
          </h1>
          <img
            src={kgslogo}
            alt="KGS logo"
            className="w-20 h-20 md:w-24 md:h-24 object-contain"
          />
        </div>

        {/* Main Content Section */}
        <div className="flex flex-col md:flex-row mt-10">
          <div className="md:w-1/2 flex justify-center" data-aos="zoom-in-down">
            <img
              src={skillimg1}
              alt="A person celebrating while looking at a laptop"
              className="w-full md:w-auto"
            />
          </div>
          <div
            className="md:w-1/2 flex bg-[#E9FFF2] flex-col justify-center text-center md:text-left p-10"
            data-aos="zoom-in-down"
          >
            <h2 className="text-2xl md:text-3xl font-bold text-orange-500">
              Remote Boot Camp
            </h2>
            <p className="mt-4 text-TextSecondaryColor">
              Get ready to accelerate your career with our Remote Bootcamp, a
              flexible, immersive program tailored to give you hands-on
              experience with the latest industry practices. Engage with top
              mentors, dive into real-world projects, and collaborate with peers
              from around the globe.
            </p>
            <span
              onClick={() => {
                navigate("/program/remote-boot-camp");
              }}
              className="mt-6 inline-flex cursor-pointer items-center text-blue-600 font-semibold"
            >
              Know More
              <img src={arrows} alt="arrow icon" className="ml-2 w-5 h-5" />
            </span>
          </div>
        </div>
      </div>
      <div className="mt-10">
        {/* Header Section */}
        <div
          className="relative flex flex-col md:flex-row items-center w-[85%] md:w-3/4 lg:w-[70%] mx-auto justify-center space-y-4 md:space-y-0 md:space-x-4 border-2 border-[#DEE7EC] rounded-xl px-6 py-2"
          data-aos="zoom-in-down"
        >
          <img src={line2} alt="img" className="absolute -top-10 -right-10" />
          <h1 className="text-2xl md:text-4xl font-bold text-center">
            Making Full Time <span className="text-blue-600">Job</span> Hunting
            Easier, Faster and More Effective
          </h1>
          <img
            src={kgslogo}
            alt="KGS logo"
            className="w-20 h-20 md:w-24 md:h-24 object-contain"
          />
        </div>

        {/* Main Content Section */}
        <div className="flex flex-col md:flex-row mt-10">
          <div
            className="md:w-1/2 flex flex-col justify-center text-center md:text-left p-10 bg-[#E0F7FF]"
            data-aos="zoom-in-down"
          >
            <h2 className="text-2xl md:text-3xl font-bold text-orange-500">
              Full Time Job Assistance Program
            </h2>
            <p className="mt-4 text-gray-700">
              Dive into new career opportunities with Full-time Job Assistance
              Program, offering personalized job search strategies, expert
              feedback, and exclusive connections to top employers.
            </p>
            <span
              onClick={() => {
                navigate("/program/full-time-job-assistance-program");
              }}
              className="mt-6 cursor-pointer inline-flex items-center text-blue-600 font-semibold"
            >
              Know More
              <img src={arrows} alt="arrow icon" className="ml-2 w-5 h-5" />
            </span>
          </div>
          <div className="md:w-1/2 flex justify-center" data-aos="zoom-in-down">
            <img
              src={skillimg3}
              alt="A person celebrating while looking at a laptop"
              className="w-full md:w-auto"
            />
          </div>
        </div>
      </div>
      <div className="mt-10">
        {/* Header Section */}
        <div
          className="relative flex flex-col md:flex-row items-center w-[85%] md:w-3/4 lg:w-[70%] mx-auto justify-center space-y-4 md:space-y-0 md:space-x-4 border-2 border-[#DEE7EC] rounded-xl px-6 py-2"
          data-aos="zoom-in"
        >
          <img src={line2} alt="img" className="absolute -top-10 -right-10" />
          <h1 className="text-2xl md:text-4xl font-bold text-center md:text-left">
            Looking for an <span className="text-blue-600">internship ?</span>
          </h1>
          <img
            src={kgslogo}
            alt="KGS logo"
            className="w-20 h-20 md:w-24 md:h-24 object-contain"
          />
        </div>

        {/* Main Content Section */}
        <div
          className="flex flex-col md:flex-row mt-10"
          data-aos="zoom-in-down"
        >
          <div className="md:w-1/2 flex flex-col justify-center text-center md:text-left p-10 bg-[#FFF1F1]">
            <h2 className="text-2xl md:text-3xl font-bold text-orange-500">
              Independent Internship
            </h2>
            <p className="mt-4 text-gray-700">
              Looking for an internship that gives you real-world experience ?
              Our Independent Internship Program offers the perfect opportunity
              to gain hands-on knowledge in your chosen field. Whether you're a
              student, recent graduate, or looking to switch careers, this
              program provides you with valuable industry exposure, skill
              development, and mentorship.
            </p>
            <span
              onClick={() => {
                navigate("/program/internship");
              }}
              className="mt-6 cursor-pointer inline-flex items-center text-blue-600 font-semibold"
            >
              Know More
              <img src={arrows} alt="arrow icon" className="ml-2 w-5 h-5" />
            </span>
          </div>
          <div
            className="md:w-1/2 flex justify-center"
            data-aos="zoom-in-right"
          >
            <img
              src={skillimg2}
              alt="A person celebrating while looking at a laptop"
              className="w-full md:w-auto"
            />
          </div>
        </div>
      </div>
      <div className="mt-10">
        {/* Header Section */}
        <div
          className="relative flex flex-col md:flex-row items-center w-[85%] md:w-3/4 lg:w-[70%] mx-auto justify-center space-y-4 md:space-y-0 md:space-x-4 border-2 border-[#DEE7EC] rounded-xl px-6 py-2"
          data-aos="zoom-in"
        >
          <img src={line} alt="img" className="absolute -top-10 -left-10" />
          <h1 className="text-2xl md:text-4xl font-bold text-center md:text-left">
            Let Your <span className="text-blue-600">Resume</span> Speak for
            You.
          </h1>
          <img
            src={kgslogo}
            alt="KGS logo"
            className="w-20 h-20 md:w-24 md:h-24 object-contain"
          />
        </div>

        {/* Main Content Section */}
        <div className="flex flex-col md:flex-row mt-10">
          <div className="md:w-1/2 flex justify-center" data-aos="zoom-in-down">
            <img
              src={skillimg5}
              alt="A person celebrating while looking at a laptop"
              className="w-full md:w-auto"
            />
          </div>
          <div
            className="md:w-1/2 flex flex-col justify-center text-center md:text-left bg-[#EDFFE9] p-10"
            data-aos="zoom-in-down"
          >
            <h2 className="text-2xl md:text-3xl font-bold text-orange-500">
              Resume Workshop
            </h2>
            <p className="mt-4 text-gray-700">
              Our Resume Workshop program is designed to help you stand out in a
              competitive job market by creating a resume that highlights your
              unique strengths and accomplishments. At KGS, we don’t just
              prepare resumes—we prepare opportunities.
            </p>
            <span
              onClick={() => {
                navigate("/program/ResumeReview");
              }}
              className="mt-6 cursor-pointer inline-flex items-center text-blue-600 font-semibold"
            >
              Know More
              <img src={arrows} alt="arrow icon" className="ml-2 w-5 h-5" />
            </span>
          </div>
        </div>
      </div>
      <div className="mt-10">
        {/* Header Section */}
        <div
          className="relative flex flex-col md:flex-row items-center w-[85%] md:w-3/4 lg:w-[70%] mx-auto justify-center space-y-4 md:space-y-0 md:space-x-4 border-2 border-[#DEE7EC] rounded-xl px-6 py-2"
          data-aos="zoom-in"
        >
          <img src={line2} alt="img" className="absolute -top-10 -right-10" />
          <h1 className="text-2xl md:text-4xl font-bold text-center md:text-left">
            Ready, Set, <span className="text-blue-600">Interview !</span>{" "}
          </h1>
          <img
            src={kgslogo}
            alt="KGS logo"
            className="w-20 h-20 md:w-24 md:h-24 object-contain"
          />
        </div>

        {/* Main Content Section */}
        <div className="flex flex-col md:flex-row mt-10 ">
          <div
            className="md:w-1/2 flex flex-col justify-center text-center md:text-left bg-[#FFFBE9] p-10"
            data-aos="zoom-in-down"
          >
            <h2 className="text-2xl md:text-3xl font-bold text-orange-500">
              {/* Mock Interview & Coaching */}
              Mock Interview And Grooming Hub
            </h2>
            <p className="mt-4 text-gray-700">
              Get ready to crush your next technical interview! Our Technical
              Mock Interview and Coaching Program is designed to help you refine
              your skills, gain confidence, and tackle tough questions with
              ease. With tailored coaching, hands-on mock interviews, and
              valuable feedback, we’ll have you interview-ready and impressing
              employers in no time.
            </p>
            <span
              onClick={() => {
                navigate("/program/mock-interview-coaching");
              }}
              className="mt-6 cursor-pointer inline-flex items-center text-blue-600 font-semibold"
            >
              Know More
              <img src={arrows} alt="arrow icon" className="ml-2 w-5 h-5" />
            </span>
          </div>
          <div className="md:w-1/2 flex justify-center" data-aos="zoom-in-down">
            <img
              src={skillimg4}
              alt="A person celebrating while looking at a laptop"
              className="w-full md:w-auto"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SkillsFrom;
