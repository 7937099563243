import React, { useState } from "react";
import axios from "axios";

const CreateCoursePlanModal = ({ onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    features_included: "",
    key_points: [],
    price: "",
  });
  const [currentKeyPoint, setCurrentKeyPoint] = useState("");
  const [errors, setErrors] = useState({});

  const validate = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Course Name is required";
    if (
      !formData.features_included.trim() ||
      isNaN(formData.features_included) ||
      Number(formData.features_included) <= 0
    ) {
      newErrors.features_included =
        "Features Included must be a positive number";
    }
    if (
      !formData.price.trim() ||
      isNaN(formData.price) ||
      Number(formData.price) <= 0
    ) {
      newErrors.price = "Price must be a positive number";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // const myData = {
    //   ...formData,
    //   key_points: formData.keyPoints,
    // };

    // console.log(myData, "--------------------------->");
    if (validate()) {
      console.log(formData, "------------->");
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_KEY}/course-plan/create`,
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response.data);
          alert("Course Plan created Successfully.");
        })
        .catch((error) => {
          console.log(error);
        });

      onClose();
    }
  };

  const addKeyPoint = () => {
    if (
      currentKeyPoint.trim() &&
      !formData.key_points.includes(currentKeyPoint)
    ) {
      setFormData((prev) => ({
        ...prev,
        key_points: [...prev.key_points, currentKeyPoint],
      }));
      setCurrentKeyPoint("");
    }
  };

  const updateKeyPoint = (index, newValue) => {
    const updatedKeyPoints = [...formData.key_points];
    updatedKeyPoints[index] = newValue;
    setFormData((prev) => ({ ...prev, key_points: updatedKeyPoints }));
  };

  const removeKeyPoint = (index) => {
    setFormData((prev) => ({
      ...prev,
      key_points: prev.key_points.filter((_, i) => i !== index),
    }));
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-md h-[400px] overflow-y-scroll p-6">
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-xl font-semibold">Create Course Plan</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
            aria-label="Close"
          >
            <svg
              className="h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="space-y-4">
            <div>
              <label
                htmlFor="name"
                className={`block text-sm font-medium ${
                  errors.name ? "text-red-500" : "text-gray-700"
                }`}
              >
                Course Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className={`mt-1 block w-full px-3 py-2 border rounded-md shadow-sm  ${
                  errors.name ? "border-red-500" : "border-gray-300"
                }`}
              />
            </div>
            <div>
              <label
                htmlFor="features_included"
                className={`block text-sm font-medium ${
                  errors.features_included ? "text-red-500" : "text-gray-700"
                }`}
              >
                Features Included
              </label>
              <input
                type="number"
                id="features_included"
                name="features_included"
                value={formData.features_included}
                onChange={handleChange}
                className={`mt-1 block w-full px-3 py-2 border rounded-md shadow-sm  ${
                  errors.features_included
                    ? "border-red-500"
                    : "border-gray-300"
                }`}
              />
            </div>
            <div>
              <label
                htmlFor="price"
                className={`block text-sm font-medium ${
                  errors.price ? "text-red-500" : "text-gray-700"
                }`}
              >
                Price
              </label>
              <input
                type="number"
                id="price"
                name="price"
                value={formData.price}
                onChange={handleChange}
                className={`mt-1 block w-full px-3 py-2 border rounded-md shadow-sm  ${
                  errors.price ? "border-red-500" : "border-gray-300"
                }`}
              />
            </div>
            <div>
              <label
                htmlFor="key_points"
                className="block text-sm font-medium text-gray-700"
              >
                Key Points
              </label>
              <div className="flex space-x-2 mb-2">
                <input
                  type="text"
                  id="key_points"
                  value={currentKeyPoint}
                  onChange={(e) => setCurrentKeyPoint(e.target.value)}
                  placeholder="Enter key point"
                  className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm  border-gray-300"
                />
                <button
                  type="button"
                  onClick={addKeyPoint}
                  className="px-4 py-2 bg-customPurple text-white rounded-md hover:bg-customPurple"
                >
                  Add
                </button>
              </div>
              {formData.key_points.length > 0 && (
                <ul className="list-disc list-inside space-y-1">
                  {formData.key_points.map((point, index) => (
                    <li key={index} className="flex items-center space-x-2">
                      <input
                        type="text"
                        value={point}
                        onChange={(e) => updateKeyPoint(index, e.target.value)}
                        className="block w-full px-3 py-2 border rounded-md shadow-sm  border-gray-300"
                      />
                      <button
                        type="button"
                        onClick={() => removeKeyPoint(index)}
                        className="text-red-500 hover:text-red-700"
                      >
                        Remove
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-customPurple text-white rounded-md hover:bg-customPurple"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateCoursePlanModal;
