import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS CSS
import img from "../../Assets/MockInterview/Group 1000003144.png";

const InterviewPractice = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-out",
      once: true,
    });
  }, []);

  return (
    <div className="w-[100%] flex flex-col lg:flex-row px-14 gap-[3rem] py-9">
      {/* Text Section */}
      <div className="w-full md:w-[50%] mb-8 md:mb-0" data-aos="fade-up">
        <h1 className="text-3xl md:text-xl font-bold mb-4">
          <span className="text-yellow-500">Mock Interview: </span>Perfect Your
          Pitch Before It Matters
        </h1>
        <p className="text-gray-600 mb-4">
          Interviews can be nerve-wracking, but they don’t have to be. With our
          <span className="font-semibold "> Mock Interview Program,</span>{" "}
          you’ll walk into the real thing prepared, polished, and confident.
          Simulating real-world interview scenarios, we provide you with
          actionable feedback on your technical responses, behavioral answers,
          and overall presentation. From common questions to curveballs, we’ll
          make sure you’re ready for anything. At KGS, we don’t just prepare you
          for interviews—we prepare you to ace them.
        </p>
        <h1 className="text-3xl md:text-xl font-bold mb-4">
          <span className="text-yellow-500"> Technical Grooming: </span>Sharpen
          Your Skills, Impress Every Time
        </h1>
        <p className="text-gray-600 mb-4">
          The tech world moves fast, and so should you. Our{" "}
          <span className="font-semibold "> Technical Grooming Program,</span>{" "}
          is designed to help you stay ahead of the curve. Whether you’re
          brushing up on fundamentals or diving deep into advanced topics, we’ll
          guide you through the skills and knowledge needed to excel in
          technical assessments and interviews. With tailored guidance from
          industry experts and realworld problem-solving practice, KGS ensures
          you’re ready to tackle any technical challenge with confidence.
        </p>
        <h1 className="text-3xl md:text-xl font-bold mb-4">
          <span className="text-yellow-500"> Behavioral Grooming: </span>
          Confidence That Speaks Volumes
        </h1>
        <p className="text-gray-600 mb-4">
          Interviews can be nerve-wracking, but they don’t have to be. With our
          <span className="font-semibold "> Mock Interview Program,</span>{" "}
          you’ll walk into the real thing prepared, polished, and confident.
          Simulating real-world interview scenarios, we provide you with
          actionable feedback on your technical responses, behavioral answers,
          and overall presentation. From common questions to curveballs, we’ll
          make sure you’re ready for anything. At KGS, we don’t just prepare you
          for interviews—we prepare you to ace them.
        </p>
      </div>
      {/* Image Section */}
      <div className="w-full md:w-[50%]" data-aos="fade-up">
        <div className="mb-5">
          <img
            src={img}
            alt="Handshake"
            className="rounded-lg shadow-lg w-[70%]"
          />
        </div>
        <div className="flex items-center mb-4">
          <span className="text-lg font-bold mr-2">4.8</span>
          <span className="text-yellow-500 text-xl">⭐⭐⭐⭐⭐</span>
        </div>
        <div
          className="flex flex-col md:flex-row md:items-center"
          data-aos="fade-left"
        >
          <button className="bg-[#ECAB06] hover:bg-[#CC8302] active:bg-[#A35C05] text-white font-bold py-2 px-4 rounded-full mb-4 md:mb-0 md:mr-4">
            Get Free Quote
          </button>
          <div className="flex items-center text-purple-700 font-bold">
            <div className="bg-purple-700 text-white p-2 rounded-full mr-2">
              📞
            </div>
            <span>
              Hotline <br />
              <span className="text-black">+1 (770) 8383-KGS</span>
            </span>
          </div>
        </div>
        <div className="flex mt-4" data-aos="fade-up">
          <div className="mr-6">
            <span className="text-2xl font-bold">100+</span>
            <p className="text-gray-500">Industry Experts</p>
          </div>
          <div className="mr-6">
            <span className="text-2xl font-bold">2000+</span>
            <p className="text-gray-500">Mock Interviews</p>
          </div>
          <div>
            <span className="text-2xl font-bold">500+</span>
            <p className="text-gray-500">Referrals given</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterviewPractice;
