import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const faqData = [
  {
    question: "What are the key features of the program?",
    answer: [
      "Job Market Research: Understand industry trends and employer expectations.",
      "Customized Job Matching: Tailored job opportunities based on your skills and goals.",
      "Access to Exclusive Job Boards: Stay updated with the latest job listings and opportunities.",
      "Comprehensive Search Tools: Use advanced filters to identify the right jobs quickly.",
      "Direct Employer Access: Get exclusive job openings and referrals.",
      "Application Tracking: Stay organized with assistance tracking job applications.",
    ],
  },
  {
    question: "Who can benefit from the program?",
    answer:
      "This program is ideal for both IT and non-IT job seekers looking to optimize their job search process. Whether you're new to the job market, switching careers, or looking for your next big opportunity, we provide the tools, guidance, and resources needed to land the perfect job.",
  },
  {
    question: "What is the pricing for the program?",
    answer: ["30 Days: $300", "90 Days: $749"],
    des: "We offer flexible pricing options to suit your needs. Stay organized with assistance tracking job applications.",
  },
  {
    question: "What’s the difference between the 30-day and 90-day program?",
    answer:
      "The 30-day program offers an intensive, short-term approach for those needing quick assistance in their job search, while the 90-day program provides extended support, allowing for deeper engagement with job opportunities, more time for resume review, and complimentary mock interview and grooming sessions.",
  },
  {
    question: "What is the application tracking assistance?",
    answer:
      "We assist you with tracking your job applications, ensuring that you follow up on all opportunities, monitor responses, and stay organized throughout the process. This helps you remain proactive and keeps your job search on track.",
  },
  {
    question: "How do I get started?",
    answer:
      "To get started, simply sign up for the program via our website or contact our team for more information. Once enrolled, you’ll be assigned to a team who will guide you through the entire process and customize your plan based on your goals and preferences.",
  },
  {
    question: "What does the Resume Review include?",
    answer:
      "Our expert resume review service includes a detailed analysis of your resume, ensuring it’s clear, ATS-friendly, and tailored to highlight your strengths effectively. We provide personalized feedback to help present your skills and qualifications in the best possible way to potential employers.",
  },
  {
    question: "Can I access the program if I’m currently employed?",
    answer:
      "Yes! Whether you’re looking for a new opportunity while employed or actively seeking full-time positions, the program is designed to fit around your schedule and help you optimize your job search efficiently.",
  },
  {
    question: "How will the program help me stand out to employers?",
    answer:
      "Our program is designed to help you present your skills and experience in the most compelling way possible. We work with you to develop a customized job search strategy that highlights your strengths, aligning your unique qualifications with the needs of employers.",
  },
];

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAnswer = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const [chartData] = useState({
    series: [31, 69, 84],
    options: {
      chart: {
        height: 390,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 5,
            size: "30%",
            background: "transparent",
            image: undefined,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            },
          },
          // Default barLabels (enabled for lg and above)
          barLabels: {
            enabled: true,
            useSeriesColors: true,
            offsetX: -8,
            fontSize: "16px",
            formatter: function (seriesName, opts) {
              return (
                seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
              );
            },
          },
        },
      },
      colors: ["#3BC0EB", "#E18B19", "#556BAA", "#0077B5"],
      labels: ["Non-It Job Aspirants", "It Job Aspirants", "Benefited"],
      responsive: [
        {
          breakpoint: 768, // lg and below
          options: {
            plotOptions: {
              radialBar: {
                barLabels: {
                  enabled: false, // Hide barLabels on md and below
                },
              },
            },
            legend: {
              show: true, // Show legend on md and below
              position: "bottom",
            },
          },
        },
        {
          breakpoint: 640, // sm and below
          options: {
            chart: {
              height: 320, // Adjust height for small screens
            },
          },
        },
      ],
    },
  });

  return (
    <div className="bg-[#F8B003] py-6 px-4 md:px-6 xl:px-16 grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-8">
      <div className="w-full h-auto mx-auto p-6 bg-white py-7 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold text-center mb-3 text-gray-800">
          Frequently <span className="text-[#F8B003]">Asked</span> Questions
        </h1>
        <p className="my-3 text-md font-normal text-TextSecondaryColor text-center">
          Gain more through understanding of us.
        </p>
        <div className="space-y-4 overflow-y-scroll decrease-scrollbar-width h-[380px]">
          {faqData.map((item, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-sm border border-gray-200 outline-none"
            >
              <button
                onClick={() => toggleAnswer(index)}
                className="w-full flex justify-between items-center p-4 text-gray-700 font-semibold text-left hover:bg-gray-50 transition-colors rounded-t-lg"
              >
                <span>{item.question}</span>
                <span className="text-xl">
                  {openIndex === index ? "-" : "+"}
                </span>
              </button>
              {openIndex === index && (
                <div className="p-4 border-t border-gray-200 space-y-4">
                  {Array.isArray(item.answer) ? (
                    <ul className="list-disc pl-6 space-y-2 text-gray-600">
                      {item.answer.map((line, i) => (
                        <li key={i}>{line}</li>
                      ))}
                    </ul>
                  ) : (
                    <p className="text-gray-600">{item.answer}</p>
                  )}
                  {item.des && <p className="text-gray-500">{item.des}</p>}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="w-full mx-auto p-2 md:p-6 bg-white py-7 rounded-lg shadow-lg relative">
        {/* Chart for md and above */}
        <div id="chart-lg" className="hidden md:block overflow-hidden relative">
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="radialBar"
            height={390}
          />
          <h1 className="text-3xl font-semibold text-center ">
            Students Enrolled in 2024 : 1950+
          </h1>
          {/* Custom text centered in the chart */}
          {/* <div className="absolute inset-0 flex items-center justify-center">
                  <span className="text-md font-semibold text-center lg:mr-6 xl:mr-0">Students enrolled <br /> in 2024 <br /> 1900+</span>
                </div> */}
        </div>

        {/* Chart for sm and below */}
        <div
          id="chart-md"
          className="md:hidden block overflow-x-hidden relative"
        >
          <h1 className="text-2xl font-semibold text-center ">
            1900+ Students Enrolled in 2024
          </h1>

          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="radialBar"
            height={390}
          />
        </div>

        <div id="html-dist"></div>
      </div>
    </div>
  );
};

export default FAQ;
