import React, { useState } from "react";
import SimpleBanner from "../../../components/common/SimpleBanner";
import HealthCareBanner from "../../../Assets/Industry/HealthCareBanner.png"; // Reuse banner for now
import heathCare_image from "../../../Assets/Industry/heathCare_image.png"; // Reuse image
import HeadingWithDot from "../../../components/common/HeadingWithDot";
import ConsultingServices_image from "../../../Assets/Industry/ConsultingServices_image.png"; // Reuse image
import EHRImplementation_image from "../../../Assets/Industry/EHRImplementation_image.png"; // Reuse image
import Telemedicine_image from "../../../Assets/Industry/Telemedicine_image.png"; // Reuse image
import BusinessCard from "../../../components/common/BusinessCard"; // Reuse Business Card

// Updated content for Telecom IT Solutions
const telecomSolutions = [
  {
    image: ConsultingServices_image, // Reuse image
    title: "Network Infrastructure Optimization",
    description:
      "We offer solutions to optimize telecom network infrastructures, ensuring faster data transfer, enhanced security, and improved performance to support the increasing demand for high-speed connectivity.",
  },
  {
    image: EHRImplementation_image, // Reuse image
    title: "Telecom Billing & CRM Solutions",
    description:
      "Our billing and CRM systems streamline customer relationship management, billing processes, and payment collection, enhancing operational efficiency for telecom companies.",
  },
  {
    image: Telemedicine_image, // Reuse image
    title: "5G & IoT Integration",
    description:
      "We assist telecom providers in integrating 5G and IoT solutions, enabling advanced connectivity and smart device management for a variety of industries, from healthcare to smart cities.",
  },
];

const processSteps = [
  {
    index: 1,
    title: "Network Assessment",
    description:
      "We start by assessing the telecom provider’s existing network infrastructure, identifying bottlenecks, and evaluating areas for optimization to deliver seamless connectivity.",
  },
  {
    index: 2,
    title: "Custom Solution Design",
    description:
      "We design tailored solutions based on the specific needs of the telecom provider, ensuring that the network infrastructure, billing systems, and 5G integration meet business goals.",
  },
  {
    index: 3,
    title: "Prototyping & Testing",
    description:
      "Our team develops prototypes of telecom solutions, allowing providers to test performance, user experience, and scalability before full deployment.",
  },
  {
    index: 4,
    title: "Implementation & Integration",
    description:
      "We ensure a smooth integration of new telecom solutions with existing systems, minimizing disruption to ongoing operations and ensuring seamless functionality.",
  },
  {
    index: 5,
    title: "Launch & Support",
    description:
      "After launch, we provide continuous monitoring and support to ensure the new systems operate efficiently, offering updates and optimizations as needed.",
  },
  {
    index: 6,
    title: "Scalability & Future-Ready Solutions",
    description:
      "Our solutions are designed with scalability in mind, allowing telecom providers to expand their networks and integrate future technologies like 6G and AI-driven connectivity.",
  },
];

const Telecom = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  return (
    <div>
      {/* Reuse the banner */}
      <SimpleBanner
        image={HealthCareBanner}
        name="Telecom"
        path="Home / Industry / Telecom"
      />

      <div className="lg:px-20 md:px-10 px-5 py-10">
        <div className="grid xl:grid-cols-2 grid-cols-1 gap-10">
          <div className="">
            {/* Reuse the image */}
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTItCIQGj11QxfvhfjQ5_mPAZf5ctv425zEPWF7NgHn-N6ezEPzTQkRs-dOK4LPNGVTGNg&usqp=CAU"
              alt="about us"
              className="w-full rounded-lg"
            />
          </div>
          <div className="flex justify-center items-center">
            <div>
              {/* Updated content for Telecom */}
              <h1 className="md:text-4xl text-3xl tracking-wider md:leading-[55px] leading-[50px] mt-2">
                IT Solutions for
                <span className="text-primaryColor"> Telecom</span>
              </h1>
              <p className="text-TextSecondaryColor mt-2 w-[90%] tracking-wide">
                Our IT solutions for the telecom industry focus on optimizing
                network infrastructures, streamlining billing processes, and
                integrating 5G and IoT solutions to meet the growing demand for
                high-speed connectivity and advanced services.
              </p>
              <p className="text-TextSecondaryColor mt-2 w-[90%] tracking-wide">
                From telecom CRM systems to next-generation network management
                solutions, we help service providers enhance customer
                experiences and stay ahead of the competition with future-ready
                technologies.
              </p>
            </div>
          </div>
        </div>

        {/* Industry Solutions */}
        <div>
          <div className="flex justify-center mt-10">
            <HeadingWithDot name="Our Industry" />
          </div>
          <h1 className="md:text-4xl text-2xl tracking-wider text-center mt-4">
            Our <span className="text-primaryColor">Telecom</span> IT Solutions
          </h1>
          <p className="text-TextSecondaryColor text-sm tracking-wider text-center m-auto xl:w-[60%] mt-4">
            We provide comprehensive IT services for the telecom sector, helping
            providers optimize their networks, manage customer relationships,
            and integrate cutting-edge 5G and IoT technologies.
          </p>

          <div className="flex flex-wrap gap-8 mt-10 justify-center">
            {telecomSolutions.map((solution, index) => (
              <div
                key={index}
                className="border p-6 rounded-lg shadow-md text-center flex flex-col items-center w-full sm:w-[calc(50%-16px)] lg:w-[calc(30.33%-16px)]"
              >
                <img
                  src={solution.image}
                  alt={solution.title}
                  className="w-20 mb-4"
                />
                <h3 className="text-lg font-semibold tracking-wider mb-2">
                  {solution.title}
                </h3>
                <p className="text-sm text-TextSecondaryColor tracking-wider">
                  {solution.description}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Process */}
        <div className="flex justify-center mt-10">
          <HeadingWithDot name="Our Process" />
        </div>
        <h1 className="md:text-4xl text-2xl tracking-wider text-center mt-4">
          Our <span className="text-primaryColor">Process</span>
        </h1>
        <p className="text-TextSecondaryColor text-sm tracking-wider text-center m-auto xl:w-[60%] mt-4">
          Our process ensures that telecom service providers benefit from
          customized, scalable solutions that enhance network performance,
          improve customer management, and support advanced 5G and IoT
          technologies.
        </p>

        <div className="flex flex-wrap gap-8 mt-10 justify-center mb-10">
          {processSteps.map((step) => (
            <div
              onMouseEnter={() => setActiveIndex(step.index)}
              onMouseLeave={() => setActiveIndex(null)}
              key={step.index}
              className="relative border p-6 rounded-lg shadow-md flex flex-col w-full sm:w-[calc(50%-16px)] lg:w-[calc(50%-16px)]"
            >
              <div
                className={`absolute -top-8 right-0 m-2 w-12 text-2xl font-mono h-12 flex items-center justify-center text-white rounded-full transition-transform duration-300 ease-linear ${
                  activeIndex === step.index
                    ? "translate-x-4 bg-violet-500"
                    : "translate-x-0 bg-primaryColor"
                }`}
              >
                {step.index}
              </div>
              <h3 className="text-xl tracking-wider mb-2">{step.title}</h3>
              <p className="text-sm text-TextSecondaryColor tracking-wider leading-6">
                {step.description}
              </p>
            </div>
          ))}
        </div>

        {/* Reuse BusinessCard */}
        <BusinessCard />
      </div>
    </div>
  );
};

export default Telecom;
