import React from "react";
import img from "../../Assets/ApplicationDevelopment/image 253.png";
const QualityAssurance = () => {
  return (
    <div className="px-5 md:px-20 gap-x-7 md:gap-y-11 flex flex-col lg:flex-row items-center justify-between w-[100%]">
      <div className="lg:w-[50%] w-[100%]">
        <h1 className="text-2xl md:text-4xl font-bold">
          Enhance Your Legacy Systems with{" "}
          <span className="text-green-500">Quality Assurance</span>
        </h1>
        <p className="mt-5 text-lg md:text-xl text-gray-600">
          In today's fast-paced business environment, ensuring the reliability
          and performance of your legacy systems is critical. Techmentee offers
          comprehensive QA & Testing services to modernize and maintain your
          software's quality for the future. Our key offerings include:
        </p>
        <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-4 text-gray-600 text-lg md:text-xl montserrat">
          <div className="flex items-start">
            <span>Automated Testing</span>
          </div>
          <div className="flex items-start">
            <span>Manual Testing</span>
          </div>
          <div className="flex items-start">
            <span>Performance Testing</span>
          </div>
          <div className="flex items-start">
            <span>Regression Testing</span>
          </div>
        </div>
        <p className="mt-5 text-lg md:text-xl text-gray-600">
          Is your business suffering from untested and unreliable applications?
          Our QA experts ensure that your systems run smoothly, minimizing
          downtime and maximizing performance. Let's work together to elevate
          the quality of your applications!
        </p>
      </div>

      <div className="w-[100%] lg:w-1/2 mt-10 md:mt-0 flex justify-center">
        <img
          src={img}
          alt="QA Testing"
          className="rounded-lg w-full object-cover"
        />
      </div>
    </div>
  );
};

export default QualityAssurance;
