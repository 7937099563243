import React, { useEffect, useState } from "react";
import img from "../../Assets/MockInterview/9384988_41713441.png";
import axios from "axios";
import encryptionModule from "../common/localStorageUtils";
import { useAuth } from "../../context/AuthContext";
import { FaPlus, FaCheck } from "react-icons/fa"; // Import both icons
import { ImSpinner8 } from "react-icons/im"; // Import spinner icon
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import TypeOfPackageSelectionModal from "./TypeOfPackageSelectionModal";
const PlanSelection = () => {
  const [selectedPlan, setSelectedPlan] = useState(1);
  const [allMockPlans, setAllMockPlans] = useState([]);
  const [loadingPlans, setLoadingPlans] = useState({}); // Track loading state for individual plans
  const [addedPlans, setAddedPlans] = useState({}); // Track added plans
  const [modalOpen, setModalOpen] = useState(false);

  const User = encryptionModule.decryptData("userInfo")?.user;
  const { isUserLogin, logout } = useAuth();
  const navigate = useNavigate();

  // Fetch mock plans from API
  const getAllMockPlans = () => {
    setLoadingPlans(true); // Set loading true when fetching starts
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/mock-interview-plans/get-all-plans`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setAllMockPlans(response?.data?.packages);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingPlans(false); // Set loading to false when fetching completes
      });
  };

  // Add Mock Plans To cart...
  const handleAddToCart = (plan) => {
    // Set loading state for the specific plan being added to cart
    setLoadingPlans((prev) => ({ ...prev, [plan._id]: true }));

    if (!isUserLogin) {
      navigate("/login");
      setLoadingPlans((prev) => ({ ...prev, [plan._id]: false })); // Reset loading if user not logged in
      return;
    }

    let data = JSON.stringify({
      user_reference: User?._id,
      type: "mockInterview",
      mockInterviews: {
        mockInterview_plan_reference: plan?._id,
      },
      courses: null,
      resumeReviews: null,
      amount: plan?.discounted_price,
      tax: 10,
      totalAmount: plan?.discounted_price,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/cart/create`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        toast.success("This Item is added to your cart");
        setLoadingPlans((prev) => ({ ...prev, [plan._id]: false })); // Reset loading after success
        setAddedPlans((prev) => ({ ...prev, [plan._id]: true })); // Mark plan as added
        setTimeout(() => {
          setAddedPlans((prev) => ({ ...prev, [plan._id]: false })); // Reset after 3 seconds
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
        setLoadingPlans((prev) => ({ ...prev, [plan._id]: false })); // Reset loading on error
        alert(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    getAllMockPlans();
  }, []);

  //Handle modal open
  const handleModalOpen = (plan) => {
    setModalOpen(true);
    setSelectedPlan(plan);
  };

  // Calculate discount percentage
  const calculateDiscountPercentage = (actualPrice, discountedPrice) => {
    if (!actualPrice || !discountedPrice) return 0;
    return ((actualPrice - discountedPrice) / actualPrice) * 100;
  };

  // Render skeleton card
  const renderSkeleton = () => (
    <div className="border rounded-lg p-4 shadow-md relative animate-pulse">
      <div className="h-48 bg-gray-300 mb-4"></div> {/* Image skeleton */}
      <div className="h-6 bg-gray-300 mb-2"></div> {/* Title skeleton */}
      <div className="h-4 bg-gray-300 mb-2"></div> {/* Price skeleton */}
      <div className="h-6 bg-gray-300"></div> {/* Discounted price skeleton */}
    </div>
  );

  return (
    <div className="py-8 px-4 max-w-7xl mx-auto text-center">
      {modalOpen && (
        <TypeOfPackageSelectionModal
          setModalOPen={setModalOpen}
          plan={selectedPlan}
          User={User}
          isUserLogin={isUserLogin}
        />
      )}
      {/* Title Section */}
      <h3 className="text-sm text-yellow-500 font-semibold mb-2">OUR PLAN</h3>
      <h2 className="text-2xl md:text-3xl font-bold mb-8">
        Select a <span className="text-yellow-500">Plan</span> Based on your
        Needs
      </h2>

      {/* Plans Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 justify-center gap-4 md:gap-6 items-center">
        {loadingPlans === true
          ? // Show skeleton cards while loading
            Array(4)
              .fill(0)
              .map((_, idx) => <div key={idx}>{renderSkeleton()}</div>)
          : // Show actual plans when data is loaded
            allMockPlans
              .filter((plan) => plan.default_plan === "true") // Filter plans where default_plan is "true"
              .map((plan) => (
                <div
                  key={plan.id}
                  className={`border rounded-lg p-4 shadow-md relative transition-transform transform hover:scale-105 cursor-pointer ${
                    selectedPlan === plan.id
                      ? "border-yellow-400 bg-yellow-100"
                      : ""
                  }`}
                  onClick={() => setSelectedPlan(plan._id)}
                >
                  {/* Add Icon */}
                  <div className="relative group">
                    <div
                      className={`absolute -top-2 -right-2 p-1 rounded text-white cursor-pointer  hover:bg-[#CC8302] active:bg-[#A35C05] ${
                        addedPlans[plan._id] ? "bg-green-500" : "bg-[#ECAB06]"
                      }`}
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent the plan selection event
                        // handleAddToCart(plan);
                        handleModalOpen(plan);
                      }}
                    >
                      {loadingPlans[plan._id] ? (
                        <ImSpinner8 className="animate-spin" size={20} />
                      ) : addedPlans[plan._id] ? (
                        <FaCheck size={20} />
                      ) : (
                        // <FaPlus size={20} />
                        <button className="text-xs lg:text-sm px-1 md:px-0 lg:px-1">
                          Enroll Now
                        </button>
                      )}
                    </div>

                    {/* Tooltip */}
                    <span className="absolute bottom-[-75px] right-[-20px] hidden p-2 text-sm text-white bg-black rounded-md group-hover:block">
                      Add to cart
                    </span>
                  </div>

                  {/* Discount Badge */}
                  <div className="absolute top-2 left-2 bg-yellow-500 text-white text-xs px-2 py-1 rounded">
                    {calculateDiscountPercentage(
                      plan.actual_price,
                      plan.discounted_price
                    ).toFixed(0)}
                    % OFF
                  </div>

                  {/* Plan Image */}
                  <div className="mb-4">
                    <img
                      src={img}
                      alt={plan?.packageName}
                      className="mx-auto"
                    />
                  </div>

                  {/* Plan Title */}
                  <h3 className="text-lg font-semibold mb-2">
                    {plan?.packageName}
                  </h3>

                  {/* Pricing */}
                  <div className="text-gray-500 line-through">
                    ${plan.actual_price}
                  </div>
                  <div className="text-xl font-bold">
                    ${plan.discounted_price}
                  </div>

                  {/* Selected Plan Checkmark */}
                  {selectedPlan === plan.id && (
                    <div className="absolute top-2 right-2 text-green-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </div>
                  )}
                </div>
              ))}
      </div>
    </div>
  );
};

export default PlanSelection;
