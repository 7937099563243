import React from "react";
import img from '../../Assets/ApplicationDevelopment/image 253.png'
const KGSModernization = () => {
  return (
    <div className="px-5 md:px-20 gap-x-7 md:gap-y-11 flex flex-col lg:flex-row items-center justify-between w-[100%]">
      <div className="lg:w-[50%] w-[100%]">
        <h1 className="text-2xl md:text-4xl font-bold">
          Empower Your Legacy Systems with <span className="text-orange-500">KGS’s Future-Ready Solutions</span>
        </h1>
        <p className="mt-5 text-lg md:text-xl text-gray-600">
          In a rapidly evolving business landscape, staying ahead is crucial. With KGS’s comprehensive product suite, you can modernize your legacy systems to meet the demands of tomorrow. Our tailored solutions are designed to enhance your business efficiency, reduce operational costs, and ensure seamless integration with the latest technologies.
        </p>
        <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-4 text-gray-800">
          <div className="flex items-start">
            <span>Advanced Monitoring</span>
          </div>
          <div className="flex items-start">
            <span>In-Depth Analytics</span>
          </div>
          <div className="flex items-start">
            <span>Proactive Maintenance</span>
          </div>
          <div className="flex items-start">
            <span>Continuous Innovation</span>
          </div>
        </div>
        <p className="mt-5 text-lg md:text-xl text-gray-600">
          Are your legacy systems holding your business back? With KGS, you can transform your infrastructure to maximize efficiency and eliminate downtime. Our experts are ready to help you unlock the full potential of your applications, ensuring that your business remains competitive and future-proof. Let's turn your ideas into reality with KGS’s cutting-edge solutions!
        </p>
      </div>

      <div className="w-[100%] lg:w-1/2 mt-10 md:mt-0 flex justify-center">
        <img
          src={img}
          alt="Modernization"
          className="rounded-lg w-full object-cover"
        />
      </div>
    </div>
  );
};

export default KGSModernization;