import React from "react";
import SimpleBanner from "../../../components/common/SimpleBanner";
import bannerImage from "../../../Assets/Programs/RemoteBootCamp_banner.png";
import InterviewPractice from "../../../components/MockInterview/InterviewPractice";
import FeatureCard from "../../../components/MockInterview/FeatureCard";
import PlanSelection from "../../../components/MockInterview/PlanSelection";
import PerksofMock from "../../../components/MockInterview/PerksofMock";
import ProcessSteps from "../../../components/MockInterview/ProcessSteps";
import ContactUsForm from "../../../components/home/ContactUsForm";
import UpsideDownTriangleLayout from "../../../components/MockInterview/UpsideDownTriangleLayout ";

const MockInterviewCoaching = () => {
  return (
    <div className="overflow-hidden">
      <SimpleBanner
        image={bannerImage}
        name="Mock Interview And Grooming Hub"
        path="Home / Programs / Mock Interview And Grooming Hub"
      />
      <InterviewPractice />
      {/* <UpsideDownTriangleLayout /> */}
      <FeatureCard />
      <PlanSelection />
      {/* <PerksofMock /> */}
      <ProcessSteps />
      <div className="mx-4 lg:mx-16">
        <ContactUsForm />
      </div>
    </div>
  );
};

export default MockInterviewCoaching;
