import React, { useState, useEffect } from "react";
import axios from "axios";
import { SlSettings } from "react-icons/sl";
import { FaTrash } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import EditCoursePlanModal from "../PlansEdit/EditCoursePlanModal";
const CoursePlanCards = () => {
  const [allCoursePlans, setAllCoursePlans] = useState([]);
  const [defaultCoursePlan, setDefaultCoursePlans] = useState([]);
  const [toggledPlans, setToggledPlans] = useState({}); // Store toggle states for each plan
  const [toggledPlansDefault, setToggledPlansDefault] = useState({}); // Store toggle states for each plan
  const [itemIndex, setItemIndex] = useState("");
  const [currentPlanId, setCurrentPlanId] = useState("");
  const [planType, setPlanType] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [load, setload] = useState(false);
  const [editCourseModalOpen, setEditCourseModalOpen] = useState(false);
  const [currentPlan, setCurrentPlan] = useState({});
  console.log(toggledPlans, "---------->t");
  console.log(toggledPlansDefault, "---------->td");

  console.log(
    defaultCoursePlan,
    "------------------------->default course plan"
  );
  const getAllCoursePlans = () => {
    setload(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/course-plan/get-all-plans`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        setAllCoursePlans(
          response?.data?.plans?.filter((each) => {
            return each.default_plan == "false";
          })
        );

        setDefaultCoursePlans(
          response?.data?.plans?.filter((each) => {
            return each.default_plan == "true";
          })
        );
        setload(false);
      })
      .catch((error) => {
        console.log(error);
        setload(false);
      });
  };

  const handleToggleAndUpdatePlan = (id, itemIndex, typeOfPlan) => {
    console.log("i am running");
    let myData;
    console.log(id, itemIndex);
    if (typeOfPlan == "defaultPlan") {
      myData = {
        default_plan: !toggledPlansDefault[itemIndex],
      };
    } else {
      myData = {
        default_plan: toggledPlans[itemIndex],
      };
    }

    console.log(myData);

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/course-plan//update/${id}`,
      headers: {},
      data: myData,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        getAllCoursePlans();
        setToggledPlans({});
        setToggledPlansDefault({});
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this plan ?")) {
      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_KEY}/course-plan//delete/${id}`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response.data);
          getAllCoursePlans();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    getAllCoursePlans();
  }, [editCourseModalOpen]);

  useEffect(() => {
    handleToggleAndUpdatePlan(currentPlanId, itemIndex, planType);
  }, [refresh]);

  const handleToggle = (planType, id, index) => {
    if (defaultCoursePlan?.length == 2) {
      alert("You already have two active plans !");
      return;
    }
    setCurrentPlanId(id);
    setItemIndex(index);
    setPlanType(planType);

    // Update the toggle state for a specific plan
    setToggledPlans((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle the current plan's state
    }));
    setRefresh(!refresh);
  };
  const handleToggleForDefaultPlans = (planType, id, index) => {
    setCurrentPlanId(id);
    setItemIndex(index);
    setPlanType(planType);
    // Update the toggle state for a specific plan
    setToggledPlansDefault((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle the current plan's state
    }));
    setRefresh(!refresh);
  };

  return (
    <div className=" justify-evenly">
      {editCourseModalOpen ? (
        <EditCoursePlanModal
          setEditCourseModalOpen={setEditCourseModalOpen}
          currentPlan={currentPlan}
        />
      ) : (
        ""
      )}
      <div>
        <h1 className="my-3 tracking-wide font-semibold">Default Plans</h1>
        <div className="flex flex-wrap gap-4">
          {defaultCoursePlan.length === 0 ? (
            <div className="w-full text-center py-10">
              {load ? (
                <p className="text-gray-500 text-xl tracking-wide">
                  Fetching your data, Please wait...
                </p>
              ) : (
                <p className="text-gray-500 text-xl tracking-wide">
                  No data available !
                </p>
              )}
            </div>
          ) : (
            defaultCoursePlan.map((each, index) => {
              return (
                <div
                  key={index}
                  className="w-[32%] relative bg-white shadow-lg rounded-lg overflow-hidden border py-5"
                >
                  <label className="absolute top-2 right-2 flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      className="sr-only"
                      checked={toggledPlansDefault[index] || true}
                      onChange={() =>
                        handleToggleForDefaultPlans(
                          "defaultPlan",
                          each._id,
                          index
                        )
                      }
                    />
                    <div
                      onClick={() => {}}
                      className={`w-14 h-8 rounded-full ${
                        toggledPlansDefault[index]
                          ? "bg-gray-300"
                          : "bg-customPurple"
                      }`}
                    ></div>
                    <div
                      className={`absolute w-6 h-6 bg-white rounded-full left-1 top-1 transition-all ${
                        !toggledPlansDefault[index] ? "translate-x-6" : ""
                      }`}
                    ></div>
                  </label>

                  <div className="p-6 flex flex-col items-center justify-center text-center">
                    {/* Card Header */}
                    <h2 className="text-[black] mb-4">
                      {each?.feature_included}
                    </h2>

                    {/* Plan Name */}
                    <p className="text-TextSecondaryColor font-medium text-3xl">
                      {each?.name}
                    </p>

                    <SlSettings size={60} color="orange" className="py-2" />

                    {/* Features List */}
                    <ul className="my-4 space-y-2 flex flex-col gap-2">
                      {each?.key_points?.map((eachItem, index) => {
                        return (
                          <li
                            key={index}
                            className="flex items-center justify-center"
                          >
                            <span className="mr-2 text-blue-500">✔️</span>
                            {eachItem}
                          </li>
                        );
                      })}
                    </ul>

                    {/* Price Section */}
                    <div className="text-center text-xl mt-4">
                      <span className="text-3xl font-bold tracking-wide">
                        ${each?.price}{" "}
                      </span>
                      / per month
                    </div>

                    {/* Buttons */}
                    <div className="flex gap-4 mt-6">
                      <button className="bg-primaryColor text-white py-2 px-4 rounded hover:bg-primaryColor-dark">
                        Enroll Now
                      </button>
                      <button className="bg-violet-500 text-white py-2 px-4 rounded hover:bg-violet-600">
                        Quick Guide
                      </button>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
      <div className="w-full">
        <h1 className="my-3 tracking-wide font-semibold">ALL Course Plans</h1>

        <div className="flex flex-wrap gap-4">
          {allCoursePlans.length === 0 ? (
            <div className="w-full text-center py-10">
              {load ? (
                <p className="text-gray-500 text-xl tracking-wide">
                  Fetching your data, Please wait...
                </p>
              ) : (
                <p className="text-gray-500 text-xl tracking-wide">
                  No data available !
                </p>
              )}{" "}
            </div>
          ) : (
            allCoursePlans.map((each, index) => {
              return (
                <div
                  key={index}
                  className="w-[32%] relative bg-white shadow-lg rounded-lg overflow-hidden border py-5"
                >
                  <label className="absolute top-2 right-2 flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      className="sr-only"
                      checked={toggledPlans[index] || false}
                      onChange={() => handleToggle("AllPlan", each._id, index)}
                    />
                    <div
                      onClick={() => {}}
                      className={`w-14 h-8 rounded-full ${
                        toggledPlans[index] ? "bg-customPurple" : "bg-gray-300"
                      }`}
                    ></div>
                    <div
                      className={`absolute w-6 h-6 bg-white rounded-full left-1 top-1 transition-all ${
                        toggledPlans[index] ? "translate-x-6" : ""
                      }`}
                    ></div>
                  </label>

                  <div className="p-6 flex flex-col items-center justify-center text-center">
                    {/* Card Header */}
                    <h2 className="text-[black] mb-4">
                      {each?.feature_included}
                    </h2>

                    {/* Plan Name */}
                    <p className="text-TextSecondaryColor font-medium text-3xl">
                      {each?.name}
                    </p>

                    <SlSettings size={60} color="orange" className="py-2" />

                    {/* Features List */}
                    <ul className="my-4 space-y-2 flex flex-col gap-2">
                      {each?.key_points?.map((eachItem, index) => {
                        return (
                          <li
                            key={index}
                            className="flex items-center justify-center"
                          >
                            <span className="mr-2 text-blue-500">✔️</span>
                            {eachItem}
                          </li>
                        );
                      })}
                    </ul>

                    {/* Price Section */}
                    <div className="text-center text-xl mt-4">
                      <span className="text-3xl font-bold tracking-wide">
                        ${each?.price}{" "}
                      </span>
                      / per month
                    </div>

                    {/* Buttons */}
                    <div className="flex gap-4 mt-6">
                      {/* Delete Button with Icon */}
                      <button
                        className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 flex items-center"
                        // onClick={
                        //   // handleDelete(each._id)
                        // }
                        onClick={() => {
                          handleDelete(each._id);
                        }}
                      >
                        <FaTrash className="mr-2" />
                        Delete
                      </button>
                      <button
                        onClick={() => {
                          setEditCourseModalOpen(true);
                          setCurrentPlan(each);
                        }}
                        className="bg-[#846CF9] tracking-wide text-white py-2 px-4 rounded flex items-center"
                        // onClick={
                        //   // handleDelete(each._id)
                        // }
                      >
                        <FaEdit size={25} className="mr-2" />
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default CoursePlanCards;
