import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import img1 from "../../Assets/ResumeReview/Frame1.png";
import img2 from "../../Assets/ResumeReview/Frame2.png";
import img3 from "../../Assets/ResumeReview/Frame3.png";

const KeyFeaturesSection = () => {
  const features = [
    {
      id: 1,
      title: "Tailored to Your Career Goals",
      description:
        "Your career journey is unique, and your resume should reflect that. Our experts take time to understand your aspirations, whether you’re a recent graduate, a mid-career professional, or looking to switch industries.",
      icon: img1,
      animation: "fade-up", // animation type for this card
    },
    {
      id: 2,
      title: "ATS-Friendly Resumes",
      description:
        "In today’s digital age, most companies use Applicant Tracking Systems (ATS) to screen resumes before they ever reach a human recruiter.We craft keyword-rich resumes designed to pass ATS filters without compromising on readability and impact.",
      icon: img3,
      animation: "fade-left", // animation type for this card
    },
    {
      id: 3,
      title: "Professional Formatting",
      description:
        "First impressions matter, and a visually appealing resume can set you apart. Our design approach focuses on creating sleek, modern layouts that are both attractive and easy to navigate.",
      icon: img3,
      animation: "fade-right", // animation type for this card
    },
    {
      id: 4,
      title: "Industry-Specific Expertise",
      description:
        "Different industries have different expectations when it comes to resumes.By tailoring the content to industry standards and trends, we position you as the ideal candidate who understands and meets the specific needs of employers",
      icon: img2,
      animation: "fade-up", // animation type for this card
    },
  ];

  useEffect(() => {
    AOS.init({
      duration: 1000, // animation duration
      easing: "ease-in-out", // easing function
      once: true, // animations will only happen once
    });
  }, []);

  return (
    <div className="bg-white py-10 px-5 md:px-20">
      {/* Section Title */}
      <h2 className="text-center text-2xl md:text-3xl font-medium text-gray-800">
        Key Features
      </h2>

      {/* Features */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 pt-4">
        {features.map((feature) => (
          <div
            key={feature.id}
            data-aos={feature.animation} // Add AOS animation to each card
            className="flex flex-col items-center text-center w-full px-4 py-6 shadow-lg rounded"
          >
            <img
              src={feature.icon}
              alt={feature.title}
              className="w-12 h-12 mb-4"
            />
            <h3 className="text-xl text-gray-800 mb-2 font-medium">
              {feature.title}
            </h3>
            <p className="text-gray-600">{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default KeyFeaturesSection;
