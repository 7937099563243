import React from "react";
import img1 from "../../Assets/ApplicationDevelopment/image.png";
import img2 from "../../Assets/ApplicationDevelopment/image (1).png";
import img3 from "../../Assets/ApplicationDevelopment/image (2).png";
const SCODDevelopment = () => {
  return (
    <div className="bg-white py-10 px-5 md:px-20">
      <div className="text-center">
        <h1 className="text-3xl md:text-5xl font-bold">
          SPECIALTY CONSULTANT <span className="text-blue-500">ON DEMAND</span>
        </h1>
        <p className="mt-5 text-lg md:text-xl text-gray-600">
          Unlock business growth with specialized consulting services tailored
          to your specific needs. Our consultants are ready to step in and
          provide the expertise you need.
        </p>
      </div>

      <div className="mt-8 text-gray-600 text-center mx-auto">
        <p>
          SCOD offers highly skilled consultants for specific projects, ensuring
          that you have the right expertise at the right time. Whether it’s
          development, strategy, or implementation, our consultants bring years
          of experience and knowledge to help you succeed.
        </p>
      </div>

      <div className="mt-10 flex flex-col md:flex-row items-center justify-center gap-5">
        <img
          src={img1}
          alt="Specialized Consulting Solutions"
          className="rounded-lg w-full md:w-1/3 object-cover"
        />
        <img
          src={img2}
          alt="Consultant Strategy"
          className="rounded-lg w-full md:w-1/3 object-cover"
        />
        <img
          src={img3}
          alt="On-Demand Consulting"
          className="rounded-lg w-full md:w-1/3 object-cover"
        />
      </div>
    </div>
  );
};

export default SCODDevelopment;
