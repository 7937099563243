import React from "react";
import img1 from '../../Assets/ApplicationDevelopment/image.png'
import img2 from '../../Assets/ApplicationDevelopment/image (1).png'
import img3 from '../../Assets/ApplicationDevelopment/image (2).png'
const Development = () => {
  return (
    <div className="bg-white py-10 px-5 md:px-20">
      <div className="text-center">
        <h1 className="text-4xl md:text-5xl font-bold">
          KGS <span className="text-orange-500">APPLICATION</span> DEVELOPMENT &amp; MODERNIZATION
        </h1>
        <p className="mt-5 text-lg md:text-xl text-gray-600">
          Drive your business growth by modernizing your legacy applications into agile, scalable systems with KGS-Technology-Group.
        </p>
      </div>
      
      <div className="mt-8 text-gray-600 text-center mx-auto">
        <p>
          At KGS-Technology-Group, we specialize in transforming outdated applications into modern, agile systems that empower your business 
          to stay competitive. Our experts meticulously plan and execute modernization strategies that minimize downtime and optimize performance, 
          ensuring a seamless transition to advanced technologies.
        </p>
      </div>

      <div className="mt-10 flex flex-col md:flex-row items-center justify-center gap-5">
        <img
          src={img1}
          alt="Application Development Process"
          className="rounded-lg w-full md:w-1/3 object-cover"
        />
        <img
          src={img2}
          alt="Mobile Application Development"
          className="rounded-lg w-full md:w-1/3 object-cover"
        />
        <img
          src={img3}
          alt="Code Development"
          className="rounded-lg w-full md:w-1/3 object-cover"
        />
      </div>
    </div>
  );
};

export default Development;