import React, { useState } from "react";
import bannerImage from "../../../Assets/Programs/RemoteBootCamp_banner.png";
import Evaluet_bg1 from "../../../Assets/Programs/Evaluet_bg1.png";
import Image1 from "../../../Assets/Programs/Image1.png";
import Image2 from "../../../Assets/Programs/Image2.png";
import Image3 from "../../../Assets/Programs/image3.png";
import Image4 from "../../../Assets/Programs/image4.png";
import EvaluateYourCareer_image from "../../../Assets/Programs/EvaluateYourCareer_image.png";
import SimpleBanner from "../../../components/common/SimpleBanner";
import ContactUsForm from "../../../components/home/ContactUsForm";
import BusinessCard from "../../../components/common/BusinessCard";
import { Link } from "react-router-dom";
import YourFullPotential from "../../../components/home/YourFullPotential";
import NextBigStep from "../../../components/home/NextBigStep";
import SkillsFrom from "../../../components/home/SkillsFrom";

const EvaluateYourCareer = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  return (
    <div className="overflow-x-hidden overflow-y-hidden">
      <SimpleBanner
        image={bannerImage}
        name="Evaluate Your Career"
        path="Home / Programs / Evaluate Your Career"
      />
      {/* <YourFullPotential/> */}
      <NextBigStep />
      <SkillsFrom />

      <div className="lg:px-20 md:px-10 px-5 py-10">
        <div className="mt-8">
          <ContactUsForm />
          <BusinessCard />
        </div>
      </div>
    </div>
  );
};

export default EvaluateYourCareer;
