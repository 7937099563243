import React, { useEffect, useState } from "react";
import whyJoinProgramImg from "../../../Assets/Programs/whyJoinProgramImg.svg";
import downloadLogo from "../../../Assets/Programs/downloadLogo.svg";
import callLogo from "../../../Assets/Programs/callLogo.svg";
import courseImg from "../../../Assets/Programs/courseImg.svg";
import planLogo from "../../../Assets/Programs/planLogo.svg";
import iconPlan from "../../../Assets/Programs/iconPlan.svg";
import bgWeReady from "../../../Assets/Programs/bgWeReady.svg";
import { IoCallSharp } from "react-icons/io5";
import KeyFeatureRightLogo from "../../../Assets/Programs/keyFeatureRightLogo.svg";
import CourseStats from "./CourseStats";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import encryptionModule from "../../../components/common/localStorageUtils";

import CourseBanner from "../../../components/common/CourseBanner";
import contact_image from "../../../Assets/Programs/contact_image.png";
import Call_icon from "../../../Assets/Programs/Call_icon.png";
import { GoDotFill } from "react-icons/go";
import { FaCircleCheck, FaFilePdf } from "react-icons/fa6";
import { SlSettings } from "react-icons/sl";

import { BsFillFileEarmarkZipFill } from "react-icons/bs";
import data from "../../../CourseData.json";

import { useAuth } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const tabs = [
  { id: 1, label: "About Course" },
  { id: 2, label: "Course Content" },
  { id: 3, label: "About Publisher" },
  { id: 4, label: "Success Stories" },
  { id: 5, label: "FAQs" },
];

const UXCourseSection = () => {
  const navigate = useNavigate();
  const [currentCourse, setCurrentCourse] = useState({});
  const [defaultPlans, setDefaultPlans] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const location = useLocation();
  const [load, setload] = useState(false);
  const [loadingRelatedCourse, setLoadingRelatedCourse] = useState(false);
  const [getAllCourse, setGetAllCourse] = useState([]);
  const User = encryptionModule.decryptData("userInfo")?.user;
  const [phoneText, setPhoneText] = useState("KGS");
  const { isUserLogin, logout } = useAuth();

  const { id } = useParams();
  const filteredData = data?.find((ele) => ele.id === Number(id));

  const getAllCourses = () => {
    setLoadingRelatedCourse(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/courses/get-all-course`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        setGetAllCourse(response?.data?.courses);
        setLoadingRelatedCourse(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingRelatedCourse(false);
      })
      .finally(() => {
        setLoadingRelatedCourse(false);
      });
  };

  useEffect(() => {
    getAllCourses();
  }, []);

  const getCourseById = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/courses/get-course/${id}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setCurrentCourse(response?.data?.course);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllCoursePlans = () => {
    setload(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/course-plan/get-all-plans`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Response of getAllCoursePlans: ", response.data);
        setDefaultPlans(
          response?.data?.plans?.filter((each) => {
            return each.default_plan == "true";
          })
        );
        setload(false);
      })
      .catch((error) => {
        console.log(error);
        setload(false);
      });
  };

  useEffect(() => {
    getAllCoursePlans();
  }, [id]);

  useEffect(() => {
    getCourseById();
  }, [location.pathname]);
  const formatTitleForUrl = (title) => {
    return title
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/&/g, "and") // Replace & with "and"
      .replace(/_/g, "-") // Replace underscores with hyphens
      .replace(/\//g, "-");
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setPhoneText((prev) => (prev === "KGS" ? "547" : "KGS"));
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const handleAddCourseToCart = (plan) => {
    let data = JSON.stringify({
      user_reference: User?._id,
      type: "course",
      courses: {
        course_plan_reference: plan._id,
        course_reference: id,
      },
      mockInterviews: null,
      resumeReviews: null,
      amount: plan?.price,
      tax: 15,
      totalAmount: plan?.price,
    });

    axios
      .post(`${process.env.REACT_APP_API_KEY}/cart/create`, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        console.log(response.data);
        alert("This Item is added in your cart");
      })
      .catch((error) => {
        console.log(error);
        alert(error?.response?.data?.message);
      });
  };

  if (load) {
    return (
      <div className="overflow-hidden">
        <div className="h-[45vh] bg-gray-50 animate-pulse flex flex-col justify-evenly px-4 md:px-10">
          <div className="h-10 w-1/3 bg-gray-200 rounded"></div>
          <div className="h-10 w-2/3 bg-gray-200 rounded"></div>
          <div className="h-28 w-full md:w-3/5 bg-gray-200 rounded"></div>
          <div className="flex gap-4">
            <div className="h-10 w-1/3 md:w-1/4 bg-gray-200 rounded"></div>
            <div className="h-10 w-1/3 md:w-1/4 bg-gray-200 rounded"></div>
          </div>
        </div>
        <div className="bg-gray-50 p-4 lg:p-10">
          <div className="bg-blue-50 grid grid-cols-2 md:grid-cols-4 gap-4 mb-4 py-8 animate-pulse w-full">
            <div className="flex flex-col items-center">
              <div className="h-10 w-20 bg-gray-200 rounded-lg mb-4"></div>
              <div className="h-6 w-32 bg-gray-200 rounded-lg"></div>
            </div>
            <div className="flex flex-col items-center">
              <div className="h-10 w-20 bg-gray-200 rounded-lg mb-4"></div>
              <div className="h-6 w-32 bg-gray-200 rounded-lg"></div>
            </div>
            <div className="flex flex-col items-center">
              <div className="h-10 w-20 bg-gray-200 rounded-lg mb-4"></div>
              <div className="h-6 w-32 bg-gray-200 rounded-lg"></div>
            </div>
            <div className="flex flex-col items-center">
              <div className="h-10 w-20 bg-gray-200 rounded-lg mb-4"></div>
              <div className="h-6 w-32 bg-gray-200 rounded-lg"></div>
            </div>
          </div>

          <div className="max-w-full lg:p-10 mx-auto grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div className="lg:col-span-2 space-y-8 ">
              <div className="animate-pulse">
                <div className="h-10 bg-gray-200 rounded w-5/6 mb-4"></div>
                <div className="h-48 bg-gray-200 rounded"></div>
              </div>
              <div className="animate-pulse">
                <div className="h-10 w-2/5 bg-gray-200 rounded mb-5"></div>
                <div className="grid grid-cols-2 gap-4 w-full bg-gray-100 rounded p-5">
                  <div className="w-1/2 h-6 bg-gray-200 rounded"></div>
                  <div className="w-1/2 h-6 bg-gray-200 rounded"></div>
                  <div className="w-1/2 h-6 bg-gray-200 rounded"></div>
                  <div className="w-1/2 h-6 bg-gray-200 rounded"></div>
                  <div className="w-1/2 h-6 bg-gray-200 rounded"></div>
                  <div className="w-1/2 h-6 bg-gray-200 rounded"></div>
                </div>
              </div>

              <div className="bg-gray-100 p-5 rounded animate-pulse">
                <div className="h-10 w-[50%] bg-gray-200 rounded mb-4"></div>
                <div className="h-[65vh] bg-gray-200 rounded"></div>
              </div>

              <div className="bg-gray-100 p-5 rounded animate-pulse grid grid-cols-1 md:grid-cols-2 gap-2">
                <div>
                  <div className="h-6 bg-gray-200 rounded mb-4"></div>
                  <div className="h-20 bg-gray-200 rounded"></div>
                </div>
                <div>
                  <div className="h-6 bg-gray-200 rounded mb-4"></div>
                  <div className="h-20 bg-gray-200 rounded"></div>
                </div>
                <div>
                  <div className="h-6 bg-gray-200 rounded mb-4"></div>
                  <div className="h-20 bg-gray-200 rounded"></div>
                </div>
                <div>
                  <div className="h-6 bg-gray-200 rounded mb-4"></div>
                  <div className="h-20 bg-gray-200 rounded"></div>
                </div>
              </div>

              {/* Skills Covered */}
              <div className="animate-pulse">
                <div className="h-10 w-[40%] bg-gray-200 rounded mb-4"></div>
                <div className="grid grid-cols-2 gap-4 w-full bg-gray-100 rounded p-5">
                  <div className="w-1/2 h-6 bg-gray-200 rounded"></div>
                  <div className="w-1/2 h-6 bg-gray-200 rounded"></div>
                  <div className="w-1/2 h-6 bg-gray-200 rounded"></div>
                  <div className="w-1/2 h-6 bg-gray-200 rounded"></div>
                  <div className="w-1/2 h-6 bg-gray-200 rounded"></div>
                  <div className="w-1/2 h-6 bg-gray-200 rounded"></div>
                </div>
              </div>
            </div>

            <div className="space-y-8 ">
              <div className="h-36 w-auto bg-gray-100 rounded p-6 animate-pulse">
                <div className="h-4 bg-gray-200 rounded w-2/5 mb-4"></div>
                <div className="h-10 bg-gray-200 rounded"></div>
              </div>

              <div className="bg-[#EBF1F8] py-10 px-6 rounded-md shadow-lg animate-pulse">
                <div className="h-7 bg-gray-200 rounded w-[85%] mx-auto mb-4"></div>
                <div className="h-20 bg-gray-200 rounded"></div>
                <div className="h-10 bg-gray-200 rounded mx-auto my-2"></div>
                <div className="h-10 bg-gray-200 rounded mx-auto my-2"></div>
                <div className="h-10 bg-gray-200 rounded mx-auto my-2"></div>
                <div className="h-5 bg-gray-200 rounded mx-auto my-3"></div>
                <div className="h-10 bg-gray-200 rounded mx-auto my-2"></div>
              </div>

              <div className="bg-[#fffef6] px-6 py-10 rounded-md shadow-lg animate-pulse">
                <div className="h-5 bg-gray-200 rounded w-28"></div>
                <div className="h-12 bg-gray-200 rounded my-4"></div>
                <div className="h-10 bg-gray-200 rounded"></div>
              </div>

              <div className="h-8 bg-gray-200 rounded w-2/3 mx-auto animate-pulse"></div>
              <div className="bg-gray-200 px-6 py-10 animate-pulse">
                <div className="h-32 w-auto bg-gray-100 rounded p-6 animate-pulse my-3">
                  <div className="h-4 bg-gray-200 rounded w-2/5 mb-4"></div>
                  <div className="h-10 bg-gray-200 rounded"></div>
                </div>
                <div className="h-32 w-auto bg-gray-100 rounded p-6 animate-pulse mt-3">
                  <div className="h-4 bg-gray-200 rounded w-2/5 mb-4"></div>
                  <div className="h-10 bg-gray-200 rounded"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="mx-4 lg:mx-10 my-10 lg:my-0 animate-pulse">
            <div className="h-10 bg-gray-200 w-4/5 md:w-2/5 rounded"></div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-6">
              <div className="bg-gray-100 p-10">
                <div className="w-[50%] bg-gray-200 h-5 mb-3 mx-auto rounded"></div>
                <div className="w-[45%] bg-gray-200 h-8 mx-auto mb-3 rounded"></div>
                <div className="w-16 h-16 rounded-full bg-gray-200 mx-auto mb-4"></div>
                <div className="h-5 bg-gray-200 mb-3 w-2/3 rounded"></div>
                <div className="h-5 bg-gray-200 mb-3 w-2/4 rounded"></div>
                <div className="h-5 bg-gray-200 mb-3  rounded"></div>
                <div className="h-5 bg-gray-200 mb-3 w-3/5 rounded"></div>
                <div className="h-5 bg-gray-200 mb-3 w-1/3 rounded"></div>
                <div className="h-5 bg-gray-200 mb-3 w-5/6 rounded"></div>
                <div className="h-5 bg-gray-200 mb-16 w-4/6 rounded"></div>
                <div className="h-10 bg-gray-200 w-[55%] mx-auto mb-4"></div>
                <div className="flex justify-center gap-4">
                  <div className="h-10 bg-gray-200 w-[45%] rounded-lg"></div>
                  <div className="h-10 bg-gray-200 w-[45%] rounded-lg"></div>
                </div>
              </div>
              <div className="bg-gray-100 p-10">
                <div className="w-[50%] bg-gray-200 h-5 mb-3 mx-auto rounded"></div>
                <div className="w-[45%] bg-gray-200 h-8 mx-auto mb-3 rounded"></div>
                <div className="w-16 h-16 rounded-full bg-gray-200 mx-auto mb-4"></div>
                <div className="h-5 bg-gray-200 mb-3 w-2/3 rounded"></div>
                <div className="h-5 bg-gray-200 mb-3 w-2/4 rounded"></div>
                <div className="h-5 bg-gray-200 mb-3 "></div>
                <div className="h-5 bg-gray-200 mb-3 w-3/5 rounded"></div>
                <div className="h-5 bg-gray-200 mb-3 w-1/3 rounded"></div>
                <div className="h-5 bg-gray-200 mb-3 w-5/6 rounded"></div>
                <div className="h-5 bg-gray-200 mb-16 w-4/6 rounded"></div>
                <div className="h-10 bg-gray-200 w-[55%] mx-auto mb-4"></div>
                <div className="flex justify-center gap-4">
                  <div className="h-10 bg-gray-200 w-[45%] rounded-lg"></div>
                  <div className="h-10 bg-gray-200 w-[45%] rounded-lg"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="h-36 bg-gray-100 mx-4 lg:mx-0 rounded-xl my-6 px-6 py-10">
            <div className="h-6 bg-gray-200 w-2/6 lg:w-1/6 rounded"></div>
            <div className="mt-4 flex gap-4 lg:gap-10">
              <div className="h-12 bg-gray-200 w-3/5 rounded"></div>
              <div className="h-12 bg-gray-200 w-1/5 rounded"></div>
              <div className="h-12 bg-gray-200 w-1/5 rounded"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="overflow-x-hidden overflow-y-hidden">
      <CourseBanner
        title={currentCourse?.title}
        description={currentCourse?.description}
        rating={currentCourse?.ratings}
        reviews={currentCourse?.reviews}
        category={currentCourse?.category?.name}
        BannerImg={currentCourse?.courseBanner}
        published={currentCourse?.published_by?.name}
      />
      <div className="bg-gray-50 p-4 lg:p-10">
        {/* <CourseStats /> */}
        <CourseStats currentCourse={currentCourse} />
        <div className="max-w-full lg:p-10 mx-auto grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Left Column: Main Content */}
          <div className="lg:col-span-2 space-y-8">
            {/* Title and Description */}
            <div>
              <h1 className="text-2xl lg:text-4xl tracking-wider  leading-normal font-medium mb-4">
                {currentCourse.title}
              </h1>
              <p className="text-[#737588] text-md tracking-wider lg:text-base">
                {currentCourse.description}
              </p>
            </div>

            {/* Key Features */}
            <div>
              <h2 className="text-2xl tracking-wide lg:text-4xl text-[#034D8E] font-semibold mb-4">
                Key Features
              </h2>
              <ul className="grid  grid-cols-1 md:grid-cols-2 gap-2 text-[#1C1C25] text-sm lg:text-base">
                {currentCourse?.about_course?.key_points?.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className="flex tracking-wider items-center gap-2"
                    >
                      <img
                        className="h-5"
                        src={KeyFeatureRightLogo}
                        alt={"keyFeaturesLogo" + index}
                      />{" "}
                      {item}
                    </li>
                  );
                })}
              </ul>
            </div>

            {/* Why Join this Program */}
            <div className="space-y-4">
              <h2 className="text-2xl lg:text-4xl text-[#034D8E] tracking-wide font-semibold ">
                Why Join this Program
              </h2>
              <div className="overflow-hidden">
                <img
                  src={whyJoinProgramImg}
                  alt="Program Image"
                  className="w-full  h-full rounded-lg shadow-lg"
                />
                {/* <button className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white rounded-lg">
                <span className="text-5xl">▶</span>
              </button> */}
              </div>
              {/* <p className="text-gray-700 text-sm lg:text-base">
              Develop skills for real career growth. Learn from experts actively
              working in the industry with real-world projects.
            </p> */}

              <ul className="grid  grid-cols-1 md:grid-cols-2 gap-2 text-[#1C1C25] text-sm lg:text-base">
                {[
                  {
                    title: "Learn from Industry Experts",
                    description:
                      "Develop skills for real career growth. Learn from experts actively working in the industry with real-world projects.",
                  },
                  {
                    title: "Real-World Projects",
                    description:
                      "Work with real-world projects and gain hands-on experience in industry.",
                  },
                  {
                    title: "Career Growth",
                    description:
                      "Gain the skills and knowledge needed to land a job in the tech industry.",
                  },
                  {
                    title: "Networking Opportunities",
                    description:
                      "Network with other professionals in the tech industry and build strong relationships.",
                  },
                ]?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="flex flex-col tracking-wider gap-2"
                    >
                      <h5 className="text-left text-[#1C1C25] text-md font-semibold ">
                        {item.title}
                      </h5>
                      <p className="text-[#737588] text-sm">
                        {" "}
                        {item.description}
                      </p>
                    </div>
                  );
                })}
              </ul>
            </div>

            {/* Skills Covered */}
            <div>
              <h2 className="text-2xl lg:text-4xl text-[#034D8E] tracking-wide font-semibold  mb-4">
                Why Us ?
              </h2>
              <ul className="grid grid-cols-1 md:grid-cols-2 gap-2 text-gray-700 text-sm lg:text-base">
                {currentCourse?.about_course?.why_choose_this_course?.map(
                  (item, index) => {
                    return (
                      <li
                        key={index}
                        className="flex tracking-wider items-center gap-2"
                      >
                        <img
                          className="h-5"
                          src={KeyFeatureRightLogo}
                          alt={"keyFeaturesLogo" + index}
                        />{" "}
                        {item}
                      </li>
                    );
                  }
                )}
              </ul>
            </div>
          </div>

          {/* Right Column: Contact and Related Courses */}
          <div className="space-y-8 ">
            {/* Contact Information */}
            <div className="bg-white p-6 rounded-lg shadow-lg flex  justify-between  space-y-2">
              <div className="flex flex-col gap-4">
                <h3 className="text-sm text-[#737588] font-semibold">
                  Contact Us
                </h3>
                <p className="text-gray-800 text-2xl md:text-2xl font-medium">
                  +1 (770) 8383-{phoneText}
                </p>
              </div>
              <div className="  border-l border-gray-500 pl-8 text-right ">
                <img className="w-full h-16" src={callLogo} alt="call Logo" />
              </div>
            </div>

            {/* Inquiry Form */}
            <div className="bg-[#EBF1F8] p-6 rounded-md shadow-lg ">
              <h4 className="font-medium text-xl font-sans  mb-2 tracking-wide text-center text-[#101A29]">
                Want to know more about this !
              </h4>
              <p className="text-[#737588] tracking-wider text-sm text-center">
                Fill the form to get more information form us. After submit the
                details, our team has contact with you.
              </p>
              <form className="space-y-3 mt-4">
                <input
                  type="text"
                  placeholder="Your Name"
                  className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <input
                  type="email"
                  placeholder="Your Email"
                  className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <input
                  type="tel"
                  placeholder="Your Phone"
                  className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <label className="flex items-center space-x-2 text-sm">
                  <input type="checkbox" />
                  <span>
                    By providing your contact details, you agree to our Privacy
                    Policy
                  </span>
                </label>
                <button
                  type="submit"
                  className="w-full bg-yellow-500 text-white py-2 rounded-md hover:bg-yellow-600"
                >
                  Submit Now
                </button>
              </form>
            </div>

            {/* Brochure Link */}
            <div className="p-6 bg-[#FFFEF6] rounded-md shadow-lg  flex flex-col gap-4">
              <h4 className="font-semibold border-l-2 pl-2 border-orange-400">
                Brochure
              </h4>
              <p className="text-sm lg:text-md text-gray-500 tracking-wider font-sans">
                Existence its certainly explained how improving household
                pretended.
              </p>
              <a
                href="#"
                className="text-[#0E2B3D] font-medium text-sm mt-2   flex items-center gap-2 "
              >
                <img
                  className="w-8 h-full"
                  src={downloadLogo}
                  alt="downloadlogo"
                />{" "}
                <span className="flex-">Download Table of Content</span>
              </a>
            </div>

            {/* Related Courses */}
            <h3 className="text-2xl text-center font-semibold">
              Related Courses
            </h3>
            <div className="bg-white  space-y-4 ">
              {loadingRelatedCourse ? (
                <p className="text-center">Loading Related Courses</p>
              ) : (
                <ul className="space-y-3 max-h-[400px] overflow-y-scroll decrease-scrollbar-width">
                  {getAllCourse
                    ?.filter((course) => course._id !== currentCourse?._id)
                    .map((course, index) => (
                      <li
                        key={index}
                        className="flex p-6 rounded-lg shadow-md  items-center space-x-3"
                      >
                        <div className="flex flex-col gap-2">
                          <h4 className="font-semibold text-xl  text-gray-800">
                            {course?.title}
                          </h4>
                          <p className="text-xs">
                            {course?.description.substring(0, 100)}
                          </p>
                          <p
                            className="text-sm text-blue-500 cursor-pointer "
                            onClick={() =>
                              navigate(
                                `/course/${formatTitleForUrl(course?.title)}/${
                                  course._id
                                }`
                              )
                            }
                          >
                            View Details
                          </p>
                        </div>
                        <img
                          src={course?.courseBanner}
                          alt="Course Thumbnail"
                          className="w-16"
                        />
                      </li>
                    ))}
                </ul>
              )}
            </div>
          </div>

          {/* Choose Your Plan */}
        </div>
        <div className="w-full mb-8 py-4 lg:mx-10 flex flex-col">
          <h2 className="text-2xl  lg:text-left tracking-wide lg:text-4xl text-[#034D8E] font-semibold mb-4">
            Choose Your Plan
          </h2>
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8 ">
            {defaultPlans.map((plan, index) => (
              <div
                key={index}
                className="flex flex-col gap-4 shadow-xl rounded p-8 items-center relative "
              >
                <h2 className="text-[#F8B003] text-sm tracking-wide">
                  {plan?.features_included} Features Included
                </h2>
                <p className="text-3xl tracking-wider font-medium text-[#5B6C74]">
                  {plan?.name}
                </p>
                <img
                  className="h-16 w-full mb-2"
                  src={planLogo}
                  alt="planLogo"
                />
                <ul className="h-64 overflow-y-auto decrease-scrollbar-width ">
                  {plan?.key_points?.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className="text-md tracking-wide flex gap-2 items-center text-[#838694]"
                      >
                        <img src={iconPlan} alt="iconPlan" />
                        {item}
                      </li>
                    );
                  })}
                </ul>

                <div className="flex flex-col gap-4 w-full p-4 float-end">
                  <h2 className="text-4xl tracking-wider font-bold text-center">
                    ${plan.price}
                    <span className="text-sm text-[#838694] ">/per month</span>
                  </h2>
                  <div className="flex flex-col md:flex-row gap-4  justify-around ">
                    <button
                      className="px-4 py-2  rounded-md bg-[#F8B003] text-white flex-1"
                      onClick={() =>
                        isUserLogin
                          ? handleAddCourseToCart(plan)
                          : navigate("/login")
                      }
                    >
                      Enroll Now
                    </button>
                    <button className="px-4 py-2 rounded-md  bg-[#674DF3] text-white flex-1">
                      Quick Guide
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* Business Card */}
        <BusinessCard />
      </div>
    </div>
  );
};

export default UXCourseSection;

function BusinessCard() {
  return (
    <div
      style={{
        backgroundImage: `url(${bgWeReady} )`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right",
      }}
      className="p-10  flex xl:flex-row flex-col xl:items-end justify-between lg:gap-0 gap-8 rounded-xl relative"
    >
      <div className="xl:w-[65%]">
        <p className="text-white">Need Any Consultations ?</p>
        <h1 className="text-white md:text-4xl text-2xl md:mt-8 mt-2 tracking-widest">
          We,re Ready to Growth IT Business
        </h1>
      </div>
      <div className="flex md:flex-row flex-col md:items-center justify-between xl:w-[35%] text-white md:gap-0 gap-6">
        <div>
          <button className="bg-[#ECAB06] px-8 rounded-md py-3 hover:bg-[#CC8302] active:bg-[#A35C05]">
            Get Free Quote
          </button>
        </div>
        <div className="flex items-center gap-2">
          <div>
            <div className="bg-white text-black w-10 h-10 rounded-full flex items-center justify-center">
              <IoCallSharp color="gray" />
            </div>
          </div>
          <div>
            <p>Hotline</p>
            <p>+1 (770) 8383-KGS</p>
          </div>
        </div>
      </div>
    </div>
  );
}
