import React, { useEffect } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import { IoMdAddCircle } from "react-icons/io";
import { useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import axios from "axios";
import { useForm } from "react-hook-form";
import encryptionModule from "../../common/localStorageUtils";

const CreateCourseModal = ({ setCourseModal, setRefresh, refresh }) => {
  const cloudName = "dnw1ttr02";
  const upload_preset = "wjs1zyo5";

  const adminData = encryptionModule.decryptData("adminInfo");
  const AdminId = adminData?.user?._id;

  const { register, handleSubmit } = useForm();
  const [load, setload] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [keyPoints, setKeyPoints] = useState("");
  const [keyPointArr, setKeyPointArr] = useState([]);
  const [chooseCourse, setChooseCourse] = useState("");
  const [chooseCourseArr, setChooseCourseArr] = useState([]);
  const [courseImg, setCourseImg] = useState([]);
  const [arrOfCourseImg, setArrOfCourseImg] = useState([]);
  const [category, setCategory] = useState([]);
  console.log(keyPointArr);

  const handleKeyPointsChange = () => {
    let arr = [...keyPointArr];
    arr.push(keyPoints);
    setKeyPointArr(arr);
    setKeyPoints("");
  };
  const handleChooseCourse = () => {
    let arr = [...chooseCourseArr];
    arr.push(chooseCourse);
    setChooseCourseArr(arr);
    setChooseCourse("");
  };

  console.log(arrOfCourseImg);
  const handleCourseImg = () => {
    if (arrOfCourseImg?.length < 2) {
      setArrOfCourseImg([...arrOfCourseImg, courseImg]);
      setCourseImg([]);
    } else {
      alert("Maximum 2 images can be added .");
    }
  };

  const removeKeyPoint = (each, itemIndex) => {
    const newArr = keyPointArr.filter((each, index) => {
      return index != itemIndex;
    });
    setKeyPointArr(newArr);
  };
  const removeCourseArr = (each, itemIndex) => {
    const newArr = chooseCourseArr.filter((each, index) => {
      return index != itemIndex;
    });
    setChooseCourseArr(newArr);
  };

  const removeCourseImg = (each, itemIndex) => {
    const newArr = arrOfCourseImg.filter((each, index) => {
      return index != itemIndex;
    });
    setArrOfCourseImg(newArr);
  };

  const getAllCatagory = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/categories/get-all-categories`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        setCategory(response?.data?.categories);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    const formFields = [
      "title",
      "aboutCourseTitle",
      "mydescription",
      "ratings",
      "reviews",
      "published_by",
      "hours_of_course",
      "total_assignments",
      "video_lessons",
      "students_enrolled",
      // "key_points",
      // "images",
      // "why_choose_this_course",
      "least_details",
      "detail",
    ];

    console.log(document.getElementsByName("description")[0]);
    formFields.forEach((field) => {
      const element = document.getElementsByName(field)[0];
      if (!element || !element.value) {
        formIsValid = false;
        errors[field] = "This field is required";
      }
    });

    setFormErrors(errors);
    return formIsValid;
  };

  const handleCourseCreate = (data) => {
    console.log(data);

    if (handleValidation(data)) {
      if (data.category == "Select Category") {
        setFormErrors((prev) => {
          return { ...prev, category: "This field is required" };
        });
        return;
      }

      if (keyPointArr.length == 0) {
        setFormErrors((prev) => {
          return { ...prev, keyPoint: "This field is required" };
        });
        return;
      }
      if (arrOfCourseImg.length == 0) {
        setFormErrors((prev) => {
          return { ...prev, courseImg: "This field is required" };
        });

        return;
      }
      if (chooseCourseArr.length == 0) {
        setFormErrors((prev) => {
          return { ...prev, chooseCourse: "This field is required" };
        });
        return;
      }

      console.log(arrOfCourseImg[0][0]);
      if (arrOfCourseImg?.length != 2) {
        alert("Please select 2 images, in order to submit.");
        return;
      }

      setload(true);
      const newArr = [];
      arrOfCourseImg?.forEach((eachfile) => {
        const file = eachfile[0];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", upload_preset);
        formData.append("public_id", `KGS/${eachfile[0].name}`);
        axios
          .post(
            `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
            formData
          )
          .then((response) => {
            // console.log(response?.data?.secure_url);
            newArr.push(response?.data?.secure_url);
            console.log(newArr);

            if (newArr.length == 2) {
              const about_course = {
                title: data?.aboutCourseTitle,
                details: data?.detail,
                key_points: keyPointArr,
                images: newArr,
                why_choose_this_course: chooseCourseArr,
                last_details: data?.least_details,
              };

              const wholeCourseData = {
                ...data,
                description: data?.mydescription,
                published_by: AdminId,
                category: data?.category,
                about_course: about_course,
              };

              console.log(wholeCourseData);

              let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_API_KEY}/courses/create`,
                headers: {
                  "Content-Type": "application/json",
                },
                data: wholeCourseData,
              };

              axios
                .request(config)
                .then((response) => {
                  console.log(response.data);
                  document.getElementById("course").reset();
                  setRefresh(!refresh);
                  alert(
                    "All images Uploaded and Course has been created successfully .."
                  );
                  setCourseModal(false);
                  setload(false);
                })
                .catch((error) => {
                  console.log(error);
                  alert("Course Couldn't be created..!");
                  setload(false);
                });
            }
          })
          .catch((error) => {
            console.log(error);
            alert("Images Couldn't be uploaded");
            setload(false);
          });
      });
    } else {
      if (data.category == "Select Ctegory") {
        setFormErrors((prev) => {
          return { ...prev, category: "This field is required" };
        });
      }

      if (keyPointArr.length == 0) {
        setFormErrors((prev) => {
          return { ...prev, keyPoint: "This field is required" };
        });
      }
      if (arrOfCourseImg.length == 0) {
        setFormErrors((prev) => {
          return { ...prev, courseImg: "This field is required" };
        });
      }
      if (chooseCourseArr.length == 0) {
        setFormErrors((prev) => {
          return { ...prev, chooseCourse: "This field is required" };
        });
      }

      console.log("form has errors");
    }
  };

  useEffect(() => {
    getAllCatagory();
  }, []);

  return (
    <div className="fixed inset-0 z-50 bg-[#0000005d] h-screen w-screen flex justify-center items-center">
      <div className="w-[60rem] bg-customWhite rounded-lg relative ">
        <IoIosCloseCircle
          onClick={() => {
            setCourseModal(false);
          }}
          className="cursor-pointer absolute top-2 right-2"
          size={25}
          color="#846cf9"
        />
        <h1 className="text-center font-semibold my-4">Create Course</h1>

        <div className="h-[30rem]  mx-auto p-6 bg-white shadow-md rounded-md overflow-y-scroll">
          <form
            id="course"
            onSubmit={handleSubmit(handleCourseCreate)}
            className="grid grid-cols-1 sm:grid-cols-2 gap-4"
          >
            {/* Title */}
            <div>
              <label
                htmlFor="title"
                className={`block text-sm font-medium ${
                  formErrors.title ? "text-red-600" : "text-gray-700"
                }`}
              >
                Title
              </label>
              <input
                type="text"
                id="title"
                name="title"
                {...register("title")}
                className={`mt-1 block w-full px-3 py-2 border ${
                  formErrors.title ? "border-red-600" : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                placeholder="Enter course title"
              />
            </div>

            <div>
              <label
                htmlFor="title"
                className={`block text-sm font-medium ${
                  formErrors.mydescription ? "text-red-600" : "text-gray-700"
                }`}
              >
                Description
              </label>
              <input
                type="text"
                id="mydescription"
                name="mydescription"
                {...register("mydescription")}
                className={`mt-1 block w-full px-3 py-2 border ${
                  formErrors.mydescription
                    ? "border-red-600"
                    : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                placeholder="Enter course description"
              />
            </div>

            {/* Category */}
            <div>
              <label
                htmlFor="category"
                className={`block text-sm font-medium ${
                  formErrors.category ? "text-red-600" : "text-gray-700"
                }`}
              >
                Category
              </label>
              <select
                id="category"
                name="category"
                {...register("category")}
                // onChange={(e)=>{
                //      setCatId()
                // }}
                className={`mt-1 block w-full px-3 py-2 border ${
                  formErrors.category ? "border-red-600" : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
              >
                <option value="Select Ctegory" selected>
                  Select Category
                </option>
                {category.map((each, index) => {
                  return (
                    <option key={index} value={each?._id}>
                      {each?.name}
                    </option>
                  );
                })}
                {/* Add options here */}
              </select>
            </div>

            {/* Ratings */}
            <div>
              <label
                htmlFor="ratings"
                className={`block text-sm font-medium ${
                  formErrors.ratings ? "text-red-600" : "text-gray-700"
                }`}
              >
                Ratings
              </label>
              {/* <input
                type="number"
                id="ratings"
                name="ratings"
                {...register("ratings")}
                className={`mt-1 block w-full px-3 py-2 border ${
                  formErrors.ratings ? "border-red-600" : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                placeholder="Enter ratings"
              /> */}
              <select
                id="ratings"
                name="ratings"
                {...register("ratings")}
                // onChange={(e)=>{
                //      setCatId()
                // }}
                className={`mt-1 block w-full px-3 py-2 border ${
                  formErrors.ratings ? "border-red-600" : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                {/* Add options here */}
              </select>
            </div>

            {/* Reviews */}
            <div>
              <label
                htmlFor="reviews"
                className={`block text-sm font-medium ${
                  formErrors.reviews ? "text-red-600" : "text-gray-700"
                }`}
              >
                Reviews
              </label>
              <input
                type="number"
                id="reviews"
                name="reviews"
                {...register("reviews")}
                className={`mt-1 block w-full px-3 py-2 border ${
                  formErrors.reviews ? "border-red-600" : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                placeholder="Enter number of reviews"
              />
            </div>

            {/* Published By */}
            <div>
              <label
                htmlFor="published_by"
                className={`block text-sm font-medium ${
                  formErrors.published_by ? "text-red-600" : "text-gray-700"
                }`}
              >
                Published By
              </label>
              <select
                id="published_by"
                name="published_by"
                {...register("published_by")}
                className={`mt-1 block w-full px-3 py-2 border ${
                  formErrors.published_by ? "border-red-600" : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
              >
                <option value="Admin">Admin</option>
                {/* Add options here */}
              </select>
            </div>

            {/* Hours of Course */}
            <div>
              <label
                htmlFor="hours_of_course"
                className={`block text-sm font-medium ${
                  formErrors.hours_of_course ? "text-red-600" : "text-gray-700"
                }`}
              >
                Hours of Course
              </label>
              <input
                type="number"
                id="hours_of_course"
                name="hours_of_course"
                {...register("hours_of_course")}
                className={`mt-1 block w-full px-3 py-2 border ${
                  formErrors.hours_of_course
                    ? "border-red-600"
                    : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                placeholder="Enter hours of course"
              />
            </div>

            {/* Total Assignments */}
            <div>
              <label
                htmlFor="total_assignments"
                className={`block text-sm font-medium ${
                  formErrors.total_assignments
                    ? "text-red-600"
                    : "text-gray-700"
                }`}
              >
                Total Assignments
              </label>
              <input
                type="number"
                id="total_assignments"
                name="total_assignments"
                {...register("total_assignments")}
                className={`mt-1 block w-full px-3 py-2 border ${
                  formErrors.total_assignments
                    ? "border-red-600"
                    : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                placeholder="Enter total assignments"
              />
            </div>

            {/* Video Lessons */}
            <div>
              <label
                htmlFor="video_lessons"
                className={`block text-sm font-medium ${
                  formErrors.video_lessons ? "text-red-600" : "text-gray-700"
                }`}
              >
                Video Lessons
              </label>
              <input
                type="number"
                id="video_lessons"
                name="video_lessons"
                {...register("video_lessons")}
                className={`mt-1 block w-full px-3 py-2 border ${
                  formErrors.video_lessons
                    ? "border-red-600"
                    : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                placeholder="Enter video lessons"
              />
            </div>

            {/* Students Enrolled */}
            <div>
              <label
                htmlFor="students_enrolled"
                className={`block text-sm font-medium ${
                  formErrors.students_enrolled
                    ? "text-red-600"
                    : "text-gray-700"
                }`}
              >
                Students Enrolled
              </label>
              <input
                type="number"
                id="students_enrolled"
                name="students_enrolled"
                {...register("students_enrolled")}
                className={`mt-1 block w-full px-3 py-2 border ${
                  formErrors.students_enrolled
                    ? "border-red-600"
                    : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                placeholder="Enter students enrolled"
              />
            </div>

            {/* ----------------------------------------------- */}

            <div>
              <label
                htmlFor="About Course Title"
                className={`block text-sm font-medium ${
                  formErrors.aboutCourseTitle ? "text-red-600" : "text-gray-700"
                }`}
              >
                About Course Title
              </label>
              <input
                type="text"
                id="aboutCourseTitle"
                name="aboutCourseTitle"
                {...register("aboutCourseTitle")}
                className={`mt-1 block w-full px-3 py-2 border ${
                  formErrors.aboutCourseTitle
                    ? "border-red-600"
                    : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                placeholder="Enter course title"
              />
            </div>
            <div>
              <label
                htmlFor="detail"
                className={`block text-sm font-medium ${
                  formErrors.detail ? "text-red-600" : "text-gray-700"
                }`}
              >
                About Course Details
              </label>
              <input
                type="text"
                id="detail"
                name="detail"
                {...register("detail")}
                className={`mt-1 block w-full px-3 py-2 border ${
                  formErrors.detail ? "border-red-600" : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                placeholder="Enter more details about the course"
              />
            </div>

            {/* Least Details */}
            <div className="col-span-2">
              <label
                htmlFor="least_details"
                className={`block text-sm font-medium ${
                  formErrors.least_details ? "text-red-600" : "text-gray-700"
                }`}
              >
                Last Details
              </label>
              <input
                type="text"
                id="least_details"
                name="least_details"
                {...register("least_details")}
                className={`mt-1 block w-full px-3 py-2 border ${
                  formErrors.least_details
                    ? "border-red-600"
                    : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                placeholder="Enter last details"
              />
            </div>

            {/* Key Points */}
            <div className="col-span-2">
              <label
                htmlFor="key_points"
                className={`block text-sm font-medium ${
                  formErrors.keyPoint ? "text-red-600" : "text-gray-700"
                }`}
              >
                Key Points
              </label>
              <div className="flex items-center gap-2">
                <input
                  type="text"
                  id="key_points"
                  name="key_points"
                  // {...register("key_points")}
                  value={keyPoints}
                  onChange={(e) => {
                    setKeyPoints(e.target.value);
                  }}
                  className={`mt-1 flex-grow block w-full px-3 py-2 border ${
                    formErrors.keyPoint ? "border-red-600" : "border-gray-300"
                  } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  placeholder="Enter key points, separated by commas"
                />
                <IoMdAddCircle
                  onClick={() => {
                    handleKeyPointsChange();
                  }}
                  className="cursor-pointer"
                  size={25}
                  color="#846cf9"
                />
              </div>
              <div className="mt-2 rounded-lg bg-gray-50 px-2 py-2 ">
                <ul className="flex flex-wrap items-center gap-1">
                  {keyPointArr?.map((each, index) => {
                    return (
                      <li
                        key={index}
                        className="flex items-center gap-1 rounded-full bg-customPurple px-2 py-1 text-sm text-white"
                      >
                        {each.substring(0, 30)}
                        <IoCloseSharp
                          onClick={() => {
                            removeKeyPoint(each, index);
                          }}
                          size={20}
                          className="font-semibold cursor-pointer"
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            {/* Images */}
            <div className="col-span-2">
              <label
                htmlFor="images"
                className={`block text-sm font-medium ${
                  formErrors.courseImg ? "text-red-600" : "text-gray-700"
                }`}
              >
                Images
              </label>
              <div className="flex items-center gap-2">
                <input
                  type="file"
                  id="images"
                  name="images"
                  filename={courseImg[0]?.name}
                  onChange={(e) => {
                    const images = e.target.files[0];
                    setCourseImg([...courseImg, images]);
                  }}
                  className={`mt-1 block w-full px-3 py-2 border ${
                    formErrors.courseImg ? "border-red-600" : "border-gray-300"
                  } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                />
                <IoMdAddCircle
                  onClick={handleCourseImg}
                  className="cursor-pointer"
                  size={25}
                  color="#846cf9"
                />
              </div>
              <div className="mt-2 rounded-lg bg-gray-50 px-2 py-2 ">
                <ul className="flex flex-wrap items-center gap-1">
                  {arrOfCourseImg?.map((each, index) => {
                    return (
                      <li className="flex items-center gap-1 rounded-full bg-customPurple px-2 py-1 text-sm text-white">
                        {each[0]?.name}
                        <IoCloseSharp
                          onClick={() => {
                            removeCourseImg(each?.name, index);
                          }}
                          size={20}
                          className="font-semibold cursor-pointer"
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            {/* Why Choose This Course */}
            <div className="col-span-2">
              <label
                htmlFor="why_choose_this_course"
                className={`block text-sm font-medium ${
                  formErrors.chooseCourse ? "text-red-600" : "text-gray-700"
                }`}
              >
                Why Choose This Course
              </label>
              <div className="flex items-center gap-2">
                <input
                  type="text"
                  id="why_choose_this_course"
                  name="why_choose_this_course"
                  value={chooseCourse}
                  onChange={(e) => {
                    setChooseCourse(e.target.value);
                  }}
                  className={`mt-1 block w-full px-3 py-2 border ${
                    formErrors.chooseCourse
                      ? "border-red-600"
                      : "border-gray-300"
                  } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  placeholder="Enter reasons, separated by commas"
                />
                <IoMdAddCircle
                  onClick={() => {
                    handleChooseCourse();
                  }}
                  className="cursor-pointer"
                  size={25}
                  color="#846cf9"
                />
              </div>

              <div className="mt-2 rounded-lg bg-gray-50 px-2 py-2 ">
                <ul className="flex flex-wrap items-center gap-1">
                  {chooseCourseArr?.map((each, index) => {
                    return (
                      <li
                        key={index}
                        className="flex items-center gap-1 rounded-full bg-customPurple px-2 py-1 text-sm text-white"
                      >
                        {each.substring(0, 30)}
                        <IoCloseSharp
                          onClick={() => {
                            removeCourseArr(each, index);
                          }}
                          size={20}
                          className="font-semibold cursor-pointer"
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            {/* Submit Button */}
            <div className="col-span-2 flex justify-center">
              <button
                type="submit"
                className={`w-[7rem] px-4 py-2 ${
                  load ? "bg-gray-400" : "bg-customPurple"
                }  text-white  rounded-md shadow-sm font-semibold hover:bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
              >
                {load ? "Submiting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateCourseModal;
