import React from "react";
import marketResearch from "../../Assets/picimgs/job-market-icon.svg";
import jobMatching from "../../Assets/picimgs/customized-job-icon.svg";
import jobBoard from "../../Assets/picimgs/job-board-icon.svg";
import searchTools from "../../Assets/picimgs/job-search-icon.svg";
import employerAccess from "../../Assets/picimgs/employee-access-icon.svg";
import applicationTracking from "../../Assets/picimgs/application-tracking-icon.svg";
import resumeReview from "../../Assets/picimgs/resume-review-icon.svg";
import groomingSessions from "../../Assets/picimgs/grooming-session-icon.svg";
import comingSoon from "../../Assets/picimgs/comingSoon.png";
import feedBack from "../../Assets/picimgs/feedback-icon.svg";

const data = [
  {
    id: 1,
    img: marketResearch,
    heading: "Job Market Research",
    contents:
      "Gain insights into current job market trends, industry demands, and employer expectations to make informed decisions and target your job search effectively.",
  },

  {
    id: 2,
    img: jobMatching,
    heading: "Customized Job Matching",
    contents:
      "Involves tailoring the job search process to align closely with your individual skills, experience, and career goals. The roles and opportunities presented to you are specifically suited to your professional profile",
  },

  {
    id: 3,
    img: jobBoard,
    heading: "Access to Job Board ",
    contents:
      "Offers exclusive listings, industry-specific boards, and advanced search tools, keeping you updated with new job postings and enhancing your visibility to potential employers",
  },

  {
    id: 4,
    img: searchTools,
    heading: "Comprehensive Search Tools",
    contents:
      "Leverage advanced search tools and filters to quickly identify job openings that align with your skills, experience, and preferences",
  },

  {
    id: 5,
    img: employerAccess,
    heading: "Direct Employer Access",
    contents:
      "Enjoy exclusive access to job openings and direct referrals to leading employers within your field, streamlining the application process",
  },

  {
    id: 6,
    img: applicationTracking,
    heading: "Application Tracking",
    contents:
      " Receive assistance with tracking your job applications, follow-ups, and responses to ensure you stay organized and informed throughout the process.",
  },

  {
    id: 7,
    img: resumeReview,
    heading: "Resume Review",
    contents:
      " You’ll receive personalized feedback. We assess its clarity, effectiveness, and ATS friendliness, helping you present your skills and qualifications in the best light possible",
  },

  {
    id: 8,
    img: groomingSessions,
    heading: "Grooming Sessions",
    contents:
      "Sharpen your technical skills and develop essential interpersonal skills with our technical and behavioral grooming sessions.",
  },

  {
    id: 9,
    img: feedBack,
    heading: "360° Feedback Analysis",
    contents:
      "Get feedback from mentors, peers, and mock interviewers to understand strengths and weaknesses. Personalized action plans for continuous improvement.",
  },
];

function OutStandingFeatures() {
  return (
    <div className="items-center bg-[#EAF9FF] p-6 md:p-16">
      <div className="flex flex-col items-center gap-8">
        <div>
          <h1 className="text-3xl md:text-[36px] mb-3  font-bold text-[#101A29] capitalize tracking-wide text-center">
            Our <span className="text-[#F8B003]">Outstanding</span> Features
          </h1>
          <p className="w-full  text-center text-[#737588] ">
            Discover what our program offers
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8">
          {data.map((item) => (
            <div
              key={item.id}
              className="bg-white shadow-md flex flex-col p-4 md:p-6 lg:p-8 items-center text-center gap-4"
            >
              <img src={item.img} alt="An icon" className="w-16" />
              <h1 className="text-[#212529] text-xl font-semibold">
                {item.heading}
              </h1>
              <p className="text-[#737588]">{item.contents}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default OutStandingFeatures;
