import React, { useEffect, useState } from "react";
import { LuPhone } from "react-icons/lu";
import { GoMail } from "react-icons/go";
import { LuClock } from "react-icons/lu";
import { FaFacebookF } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";

const TopNavbar = () => {
  const [phoneText, setPhoneText] = useState("KGS");

  const phone = {
    text: `+1 (770) 8383-${phoneText}`,
    path: "tel:+1770838383",
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setPhoneText((prev) => (prev === "KGS" ? "547" : "KGS"));
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="bg-[#23272D] py-4 text-white px-20 flex justify-between items-center hidden lg:flex">
      <div className="flex items-center gap-5 text-sm">
        <div className="flex items-center gap-2 pr-4 border-r-2 border-[#F8B003]">
          <LuPhone />{" "}
          <span className="montserrat">
            <a href={phone.path}>{phone.text}</a>
          </span>
        </div>
        <div className="flex items-center gap-2 pr-4 border-r-2 border-[#F8B003]">
          <GoMail />{" "}
          <span className="montserrat">
            <a href="mailto:info@kgstechnologygroup.com">
              info@kgstechnologygroup.com
            </a>
          </span>
        </div>
        <div className="flex items-center gap-2">
          <LuClock />{" "}
          <span className="montserrat"> Mon - Fri: 8:00AM - 6:00PM</span>
        </div>
      </div>
      <div className="flex items-center gap-5 text-lg">
        <a href="https://www.facebook.com/">
          <FaFacebookF />
        </a>
        <a href="https://twitter.com/">
          <FaTwitter />
        </a>
        <a href="https://www.youtube.com/">
          <FaYoutube />
        </a>
        <a href="https://www.linkedin.com/">
          <FaLinkedinIn />
        </a>
      </div>
    </div>
  );
};

export default TopNavbar;
