import Login from "../screens/nonAuth/Login";
import Register from "../screens/nonAuth/Register";
import Dashboard from "../screens/auth/Dashboard";
import Home from "../screens/users/Home";
import Setting from "../screens/auth/Setting";
import About from "../screens/users/About";
import Contact from "../screens/users/Contact";
import ForgotPassword from "../screens/nonAuth/ForgotPassword";
import Services from "../screens/users/Services";
import KnowledgeCenter from "../screens/users/KnowledgeCenter";
import Support from "../screens/users/Support";
// import Industry from "../screens/users/Industry";
import HealthCare from "../screens/users/industry/HealthCare";
import RemoteBootCamp from "../screens/users/programs/RemoteBootCamp";
import EvaluateYourCareer from "../screens/users/programs/EvaluateYourCareer";
import Jobs from "../screens/users/programs/Jobs";
import JobDetail from "../screens/users/programs/JobDetail";
import CourseDetails from "../screens/users/programs/CourseDetails";
import ResumeReview from "../screens/users/programs/ResumeReview";
import MockInterviewCoaching from "../screens/users/programs/MockInterviewCoaching";
import { ApplicationDevelopment } from "../screens/users/Services/ApplicationDevelopment";
import Course from "../screens/auth/Course.jsx";
import MicrosoftServices from "../screens/users/Services/MicrosoftServices.jsx";
import BusinessAnalysisService from "../screens/users/Services/BusinessAnalysisService.jsx";
import BusinessIntelligenceandDataAnalytics from "../screens/users/Services/BusinessIntelligenceandDataAnalytics.jsx";
import QATesting from "../screens/users/Services/QATesting.jsx";
import ITStaffingDeliveryModel from "../screens/users/Services/ITStaffingDeliveryModel.jsx";
import SCODSpecialtyConsultant from "../screens/users/Services/SCODSpecialtyConsultant.jsx";
import KGSProductSuite from "../screens/users/Services/KGSProductSuite.jsx";
import AdminJobs from "../screens/auth/AdminJobs.jsx";
import AdminErrorPage from "../screens/AdminErrorPage.jsx";
import Plans from "../screens/auth/Plans.jsx";
import Cart from "../screens/users/Cart.jsx";
import Profile from "../screens/users/Profile.jsx";
import Issues from "../screens/auth/Issues.jsx";
import Transactions from "../screens/auth/Transactions.jsx";
import CoursePage from "../screens/users/programs/Course.jsx";
import Automotive from "../screens/users/industry/Automotive.jsx";
import Banking from "../screens/users/industry/Banking.jsx";
import Government from "../screens/users/industry/Government.jsx";
import Infrastructure from "../screens/users/industry/Infrastructure.jsx";
import RealEstate from "../screens/users/industry/RealEstate.jsx";
import Telecom from "../screens/users/industry/Telecom.jsx";
import ECommerce from "../screens/users/industry/ECommerce.jsx";
import FullTimeJobProgram from "../screens/users/programs/FullTimeJobProgram.jsx";
import CourseCreatePage from "../screens/auth/CourseCreatePage.jsx";
import EditCoursePage from "../screens/auth/EditCoursePage.jsx";
import Internship from "../screens/users/programs/Internship.jsx";
import OurJobOpenings from "../screens/users/programs/OurJobOpenings.jsx";
import Career from "../screens/users/Career.jsx";

export const authRoutes = [
  {
    path: "/dashboard",
    component: <Dashboard />,
  },
  {
    path: "/settings",
    component: <Setting />,
  },
  {
    path: "/course",
    component: <Course />,
  },
  {
    path: "/createCourse",
    component: <CourseCreatePage />,
  },
  {
    path: "/editCourse/:id",
    component: <EditCoursePage />,
  },
  {
    path: "/plans",
    component: <Plans />,
  },
  {
    path: "/admin-jobs",
    component: <AdminJobs />,
  },
  {
    path: "/issues",
    component: <Issues />,
  },
  {
    path: "/transactions",
    component: <Transactions />,
  },
  {
    path: "*",
    component: <AdminErrorPage />,
  },
];

export const nonAuthRoutes = [
  {
    path: "/login",
    component: <Login />,
  },
  {
    path: "/register",
    component: <Register />,
  },
  {
    path: "/forgot-password",
    component: <ForgotPassword />,
  },
];

export const userRoutes = [
  {
    path: "/",
    component: <Home />,
  },
  {
    path: "/program/internship",
    component: <Internship />,
  },
  {
    path: "/about-us",
    component: <About />,
  },
  {
    path: "/services",
    component: <Services />,
  },
  {
    path: "/program/remote-boot-camp",
    component: <RemoteBootCamp />,
  },
  {
    path: "/program/full-time-job-assistance-program",
    component: <FullTimeJobProgram />,
  },
  {
    path: "/program/ResumeReview",
    component: <ResumeReview />,
  },
  {
    path: "/program/mock-interview-coaching",
    component: <MockInterviewCoaching />,
  },
  {
    path: "/program/evaluate-your-career",
    component: <EvaluateYourCareer />,
  },
  {
    path: "/services/application-development",
    component: <ApplicationDevelopment />,
  },
  {
    path: "/services/microsoft-services",
    component: <MicrosoftServices />,
  },
  {
    path: "/services/business-analysis-service",
    component: <BusinessAnalysisService />,
  },
  {
    path: "/services/business-intelligence-and-data-analytics",
    component: <BusinessIntelligenceandDataAnalytics />,
  },
  {
    path: "/services/qa-testing",
    component: <QATesting />,
  },
  {
    path: "/services/it-staffing-delivery-model",
    component: <ITStaffingDeliveryModel />,
  },
  {
    path: "/services/scod-specialty-consultant-on-demand",
    component: <SCODSpecialtyConsultant />,
  },
  {
    path: "/services/kgss-product-suite",
    component: <MicrosoftServices />,
  },
  {
    path: "/services/business-analysis-service",
    component: <BusinessAnalysisService />,
  },
  {
    path: "/services/business-intelligence-and-data-analytics",
    component: <BusinessIntelligenceandDataAnalytics />,
  },
  {
    path: "/services/qa-testing",
    component: <QATesting />,
  },
  {
    path: "/services/it-staffing-delivery-model",
    component: <ITStaffingDeliveryModel />,
  },
  {
    path: "/services/scod-specialty-consultant-on-demand",
    component: <SCODSpecialtyConsultant />,
  },
  {
    path: "/services/kgss-product-suite",
    component: <KGSProductSuite />,
  },
  {
    path: "/knowledge-center",
    component: <KnowledgeCenter />,
  },
  {
    path: "/support",
    component: <Support />,
  },
  {
    path: "/contact",
    component: <Contact />,
  },
  {
    path: "/jobs",
    component: <Jobs />,
  },
  {
    path: "/jobs/details/:id",
    component: <JobDetail />,
  },
  {
    path: "/course/:title/:id",
    component: <CourseDetails />,
  },
  {
    path: "/courses",
    component: <CoursePage />,
  },
  {
    path: "/cart",
    component: <Cart />,
  },
  {
    path: "/profile",
    component: <Profile />,
  },
  {
    path: "/industry/health-care",
    component: <HealthCare />,
  },
  {
    path: "/industry/automotive",
    component: <Automotive />,
  },
  {
    path: "/industry/banking",
    component: <Banking />,
  },
  {
    path: "/industry/e-commerce",
    component: <ECommerce />,
  },
  {
    path: "/industry/government",
    component: <Government />,
  },
  {
    path: "/industry/infrastructure",
    component: <Infrastructure />,
  },
  {
    path: "/industry/realEstate",
    component: <RealEstate />,
  },
  {
    path: "/industry/telecom",
    component: <Telecom />,
  },
  {
    path: "/program/evaluate-your-career/view-job-openings",
    component: <OurJobOpenings />,
  },
  {
    path: "/program/join-our-stem",
    component: <Career />,
  },
];
