import React, { useEffect } from "react";
import bg from "../../Assets/Home/BusinessCard_bg.png";
import { IoCallSharp } from "react-icons/io5";
import AOS from "aos";
import "aos/dist/aos.css";

const BusinessCard = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right",
      }}
      className="p-10 flex xl:flex-row flex-col xl:items-end justify-between xlg:gap-0 gap-8 rounded-xl relative"
      data-aos="fade-up" // AOS attribute for animation
    >
      <div className="xl:w-[65%]" data-aos="fade-right">
        <p className="text-white">Need Any Consultations ?</p>
        <h1 className="text-white md:text-4xl text-2xl md:mt-8 mt-2 tracking-wider">
          We,re Ready to Growth IT Business
        </h1>
      </div>
      <div
        className="flex md:flex-row flex-col md:items-center justify-between xl:w-[35%] text-white md:gap-0 gap-6"
        data-aos="fade-left"
      >
        <div>
          <button className="bg-primaryColor px-8 rounded-md py-3">
            Get Free Quote
          </button>
        </div>
        <div className="flex items-center gap-2">
          <div>
            <div className="bg-white text-black w-10 h-10 rounded-full flex items-center justify-center">
              <IoCallSharp color="gray" />
            </div>
          </div>
          <div>
            <p>Hotline</p>
            <p>+1 (770) 8383-KGS</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessCard;
