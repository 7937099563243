import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaEdit } from "react-icons/fa";
import { MdDeleteSweep } from "react-icons/md";
import EditResumePlanModal from "../PlansEdit/EditResumePlanModal";
const ResumePlanCard = ({ resumePlanModal }) => {
  const [allResumePlans, setAllResumePlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [currentPlan, setCurrentPlan] = useState({});
  const [loadResumeEditModal, setloadResumeEditModal] = useState(false);

  const planTypes = ["CORE", "PREMIUM", "PREMIUM PLUS"];

  // Fetch all resume plans
  const getAllResumePlans = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/resume-plans/get-all-plans`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        setAllResumePlans(response?.data?.plans);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this plan ?")) {
      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_KEY}/resume-plans/delete/${id}`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response.data);
          getAllResumePlans();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // Update resume plan's default status
  const updatePlanStatus = (planId, isActive) => {
    let config = {
      method: "put",
      url: `${process.env.REACT_APP_API_KEY}/resume-plans/update/${planId}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        default_plan: isActive ? "true" : "false",
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Status updated successfully", response);
        getAllResumePlans(); // Refresh the plans after the update
      })
      .catch((error) => {
        console.log("Error updating status", error);
      });
  };

  useEffect(() => {
    getAllResumePlans();
  }, [resumePlanModal, loadResumeEditModal]);

  const handleToggle = (plan) => {
    // Toggle the plan's active status locally
    const updatedPlans = allResumePlans.map((p) =>
      p._id === plan._id
        ? { ...p, default_plan: p.default_plan === "true" ? "false" : "true" }
        : p
    );
    setAllResumePlans(updatedPlans);

    // Call the update API to persist the change
    updatePlanStatus(plan._id, plan.default_plan === "false");
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-10 justify-center">
      {loadResumeEditModal ? (
        <EditResumePlanModal
          setloadResumeEditModal={setloadResumeEditModal}
          currentPlan={currentPlan}
        />
      ) : (
        ""
      )}
      {allResumePlans.map((plan, index) => (
        <div
          key={plan._id}
          className={`p-8 border rounded-lg shadow-lg transition-transform transform hover:scale-105 ${
            plan.default_plan === "true"
              ? "bg-yellow-500 text-white"
              : "bg-white"
          }`}
        >
          <div className="mb-4">
            <span className="text-4xl font-bold ">${plan.price}</span>
            {/* <span className="text-2xl text-center font-bold  bg-[#DFA72E] px-4 py-2 rounded-lg">
              {planTypes[index % planTypes.length]}
            </span> */}
          </div>
          <h3
            className={`text-xl font-semibold mb-4 rounded-lg ${
              plan.default_plan === "true"
                ? "text-white bg-[#DFA72E] inline-block px-4 py-2"
                : "text-white bg-primaryColor inline-block px-4 py-2"
            }`}
          >
            {plan.type}
          </h3>
          <ul
            className={`mb-6 ${
              plan.default_plan === "true" ? "text-white" : "text-gray-600"
            }`}
          >
            {plan.key_points.map((feature, index) => (
              <li key={index} className="flex space-x-2 mb-2">
                <span>✔️</span>
                <span>{feature}</span>
              </li>
            ))}
          </ul>

          {/* Toggle Default Plan Button */}
          <div className="flex justify-between items-center">
            <a
              href="#"
              className={`py-2 px-6 rounded-lg font-semibold transition ${
                plan.default_plan === "true"
                  ? "bg-white text-primaryColor hover:bg-yellow-100"
                  : "bg-primaryColor text-white hover:bg-yellow-600"
              }`}
            >
              Enroll Now →
            </a>

            {/* Toggle Switch */}
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={plan.default_plan === "true"} // Convert string to boolean
                onChange={() => handleToggle(plan)}
                className="sr-only peer"
              />
              <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            </label>
            <div className=" absolute top-3 right-3 flex justify-end items-center gap-2">
              <FaEdit
                onClick={() => {
                  setloadResumeEditModal(true);
                  setCurrentPlan(plan);
                }}
                size={28}
                className="cursor-pointer font-medium text-blue-600 dark:text-blue-500 hover:underline"
              />
              <MdDeleteSweep
                onClick={() => {
                  handleDelete(plan._id);
                }}
                size={30}
                className="font-medium text-red-600 dark:text-red-500 hover:underline  cursor-pointer"
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ResumePlanCard;
