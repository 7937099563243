import React from "react";
import { IoIosCloseCircle } from "react-icons/io";
import axios from "axios";
import { useState } from "react";
import { IoMdAddCircle } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";

const CreateResumePlan = ({ setResumePlanModal }) => {
  const [key_points, setKeyPoints] = useState("");
  const [keyPointsArr, setKeypointsArr] = useState([]);
  const [resume, setResume] = useState({
    price: "",
    type: "",
  });

  const handleKeypointsChange = () => {
    const arr = [...keyPointsArr];
    arr.push(key_points);
    setKeypointsArr(arr);
    setKeyPoints("");
  };

  const removeKeyPoint = (item, itemIndex) => {
    const filterKeyPoints = keyPointsArr.filter((each, index) => {
      return index != itemIndex;
    });

    setKeypointsArr(filterKeyPoints);
  };

  const handleInputChange = (name, value) => {
    setResume((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleResumeSubmit = (e) => {
    e.preventDefault();
    console.log(resume);

    const myData = {
      price: resume?.price,
      type: resume?.type,
      key_points: keyPointsArr,
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/resume-plans/create`,
      headers: {
        "Content-Type": "application/json",
      },
      data: myData,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        alert("Resume Created SuccessFully");
        setResume({});
        setResumePlanModal(false);
        setKeypointsArr([]);
      })
      .catch((error) => {
        console.log(error);
        alert("Resume Couldn't be Created..!");
      });
  };

  return (
    <div className="fixed inset-0 z-50 bg-[#0000005d] h-screen w-screen flex justify-center items-center">
      <div className="w-[30rem] bg-customWhite rounded-lg relative ">
        <IoIosCloseCircle
          onClick={() => {
            setResumePlanModal(false);
          }}
          className="cursor-pointer absolute top-2 right-2"
          size={25}
          color="#846cf9"
        />
        <h1 className="text-center font-semibold tracking-wide my-4">
          Create Resume Plan
        </h1>
        <div>
          <form
            onSubmit={handleResumeSubmit}
            class="w-full max-w-lg mx-auto p-4"
          >
            <div class="mb-4">
              <label
                class="block text-gray-700 text-sm tracking-wide mb-2"
                for="price"
              >
                Price
              </label>
              <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="price"
                type="number"
                value={resume?.price}
                onChange={(e) => {
                  handleInputChange("price", e.target.value);
                }}
                placeholder="Enter price"
                required
              />
            </div>

            <div class="mb-4">
              <label
                class="block text-gray-700 text-sm mb-2 tracking-wide"
                for="type"
              >
                Type
              </label>
              <select
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="type"
                value={resume?.type}
                onChange={(e) => {
                  handleInputChange("type", e.target.value);
                }}
                required
              >
                <option value="">Select Type</option>
                <option value="Review over Mail">Review over Mail</option>
                <option value="Live Video Call">Live Video Call</option>
              </select>
            </div>

            {/* <div class="mb-4">
              <label
                class="block text-gray-700 text-sm font-bold mb-2"
                for="key_points"
              >
                Key Points (comma separated)
              </label>
              <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="key_points"
                type="text"
                value={resume?.keyPoints}
                placeholder="Enter key points (comma separated)"
                required
              />
            </div> */}

            <div className="col-span-2">
              <label
                htmlFor="key_points"
                className={`block text-sm font-medium
                  text-gray-700
                `}
              >
                Key Points
              </label>
              <div className="flex items-center gap-2">
                <input
                  type="text"
                  id="key_points"
                  name="key_points"
                  // {...register("key_points")}
                  value={key_points}
                  onChange={(e) => {
                    setKeyPoints(e.target.value);
                  }}
                  className={`mt-1 flex-grow block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  placeholder="Enter key points, separated by commas"
                />
                <IoMdAddCircle
                  onClick={() => {
                    handleKeypointsChange();
                  }}
                  className="cursor-pointer"
                  size={25}
                  color="#846cf9"
                />
              </div>
              <div className="mt-2 rounded-lg bg-gray-50 px-2 py-2 ">
                <ul className="flex flex-wrap items-center gap-1">
                  {keyPointsArr?.map((each, index) => {
                    return (
                      <li
                        key={index}
                        className="flex items-center gap-1 rounded-full bg-customPurple px-2 py-1 text-sm text-white"
                      >
                        {each.substring(0, 30)}
                        <IoCloseSharp
                          onClick={() => {
                            removeKeyPoint(each, index);
                          }}
                          size={20}
                          className="font-semibold cursor-pointer"
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <div class="flex items-center justify-between">
              <button
                class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateResumePlan;
