import React, { useEffect, useState } from "react";
import axios from "axios";
import encryptionModule from "../common/localStorageUtils";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const PlanSection = () => {
  const [plans, setPlans] = useState([]);
  const [loadingPlanId, setLoadingPlanId] = useState(null); // Track the ID of the plan being added to the cart
  const User = encryptionModule.decryptData("userInfo")?.user;
  const { isUserLogin, logout } = useAuth();
  const navigate = useNavigate();

  // Fetch all resume plans
  const getAllResumePlans = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/resume-plans/get-all-plans`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        // Filter plans to only those with default_plan set to true
        const filteredPlans = response.data.plans.filter(
          (plan) => plan.default_plan === "true"
        );
        setPlans(filteredPlans);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Add Mock Plans To cart...
  const handleAddToCart = (plan) => {
    setLoadingPlanId(plan._id); // Set loading for the specific plan
    if (!isUserLogin) {
      navigate("/login");
      setLoadingPlanId(null); // Reset loading if user is not logged in
      return;
    }

    let data = JSON.stringify({
      user_reference: User?._id,
      type: "resumeReview",
      resumeReviews: {
        resume_review_plan: plan?._id,
      },
      courses: null,
      mockInterviews: null,
      amount: plan?.price,
      tax: 10,
      totalAmount: plan?.price,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/cart/create`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        alert("This Item is added to your cart");
        setLoadingPlanId(null); // Reset loading once the request is complete
      })
      .catch((error) => {
        console.log(error);
        alert(error?.response?.data?.message);
        setLoadingPlanId(null); // Reset loading if the request fails
      });
  };

  useEffect(() => {
    getAllResumePlans();
  }, []);

  const planTypes = ["CORE", "PREMIUM", "PREMIUM PLUS"];

  return (
    <div id="resume-plans" className="bg-white py-5 px-5 md:px-20">
      {/* Section Title */}
      <div className="text-center mb-10">
        <p className="text-yellow-500 uppercase text-sm tracking-widest">
          Pricing
        </p>
        <h2 className="text-3xl font-medium text-gray-800 mt-2">
          Select a <span className="text-yellow-500">Plan</span> Based on Your
          Needs
        </h2>
      </div>

      {/* Pricing Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 justify-center">
        {plans.map((plan, index) => (
          <div
            key={plan._id}
            className={`p-8 border flex flex-col justify-between  rounded-lg shadow-lg transition-transform transform hover:scale-105 ${
              plan.default_plan === "true"
                ? "bg-yellow-500 text-white"
                : "bg-white"
            }`}
          >
            <div>
              <div className="mb-4 flex justify-between items-center">
                <span className="text-4xl font-bold">${plan.price}</span>
                <span className="text-2xl text-center font-bold  bg-[#DFA72E] px-4 py-2 rounded-lg">
                  {planTypes[index % planTypes.length]}
                </span>
                {/* <span className="text-lg font-medium">/Per Session</span> */}
              </div>
              {/* <h3
                className={`text-xl font-semibold mb-4 rounded-lg ${
                  plan.default_plan === "true"
                    ? "text-white bg-[#DFA72E] inline-block px-4 py-2"
                    : "text-white bg-primaryColor inline-block px-4 py-2"
                }`}
              >
                {plan.type}
              </h3> */}
              <ul
                className={`mb-6 ${
                  plan.default_plan === "true" ? "text-white" : "text-gray-600"
                }`}
              >
                {plan.key_points.map((feature, index) => (
                  <li key={index} className="flex space-x-2 mb-2">
                    <span>✔️</span>
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="w-fit mx-auto">
              <button
                className={`py-3 px-6 rounded-lg w-[14rem] outline-none font-semibold transition ${
                  plan.default_plan === "true"
                    ? "mt-4 bg-white mx-3 text-yellow-500  hover:bg-yellow-100 transition"
                    : "bg-primaryColor text-white hover:bg-yellow-600"
                }`}
              >
                Enroll Now →
              </button>
              {/* Add to Cart Button */}
              <button
                onClick={() => {
                  handleAddToCart(plan);
                }}
                className={` py-3 px-6 mt-4 mx-3 rounded-lg w-[14rem] outline-none font-semibold transition ${
                  plan.default_plan === "true"
                    ? " bg-white  text-yellow-500  hover:bg-yellow-100"
                    : "bg-primaryColor text-white hover:bg-yellow-600"
                }`}
              >
                {loadingPlanId === plan._id ? "Adding..." : "Add To Cart"}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlanSection;
