import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const PayPalButton = ({ totalAmount,handlePurchased }) => {
  return (
    <PayPalScriptProvider options={{ "client-id": "AXbFLJYLF29ENZ0rxWW5Bx-0VDmd2bU1Vr9XMCGZaH95I-Ioyy8QSUgjowNiiL0IzRSJK9dA8keJoJpv", currency: "USD", intent: "capture" }}>
      <PayPalButtons
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: totalAmount.toFixed(2), // The total amount
                  currency_code: 'USD', // Change 'USD' to the currency you want to use
                },
              },
            ],
          });
        }}
        onApprove={async (data, actions) => {
          const order = await actions.order.capture();
          console.log("Order details: ", order);
          // Handle successful payment here
          handlePurchased()
        }}
        onError={(err) => {
          console.error("PayPal Checkout onError", err);
        }}
      />
    </PayPalScriptProvider>
  );
};

export default PayPalButton;
