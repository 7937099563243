import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import AboutUs10YearExperience from "../../Assets/AboutUs/AboutUs10YearExperience.png";
import WinningCompany from "../../Assets/AboutUs/WinningCompany.png";
import SolutionRocket from "../../Assets/AboutUs/SolutionRocket.png";
import HeadingWithDot from "../common/HeadingWithDot";

const AboutUs = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with a 1000ms duration
  }, []);

  return (
    <div>
      <div className="grid xl:grid-cols-2 grid-cols-1 gap-10">
        <div data-aos="fade-right">
          <img
            src={AboutUs10YearExperience}
            alt="about us"
            className="w-full"
          />
        </div>
        <div data-aos="fade-left">
          <HeadingWithDot name="About Us" />
          <h1 className="md:text-4xl text-3xl tracking-wider font-semibold md:leading-[50px] mt-2">
            A Structured Approach to Delivering
            <span className="text-primaryColor"> High-Quality </span>
            Solutions
          </h1>
          <p className="text-TextSecondaryColor mt-2 w-[90%] font-normal tracking-wide">
            KGS Technology Group is driven to deliver innovative customized
            services that address the diverse needs of clients across numerous
            industries.With a strategic focus on expanding its core practices,
            KGS consistently delivers cutting-edge designs that drive meaningful
            results. Our consultants are celebrated for their exceptional
            technical expertise, deep business insights, and unwavering
            commitment to excellence. Operating on a global scale, KGS proudly
            serves clients from offices in both the United States and India,
            ensuring world-class service and support wherever it's needed.
          </p>
          <div className="grid md:grid-cols-2 grid-cols-1 md:gap-4 gap-2 mt-4">
            <div className="w-full rounded-md" data-aos="zoom-in">
              <img src={WinningCompany} alt="" className="w-16" />
              <h1 className="mt-6 text-normal">Awards Winning Company</h1>
              <p className="text-TextSecondaryColor text-sm tracking-wider mt-2">
                KGS Technology Group is recognized for excellence in IT
                solutions, delivering innovative results to clients.
              </p>
            </div>
            <div className="w-full rounded-md" data-aos="zoom-in">
              <img src={SolutionRocket} alt="" className="w-16" />
              <h1 className="mt-6 text-normal">Startup IT Solution </h1>
              <p className="text-TextSecondaryColor text-sm tracking-wider mt-2">
                We provide tailored IT solutions for startups, empowering
                businesses to grow and succeed in technology.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
