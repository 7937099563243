import React, { useEffect, useState } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function TypeOfPackageSelectionModal({
  setModalOPen,
  plan,
  User,
  isUserLogin,
}) {
  const [noOfTechnicalGrooming, setNoOfTechnicalGrooming] = useState(0);
  const [noOfMockInterview, setNoOfMockInterview] = useState(0);
  const [noOfBehaviouralGrooming, setNoOfBehaviouralGrooming] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  console.log("Plan: ", plan);
  console.log("Size: ", plan.size);
  const maxSize = plan?.size;

  const handleAddToCart = () => {
    if (isUserLogin) {
      setLoading(true);
      if (totalItems !== maxSize) {
        toast.error(`You have to select ${maxSize} items`);
        return;
      }
      let data = {
        user_reference: User?._id,
        type: "package",
        packages: {
          packageId: plan?._id,
          packageName: plan?.packageName,
          technicalGrooming: noOfTechnicalGrooming,
          mockInterview: noOfMockInterview,
          behaviouralGrooming: noOfBehaviouralGrooming,
        },
        courses: null,
        resumeReviews: null,
        amount:
          plan?.discounted_price != 0
            ? plan?.discounted_price
            : plan?.actual_price,
        tax: 10,
        totalAmount:
          plan?.discounted_price != 0
            ? plan?.discounted_price
            : plan?.actual_price,
      };

      console.log("Data to be submitted:", data);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_KEY}/cart/create`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response.data);
          toast.success("This Item is added to your cart");
          setLoading(false);
          setModalOPen(false);
        })
        .catch((error) => {
          console.log(error);
          toast.error(error?.response?.data?.message);
          setLoading(false);
        });
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    setTotalItems(
      noOfTechnicalGrooming + noOfMockInterview + noOfBehaviouralGrooming
    );
  }, [noOfTechnicalGrooming, noOfMockInterview, noOfBehaviouralGrooming]);

  return (
    <div className="fixed inset-0 z-50 bg-[#0000005d] h-screen w-screen flex justify-center items-center">
      <div className="w-[45rem] bg-customWhite rounded-lg relative p-8 max-h-[80%] overflow-y-auto">
        <IoIosCloseCircle
          onClick={() => {
            setModalOPen(false);
          }}
          className="cursor-pointer absolute top-2 right-2 text-[#ECAB06] hover:text-[#CC8302] active:text-[#A35C05] rounded-full"
          size={25}
        />
        <h1 className="font-semibold tracking-wide my-4 text-xl ">
          Select {maxSize} items
        </h1>

        {totalItems === maxSize && (
          <p className="text-red-500 font-medium tracking-wide mb-2">
            You have reached the maximum item limit
          </p>
        )}

        <div className="flex flex-wrap gap-4 justify-center items-center ">
          <div className="flex flex-col w-[18rem] bg-gray-200 shadow-lg text-yellow-500 p-8 rounded-md h=full items-center">
            <h1 className="font-bold text-lg">Technical Grooming</h1>
            <p className="text-gray-800 mt-2">
              Sharpen skills with expert training, tools, and hands-on learning
              for tech excellence.
            </p>
            <div className="flex mt-4 items-center gap-1">
              <FaMinus
                className="bg-white rounded-full p-1 cursor-pointer"
                color="red"
                size={18}
                onClick={() => {
                  {
                    noOfTechnicalGrooming > 0 &&
                      setNoOfTechnicalGrooming(noOfTechnicalGrooming - 1);
                  }
                }}
              />
              <p>{noOfTechnicalGrooming}</p>

              <FaPlus
                className="bg-white rounded-full p-1 cursor-pointer"
                color="green"
                size={18}
                onClick={() => {
                  {
                    totalItems < maxSize &&
                      setNoOfTechnicalGrooming(noOfTechnicalGrooming + 1);
                  }
                }}
              />
            </div>
          </div>

          <div className="flex flex-col w-[18rem] bg-gray-200  shadow-lg text-yellow-500 p-8 rounded-md h-full items-center">
            <h1 className="font-bold ">Mock Interview</h1>
            <p className="text-gray-800 mt-2">
              Build confidence with realistic practice, expert feedback, and
              tailored interview preparation.
            </p>
            <div className="flex mt-4 items-center gap-1">
              <FaMinus
                className="bg-white rounded-full p-1 cursor-pointer"
                color="red"
                size={18}
                onClick={() => {
                  {
                    noOfMockInterview > 0 &&
                      setNoOfMockInterview(noOfMockInterview - 1);
                  }
                }}
              />
              <p>{noOfMockInterview}</p>

              <FaPlus
                className="bg-white rounded-full p-1 cursor-pointer"
                color="green"
                size={18}
                onClick={() => {
                  {
                    totalItems < maxSize &&
                      setNoOfMockInterview(noOfMockInterview + 1);
                  }
                }}
              />
            </div>
          </div>

          <div className="flex flex-col bg-gray-200 text-yellow-500 w-[18rem] p-8 rounded-md mt-2 md:col-span-2 h-full shadow-lg justify-center items-center">
            <h1 className="font-bold ">Behavioural Grooming</h1>
            <p className="text-gray-800 mt-2 ">
              Boost confidence, communication, and emotional intelligence for
              impactful personal and professional growth.
            </p>
            <div className="flex justify-end mt-4 items-center gap-1">
              <FaMinus
                className="bg-white rounded-full p-1 cursor-pointer"
                color="red"
                size={18}
                onClick={() => {
                  {
                    noOfBehaviouralGrooming > 0 &&
                      setNoOfBehaviouralGrooming(noOfBehaviouralGrooming - 1);
                  }
                }}
              />
              <p>{noOfBehaviouralGrooming}</p>

              <FaPlus
                className="bg-white rounded-full p-1 cursor-pointer"
                color="green"
                size={18}
                onClick={() => {
                  {
                    totalItems < maxSize &&
                      setNoOfBehaviouralGrooming(noOfBehaviouralGrooming + 1);
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between gap-2 mt-4 items-center">
          <p>No. of items selected: {totalItems}</p>
          <button
            className="bg-[#ECAB06] hover:bg-[#CC8302] active:bg-[#A35C05] px-4 py-2 rounded-md text-white font-bold shadow-md"
            onClick={handleAddToCart}
          >
            {loading ? "Adding to Cart" : "Add to Cart"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default TypeOfPackageSelectionModal;
