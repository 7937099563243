import React from "react";
import img from "../../Assets/ApplicationDevelopment/image 253.png";
const DataDrivenFuture = () => {
  return (
    <div className="px-5 md:px-20 gap-x-7 md:gap-y-11 flex flex-col lg:flex-row items-center justify-between w-[100%]">
      <div className="lg:w-[50%] w-[100%]">
        <h1 className="text-2xl md:text-4xl font-bold">
          Empower Your Business for{" "}
          <span className="text-blue-500">the Data-Driven Future</span>
        </h1>
        <p className="mt-5 text-lg md:text-xl text-gray-600">
          In today's dynamic business environment, leveraging data to gain
          insights and drive decisions is crucial. Techmentee offers
          cutting-edge Business Intelligence and Data Analytics services to help
          you modernize and optimize your operations for the future. Our key
          offerings include:
        </p>
        <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-4 text-gray-600 text-lg md:text-xl montserrat">
          <div className="flex items-start">
            <span>Data Visualization & Dashboards</span>
          </div>
          <div className="flex items-start">
            <span>Predictive Analytics</span>
          </div>
          <div className="flex items-start">
            <span>Advanced Reporting</span>
          </div>
          <div className="flex items-start">
            <span>Continuous Data Optimization</span>
          </div>
        </div>
        <p className="mt-5 text-lg md:text-xl text-gray-600">
          Is your business lagging behind due to a lack of data-driven insights?
          Our experts can help you harness the power of data to drive your
          business forward. Let's work together to turn your data into
          actionable insights!
        </p>
      </div>

      <div className="w-[100%] lg:w-1/2 mt-10 md:mt-0 flex justify-center">
        <img
          src={img}
          alt="Data Analytics"
          className="rounded-lg w-full object-cover"
        />
      </div>
    </div>
  );
};

export default DataDrivenFuture;
