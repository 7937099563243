import React, { useEffect } from "react";
import img from "../../Assets/ResumeReview/Frame.png";
import AOS from "aos";
import "aos/dist/aos.css";

const ResumeReviewSection = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Set animation duration
      once: true, // To make it run only once
    });
  }, []);

  return (
    <div className="bg-white py-10 px-5 md:px-20 w-full">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div
          className="w-full flex items-center justify-center"
          data-aos="fade-up" // Add animation to this div
        >
          <img
            src={img}
            alt="Resume Review"
            className="rounded-lg mb-5 md:mb-0 w-[80%] max-w-xs md:max-w-none"
          />
        </div>
        <div className="w-full" data-aos="fade-left">
          {" "}
          {/* Add animation to this div */}
          <h2 className="text-2xl md:text-4xl font-medium text-gray-800 mb-2 xl:mb-4">
            Stand Out.<span className="text-yellow-500">Get Noticed.</span> Get
            Hired.
          </h2>
          <p className="text-gray-600 text-[17px] xl:text-lg">
            Let's face it—a basic resume won't cut it in today's job market. You
            might need something that shouts, “I'm the one you've been looking
            for!” That's where we come in. Our Resume Preparation Program
            combines creativity and strategy to craft resumes that don't just
            talk—they command attention.
            <br /> <br />
            Our Resume Workshop program is designed to help you stand out in a
            competitive job market by creating a resume that highlights your
            unique strengths and accomplishments. At KGS, we don't just prepare
            resumes—we prepare opportunities.
          </p>
          <button className="mt-6 bg-[#ECAB06] hover:bg-[#CC8302] active:bg-[#A35C05] outline-none text-white font-semibold py-3 px-4 rounded-lg hover:bg-yellow-600 transition duration-300">
            Enroll Now →
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResumeReviewSection;
