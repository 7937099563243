import React from "react";
import img1 from "../../Assets/ApplicationDevelopment/image.png";
import img2 from "../../Assets/ApplicationDevelopment/image (1).png";
import img3 from "../../Assets/ApplicationDevelopment/image (2).png";

const BusinessIntelligence = () => {
  return (
    <div className="bg-white py-10 px-5 md:px-20">
      <div className="text-center">
        <h1 className="text-3xl md:text-5xl font-bold">
          BUSINESS <span className="text-blue-500">INTELLIGENCE</span> &amp;
          DATA ANALYTICS
        </h1>
        <p className="mt-5 text-lg md:text-xl text-gray-600">
          Leverage the power of data analytics to drive informed decisions and
          accelerate your business growth.
        </p>
      </div>

      <div className="mt-8 text-gray-600 text-center mx-auto">
        <p>
          In today's data-centric world, having a robust BI strategy is critical
          to success. Techmentee helps you harness your data's full potential by
          offering expert BI and Data Analytics services. From transforming raw
          data into actionable insights to ensuring data accuracy and
          availability, we support your business every step of the way.
        </p>
      </div>

      <div className="mt-10 flex flex-col md:flex-row items-center justify-center gap-5">
        <img
          src={img1}
          alt="Data Visualization"
          className="rounded-lg w-full md:w-1/3 object-cover"
        />
        <img
          src={img2}
          alt="Advanced Analytics"
          className="rounded-lg w-full md:w-1/3 object-cover"
        />
        <img
          src={img3}
          alt="Data Reporting"
          className="rounded-lg w-full md:w-1/3 object-cover"
        />
      </div>
    </div>
  );
};

export default BusinessIntelligence;
