import React from "react";
import { IoIosCloseCircle } from "react-icons/io";
import { useState } from "react";
import axios from "axios";

const CreateCategoryModal = ({ setCategoryModal }) => {
  const [formErrors, setFormErrors] = useState({});

  const [categoryName, setCategoryName] = useState("");
  const [load, setload] = useState(false);

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    const formFields = ["categoryName"];
    formFields.forEach((field) => {
      const element = document.getElementsByName(field)[0];
      if (!element || !element.value) {
        formIsValid = false;
        errors[field] = "This field is required";
      }
    });

    setFormErrors(errors);
    return formIsValid;
  };

  const handleCategoryCreate = (e) => {
    e.preventDefault();

    if (handleValidation()) {
      setload(true);
      let myData = {
        name: categoryName,
      };
      console.log(myData);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_KEY}/categories/create`,
        headers: {
          "Content-Type": "application/json",
        },
        data: myData,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response.data);
          document.getElementById("category").reset();
          setCategoryModal(false);
          setload(false);
          alert("Catagory created successfully");
        })
        .catch((error) => {
          console.log(error);
          setload(false);
          alert(`${error?.response?.data?.message}, Category must be Unique.`);
        });
    } else {
      console.log("form has errors");
    }
  };

  return (
    <div className="fixed inset-0 z-50 bg-[#0000005d] h-screen w-screen flex justify-center items-center">
      <div className="w-[30rem] bg-customWhite rounded-lg relative ">
        <IoIosCloseCircle
          onClick={() => {
            setCategoryModal(false);
          }}
          className="cursor-pointer absolute top-2 right-2"
          size={25}
          color="#846cf9"
        />

        <h1 className="text-center font-semibold my-4">Create Category</h1>

        <div className="max-w-md mx-auto p-6 bg-white  rounded-md">
          <form id="category" onSubmit={handleCategoryCreate}>
            <div className="mb-4">
              <label
                htmlFor="categoryName"
                className={`${
                  formErrors.categoryName ? "text-red-600" : "text-gray-700"
                }tracking-wide block text-sm font-medium `}
              >
                Category Name
              </label>
              <input
                type="text"
                id="categoryName"
                name="categoryName"
                value={categoryName}
                onChange={(e) => {
                  setCategoryName(e.target.value);
                }}
                className={`${
                  formErrors.categoryName ? "border-red-600" : "border-gray-300"
                } racking-wide mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                placeholder="Enter category name"
              />
            </div>

            {/* <div className="mb-4">
              <label
                htmlFor="description"
                className={`${
                  formErrors.mydescription ? "text-red-600" : "text-gray-700"
                }block tracking-wide text-sm font-medium`}
              >
                Description
              </label>
              <textarea
                id="mydescription"
                name="mydescription"
                value={mydescription}
                onChange={(e) => {
                  setMyDescription(e.target.value);
                }}
                className={`${
                  formErrors.mydescription
                    ? "border-red-600"
                    : "border-gray-300"
                } tracking-wide mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                placeholder="Enter description"
                rows="4"
              />
            </div> */}

            <div className="col-span-2 flex justify-center">
              <button
                type="submit"
                className={`w-[8rem] px-4 py-2 ${
                  load ? "bg-gray-400" : "bg-customPurple"
                }  text-white font-semibold rounded-md shadow-sm hover:bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
              >
                {load ? "Submiting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateCategoryModal;
