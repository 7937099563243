import React from "react";
import SimpleBanner from "../../components/common/SimpleBanner";
import CareerBanner from "../../Assets/banner/Career.png";
import PartOfGlobalCommunity from "../../components/Career/PartOfGlobalCommunity";
function Career() {
  return (
    <div className="overflow-hidden">
      <SimpleBanner
        image={CareerBanner}
        name="Join Our STEM Community"
        path="Home / Career"
      />
      <PartOfGlobalCommunity />
    </div>
  );
}

export default Career;
