import React from 'react'
import SimpleBanner from "../../../components/common/SimpleBanner";
import AboutUs_banner from "../../../Assets/banner/bussiness.png";
import Development from '../../../components/BusinessAnalysisService/Development';
import LegacyApps from '../../../components/BusinessAnalysisService/LegacyApps';
import CostEfficiency from '../../../components/BusinessAnalysisService/CostEfficiency'
const BusinessAnalysisService = () => {
  return (
    <>
      <SimpleBanner
        image={AboutUs_banner}
        name="Business Analysis Service"
        path="Home / Services / Business Analysis Service"
      />
      <Development/>
      <LegacyApps/>
      <CostEfficiency/>
    </>
  )
}
export default BusinessAnalysisService