import React from "react";
import Courses from "../../../components/programs/remoteBootCamp/Courses";
import SimpleBanner from "../../../components/common/SimpleBanner";
import bannerImage from "../../../Assets/Programs/RemoteBootCamp_banner.png";

const CoursePage = () => {
  return (
    <div className="overflow-x-hidden overflow-y-hidden">
      <SimpleBanner
        image={bannerImage}
        name="Courses"
        path="Home / Courses"
      />
      <Courses />
    </div>
  );
};

export default CoursePage;
