import React from 'react'
import SimpleBanner from "../../../components/common/SimpleBanner";
import AboutUs_banner from "../../../Assets/banner/QATESTING.png";
import QAandTesting from '../../../components/QATesting/QAandTesting';
import QualityAssurance from '../../../components/QATesting/QualityAssurance';
import TestingEfficiency from '../../../components/QATesting/TestingEfficiency';
const QATesting = () => {
  return (
    <>
    <SimpleBanner
      image={AboutUs_banner}
      name="QA & Testing"
      path="Home / Services / QA & Testing"
    />
    <QAandTesting/>
    <QualityAssurance/>
    <TestingEfficiency/>
  </>
  )
}

export default QATesting