import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Navigate } from "react-router-dom";
import encryptionModule from "../../components/common/localStorageUtils";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const AuthLayout = (props) => {
  const navigate = useNavigate();
  // const Data = encryptionModule.decryptData("adminInfo");
  // console.log("Data");

  // useEffect(() => {
  //   if (!Data) {
  //     navigate("/");
  //   }
  // }, []);

  // console.log("Local Storage Admin Info:", localStorage.getItem('adminInfo'));

  if (!encryptionModule.decryptData('adminInfo')) {
      return (
          <Navigate to={{ pathname: '/' }} />
      );
  }

  return (
    <React.Fragment>
      <DashboardLayout>{props.children}</DashboardLayout>
    </React.Fragment>
  );
};

export default AuthLayout;
