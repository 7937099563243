// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import encryptionModule from '../components/common/localStorageUtils';

// const AuthProvider = ({ children }) => {
//   // const navigate = useNavigate();
//   // const [isAuthenticated, setIsAuthenticated] = useState(false);

//   const [isUserLogin, setIsUserLogin] = useState(false)

//   useEffect(() => {
//     const data = encryptionModule.decryptData('userInfo')
//     if (data?.token) {
//       setIsUserLogin(true)
//     } else {
//       setIsUserLogin(false)
//     }
//   }, [])

//   // useEffect(() => {
//   //   const adminInfo = localStorage.getItem('adminInfo');
//   //   if (adminInfo) {
//   //     setIsAuthenticated(true);
//   //   }
//   // }, []);

//   // useEffect(() => {
//   //   if (isAuthenticated) {
//   //     navigate('/dashboard');
//   //   } else {
//   //     // navigate('/login');
//   //     return
//   //   }
//   // }, [isAuthenticated, navigate]);

//   return (
//     <React.Fragment>
//       {children}
//     </React.Fragment>
//   )
// }

// export default AuthProvider

import React, { useState, useEffect, createContext, useContext } from "react";
import encryptionModule from "../components/common/localStorageUtils";
import { useNavigate } from "react-router-dom";
// Create a context for authentication state
const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext); // Hook to use the auth context

const AuthProvider = ({ children }) => {
  const [isUserLogin, setIsUserLogin] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const data = encryptionModule.decryptData("userInfo");
    if (data?.token) {
      setIsUserLogin(true);
    } else {
      setIsUserLogin(false);
    }
  }, []);
  const logout = () => {
    localStorage.removeItem("userInfo");
    setIsUserLogin(false);
    navigate("/login");
  };

  return (
    <AuthContext.Provider value={{ isUserLogin, logout, setIsUserLogin }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
