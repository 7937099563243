import React from 'react'
import SimpleBanner from "../../../components/common/SimpleBanner";
import AboutUs_banner from "../../../Assets/banner/bussiness2.png";
import DataDrivenFuture from '../../../components/BusinessIntelligenceandDataAnalytics/DataDrivenFuture';
import BusinessIntelligence from '../../../components/BusinessIntelligenceandDataAnalytics/BusinessIntelligence';
import DataEfficiency from '../../../components/BusinessIntelligenceandDataAnalytics/DataEfficiency'
const BusinessIntelligenceandDataAnalytics = () => {
  return (
    <>
    <SimpleBanner
      image={AboutUs_banner}
      name="Business Intelligence and Data Analytics"
      path="Home / Services / Business Intelligence and Data Analytics"
    />
    <BusinessIntelligence/>
    <DataDrivenFuture/>
    <DataEfficiency/>
  </>
  )
}

export default BusinessIntelligenceandDataAnalytics