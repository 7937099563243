import React from "react";
import img1 from "../../Assets/imgs/img1.png";
import img2 from "../../Assets/imgs/img2.png";
import img3 from "../../Assets/imgs/img.png";
import img4 from "../../Assets/imgs/img4.png";
const IndependentInternship = () => {
  return (
    <div>
      <div className="text-center p-6">
        <div className="w-[100%] lg:w-[80%]  mt-[2rem] inline-block bg-white border border-gray-200 rounded-lg p-4 shadow-md">
          <p className="text-lg md:text-xl lg:text-4xl font-semibold capitalize">
            Experience,
            <span className="text-blue-500"> Learn and Thrive</span> with us
            <img
              alt="Logo with a circular design and the letters 'KG' in the center"
              className="inline-block ml-2"
              height="40"
              src="https://storage.googleapis.com/a1aa/image/1GoRhGVfFAXUZaU5kzvuJAN916peFjwE5wJ1Hy0oavhJgfunA.jpg"
              width="40"
            />
          </p>
        </div>
        <p className="mt-6 text-lg md:text-xl lg:text-2xl font-semibold">
          The
          <span className="text-yellow-500"> INDEPENDENT </span>
          <span className="text-yellow-500">INTERNSHIP </span>
        </p>
        <p className="mt-4 text-gray-600 text-sm md:text-base lg:text-lg lg:px-16 px-2">
          The Independent Internship program provides you with the opportunity
          to gain practical, hands-on experience in your desired field. Perfect
          for students, recent graduates, and career switchers, this unpaid
          internship offers valuable industry exposure, helping you refine your
          skills, expand your professional network, and establish a solid career
          foundation.
        </p>
      </div>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-10 md:mx-16 mx-6 mt-14">
        <div className="space-y-10">
          <div className="relative py-8 px-6 text-center border rounded-lg shadow-md">
            <div
              style={{ fontFamily: "Oregano, cursive" }}
              className="absolute -top-5 right-6 bg-blue-600 text-white rounded-full w-10 h-10 lg:w-14 lg:h-14 text-2xl lg:text-4xl flex items-center justify-center"
            >
              1
            </div>
            <p className="text-xl font-medium text-gray-800 mb-2 text-start">
              “Real-World Projects”
            </p>
            <p className="text-TextSecondaryColor text-start tracking-wide">
              Tackle assignments that reflect actual industry challenges, giving
              you practical insights and problem-solving abilities.
            </p>
          </div>
          <div className="relative py-8 px-6 text-center border rounded-lg shadow-md">
            <div
              style={{ fontFamily: "Oregano, cursive" }}
              className="absolute -top-5 right-6 bg-blue-600 text-white rounded-full w-10 h-10 lg:w-14 lg:h-14 text-2xl lg:text-4xl flex items-center justify-center"
            >
              2
            </div>
            <p className="text-xl font-medium text-gray-800 mb-2 text-start">
              “Flexible Learning Opportunities”
            </p>
            <p className="text-TextSecondaryColor text-start tracking-wide">
              Work at your own pace and schedule, balancing other commitments
              while gaining valuable experience.
            </p>
          </div>
        </div>
        <div>
          <img src={img1} alt="img" className="mx-auto" />
        </div>
        <div>
          <img src={img2} alt="img" className="mx-auto" />
        </div>
        <div className="space-y-10">
          <div className="relative py-8 px-6 text-center border rounded-lg shadow-md">
            <div
              style={{ fontFamily: "Oregano, cursive" }}
              className="absolute -top-5 right-6 bg-blue-600 text-white rounded-full w-10 h-10 lg:w-14 lg:h-14 text-2xl lg:text-4xl flex items-center justify-center"
            >
              3
            </div>
            <p className="text-xl font-medium text-gray-800 mb-2 text-start">
              “Skill Enhancement”
            </p>
            <p className="text-TextSecondaryColor text-start tracking-wide">
              Develop both technical and soft skills, from project management
              and teamwork to communication and critical thinking.
            </p>
          </div>
          <div className="relative py-8 px-6 text-center border rounded-lg shadow-md">
            <div
              style={{ fontFamily: "Oregano, cursive" }}
              className="absolute -top-5 right-6 bg-blue-600 text-white rounded-full w-10 h-10 lg:w-14 lg:h-14 text-2xl lg:text-4xl flex items-center justify-center"
            >
              4
            </div>
            <p className="text-xl font-medium text-gray-800 mb-2 text-start">
              “Mentorship and Guidance”
            </p>
            <p className="text-TextSecondaryColor text-start tracking-wide">
              Receive support and feedback from experienced professionals to
              help you grow and refine your abilities.
            </p>
          </div>
        </div>
        {/* <div className="space-y-10">
          <div className="relative py-8 px-6 text-center border rounded-lg shadow-md">
            <div
              style={{ fontFamily: "Oregano, cursive" }}
              className="absolute -top-5 right-6 bg-blue-600 text-white rounded-full w-10 h-10 lg:w-14 lg:h-14 text-2xl lg:text-4xl flex items-center justify-center"
            >
              5
            </div>
            <p className="text-xl font-medium text-gray-800 mb-2 text-start">
              “Gain Real World Experience”
            </p>
            <p className="text-TextSecondaryColor text-start tracking-wide">
              Unpaid internships offer practical experience that can’t be
              replicated in a classroom. Working on real projects and tasks
              provides a deeper understanding of the industry and daily job
              responsibilities, which is invaluable for future employment.
            </p>
          </div>
          <div className="relative py-8 px-6 text-center border rounded-lg shadow-md">
            <div
              style={{ fontFamily: "Oregano, cursive" }}
              className="absolute -top-5 right-6 bg-blue-600 text-white rounded-full w-10 h-10 lg:w-14 lg:h-14 text-2xl lg:text-4xl flex items-center justify-center"
            >
              6
            </div>
            <p className="text-xl font-medium text-gray-800 mb-2 text-start">
              “Gain Real World Experience”
            </p>
            <p className="text-TextSecondaryColor text-start tracking-wide">
              Unpaid internships offer practical experience that can’t be
              replicated in a classroom. Working on real projects and tasks
              provides a deeper understanding of the industry and daily job
              responsibilities, which is invaluable for future employment.
            </p>
          </div>
        </div> */}
        {/* <div>
          <img src={img3} alt="img" className="mx-auto" />
        </div> */}
        {/* <div>
          <img src={img4} alt="img" className="mx-auto" />
        </div> */}
        {/* <div className="space-y-10">
          <div className="relative py-8 px-6 text-center border rounded-lg shadow-md">
            <div
              style={{ fontFamily: "Oregano, cursive" }}
              className="absolute -top-5 right-6 bg-blue-600 text-white rounded-full w-10 h-10 lg:w-14 lg:h-14 text-2xl lg:text-4xl flex items-center justify-center"
            >
              7
            </div>
            <p className="text-xl font-medium text-gray-800 mb-2 text-start">
              “Gain Real World Experience”
            </p>
            <p className="text-TextSecondaryColor text-start tracking-wide">
              Unpaid internships offer practical experience that can’t be
              replicated in a classroom. Working on real projects and tasks
              provides a deeper understanding of the industry and daily job
              responsibilities, which is invaluable for future employment.
            </p>
          </div>
          <div className="relative py-8 px-6 text-center border rounded-lg shadow-md">
            <div
              style={{ fontFamily: "Oregano, cursive" }}
              className="absolute -top-5 right-6 bg-blue-600 text-white rounded-full w-10 h-10 lg:w-14 lg:h-14 text-2xl lg:text-4xl flex items-center justify-center"
            >
              8
            </div>
            <p className="text-xl font-medium text-gray-800 mb-2 text-start">
              “Gain Real World Experience”
            </p>
            <p className="text-TextSecondaryColor text-start tracking-wide">
              Unpaid internships offer practical experience that can’t be
              replicated in a classroom. Working on real projects and tasks
              provides a deeper understanding of the industry and daily job
              responsibilities, which is invaluable for future employment.
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default IndependentInternship;
