import React from "react";

function WhyShouldyouJoin() {
  return (
    <div className="flex flex-col justify-center items-center gap-5 mb-12 md:mx-6 lg:mx-0 mx-5 ">
      <h1 className="md:text-4xl text-3xl font-semibold  capitalize tracking-wide text-center ">
        Why should you join this program:
      </h1>
      <p className="w-full lg:w-[70%] text-center   md:text-[20px]  text-TextSecondaryColor">
        Navigating today’s job market can be overwhelming, especially when
        balancing the demands of work, personal life, and the job search
        process. Many job seekers struggle to find the time and focus to apply
        for jobs, all while dealing with the pressure of landing an interview.
        In the rush of daily life, it’s easy to lose sight of what you truly
        want in your career. Our program guides you to clarity, helping you
        connect with opportunities that align with your deeper aspirations.Here,
        discover the Job You’re Meant For.
      </p>
    </div>
  );
}

export default WhyShouldyouJoin;
