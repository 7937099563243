import React from "react";
import CountUp from "react-countup";

const CourseStats = ({currentCourse}) => {
  // console.log('-------------------->',currentCourse);
  
  const stats = [
    { value: currentCourse.hours_of_course, label: "Total Hours" },
    { value: currentCourse.total_assignments, label: "Total Assignments" },
    { value: currentCourse.video_lessons, label: "Video Lessons" },
    { value: currentCourse.students_enrolled, label: "Students Enrolled" },
  ];

  return (
    <div className="min-w-screen bg-blue-100  flex  justify-center  items-center ">
      <div className="min-w-screen overflow-hidden flex  p-10 items-center justify-center lg:gap-20 gap-8 lg:space-x-20  ">
        {stats.map((stat, index) => (
          <div
            key={index}
            className={`text-center flex flex-col  items-center ${
              index < stats.length - 1
                ? "border-r-2  border-blue-700 pr-4 lg:pr-16"
                : ""
            }`}
          >
            <span className="text-orange-500 text-2xl mb-2 lg:text-4xl font-semibold">
              <CountUp
                end={stat.value}
                duration={2}
                suffix={stat.suffix || ""}
              />
            </span>
            <span className="text-gray-700 font-medium text-[10px] lg:text-lg">
              {stat.label}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CourseStats;
