import React, { useState, useEffect } from "react";
import bg from "../../Assets/MockInterview/Frame.png";
import AOS from "aos";
import "aos/dist/aos.css";

const FeatureCard = () => {
  const [openBox, setOpenBox] = useState(null);

  const toggleBox = (box) => {
    setOpenBox(openBox === box ? null : box);
  };

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: "ease-in-out", // Easing function
      once: true, // Only animate once when scrolled into view
    });
  }, []);

  return (
    <div
      className="tracking-wide flex items-center justify-center bg-no-repeat"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "right",
      }}
    >
      <div className="mx-auto px-4 py-8 w-full lg:w-[70%]">
        <h1
          className="text-2xl md:text-3xl lg:text-4xl font-bold text-white mb-8 text-center"
          data-aos="fade-up"
        >
          Here's what you will get
        </h1>

        <div className="flex flex-col lg:flex-row items-center justify-between gap-6 mt-6 mb-14">
          {/* Bottom Left Box */}
          <div
            className="cursor-pointer w-full lg:w-[30%] font-semibold px-8 py-6 text-black bg-white text-center rounded-lg shadow-lg hover:bg-gray-300"
            onClick={() => toggleBox("behavioralGrooming")}
            data-aos="fade-right"
          >
            Behavioral Grooming
            {openBox === "behavioralGrooming" && (
              <div className="mt-4 bg-gray-100 text-gray-700 p-4 rounded-md">
                <ul className="text-start list-disc p-2">
                  <li>90 Mins Virtual Face-To-Face Live Session</li>
                  <li>Soft Skills Development</li>
                  <li>Real-World Scenarios</li>
                  <li>Non-Verbal Communication Coaching</li>
                  <li>Behavioral Response Guide</li>
                </ul>
              </div>
            )}
          </div>

          {/* Bottom Right Box */}
          <div
            className="cursor-pointer w-full lg:w-[30%] font-semibold px-8 py-6 text-black bg-white text-center rounded-lg shadow-lg hover:bg-gray-300"
            onClick={() => toggleBox("technicalGrooming")}
            data-aos="fade-left"
          >
            Technical Grooming
            {openBox === "technicalGrooming" && (
              <div className="mt-4 bg-gray-100 text-gray-700 p-4 rounded-md">
                <ul className="text-start list-disc p-2">
                  <li>90 Mins Virtual Face-To-Face Live Session</li>
                  <li>Personalized Skill Gap Analysis</li>
                  <li>Focus on Latest Tech Tools & Frameworks</li>
                  <li>Advanced Problem-Solving Strategies</li>
                  <li>Real-Time Feedback</li>
                </ul>
              </div>
            )}
          </div>
        </div>

        {/* Top Box */}
        <div
          className="cursor-pointer w-full lg:w-[60%] mx-auto font-semibold px-8 py-6 text-black bg-white text-center rounded-lg shadow-lg hover:bg-gray-300"
          onClick={() => toggleBox("mockInterview")}
          data-aos="fade-up"
        >
          Mock Interview
          {openBox === "mockInterview" && (
            <div className="mt-4 bg-gray-100 text-gray-700 p-4 rounded-md">
              <ul className="text-start list-disc p-2">
                <li>90 Mins Virtual Face-To-Face Live Session</li>
                <li>Actionable Improvement Plan</li>
                <li>Diverse Question Pool</li>
                <li>Interview Strategy</li>
                <li>Custom Mock Interview Report</li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeatureCard;
