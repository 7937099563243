import React, { useEffect, useState } from "react";
import remote_boot_camp_image from "../../../Assets/Programs/remote_boot_camp_image.png";
import ConsultingServices_image from "../../../Assets/Industry/ConsultingServices_image.png";
import EHRImplementation_image from "../../../Assets/Industry/EHRImplementation_image.png";
import Telemedicine_image from "../../../Assets/Industry/Telemedicine_image.png";
import FullTimeJob_image from "../../../Assets/Programs/FullTimeJob_image.png";
import bannerImage from "../../../Assets/Programs/RemoteBootCamp_banner.png";
import SimpleBanner from "../../../components/common/SimpleBanner";
import TopSection from "../../../components/programs/TopSection";
import { color } from "framer-motion";
import {
  PiBagSimpleFill,
  PiClockFill,
  PiAirplaneTiltFill,
  PiBabyFill,
  PiMedalFill,
  PiCurrencyDollarFill,
} from "react-icons/pi"; // Example icons
import { Link } from "react-router-dom";
import axios from "axios";
import { IoLocationOutline } from "react-icons/io5";
import BusinessCard from "../../../components/common/BusinessCard";
import OutStandingFeatures from "../../../components/programs/OutStandingFeatures";
import ApexChart from "../../../components/programs/ApexChart";
import Plans from "../../../components/programs/Plans";
import FAQ from "../../../components/FullTimeJobAssistance/FAQ";
import WhyShouldyouJoin from "../../../components/FullTimeJobAssistance/WhyShouldyouJoin";

const JobCard = ({ job }) => {
  const formattedTitle = job.job_title.toLowerCase().replace(/\s+/g, "-");
  return (
    <Link
      to={`/jobs/${formattedTitle}/${job._id}`}
      className="border border-gray-300 rounded-lg p-6 shadow-md bg-white flex flex-col gap-4"
    >
      <h3 className="text-lg font-semibold">{job.job_title}</h3>
      <div className="flex gap-8 items-center">
        <p className="text-sm text-green-600 bg-green-100 px-3 py-1 rounded-md">
          {job.job_type}
        </p>
        <p className="text-sm text-gray-500">Salary: {job.salary}</p>
      </div>
      <div className="flex items-center">
        <img
          src={job.logo}
          alt={`${job.company} logo`}
          className="w-12 h-12 mr-4 object-cover"
        />
        <div>
          <p className="font-medium text-gray-700">{job.company}</p>
          <div className="flex items-center text-sm text-gray-500">
            <IoLocationOutline className="mr-1" />
            <p>{job.location}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};
const SkeletonLoader = () => {
  return (
    <div className="border border-gray-300 rounded-lg p-6 shadow-md bg-white flex flex-col gap-4 animate-pulse">
      <div className="h-6 bg-gray-300 mb-4"></div>
      <div className="flex gap-8 items-center">
        <div className="h-4 bg-gray-300 w-24"></div>
        <div className="h-4 bg-gray-300 w-16"></div>
      </div>
      <div className="flex items-center">
        <div className="w-12 h-12 bg-gray-300 mr-4"></div>
        <div>
          <div className="h-4 bg-gray-300 w-32 mb-2"></div>
          <div className="h-4 bg-gray-300 w-20"></div>
        </div>
      </div>
    </div>
  );
};

const FullTimeJobProgram = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  // Fetch jobs from API
  const getJobData = () => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_KEY}/jobs/get-all-jobs`,
    };

    axios
      .request(config)
      .then((response) => {
        setJobs(response.data?.jobs);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false after fetching
      });
  };

  useEffect(() => {
    getJobData();
  }, []);

  const employeeBenefits = [
    {
      icon: <PiBagSimpleFill color="white" size={25} />,
      bgColor: "bg-blue-500",
      title: "Design and Creatives",
      description:
        "We encourage creativity and provide opportunities for our employees to engage in design and creative projects, enhancing their innovative skills.",
    },
    {
      icon: <PiClockFill color="white" size={25} />,
      bgColor: "bg-green-500",
      title: "Flexible Hours",
      description:
        "We offer flexible working hours to help employees balance their work and personal life, promoting productivity and well-being.",
    },
    {
      icon: <PiAirplaneTiltFill color="white" size={25} />,
      bgColor: "bg-yellow-500",
      title: "Work and Travel",
      description:
        "Our work and travel policy allows employees to work remotely while exploring new places, providing a perfect blend of professional and personal growth.",
    },
    {
      icon: <PiBabyFill color="white" size={25} />,
      bgColor: "bg-pink-500",
      title: "Paid Parental Leave",
      description:
        "We support our employees during important life moments by offering paid parental leave to ensure they have time with their growing families.",
    },
    {
      icon: <PiMedalFill color="white" size={25} />,
      bgColor: "bg-purple-500",
      title: "Yearly Bonus",
      description:
        "We value our team's contributions and reward their hard work with a yearly bonus that reflects their achievements and dedication.",
    },
    {
      icon: <PiCurrencyDollarFill color="white" size={25} />,
      bgColor: "bg-red-500",
      title: "Smart Salary",
      description:
        "We offer competitive salaries and a smart compensation plan to ensure that our employees are rewarded for their expertise and hard work.",
    },
  ];

  return (
    <div className="overflow-x-hidden overflow-y-hidden">
      <SimpleBanner
        image={bannerImage}
        name="Full-Time Job Assistance Program"
        path="Home / Programs / Full-Time Job Assistance Program"
      />
      <TopSection />
      <OutStandingFeatures />
      <FAQ />

      {/* <ApexChart /> */}
      <Plans />
      <WhyShouldyouJoin />
      {/* <div className="lg:px-20 md:px-10 px-5 py-10">
        <div className="grid xl:grid-cols-2 grid-cols-1 gap-10 ">
          <div className="flex justify-center items-center">
            <div>
              <h1 className="md:text-4xl text-3xl tracking-wider md:leading-[70px] leading-[50px] mt-2">
                Full-Time Job
                <span className="text-primaryColor"> assistance </span>
                Program
              </h1>
              <p className="text-TextSecondaryColor mt-2 w-[90%] font-light tracking-wide">
                The Remote BootCamp program is an intensive, online training
                course designed to equip participants with in-demand skills in a
                short period. Delivered entirely remotely, this program offers a
                flexible learning environment, allowing individuals to
                participate from anywhere in the world.
              </p>
              <p className="text-TextSecondaryColor mt-2 w-[90%] font-light tracking-wide">
                Transform your career with our Remote BootCamp program. Choose
                from a wide range of 60 courses designed to enhance your skills
                and advance your career. Whether you're a professional looking
                to upgrade your skills or a fresher entering the field, we have
                tailored plans for you.
              </p>
            </div>
          </div>
          <div className="">
            <img
              src={FullTimeJob_image}
              alt="FullTimeJob_image"
              className="w-full"
            />
          </div>
        </div>
        <div className="py-10 mt-10">
          <h1 className="md:text-4xl text-2xl tracking-wider text-center mt-4 font-bold">
            Join a Workplace That Gives You More
          </h1>
          <p className="text-TextSecondaryColor tracking-wider text-center m-auto lg:w-[50%] mt-4">
            Learning Opportunities , networking budgets, flexible hours, top
            benefits to keep employees healthy and happy.
          </p>

          <div className="flex flex-wrap gap-8 mt-10 justify-center">
            {employeeBenefits.map((solution, index) => (
              <div
                key={index}
                className="p-6 rounded-lg shadow-md text-center flex flex-col items-center justify-between w-full sm:w-[calc(50%-16px)] lg:w-[calc(30.33%-16px)]"
              >
                <div className={`border p-4 rounded-full ${solution?.bgColor}`}>
                  {solution?.icon}
                </div>
                <h3 className="text-lg font-semibold tracking-wider mb-2 mt-4">
                  {solution.title}
                </h3>
                <p className="text-sm text-TextSecondaryColor tracking-wider leading-6">
                  {solution.description}
                </p>
                <div className="h-full flex float-end justify-end mt-4 font-semibold hover:text-primaryColor">
                  <Link to="/">Learn More</Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}
      {/* <section className="py-20 px-4 bg-blue-100 text-center">
        
        <h2 className="text-3xl font-semibold mb-6">
          Interested in Joining Our Team?
        </h2>
        <div className="max-w-lg mx-auto flex items-center relative">
          <input
            type="text"
            placeholder="Search Job Title..."
            className="w-full px-4 py-3 border border-gray-300 rounded-3xl shadow-sm focus:outline-none"
          />
          <button className="absolute right-1 bg-orange-600 text-white px-4 py-2 rounded-3xl hover:bg-blue-600 transition-colors">
            Search
          </button>
        </div>
        <blockquote className="mt-8 italic text-lg text-gray-500 tracking-wider">
         You can also browse the open role below to find what your looking for
        </blockquote>
      </section> */}
      {/* <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 py-12 lg:px-20 md:px-10 px-5">
        {loading ? (
          Array.from({ length: 6 }).map((_, index) => (
            <SkeletonLoader key={index} />
          ))
        ) : jobs.length > 0 ? (
          jobs.map((job, index) => <JobCard key={index} job={job} />)
        ) : (
          <div className="col-span-full text-center text-lg font-medium text-gray-600">
            No jobs found.
          </div>
        )}
      </div> */}
      <div className="pt-2 pb-12 lg:px-20 md:px-10 px-5">
        <BusinessCard />
      </div>
    </div>
  );
};

export default FullTimeJobProgram;
