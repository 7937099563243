import React, { useState } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import axios from "axios";

const CreateJobsModal = ({ onClose }) => {
  const [formData, setFormData] = useState({
    job_title: "",
    job_type: "",
    salary: "",
    location: "",
    job_posted_date: "",
    job_expire: "",
    experience: "",
    job_level: "",
    education: "",
    job_description: [""],
    requirements: [""],
    desirable: [""],
    benefits: [""],
  });

  const clearForm = () => {
    setFormData({
      job_title: "",
      job_type: "",
      salary: "",
      location: "",
      job_posted_date: "",
      job_expire: "",
      experience: "",
      job_level: "",
      education: "",
      job_description: [""],
      requirements: [""],
      desirable: [""],
      benefits: [""],
    });
  };

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = () => {
    const newErrors = {};
    if (!formData.job_title) newErrors.job_title = true;
    if (!formData.job_type) newErrors.job_type = true;
    if (!formData.salary || formData.salary <= 0) newErrors.salary = true;
    if (!formData.location) newErrors.location = true;
    if (!formData.job_expire) newErrors.job_expire = true;
    if (!formData.experience) newErrors.experience = true;
    if (!formData.job_level) newErrors.job_level = true;
    if (!formData.education) newErrors.education = true;
    if (formData.job_description.length === 0) newErrors.job_description = true;
    if (formData.requirements.length === 0) newErrors.requirements = true;
    if (formData.desirable.length === 0) newErrors.desirable = true;
    if (formData.benefits.length === 0) newErrors.benefits = true;

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    if (errors[id]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [id]: false,
      }));
    }
  };

  const handleArrayChange = (index, e, field) => {
    const newArray = [...formData[field]];
    newArray[index] = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      [field]: newArray,
    }));
    if (errors[field]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: false,
      }));
    }
  };

  const addArrayItem = (field) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: [...prevData[field], ""],
    }));
  };

  const removeArrayItem = (index, field) => {
    const newArray = formData[field].filter((_, i) => i !== index);
    setFormData((prevData) => ({
      ...prevData,
      [field]: newArray,
    }));
  };

  const handleSubmit = (e) => {
    console.log(formData);
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_KEY}/jobs/create`,
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response.data);
          clearForm();
          setLoading(false);
          onClose();
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  return (
    <div className="fixed inset-0 z-50 bg-[#0000005d] h-screen w-screen flex justify-center items-center">
      <div className="w-[80%] h-[90vh] bg-customWhite p-10 rounded-lg relative overflow-y-scroll">
        <IoIosCloseCircle
          onClick={() => onClose(false)}
          className="cursor-pointer absolute top-2 right-2"
          size={25}
          color="#846cf9"
        />
        <h1 className="text-center font-semibold my-4">Create Job</h1>

        <div>
          <form onSubmit={handleSubmit}>
            {/* Job Title */}
            <div className="mb-4">
              <label
                htmlFor="job_title"
                className={`${
                  errors.job_title ? "text-red-500" : "text-gray-700"
                } block text-sm font-medium`}
              >
                Job Title
              </label>
              <input
                type="text"
                id="job_title"
                value={formData.job_title}
                onChange={handleChange}
                className={`${
                  errors.job_title ? "border-red-500" : "border-gray-300"
                } mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
              />
            </div>

            {/* Job Type */}
            <div className="mb-4">
              <label
                htmlFor="job_type"
                className={`${
                  errors.job_type ? "text-red-500" : "text-gray-700"
                } block text-sm font-medium`}
              >
                Job Type
              </label>
              <select
                id="job_type"
                value={formData.job_type}
                onChange={handleChange}
                className={`${
                  errors.job_type ? "border-red-500" : "border-gray-300"
                } mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
              >
                <option value="">Select job type</option>
                <option value="full-time">Full-Time</option>
                <option value="part-time">Part-Time</option>
                <option value="contract">Contract</option>
                <option value="internship">Internship</option>
              </select>
            </div>

            {/* Salary */}
            <div className="mb-4">
              <label
                htmlFor="salary"
                className={`${
                  errors.salary ? "text-red-500" : "text-gray-700"
                } block text-sm font-medium`}
              >
                Salary
              </label>
              <input
                type="number"
                id="salary"
                value={formData.salary}
                onChange={handleChange}
                className={`${
                  errors.salary ? "border-red-500" : "border-gray-300"
                } mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
              />
            </div>

            {/* Location */}
            <div className="mb-4">
              <label
                htmlFor="location"
                className={`${
                  errors.location ? "text-red-500" : "text-gray-700"
                } block text-sm font-medium`}
              >
                Location
              </label>
              <input
                type="text"
                id="location"
                value={formData.location}
                onChange={handleChange}
                className={`${
                  errors.location ? "border-red-500" : "border-gray-300"
                } mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
              />
            </div>

            {/* Job Expire */}
            <div className="mb-4">
              <label
                htmlFor="job_expire"
                className={`${
                  errors.job_expire ? "text-red-500" : "text-gray-700"
                } block text-sm font-medium`}
              >
                Job Expiry Date
              </label>
              <input
                type="date"
                id="job_expire"
                value={formData.job_expire}
                onChange={handleChange}
                className={`${
                  errors.job_expire ? "border-red-500" : "border-gray-300"
                } mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
              />
            </div>

            {/* Experience */}
            <div className="mb-4">
              <label
                htmlFor="experience"
                className={`${
                  errors.experience ? "text-red-500" : "text-gray-700"
                } block text-sm font-medium`}
              >
                Experience
              </label>
              <input
                type="text"
                id="experience"
                value={formData.experience}
                onChange={handleChange}
                className={`${
                  errors.experience ? "border-red-500" : "border-gray-300"
                } mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
              />
            </div>

            {/* Job Level */}
            <div className="mb-4">
              <label
                htmlFor="job_level"
                className={`${
                  errors.job_level ? "text-red-500" : "text-gray-700"
                } block text-sm font-medium`}
              >
                Job Level
              </label>
              <select
                id="job_level"
                value={formData.job_level}
                onChange={handleChange}
                className={`${
                  errors.job_level ? "border-red-500" : "border-gray-300"
                } mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
              >
                <option value="">Select job level</option>
                <option value="entry">Entry</option>
                <option value="mid">Mid</option>
                <option value="senior">Senior</option>
                <option value="lead">Lead</option>
              </select>
            </div>

            {/* Education */}
            <div className="mb-4">
              <label
                htmlFor="education"
                className={`${
                  errors.education ? "text-red-500" : "text-gray-700"
                } block text-sm font-medium`}
              >
                Education
              </label>
              <input
                type="text"
                id="education"
                value={formData.education}
                onChange={handleChange}
                className={`${
                  errors.education ? "border-red-500" : "border-gray-300"
                } mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
              />
            </div>

            {/* Job Description */}
            <div className="mb-4">
              <label
                htmlFor="job_description"
                className={`${
                  errors.job_description ? "text-red-500" : "text-gray-700"
                } block text-sm font-medium`}
              >
                Job Description
              </label>
              {formData.job_description.map((desc, index) => (
                <div key={index} className="flex mb-2">
                  <input
                    type="text"
                    value={desc}
                    onChange={(e) =>
                      handleArrayChange(index, e, "job_description")
                    }
                    className={`${
                      errors.job_description
                        ? "border-red-500"
                        : "border-gray-300"
                    } mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  />
                  {formData.job_description.length > 1 && (
                    <button
                      type="button"
                      onClick={() => removeArrayItem(index, "job_description")}
                      className="ml-2 text-red-500"
                    >
                      Remove
                    </button>
                  )}
                </div>
              ))}
              <button
                type="button"
                onClick={() => addArrayItem("job_description")}
                className="text-blue-500"
              >
                Add Description
              </button>
            </div>

            {/* Requirements */}
            <div className="mb-4">
              <label
                htmlFor="requirements"
                className={`${
                  errors.requirements ? "text-red-500" : "text-gray-700"
                } block text-sm font-medium`}
              >
                Requirements
              </label>
              {formData.requirements.map((req, index) => (
                <div key={index} className="flex mb-2">
                  <input
                    type="text"
                    value={req}
                    onChange={(e) =>
                      handleArrayChange(index, e, "requirements")
                    }
                    className={`${
                      errors.requirements ? "border-red-500" : "border-gray-300"
                    } mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  />
                  {formData.requirements.length > 1 && (
                    <button
                      type="button"
                      onClick={() => removeArrayItem(index, "requirements")}
                      className="ml-2 text-red-500"
                    >
                      Remove
                    </button>
                  )}
                </div>
              ))}
              <button
                type="button"
                onClick={() => addArrayItem("requirements")}
                className="text-blue-500"
              >
                Add Requirement
              </button>
            </div>

            <div className="mb-4">
              <label
                htmlFor="desirable"
                className={`${
                  errors.desirable ? "text-red-500" : "text-gray-700"
                } block text-sm font-medium`}
              >
                Desirable Skills
              </label>
              {formData.desirable.map((item, index) => (
                <div key={index} className="flex mb-2">
                  <input
                    type="text"
                    value={item}
                    onChange={(e) => handleArrayChange(index, e, "desirable")}
                    className={`${
                      errors.desirable ? "border-red-500" : "border-gray-300"
                    } mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  />
                  {formData.desirable.length > 1 && (
                    <button
                      type="button"
                      onClick={() => removeArrayItem(index, "desirable")}
                      className="ml-2 text-red-500"
                    >
                      Remove
                    </button>
                  )}
                </div>
              ))}
              <button
                type="button"
                onClick={() => addArrayItem("desirable")}
                className="text-blue-500"
              >
                Add Desirable Skill
              </button>
            </div>

            <div className="mb-4">
              <label
                htmlFor="benefits"
                className={`${
                  errors.benefits ? "text-red-500" : "text-gray-700"
                } block text-sm font-medium`}
              >
                Benefits
              </label>
              {formData.benefits.map((item, index) => (
                <div key={index} className="flex mb-2">
                  <input
                    type="text"
                    value={item}
                    onChange={(e) => handleArrayChange(index, e, "benefits")}
                    className={`${
                      errors.benefits ? "border-red-500" : "border-gray-300"
                    } mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  />
                  {formData.benefits.length > 1 && (
                    <button
                      type="button"
                      onClick={() => removeArrayItem(index, "benefits")}
                      className="ml-2 text-red-500"
                    >
                      Remove
                    </button>
                  )}
                </div>
              ))}
              <button
                type="button"
                onClick={() => addArrayItem("benefits")}
                className="text-blue-500"
              >
                Add Benefit
              </button>
            </div>

            {/* Submit Button */}
            <div className="flex justify-center">
              <button
                type="submit"
                className="bg-customPurple text-white w-[300px] py-2 rounded-md shadow-sm hover:bg-customPurpleHover"
              >
                {loading ? "Loading..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateJobsModal;
