import React from "react";
import img from "../../Assets/ApplicationDevelopment/image 253.png";
const SCODLegacy = () => {
  return (
    <div className="px-5 md:px-20 gap-x-7 md:gap-y-11 flex flex-col lg:flex-row items-center justify-between w-[100%]">
      <div className="lg:w-[50%] w-[100%]">
        <h1 className="text-2xl md:text-4xl font-bold">
          Future-Proof Your Legacy Systems with{" "}
          <span className="text-blue-500">Specialized Consultants</span>
        </h1>
        <p className="mt-5 text-lg md:text-xl text-gray-600">
          Legacy systems often require specialized attention to meet modern
          demands. With SCOD, you get access to consultants with deep expertise
          in legacy system modernization. Our consultants work with you to
          ensure your systems are optimized for the future. Key offerings
          include:
        </p>
        <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-4 text-gray-600 text-lg md:text-xl montserrat">
          <div className="flex items-start">
            <span>Expert Assessments</span>
          </div>
          <div className="flex items-start">
            <span>Tailored Solutions</span>
          </div>
          <div className="flex items-start">
            <span>On-Demand Consulting</span>
          </div>
          <div className="flex items-start">
            <span>Future-Ready Strategies</span>
          </div>
        </div>
        <p className="mt-5 text-lg md:text-xl text-gray-600">
          Our SCOD consultants help you transition your legacy systems
          seamlessly, ensuring minimal downtime and maximum efficiency. Start
          leveraging expert knowledge today!
        </p>
      </div>

      <div className="w-[100%] lg:w-1/2 mt-10 md:mt-0 flex justify-center">
        <img
          src={img}
          alt="Specialty Consultant on Legacy Systems"
          className="rounded-lg w-full object-cover"
        />
      </div>
    </div>
  );
};

export default SCODLegacy;
