import React, { useEffect, useState } from "react";
import { FaUsers } from "react-icons/fa";
import { GrNotes } from "react-icons/gr";
import { LiaMoneyCheckAltSolid } from "react-icons/lia";
import axios from "axios";

const Dashboard = () => {
  const [users, setUsers] = useState([]);
  const [appliedJobs, setAppliedJobs] = useState([]);

  const fetchData = async () => {
    try {
      const [usersResponse, jobsResponse] = await Promise.all([
        axios.get(`${process.env.REACT_APP_API_KEY}/users/get-all-users`),
        axios.get(`${process.env.REACT_APP_API_KEY}/jobs/get-all-jobs`),
      ]);

      // Filter out admin users
      const filteredUsers = usersResponse?.data?.users?.filter(
        (user) => user.role !== "admin"
      );
      setUsers(filteredUsers);

      // Set applied jobs
      setAppliedJobs(jobsResponse?.data?.jobs);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const cardData = [
    {
      id: 1,
      icon: <FaUsers size={40} className="text-customPurpleHover" />,
      value: users.length,
      label: "Users",
    },
    {
      id: 2,
      icon: <GrNotes size={40} className="text-customPurpleHover" />,
      value: appliedJobs?.length,
      label: "Applied Jobs",
    },
    {
      id: 3,
      icon: (
        <LiaMoneyCheckAltSolid size={50} className="text-customPurpleHover" />
      ),
      value: "$ 0",
      label: "Revenue",
    },
  ];
  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {cardData.map((card) => (
          <div
            key={card.id}
            className="flex items-center border border-customPurple rounded-md shadow-md shadow-customPurple justify-between p-10 bg-gradient-to-tr from-violet-300 to-white hover:scale-105 transition duration-300 ease-linear"
          >
            <div>{card?.icon}</div>
            <div className="flex flex-col gap-4">
              <h1 className="text-3xl text-center text-customPurpleHover font-bold">
                {card.value}
              </h1>
              <h1 className="text-center font-sans text-xl text-customPurpleHover">
                {card.label}
              </h1>
            </div>
          </div>
        ))}
      </div>
      <img src="https://i.pinimg.com/originals/81/08/4d/81084d04dbcadec0b75a7d494b253d7d.gif" alt="" className="mt-8 border border-customPurple w-full rounded-md shadow-lg"/>
    </div>
  );
};

export default Dashboard;
