import React from "react";
import img1 from "../../Assets/ApplicationDevelopment/image.png";
import img2 from "../../Assets/ApplicationDevelopment/image (1).png";
import img3 from "../../Assets/ApplicationDevelopment/image (2).png";
const ITStaffingDevelopment = () => {
  return (
    <div className="bg-white py-10 px-5 md:px-20">
      <div className="text-center">
        <h1 className="text-3xl md:text-5xl font-bold">
          IT <span className="text-blue-500">STAFFING</span> &amp; DELIVERY
          MODEL
        </h1>
        <p className="mt-5 text-lg md:text-xl text-gray-600">
          Optimize your business operations with Techmentee's flexible IT
          staffing and delivery models. We provide the right talent at the right
          time.
        </p>
      </div>

      <div className="mt-8 text-gray-600 text-center mx-auto">
        <p>
          Techmentee’s IT Staffing & Delivery Model ensures that your projects
          are handled by professionals with the necessary skills. Our scalable
          solutions and tailored staffing models help businesses meet their IT
          goals efficiently and effectively.
        </p>
      </div>

      <div className="mt-10 flex flex-col md:flex-row items-center justify-center gap-5">
        <img
          src={img1}
          alt="IT Staffing Solutions"
          className="rounded-lg w-full md:w-1/3 object-cover"
        />
        <img
          src={img2}
          alt="Delivery Models"
          className="rounded-lg w-full md:w-1/3 object-cover"
        />
        <img
          src={img3}
          alt="Resource Management"
          className="rounded-lg w-full md:w-1/3 object-cover"
        />
      </div>
    </div>
  );
};

export default ITStaffingDevelopment;
