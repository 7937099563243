import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import HeadingWithDot from "../common/HeadingWithDot";
import Code_icon from "../../Assets/Home/Code_icon.png";
import automotive from "../../Assets/Home/Automotive.png";
import banking from "../../Assets/Home/Banking.png";
import telecom from "../../Assets/Home/Telecom.png";
import ecommerce from "../../Assets/Home/E-commerce.png";
import healthcare from "../../Assets/Home/Healthcare.png";
import infrastructure from "../../Assets/Home/Infrastructure.png";
import realestate from "../../Assets/Home/Realestate.png";
import government from "../../Assets/Home/Government.png";
import { Link } from "react-router-dom";

const industries = [
  {
    id: 1,
    title: "Health care",
    description: "Healthcare",
    imageUrl: healthcare,
    icon: Code_icon,
    path: "/industry/health-care",
  },
  {
    id: 2,
    title: "Automotive",
    description: "Automotive",
    imageUrl: automotive,
    icon: Code_icon,
    path: "/industry/automotive",
  },
  {
    id: 3,
    title: "Banking",
    description: "Banking & Finance",
    imageUrl: banking,
    icon: Code_icon,
    path: "/industry/banking",
  },
  {
    id: 4,
    title: "E-Commerce",
    description: "E-Commerce",
    imageUrl: ecommerce,
    icon: Code_icon,
    path: "/industry/e-commerce",
  },
  {
    id: 5,
    title: "Government",
    description: "Government",
    imageUrl: government,
    icon: Code_icon,
    path: "/industry/government",
  },
  {
    id: 6,
    title: "Infrastructure",
    description: "Infrastructure",
    imageUrl: infrastructure,
    icon: Code_icon,
    path: "/industry/infrastructure",
  },
  {
    id: 7,
    title: "Real Estate",
    description: "Real Estate",
    imageUrl: realestate,
    icon: Code_icon,
    path: "/industry/realEstate",
  },
  {
    id: 8,
    title: "Telecom",
    description: "Telecom",
    imageUrl: telecom,
    icon: Code_icon,
    path: "/industry/telecom",
  },
];

const OurIndustry = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="mt-10">
      <div className="flex justify-center" data-aos="fade-up">
        <HeadingWithDot name="Our Industry" />
      </div>
      <h1
        className="md:text-4xl text-2xl tracking-wider text-center mt-2"
        data-aos="fade-up"
      >
        The Industries We Serve
      </h1>
      <p
        className="text-TextSecondaryColor text-sm tracking-wider text-center m-auto xl:w-[40%] mt-4"
        data-aos="fade-up"
      >
        IT service providers work closely adipiscing elit. Morbi obortis ligula
        euismod sededesty am augue nisl.
      </p>
      <div
        className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 mt-8"
        data-aos="fade-up"
      >
        {industries.map((industry) => (
          <Link
            to={industry.path}
            key={industry.id}
            className="h-[320px] rounded-lg"
            style={{
              backgroundImage: `url(${industry.imageUrl})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right",
            }}
            data-aos="zoom-in"
          >
            {/* <div className="h-[160px] flex items-end justify-center">
              <h1 className="text-white font-bold text-3xl">{industry.title}</h1>
            </div>
            <div className="ml-6 mt-8">
              <img src={industry.icon} alt="" className="w-12" />
              <p className="text-white tracking-wide mt-2">{industry.description}</p>
            </div> */}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default OurIndustry;
