import React, { useEffect, useState } from "react";
import { User, BookOpen, Calendar, Award, LogOut } from "lucide-react";
import encryptionModule from "../../components/common/localStorageUtils";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ViewOrderModal from "../../components/Modals/ViewOrderModal";
import CreateIssueModal from "../../components/Modals/CreateIssueModal";

const UserData = encryptionModule.decryptData("userInfo")?.user;

const Tab = ({ label, isActive, onClick }) => (
  <button
    onClick={onClick}
    className={`px-4 py-2 text-sm font-medium border-b-2 ${
      isActive
        ? "border-blue-500 text-blue-500"
        : "border-transparent text-gray-600"
    }`}
  >
    {label}
  </button>
);

const ProfileSection = ({ title, children }) => (
  <div className="bg-white p-6 rounded-lg shadow-md mb-6">
    <h2 className="text-2xl font-semibold mb-4">{title}</h2>
    {children}
  </div>
);

const IssueTable = ({ issues }) => {
  const getStatusClass = (status) => {
    switch (status) {
      case "open":
        return "bg-orange-300 text-orange-800";
      case "in_progress":
        return "bg-blue-300 text-blue-800";
      case "resolved":
        return "bg-green-300 text-green-800";
      case "closed":
        return "bg-red-300 text-red-800";
      default:
        return "bg-gray-200 text-gray-800";
    }
  };

  if (issues.length === 0) {
    return (
      <div className="text-center text-gray-600 py-4">
        No issues found.
      </div>
    );
  }

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white shadow-md rounded-lg">
        <thead>
          <tr className="bg-gray-100 text-left">
            <th className="p-4">Issue Title</th>
            <th className="p-4">Description</th>
            <th className="p-4">Phone Number</th>
            <th className="p-4">Date/Time</th>
            <th className="p-4">Status</th>
          </tr>
        </thead>
        <tbody>
          {issues.map((issue) => (
            <tr key={issue._id} className="border-b">
              <td className="p-4">{issue.title}</td>
              <td className="p-4">{issue.description}</td>
              <td className="p-4">{issue.phoneNumber}</td>
              <td className="p-4">
                {new Date(issue.createdAt).toLocaleString()}
              </td>
              <td className='p-4'>
                <p className={`text-center px-2 rounded-md cursor-not-allowed ${getStatusClass(issue.status)}`}>
                  {issue.status === 'in_progress' ? "in progress" : issue.status}
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const SkeletonLoader = () => (
  <div className="animate-pulse">
    <div className="bg-gray-200 h-6 mb-4 rounded"></div>
    <div className="bg-gray-200 h-6 mb-4 rounded"></div>
    <div className="bg-gray-200 h-6 mb-4 rounded"></div>
  </div>
);

const Profile = () => {
  const [purchasedCourse, setPurchasedItems] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState(null);
  const [activeTab, setActiveTab] = useState("Courses");
  const [isCreateIssueModalOpen, setCreateIssueModalOpen] = useState(false);
  const [issues, setIssues] = useState([]);
  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false);
  const [loadingCourses, setLoadingCourses] = useState(true); // Loading state for courses
  const [loadingIssues, setLoadingIssues] = useState(true); // Loading state for issues

  const fetchPurchasedItems = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_KEY}/purchased/purchased-items/${UserData?._id}`
      );
      setPurchasedItems(response.data?.data);
    } catch (err) {
      console.log(err.message);
    } finally {
      setLoadingCourses(false); // Set loading to false after fetching
    }
  };

  const fetchIssues = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_KEY}/issues/get-issues-user/${UserData?._id}`
      );
      setIssues(response.data);
    } catch (err) {
      console.log(err.message);
    } finally {
      setLoadingIssues(false); // Set loading to false after fetching
    }
  };

  useEffect(() => {
    fetchPurchasedItems();
    fetchIssues();
  }, []);

  const user = {
    name: UserData?.name,
    email: UserData?.email,
    joinDate: "January 15, 2023",
    completedCourses: 3,
  };

  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleViewDetails = (items) => {
    setSelectedItems(items);
    setModalOpen(true);
  };

  const confirmLogout = () => {
    logout();
    navigate("/login");
  };

  const cancelLogout = () => {
    setLogoutModalOpen(false);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold">My Profile</h1>

        <button
          onClick={() => setCreateIssueModalOpen(true)}
          className="bg-primaryColor hover:bg-[#e2ae21] text-white font-bold py-2 px-4 rounded flex items-center"
        >
          Create Issues
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="md:col-span-1">
          <ProfileSection title="Personal Information">
            <div className="flex items-center mb-4">
              <User className="text-blue-500 mr-2" size={24} />
              <div>
                <div className="font-semibold">{user.name}</div>
                <div className="text-sm text-gray-600">{user.email}</div>
              </div>
            </div>
            <div className="flex items-center">
              <Calendar className="text-blue-500 mr-2" size={24} />
              <div className="text-sm text-gray-600">
                Joined {user.joinDate}
              </div>
            </div>
          </ProfileSection>

          <ProfileSection title="Achievements">
            <div className="flex items-center">
              <Award className="text-blue-500 mr-2" size={24} />
              <div className="text-sm text-gray-600">
                {user.completedCourses} Courses Completed
              </div>
            </div>
          </ProfileSection>

          <button
            onClick={() => setLogoutModalOpen(true)} // Open logout confirmation modal
            className="bg-primaryColor hover:bg-[#e2ae21] text-white font-bold py-2 px-4 rounded flex items-center"
          >
            <LogOut className="mr-2" size={18} />
            Logout
          </button>
        </div>

        <div className="md:col-span-2">
          <div className="border-b mb-6">
            <Tab
              label="My Courses"
              isActive={activeTab === "Courses"}
              onClick={() => setActiveTab("Courses")}
            />
            <Tab
              label="My Issues"
              isActive={activeTab === "Issues"}
              onClick={() => setActiveTab("Issues")}
            />
          </div>

          {activeTab === "Courses" ? (
            <ProfileSection title="My Purchased Courses">
              {loadingCourses ? ( // Show skeleton loader while loading
                <SkeletonLoader />
              ) : purchasedCourse.length === 0 ? (
                <div className="text-center text-gray-600 py-4">
                  No courses purchased.
                </div>
              ) : (
                purchasedCourse.map((course) => (
                  <div key={course._id} className="bg-gray-100 p-4 rounded-lg flex items-center mb-4">
                    <div className="flex-grow">
                      <h3 className="font-semibold">
                        Total{" "}
                        {(course?.items[0]?.courses?.length || 0) +
                          (course?.items[0]?.mockInterviews?.length || 0) +
                          (course?.items[0]?.resumeReviews?.length || 0)}
                        &nbsp; Items Purchased
                      </h3>
                      <div className="text-sm text-gray-600 my-3">
                        Purchased Date : {new Date(course?.createdAt).toLocaleString()}
                      </div>
                      <button
                        onClick={() => handleViewDetails(course?.items[0])}
                        className="text-blue-500"
                      >
                        View Details
                      </button>
                    </div>
                    <BookOpen className="text-blue-500" />
                  </div>
                ))
              )}
            </ProfileSection>
          ) : (
            <ProfileSection title="My Issues">
              {loadingIssues ? ( // Show skeleton loader while loading
                <SkeletonLoader />
              ) : issues.length === 0 ? (
                <div className="text-center text-gray-600 py-4">
                  No issues found.
                </div>
              ) : (
                <IssueTable issues={issues} />
              )}
            </ProfileSection>
          )}
        </div>
      </div>

      {/* Modal for viewing order details */}
      <ViewOrderModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        selectedItems={selectedItems}
      />

      {/* Modal for creating a new issue */}
      <CreateIssueModal
        isOpen={isCreateIssueModalOpen}
        onClose={() => setCreateIssueModalOpen(false)}
        fetchIssues={fetchIssues}
      />

      {/* Custom logout confirmation modal */}
      {isLogoutModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-lg font-semibold mb-4">Are you sure you want to logout?</h2>
            <div className="flex justify-end space-x-4">
              <button
                onClick={confirmLogout}
                className="bg-red-500 text-white px-4 py-2 rounded"
              >
                Yes, Logout
              </button>
              <button
                onClick={cancelLogout}
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
