import React from "react";
import img from "../../Assets/ApplicationDevelopment/image 253.png";
const LegacyApps = () => {
  return (
    <div className="px-5 md:px-20 gap-x-7 md:gap-y-11 flex flex-col lg:flex-row items-center justify-between w-[100%]">
      <div className="lg:w-[50%] w-[100%]">
        <h1 className="text-2xl md:text-4xl font-bold">
          Power your Legacy Apps for{" "}
          <span className="text-orange-500">the Future</span>
        </h1>
        <p className="mt-5 text-lg md:text-xl text-gray-600">
          Business environments are highly demanding, and with costs mounting in
          all directions seeking assistance from the best software company in
          Atlanta, GA, USA, can ease all your concerns. That's why with flexible
          and cost-effective engagement models, Techmentee assists you with
          modernizing your business software for the future! Our key offerings:
        </p>
        <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-4 text-gray-600 text-lg md:text-xl montserrat">
          <div className="flex items-start">
            <span>Proactive Monitoring</span>
          </div>
          <div className="flex items-start">
            <span>Root Cause Analysis</span>
          </div>
          <div className="flex items-start">
            <span>Preventative Maintenance</span>
          </div>
          <div className="flex items-start">
            <span>Continuous Improvement</span>
          </div>
        </div>
        <p className="mt-5 text-lg md:text-xl text-gray-600">
          Are you left behind because of your business' inefficient application?
          Our experts can assist you with bringing your infrastructure into the
          future. Eliminating downtime to maximize the benefits of running
          critical applications, we strive to upscale your business with the
          best practices. Let's start working on your ideas!
        </p>
      </div>

      <div className="w-[100%] lg:w-1/2 mt-10 md:mt-0 flex justify-center">
        <img
          src={img}
          alt="Phone with Charts"
          className="rounded-lg w-full object-cover"
        />
      </div>
    </div>
  );
};

export default LegacyApps;
