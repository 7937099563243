import React, { useEffect, useState } from "react";

import { CiLocationOn } from "react-icons/ci";
import { CiStopwatch } from "react-icons/ci";
import { FiDollarSign } from "react-icons/fi";
import jobLogo from "../../../Assets/JobDetail/jobLogo.svg";
import axios from "axios";
import encryptionModule from "../../../components/common/localStorageUtils";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../components/common/Pagination/Pagination";
import ApplyJobModal from "../../../components/common/ApplyJobModal";
import JobCards from "../../../components/JobOpeningCards/JobCards";
import SkeletonLoader from "../../../components/common/SkeletonLoader";

const userData = encryptionModule.decryptData("userInfo");

const FilterSection = ({ title, options, selectedOption, onOptionChange }) => {
  return (
    <div className="mb-6">
      <h3 className="text-lg text-left font-semibold mb-2">{title}</h3>
      {options.map((option, index) => (
        <div key={index} className="flex items-center mb-2">
          <input
            type="radio"
            name={title} // Group checkboxes per section
            id={`${title}-${index}`}
            className="form-radio h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
            checked={selectedOption === option.label}
            onChange={() => onOptionChange(title, option.label)}
          />
          <label
            htmlFor={`${title}-${index}`}
            className="ml-2 text-sm text-gray-700"
          >
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
};

const Filters = ({ setFilters }) => {
  const [selectedFilters, setSelectedFilters] = useState({
    "Type of employment": "All",
    "Date of posting": 0, // Default as 0 (number)
    Specialization: "All",
  });

  const handleOptionChange = (title, label) => {
    setSelectedFilters((prev) => {
      if (title === "Date of posting") {
        // Extract number using regex and default to 0 if not found
        const days = parseInt(label.match(/\d+/)?.[0]) || 0;
        return { ...prev, [title]: days };
      }
      return { ...prev, [title]: label };
    });
  };

  useEffect(() => {
    setFilters({
      dateOfPosting: selectedFilters["Date of posting"],
      jobType: selectedFilters["Type of employment"],
      jobLevel: selectedFilters?.Specialization,
      active: "Active",
    });
  }, [selectedFilters]);

  return (
    <div className="bg-white p-6 lg:border border-gray-300 rounded-lg shadow-sm">
      <h2 className="hidden lg:block text-lg font-semibold text-left mb-4">
        Filters
      </h2>
      <FilterSection
        title="Type of employment"
        options={[
          { label: "All" },
          { label: "Full-Time" },
          { label: "Contract" },
          { label: "Part-Time" },
        ]}
        selectedOption={selectedFilters["Type of employment"]}
        onOptionChange={handleOptionChange}
      />
      <FilterSection
        title="Date of posting"
        options={[
          { label: "All" },
          { label: "Last 3 days" },
          { label: "Last 7 days" },
          { label: "Last 15 days" },
        ]}
        selectedOption={
          selectedFilters["Date of posting"] === 0
            ? "All"
            : `Last ${selectedFilters["Date of posting"]} days`
        }
        onOptionChange={handleOptionChange}
      />
      <FilterSection
        title="Specialization"
        options={[
          { label: "All" },
          { label: "Entry Level" },
          { label: "Intermediate Level" },
          { label: "Advance Level" },
        ]}
        selectedOption={selectedFilters["Specialization"]}
        onOptionChange={handleOptionChange}
      />
    </div>
  );
};

const OurJobOpenings = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [applyJobModal, setApplyJobModal] = useState(false);
  const [currentJob, setCurrentJob] = useState(false);
  const [selectedCard, setSelectedCard] = useState("");
  const [loadingIndex, setLoadingIndex] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [load, setLoad] = useState(false);
  const [resumeFile, setResumeFile] = useState("");
  const [isApplied, setIsApplied] = useState(false);
  //For handling Pagination...
  const [toggle, setToggle] = useState(false);
  const [paginationResponse, setPaginationResponse] = useState({
    totalRecords: 0,
    currentPage: 1,
    totalPages: 0,
    limit: 4,
  });

  const [filters, setFilters] = useState({
    dateOfPosting: 0,
    jobType: "All",
    jobLevel: "All",
    active: "Active",
  });

  //On Update OF toggle this will call the Get API ...
  const toggleBetween = () => {
    setToggle(!toggle);
  };

  console.log("Query Object:", filters);
  const queryString = new URLSearchParams(filters).toString();

  const active = "Active";
  const fetchAllJobs = () => {
    setLoad(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/job-posts/?page=${paginationResponse?.currentPage}&limit=${paginationResponse?.limit}&${queryString}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        setJobs(response.data.data);
        const pagination = response.data.pagination;
        setPaginationResponse({
          totalRecords: pagination?.totalPosts || 0,
          currentPage: pagination?.currentPage || 1,
          totalPages: pagination?.totalPages || 0,
          limit: 4,
        });
        setLoad(false);
      })
      .catch((error) => {
        console.log(error);
        setLoad(false);
      });
  };

  //Apply for the Job
  const JobApply = (id, jobOwnerId, payLoad, index) => {
    setLoadingIndex(index);
    setLoading(true);

    if (!resumeFile && !isApplied) {
      toast.error("Please Provide your resume, while applying for any job !");
      return;
    }
    // Create FormData object
    const formattedData = new FormData();

    formattedData.append(
      "candidate",
      JSON.stringify({
        fullName: payLoad?.fullName,
        email: payLoad?.email,
        phoneNumber: payLoad?.phoneNumber,
      })
    );
    formattedData.append("file", resumeFile);
    console.log(formattedData);
    let config = {
      method: "Post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/job-posts/apply/${id}/${jobOwnerId}`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formattedData,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        toast.success(response.data.message);
        setLoading(false);
        setApplyJobModal(false);
        setResumeFile("");
        if (response?.data?.success != false) {
          fetchAllJobs();
        }
      })
      .catch((error) => {
        console.log(error);
        // toast.success(
        //   error?.response?.data?.error ||
        //     "We have already received your application, Thanks for your interest !"
        // );
        let message;
        if (error?.response?.data?.operationType === "resumeUpload") {
          message = error?.response?.data?.error;
        } else {
          message =
            "We have already received your application, Thanks for your interest!";
        }
        toast(message, {
          theme: "dark",
          autoClose: 3000, // 3 seconds
        });
        setLoading(false);
        setResumeFile("");

        fetchAllJobs();
      })
      .finally(() => {
        setLoadingIndex(null);
      });
  };

  useEffect(() => {
    const userData = encryptionModule.decryptData("userInfo");

    setUserInfo(userData);
  }, []);

  useEffect(() => {
    fetchAllJobs();
  }, [toggle, filters]);

  return (
    <div className="w-full p-6 md:p-16">
      {applyJobModal ? (
        <ApplyJobModal
          userData={userInfo}
          loading={loading}
          setApplyJobModal={setApplyJobModal}
          JobApply={JobApply}
          currentJob={currentJob}
          setResumeFile={setResumeFile}
          resumeFile={resumeFile}
        />
      ) : (
        ""
      )}
      <div className="px-0 py-8">
        <header className="text-center mb-12">
          <h1 className="text-3xl font-bold mb-4">
            Check Out Our <span className="text-yellow-600">Current Job</span>{" "}
            Openings.
          </h1>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Discover exciting career opportunities with us! We’re looking for
            passionate, talented individuals to join our team and help us make a
            difference. We have a variety of roles that could be the perfect fit
            for you. Explore our current job openings and take the next step in
            your career today!
          </p>
        </header>

        <div className="flex flex-col lg:flex-row gap-8">
          <aside className="lg:w-1/4 hidden lg:block">
            <Filters setFilters={setFilters} />
          </aside>

          <main className="lg:w-3/4">
            <div className="lg:hidden mb-4">
              <button
                className="w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                onClick={() => setIsFilterOpen(true)}
              >
                Filters
              </button>
            </div>

            {load ? (
              // Show skeleton loaders when loading
              <>
                {[...Array(3)].map((_, index) => (
                  <SkeletonLoader key={index} />
                ))}
              </>
            ) : jobs.length > 0 ? (
              // Render job cards when jobs are available
              jobs.map((job, index) => (
                <JobCards
                  userData={userInfo}
                  setCurrentJob={setCurrentJob}
                  key={job._id}
                  job={job}
                  index={index}
                  setApplyJobModal={setApplyJobModal}
                  JobApply={JobApply}
                  load={loadingIndex === index}
                  isApplied={isApplied}
                  setIsApplied={setIsApplied}
                />
              ))
            ) : (
              // Show "No jobs available" message when no jobs are found
              <div className="text-center text-gray-500 py-8">
                <p className="text-lg font-semibold">No jobs available</p>
                <p className="text-sm">
                  Please check back later for new job postings.
                </p>
              </div>
            )}
            <div className="p-2 w-full">
              <Pagination
                paginationResponse={paginationResponse}
                onPageChange={(page) =>
                  setPaginationResponse((prev) => ({
                    ...prev,
                    currentPage: page,
                  }))
                }
                toggleBetween={toggleBetween}
              />
            </div>
          </main>
        </div>
      </div>

      {isFilterOpen && (
        <div className="fixed   inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white  rounded-lg max-w-md w-full max-h-screen overflow-auto">
            <div className="p-4">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold">Filters</h2>
                <button
                  className="text-gray-500 hover:text-gray-700"
                  onClick={() => setIsFilterOpen(false)}
                >
                  ✕
                </button>
              </div>
              <Filters />
            </div>
            <div className="border-t p-4">
              <button
                className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                onClick={() => setIsFilterOpen(false)}
              >
                Apply Filters
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OurJobOpenings;
