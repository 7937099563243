import React from "react";

const Pagination = ({ paginationResponse, onPageChange, toggleBetween }) => {
  if (!paginationResponse) {
    return (
      <div className="text-red-500 text-center py-4">
        Error: Pagination data is missing.
      </div>
    );
  }

  const { totalRecords, currentPage, totalPages, limit } = paginationResponse;
  if (!totalRecords || !currentPage || !totalPages || !limit) {
    return;
  }

  return (
    <div className="flex flex-col md:flex-row justify-between items-center">
      {/* Information */}
      <div className="text-sm text-gray-700 mb-4 md:mb-0">
        Showing{" "}
        <span className="font-semibold">{(currentPage - 1) * limit + 1}</span>{" "}
        {/* to{" "} */}-
        <span className="font-semibold">
          {Math.min(currentPage * limit, totalRecords)}
        </span>{" "}
        of <span className="font-semibold">{totalRecords}</span>
        {/* records */}
      </div>

      {/* Pagination Controls */}
      <div className="flex items-center space-x-2">
        {/* Previous Button */}
        <button
          className={`px-4 py-2 rounded-md font-semibold transition ${
            currentPage === 1
              ? " text-gray-500 cursor-not-allowed"
              : "bg-blue-500 text-white hover:bg-blue-600"
          }`}
          disabled={currentPage === 1}
          //   onClick={() => currentPage > 1 && onPageChange(currentPage - 1)}
          onClick={() => {
            if (currentPage > 1) {
              onPageChange(currentPage - 1);
              toggleBetween(); // New function
            }
          }}
        >
          Prev
        </button>

        {/* Page Numbers */}
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            className={`px-4 py-2 rounded-md font-semibold transition ${
              currentPage === index + 1
                ? "bg-blue-500 text-white"
                : "bg-gray-100 text-gray-700 hover:bg-gray-200"
            }`}
            onClick={() => {
              onPageChange(index + 1);
              toggleBetween();
            }}
          >
            {index + 1}
          </button>
        ))}

        {/* Next Button */}
        <button
          className={`px-4 py-2 rounded-md font-semibold transition ${
            currentPage === totalPages
              ? " text-gray-500 cursor-not-allowed"
              : "bg-blue-500 text-white hover:bg-blue-600"
          }`}
          disabled={currentPage === totalPages}
          onClick={() => {
            if (currentPage < totalPages) {
              onPageChange(currentPage + 1); // Existing function
              toggleBetween(); // New function
            }
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Pagination;
