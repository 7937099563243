import React, { useState } from "react";
import SimpleBanner from "../../../components/common/SimpleBanner";
import HealthCareBanner from "../../../Assets/Industry/HealthCareBanner.png"; // Using the same banner image for now
import heathCare_image from "../../../Assets/Industry/heathCare_image.png"; // Reuse the image
import HeadingWithDot from "../../../components/common/HeadingWithDot";
import ConsultingServices_image from "../../../Assets/Industry/ConsultingServices_image.png"; // Reuse image
import EHRImplementation_image from "../../../Assets/Industry/EHRImplementation_image.png"; // Reuse image
import Telemedicine_image from "../../../Assets/Industry/Telemedicine_image.png"; // Reuse image
import BusinessCard from "../../../components/common/BusinessCard"; // Reuse Business Card

// Update the hardcoded content for banking industry
const bankingSolutions = [
  {
    image: ConsultingServices_image, // Reuse image
    title: "Digital Banking Solutions",
    description:
      "We offer digital banking solutions that streamline online banking operations, enhance security, and provide a seamless customer experience across all platforms.",
  },
  {
    image: EHRImplementation_image, // Reuse image
    title: "Blockchain for Banking",
    description:
      "Our blockchain solutions ensure transparency and security in banking operations, enabling faster transactions and reducing fraud.",
  },
  {
    image: Telemedicine_image, // Reuse image
    title: "Mobile Payment Solutions",
    description:
      "We develop mobile payment solutions that allow secure, convenient, and efficient payment processes for both banks and their customers.",
  },
];

const processSteps = [
  {
    index: 1,
    title: "Market Analysis",
    description:
      "We analyze the banking sector to understand evolving trends, regulatory requirements, and the competitive landscape, ensuring our solutions are forward-thinking.",
  },
  {
    index: 2,
    title: "Risk & Compliance Testing",
    description:
      "Our solutions go through rigorous risk and compliance testing to meet financial regulations and standards, ensuring data security and financial integrity.",
  },
  {
    index: 3,
    title: "Requirement Gathering",
    description:
      "We collaborate with banks to gather their specific requirements, ensuring that our solutions meet both their current needs and future challenges.",
  },
  {
    index: 4,
    title: "Design & Prototyping",
    description:
      "Our design phase focuses on user-friendly interfaces, ensuring that both bank employees and customers have a seamless and efficient experience.",
  },
  {
    index: 5,
    title: "Deployment",
    description:
      "We manage the deployment of our solutions, ensuring minimal disruption to daily banking operations and seamless integration with existing systems.",
  },
  {
    index: 6,
    title: "Post-launch Support",
    description:
      "We provide ongoing support and updates to ensure that our solutions stay secure, compliant, and effective in the dynamic banking industry.",
  },
];

const Banking = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  return (
    <div>
      {/* Reuse the banner, no changes */}
      <SimpleBanner
        image={HealthCareBanner}
        name="Banking"
        path="Home / Industry / Banking"
      />

      <div className="lg:px-20 md:px-10 px-5 py-10">
        <div className="grid xl:grid-cols-2 grid-cols-1 gap-10">
          <div className="">
            {/* Reuse the image */}
            <img
              src="https://www.arabianbusiness.com/cloud/2024/01/23/Online-banking.jpg"
              alt="about us"
              className="w-full rounded-lg"
            />
          </div>
          <div className="flex justify-center items-center">
            <div>
              {/* Change the content to match banking industry */}
              <h1 className="md:text-4xl text-3xl tracking-wider md:leading-[55px] leading-[50px] mt-2">
                Innovative Banking IT Solutions
                <span className="text-primaryColor"> Company</span>
              </h1>
              <p className="text-TextSecondaryColor mt-2 w-[90%] tracking-wide">
                Our banking IT solutions focus on enhancing security,
                efficiency, and customer experience, helping banks to meet
                modern financial demands and regulatory requirements.
              </p>
              <p className="text-TextSecondaryColor mt-2 w-[90%] tracking-wide">
                From digital banking to mobile payment solutions, we offer
                cutting-edge technologies that help financial institutions grow
                and adapt in a rapidly evolving marketplace.
              </p>
            </div>
          </div>
        </div>

        {/* Industry Solutions */}
        <div>
          <div className="flex justify-center mt-10">
            <HeadingWithDot name="Our Industry" />
          </div>
          <h1 className="md:text-4xl text-2xl tracking-wider text-center mt-4">
            Our <span className="text-primaryColor">Banking</span> IT Solutions
          </h1>
          <p className="text-TextSecondaryColor text-sm tracking-wider text-center m-auto xl:w-[60%] mt-4">
            We offer comprehensive IT solutions to enhance digital banking
            operations, support blockchain initiatives, and streamline payment
            systems for the banking industry.
          </p>

          <div className="flex flex-wrap gap-8 mt-10 justify-center">
            {bankingSolutions.map((solution, index) => (
              <div
                key={index}
                className="border p-6 rounded-lg shadow-md text-center flex flex-col items-center w-full sm:w-[calc(50%-16px)] lg:w-[calc(30.33%-16px)]"
              >
                <img
                  src={solution.image}
                  alt={solution.title}
                  className="w-20 mb-4"
                />
                <h3 className="text-lg font-semibold tracking-wider mb-2">
                  {solution.title}
                </h3>
                <p className="text-sm text-TextSecondaryColor tracking-wider">
                  {solution.description}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Process */}
        <div className="flex justify-center mt-10">
          <HeadingWithDot name="Our Process" />
        </div>
        <h1 className="md:text-4xl text-2xl tracking-wider text-center mt-4">
          Our <span className="text-primaryColor">Process</span>
        </h1>
        <p className="text-TextSecondaryColor text-sm tracking-wider text-center m-auto xl:w-[60%] mt-4">
          Our process ensures the development of secure, scalable, and
          innovative solutions tailored to meet the unique challenges of the
          banking industry.
        </p>

        <div className="flex flex-wrap gap-8 mt-10 justify-center mb-10">
          {processSteps.map((step) => (
            <div
              onMouseEnter={() => setActiveIndex(step.index)}
              onMouseLeave={() => setActiveIndex(null)}
              key={step.index}
              className="relative border p-6 rounded-lg shadow-md flex flex-col w-full sm:w-[calc(50%-16px)] lg:w-[calc(50%-16px)]"
            >
              <div
                className={`absolute -top-8 right-0 m-2 w-12 text-2xl font-mono h-12 flex items-center justify-center text-white rounded-full transition-transform duration-300 ease-linear ${
                  activeIndex === step.index
                    ? "translate-x-4 bg-violet-500"
                    : "translate-x-0 bg-primaryColor"
                }`}
              >
                {step.index}
              </div>
              <h3 className="text-xl tracking-wider mb-2">{step.title}</h3>
              <p className="text-sm text-TextSecondaryColor tracking-wider leading-6">
                {step.description}
              </p>
            </div>
          ))}
        </div>

        {/* Reuse BusinessCard */}
        <BusinessCard />
      </div>
    </div>
  );
};

export default Banking;
