import React, { useEffect, useState } from "react";
import SimpleBanner from "../../../components/common/SimpleBanner";
import AboutUs_banner from "../../../Assets/AboutUs/AboutUs_banner.png";
import { IoLocationOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import BusinessCard from "../../../components/common/BusinessCard";
import axios from "axios";

// Utility function to format job title for URL
const formatTitleForUrl = (title) => {
  return title
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/&/g, "and") // Replace & with "and"
    .replace(/_/g, "-"); // Replace underscores with hyphens
};

// JobCard component
const JobCard = ({ job }) => {
  const formattedTitle = formatTitleForUrl(job?.job_title);
  return (
    <Link
      to={`/jobs/${formattedTitle}/${job._id}`}
      className="border border-gray-300 rounded-lg p-6 shadow-md bg-white flex flex-col gap-4"
    >
      {/* First line: Job title */}
      <h3 className="text-lg font-semibold">{job?.job_title}</h3>

      {/* Second line: Type and salary */}
      <div className="flex gap-8 items-center">
        <p className="text-sm text-green-600 bg-green-100 px-3 py-1 rounded-md">
          {job?.job_type}
        </p>
        <p className="text-sm text-gray-500">Salary: {job?.salary}</p>
      </div>

      {/* Third line: Company logo, company name, and location */}
      <div className="flex items-center">
        <img
          src={job.logo}
          alt={`${job.company} logo`}
          className="w-12 h-12 mr-4 object-cover"
        />
        <div>
          <p className="font-medium text-gray-700">{job.company}</p>
          <div className="flex items-center text-sm text-gray-500">
            <IoLocationOutline className="mr-1" />
            <p>{job.location}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};

// SkeletonLoader component
const SkeletonLoader = () => {
  return (
    <div className="border border-gray-300 rounded-lg p-6 shadow-md bg-white flex flex-col gap-4 animate-pulse">
      <div className="h-6 bg-gray-300 mb-4"></div>
      <div className="flex gap-8 items-center">
        <div className="h-4 bg-gray-300 w-24"></div>
        <div className="h-4 bg-gray-300 w-16"></div>
      </div>
      <div className="flex items-center">
        <div className="w-12 h-12 bg-gray-300 mr-4"></div>
        <div>
          <div className="h-4 bg-gray-300 w-32 mb-2"></div>
          <div className="h-4 bg-gray-300 w-20"></div>
        </div>
      </div>
    </div>
  );
};

// Jobs component
const Jobs = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  const getJobData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/jobs/get-all-jobs`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setJobs(response.data?.jobs);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false after fetching
      });
  };

  useEffect(() => {
    getJobData();
  }, []);

  return (
    <div className="overflow-x-hidden overflow-y-hidden">
      <SimpleBanner
        image={AboutUs_banner}
        name="All Jobs"
        path="Home / Evaluate Your Career / All Jobs"
      />
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-6 lg:px-20 md:px-10 px-5 py-20">
        {loading ? ( // Show skeleton while loading
          Array.from({ length: 6 }).map((_, index) => <SkeletonLoader key={index} />)
        ) : jobs.length > 0 ? (
          jobs.map((job, index) => <JobCard key={index} job={job} />)
        ) : (
          <div className="col-span-full text-center text-lg font-medium text-gray-600">
            Jobs Not Found
          </div>
        )}
      </div>
      <div className="lg:px-20 md:px-10 px-5 pb-10">
        <BusinessCard />
      </div>
    </div>
  );
};

export default Jobs;
