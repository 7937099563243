import React, { useState } from "react";
import SimpleBanner from "../../../components/common/SimpleBanner";
import HealthCareBanner from "../../../Assets/Industry/HealthCareBanner.png";
import heathCare_image from "../../../Assets/Industry/heathCare_image.png";
import HeadingWithDot from "../../../components/common/HeadingWithDot";
import ConsultingServices_image from "../../../Assets/Industry/ConsultingServices_image.png";
import EHRImplementation_image from "../../../Assets/Industry/EHRImplementation_image.png";
import Telemedicine_image from "../../../Assets/Industry/Telemedicine_image.png";
import BusinessCard from "../../../components/common/BusinessCard";

const healthcareSolutions = [
  {
    image: ConsultingServices_image,
    title: "Consulting Services",
    description:
      "Our team of experts provides consulting services to healthcare providers, helping them identify areas for improvement and develop customized healthcare IT solutions that meet their unique needs.",
  },
  {
    image: EHRImplementation_image,
    title: "EHR Implementation",
    description:
      "Our electronic health record solutions make it easy for healthcare providers to access and share patient information securely, leading to better treatment plans and patient outcomes.",
  },
  {
    image: Telemedicine_image,
    title: "Telemedicine",
    description:
      "Our telemedicine solutions allow healthcare providers to consult with patients remotely, increasing access to care and reducing the need for in-person visits.",
  },
];

const processSteps = [
  {
    index: 1,
    title: "Research and Analysis",
    description:
      "We start by conducting extensive research and analysis to understand the healthcare industry's unique needs and challenges. This helps us identify opportunities for innovation and improvement in patient care, operational efficiency, and cost savings.",
  },
  {
    index: 2,
    title: "Testing & Quality Assurance",
    description:
      "Quality is paramount in healthcare. Our rigorous testing and quality assurance processes ensure that all IT solutions meet the highest standards of performance, security, and compliance before deployment.",
  },
  {
    index: 3,
    title: "Requirement Gathering",
    description:
      "We collaborate closely with our clients to gather their specific requirements and goals for the project. By prioritizing their needs and preferences, we ensure that the final product aligns with their vision and objectives.",
  },
  {
    index: 4,
    title: "Design & Prototyping",
    description:
      "Our design and prototyping phase transforms ideas into visual representations, allowing clients to visualize the end product. We incorporate client feedback into the design process to create user-friendly and efficient solutions.",
  },
  {
    index: 5,
    title: "Launch & Deployment",
    description:
      "We ensure a smooth launch and deployment of our solutions, providing support every step of the way. Our team works to ensure that systems are fully operational and integrated into the healthcare environment effectively.",
  },
  {
    index: 6,
    title: "Continuous Improvement",
    description:
      "Post-launch, we remain committed to our clients, offering ongoing support and improvements. We continuously gather feedback and data to enhance our solutions, ensuring they evolve with changing healthcare needs.",
  },
];

const HealthCare = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  return (
    <div>
      <SimpleBanner
        image={HealthCareBanner}
        name="Health Care"
        path="Home / Industry / Health Care"
      />
      <div className="lg:px-20 md:px-10 px-5 py-10">
        <div className="grid xl:grid-cols-2 grid-cols-1 gap-10 ">
          <div className="">
            <img src={heathCare_image} alt="about us" className="w-full" />
          </div>
          <div className="flex justify-center items-center">
            <div>
              <h1 className="md:text-4xl text-3xl tracking-wider md:leading-[55px] leading-[50px] mt-2">
                Healthcare IT Development Company
                <span className="text-primaryColor">Company</span>
              </h1>
              <p className="text-TextSecondaryColor mt-2 w-[90%] tracking-wide">
                We understand the importance of utilizing cutting-edge
                technology to improve healthcare delivery and patient outcomes.
                Our healthcare IT solutions are designed to streamline
                processes, enhance communication, and provide patients with
                better care .
              </p>
              <p className="text-TextSecondaryColor mt-2 w-[90%] tracking-wide">
                At the heart of our company is a deep understanding of the
                challenges facing the automotive industry today. From the rapid
                shift toward electric and autonomous vehicles to the growing
                demand for sustainable transportation solutions, our team is
                constantly working to stay ahead of the curve and anticipate the
                needs of our clients. So whether you are a large automotive
                manufacturer or a small independent repair shop, we are here to
                help you navigate the complexities of the automotive technology
                landscape and achieve your business objectives.
              </p>
            </div>
          </div>
        </div>

        {/* Industry */}
        <div>
          <div className="flex justify-center mt-10">
            <HeadingWithDot name="Our Industry" />
          </div>
          <h1 className="md:text-4xl text-2xl tracking-wider text-center mt-4">
            Our <span className="text-primaryColor">Healthcare</span> IT
            Solutions
          </h1>
          <p className="text-TextSecondaryColor text-sm tracking-wider text-center m-auto xl:w-[60%] mt-4">
            We offer comprehensive IT solutions to improve patient care and
            enhance healthcare delivery. Our solutions are tailored to meet the
            unique needs of healthcare providers, from EHR implementation to
            telemedicine platforms and practice management solutions.
          </p>

          <div className="flex flex-wrap gap-8 mt-10 justify-center">
            {healthcareSolutions.map((solution, index) => (
              <div
                key={index}
                className="border p-6 rounded-lg shadow-md text-center flex flex-col items-center w-full sm:w-[calc(50%-16px)] lg:w-[calc(30.33%-16px)]"
              >
                <img
                  src={solution.image}
                  alt={solution.title}
                  className="w-20 mb-4"
                />
                <h3 className="text-lg font-semibold tracking-wider mb-2">
                  {solution.title}
                </h3>
                <p className="text-sm text-TextSecondaryColor tracking-wider">
                  {solution.description}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Our Industry */}
        <div className="flex justify-center mt-10">
          <HeadingWithDot name="Our Industry" />
        </div>
        <h1 className="md:text-4xl text-2xl tracking-wider text-center mt-4">
          Our <span className="text-primaryColor">Healthcare</span> IT Solutions
        </h1>
        <p className="text-TextSecondaryColor text-sm tracking-wider text-center m-auto xl:w-[60%] mt-4">
          We offer comprehensive IT solutions to improve patient care and
          enhance healthcare delivery. Our solutions are tailored to meet the
          unique needs of healthcare providers, from EHR implementation to
          telemedicine platforms and practice management solutions.
        </p>

        <div className="flex flex-wrap gap-8 mt-10 justify-center mb-10">
          {processSteps.map((step) => (
            <div
              onMouseEnter={() => setActiveIndex(step.index)}
              onMouseLeave={() => setActiveIndex(null)}
              key={step.index}
              className="relative border p-6 rounded-lg shadow-md flex flex-col w-full sm:w-[calc(50%-16px)] lg:w-[calc(50%-16px)]"
            >
              <div
                className={`absolute -top-8 right-0 m-2 w-12 text-2xl font-mono h-12 flex items-center justify-center  text-white rounded-full transition-transform duration-300 ease-linear ${
                  activeIndex === step.index
                    ? "translate-x-4 bg-violet-500"
                    : "translate-x-0 bg-primaryColor"
                }`}
              >
                {step.index}
              </div>
              <h3 className="text-xl tracking-wider mb-2">{step.title}</h3>
              <p className="text-sm text-TextSecondaryColor tracking-wider leading-6">
                {step.description}
              </p>
            </div>
          ))}
        </div>

        <BusinessCard />
      </div>
    </div>
  );
};

export default HealthCare;
