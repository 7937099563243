const SkeletonLoader = () => {
  return (
    <div className="h-[15rem] bg-white relative rounded-lg shadow-sm mb-4 overflow-hidden border border-gray-300 animate-pulse">
      <div className="p-4 lg:p-6 flex">
        {/* Image Placeholder */}
        <div className="w-16 h-16 bg-gray-300 rounded-lg mr-4"></div>

        {/* Text Content Placeholder */}
        <div className="flex-1">
          {/* Company Name and Job Title */}
          <div className="h-4 bg-gray-300 rounded w-1/3 mb-2"></div>
          <div className="h-6 bg-gray-300 rounded w-1/2 mb-3"></div>

          {/* Job Details */}
          <div className="flex gap-4 mb-4">
            <div className="h-4 bg-gray-300 rounded w-1/4"></div>
            <div className="h-4 bg-gray-300 rounded w-1/4"></div>
            <div className="h-4 bg-gray-300 rounded w-1/4"></div>
          </div>

          {/* Job Description */}
          <div className="h-4 bg-gray-300 rounded w-full mb-2"></div>
          <div className="h-4 bg-gray-300 rounded w-4/5 mb-2"></div>
          <div className="h-4 bg-gray-300 rounded w-3/5"></div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoader;
