import React from "react";

import Img1 from "../../Assets/Career/Img1.png";
import Img2 from "../../Assets/Career/Img2.png";
import Img3 from "../../Assets/Career/Img3.png";
import Img4 from "../../Assets/Career/Img4.png";
import keyFeatureImg1 from "../../Assets/Career/keyFeatureImg1.png";
import keyFeatureImg2 from "../../Assets/Career/keyFeatureImg2.png";
import keyFeatureImg3 from "../../Assets/Career/keyFeatureImg3.png";
import keyFeatureImg4 from "../../Assets/Career/keyFeatureImg4.png";
import bringVersion from "../../Assets/Career/bringVersion.png";
import logo from "../../Assets/Career/logo.png";
import abstractLine from "../../Assets/Career/icon.png";
import leftIcon from "../../Assets/Career/leftIcon.png";
import rightIcon from "../../Assets/Career/rightIcon.png";

function PartOfGlobalCommunity() {
  return (
    <div className="w-full overflow-hidden">
      {/* <div className="items-center justify-center flex mt-16 mb-8">
        <div className="flex  gap-4 border-[3px] rounded-lg border-[#DEE7EC] text-center items-center justify-center relative  w-[90%] md:w-[60%] lg:w-[40%] p-2 ">
          <img
            src={abstractLine}
            alt=""
            className="absolute -top-12 -left-12 hidden md:block"
          />
          <h1 className="capitalize text-center text-xl md:text-3xl font-semibold font-serif text-[#040303] tracking-wide leading-relaxed">
            Connect <span className="text-[#407BFF]">.</span> Learn
            <span className="text-[#407BFF]">.</span> Innovate
            <span className="text-[#407BFF]">.</span>
          </h1>
          <img src={logo} alt="" className="" />
        </div>
      </div> */}
      <div className="flex flex-col relative gap-2 text-center items-center justify-center my-4 p-auto lg:p-16  ">
        <h1 className="text-2xl md:text-4xl tracking-wide font-bold text-[#212529] mb-6 capitalize">
          {/* Join our <span className="text-[#F8B003]">STEM</span> Community */}
          Are you <span className="text-[#F8B003]">passionate</span> about
          Science, Technology, Engineering, and Mathematics?
        </h1>
        <img
          src={leftIcon}
          alt=""
          className="absolute left-0 bottom-0 hidden lg:block "
        />
        <img
          src={rightIcon}
          alt=""
          className="absolute right-0 bottom-0 hidden lg:block "
        />

        <p className="tracking-wide w-[90%] lg:w-[70%] text-center text-[#737588] mb-3">
          Join our STEM Community- a vibrant network of like-minded individuals
          driven by curiosity, creativity, and a shared vision to shape the
          future. Whether you’re a student, professional, or enthusiast, our
          community is the perfect place to connect, learn, and grow. A STEM
          Community is more than a network, it is a movement to inspire,
          educate, and empower individuals in their STEM journeys. By fostering
          collaboration, providing resources, and promoting diversity, these
          communities play a pivotal role in shaping the future of innovation
          and progress.
        </p>
      </div>
      <div className="w-full h-full flex flex-col gap-8 ">
        {/* <div className="items-center justify-center flex mt-16 ">
          <div className="  gap-4 border-[3px] rounded-lg border-[#DEE7EC] text-center items-center justify-center relative  w-[90%] md:w-[80%] lg:w-1/2 flex p-2 ">
            <img
              src={abstractLine}
              alt=""
              className="absolute -top-12 -left-12 hidden md:block"
            />
            <h1 className="capitalize text-center text-xl md:text-3xl font-semibold font-serif text-[#040303] tracking-wide leading-relaxed">
              Be a part of{" "}
              <span className="text-[#407BFF]">Global Community</span>
            </h1>
            <img src={logo} alt="" className="" />
          </div>
        </div> */}
        <div className="items-center justify-center flex mt-[-4px] mb-8">
          <div className="flex  gap-4 border-[3px] rounded-lg border-[#DEE7EC] text-center items-center justify-center relative  w-[90%] md:w-[60%] lg:w-[40%] p-2 ">
            <img
              src={abstractLine}
              alt=""
              className="absolute -top-12 -left-12 hidden md:block"
            />
            <h1 className="capitalize text-center text-xl md:text-3xl font-semibold font-serif text-[#040303] tracking-wide leading-relaxed">
              Connect <span className="text-[#407BFF]">.</span> Learn
              <span className="text-[#407BFF]">.</span> Innovate
              <span className="text-[#407BFF]">.</span>
            </h1>
            <img src={logo} alt="" className="" />
          </div>
        </div>
        <div className="flex flex-col-reverse lg:flex-row items-center">
          <img
            src={Img1}
            alt="img1"
            className="h-full object-cover w-full lg:w-[50%] "
          />
          <div className="bg-[#FFF1F1] w-full xl:h-[360px] flex flex-col justify-center p-4 md:p-10 lg:w-[50%] -mt-6">
            <h2 className="text-2xl font-bold text-[#E18B19]  mb-4">
              A Network of Innovators
            </h2>
            <p className="text-[#363533] tracking-wide text-xl leading-relaxed ">
              Connect with students, educators, professionals, and researchers
              who share your enthusiasm for STEM. Exchange ideas, collaborate on
              projects, and be inspired by the achievements of others in the
              community.
            </p>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row  items-center">
          <div className="bg-[#E0F7FF] w-full xl:h-[360px] flex flex-col justify-center p-4 md:p-10 lg:w-[50%] -mt-6">
            <h2 className="text-2xl font-bold text-[#E18B19] mb-4">
              Unparalleled Resources
            </h2>
            <p className="text-[#363533] tracking-wide text-xl leading-relaxed ">
              Dive into a rich collection of exclusive resources, including
              industry insights, study materials, career advice, and tools
              designed to enhance your knowledge and skills in STEM fields.
            </p>
          </div>
          <img
            src={Img2}
            alt="img2"
            className="h-full object-cover w-full lg:w-[50%] "
          />
        </div>

        <div className="flex flex-col-reverse lg:flex-row items-center">
          <img
            src={Img3}
            alt="img2"
            className="h-full object-cover w-full lg:w-[50%] "
          />
          <div className="bg-[#FFFBE9] w-full xl:h-[360px] flex flex-col justify-center p-4 md:p-10 lg:w-[50%] -mt-6">
            <h2 className="text-2xl font-bold text-[#E18B19] mb-4">
              Mentorship Opportunities
            </h2>
            <p className="text-[#363533] tracking-wide text-xl leading-relaxed">
              Learn from experts and industry leaders who are ready to guide you
              at every step of your journey. Whether you're a beginner or a
              seasoned professional, our mentors provide personalized advice to
              help you succeed.
            </p>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row items-center">
          <div className="bg-[#EDFFE9] w-full xl:h-[360px] flex flex-col justify-center p-4 md:p-10 lg:w-[50%] -mt-6">
            <h2 className="text-2xl font-bold text-[#E18B19] mb-4">
              Global Connections
            </h2>
            <p className="text-[#363533] tracking-wide text-xl leading-relaxed">
              Expand your horizons by connecting with members from across the
              globe. Engage in cross-cultural learning, exchange diverse
              perspectives, and be part of a truly international community.
            </p>
          </div>
          <img
            src={Img4}
            alt="img2"
            className="h-full object-cover w-full lg:w-[50%] "
          />
        </div>
      </div>
      <div className="items-center justify-center flex mt-2 ">
        <div className="  gap-4 border-[3px] rounded-lg border-[#DEE7EC] text-center items-center justify-center relative  w-[90%] md:w-[80%] lg:w-1/2 flex p-2 ">
          <img
            src={abstractLine}
            alt=""
            className="absolute -top-12 -left-12 hidden md:block"
          />
          <h1 className="capitalize text-center text-xl md:text-3xl font-semibold font-serif text-[#040303] tracking-wide leading-relaxed">
            Be a part of{" "}
            <span className="text-[#407BFF]">Global Community</span>
          </h1>
          <img src={logo} alt="" className="" />
        </div>
      </div>
      <div className=" p-6 md:w-4/5 mx-auto">
        {/* <h1 className="text-2xl md:text-4xl mb-10">
          About <span className="text-[#F8B003]">STEM Community</span> .....
        </h1> */}
        <h1 className="text-xl md:text-4xl text-center text-[#1C1C25] mb-4">
          About <span className="text-[#F8B003]">STEM Community</span> .....
        </h1>
        <p className="text-[#737588] tracking-wide md:w-[90%] mx-auto text-center my-1">
          A STEM Community is more than a network; it is a movement to inspire,
          educate, and empower individuals in their STEM journeys. By fostering
          collaboration, providing resources, and promoting diversity, these
          communities play a pivotal role in shaping the future of innovation
          and progress.{" "}
        </p>
        <p className="text-[#737588] tracking-wide md:w-[90%] mx-auto text-center my-1">
          Your passion for{" "}
          <span className="text-[#F8B003] text-xl italic font-medium">
            STEM{" "}
          </span>{" "}
          has the potential to transform the world. Join our community and be
          part of a movement that values curiosity, celebrates achievements, and
          creates opportunities for everyone in STEM. Joining a STEM Community
          means being part of something greater—an ecosystem where ideas turn
          into action and dreams transform into reality.
        </p>
        <p className="text-[#737588] tracking-wide md:w-[90%] mx-auto text-center my-1">
          For professionals, STEM Communities serve as platforms for growth and
          career advancement. They provide direct access to job opportunities,
          internships, and mentorship programs, helping members navigate their
          career paths effectively. Networking events and expert panels connect
          members with influential leaders and innovators, offering guidance and
          inspiration for long-term success.
        </p>
      </div>

      {/* Key Benefits  */}

      <div className="w-full h-full flex flex-col gap-8">
        {/* <div className="p-8 lg:p-24 lg:pt-1"> */}
        <h2 className="text-center text-black text-2xl lg:text-4xl font-bold mb-7">
          Key Benefits{" "}
        </h2>

        {/* </div> */}

        <div className="w-full grid grid-cols-1 gap-8  bg-[#ECF6FA] md:grid-cols-2 xl:grid-cols-4 px-8 py-8 lg:px-16 lg:mb-16 lg:mt-[9rem] ">
          <div className="flex flex-col gap-4 relative md:-top-14 lg:-top-28 bg-white p-4 lg:px-6  border border-gray-400 ">
            <img
              src={keyFeatureImg1}
              alt="keyFeature1"
              className=" w-12 h-12  lg:w-24 lg:h-24"
            />
            <h2 className="text-md lg:text-xl font-medium tracking-wide capitalize">
              Exposure to new Research and Trends
            </h2>
            <p className="text-gray-500 font-normal text-sm lg:text-lg">
              Keep up with the latest research, developments, and innovations in
              STEM. Learn about emerging technologies and industry trends that
              could shape the future of your field.
            </p>
          </div>
          <div className="flex flex-col gap-4 relative md:-top-20 lg:-top-44  bg-white p-4 lg:px-6 border border-gray-400">
            <img
              src={keyFeatureImg2}
              alt="keyFeature2"
              className=" w-12 h-12  lg:w-24 lg:h-24"
            />
            <h2 className="text-md lg:text-xl font-medium tracking-wide">
              Skill Development and Training
            </h2>
            <p className="text-gray-500 font-normal text-sm lg:text-lg">
              Participate in workshops, webinars, and hands-on projects that
              provide practical skills and knowledge, allowing you to stay
              updated with the latest tools, technologies, and trends in your
              field.
            </p>
          </div>
          <div className="flex flex-col gap-4 relative md:-top-14 lg:-top-28 bg-white  p-4 lg:px-6 border border-gray-400">
            <img
              src={keyFeatureImg3}
              alt="keyFeature1"
              className=" w-12 h-12  lg:w-24 lg:h-24"
            />
            <h2 className="text-md lg:text-xl font-medium tracking-wide">
              Access to Specialized Tools and Software
            </h2>
            <p className="text-gray-500 font-normal text-sm lg:text-lg">
              Take advantage of exclusive access to advanced tools, software,
              and technologies that are essential for your studies, research, or
              professional projects, helping you stay competitive in the STEM
              workforce.
            </p>
          </div>
          <div className="flex flex-col gap-4 relative md:-top-20 lg:-top-44  bg-white  p-4 lg:px-6 border border-gray-400">
            <img
              src={keyFeatureImg4}
              alt="keyFeature1"
              className=" w-12 h-12  lg:w-24 lg:h-24"
            />
            <h2 className="text-md lg:text-xl font-medium tracking-wide">
              Interdisciplinary Collaboration
            </h2>
            <p className="text-gray-500 font-normal text-sm lg:text-lg">
              Work with members from various STEM disciplines to gain diverse
              insights and collaborate on multidisciplinary projects. This
              exposure broadens your understanding and strengthens your ability
              to solve complex, cross-domain problems.
            </p>
          </div>
        </div>
      </div>

      {/* bringVersion */}
    </div>
  );
}

export default PartOfGlobalCommunity;
