import React, { useState } from "react";
import CreateCoursePlanModal from "../../components/admin/Plans/CreateCoursePlanModal";
import CreateMockPlan from "../../components/admin/Plans/CreateMockPlan";
import CreateResumePlan from "../../components/admin/Plans/CreateResumePlan";
import MockPlanCard from "../../components/admin/PlanDetails/MockPlanCard";
import ResumePlanCards from "../../components/admin/PlanDetails/ResumePlanCards";
import CoursePlanCards from "../../components/admin/PlanDetails/CoursePlanCards";

const Plans = () => {
  const [activeTab, setActiveTab] = useState("coursePlans");
  const [isModalOpenCoursePlan, setIsModalOpenCoursePlan] = useState(false);
  const [isMockPlanModalOpen, setMockPlanModal] = useState(false);
  const [resumePlanModal, setResumePlanModal] = useState(false);
  const [isMockCompOpen, setMokeCompOpen] = useState(false);
  const [isResumeCompOpen, setResumeCompOpen] = useState(false);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // Function to handle button click dynamically based on active tab
  const handleButtonClick = () => {
    switch (activeTab) {
      case "coursePlans":
        // Action for Job Plans tab
        console.log("Creating a Job Plan...");
        setIsModalOpenCoursePlan(true);
        break;
      case "mockPlans":
        // Action for Mock Plans tab
        setMockPlanModal(true);
        console.log("Creating a Mock Plan...");
        break;
      case "resumePlans":
        // Action for Resume Plans tab
        setResumePlanModal(true);
        console.log("Creating a Resume Plan...");
        break;
      default:
        break;
    }
  };

  // Function to get button text based on active tab
  const getButtonText = () => {
    switch (activeTab) {
      case "coursePlans":
        return "Create Course Plan";
      case "mockPlans":
        return "Create Mock Plan";
      case "resumePlans":
        return "Create Resume Plan";
      default:
        return "";
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <div className="flex space-x-4 border border-purple-400">
          <button
            onClick={() => handleTabChange("coursePlans")}
            className={`py-2 px-4 ${
              activeTab === "coursePlans" ? "bg-customPurple text-white" : ""
            }`}
          >
            Course Plans
          </button>
          <button
            onClick={() => handleTabChange("mockPlans")}
            className={`py-2 px-4 ${
              activeTab === "mockPlans" ? "bg-customPurple text-white" : ""
            }`}
          >
            Mock Plans
          </button>
          <button
            onClick={() => handleTabChange("resumePlans")}
            className={`py-2 px-4 ${
              activeTab === "resumePlans" ? "bg-customPurple text-white" : ""
            }`}
          >
            Resume Plans
          </button>
        </div>
        <button
          onClick={handleButtonClick}
          className="bg-customPurple rounded-lg px-4 py-2 font-semibold text-white"
        >
          {getButtonText()}
        </button>
      </div>

      <div>
        {activeTab === "coursePlans" && (
          <div>
            {/* Content for Job Plans */}
            <CoursePlanCards />
          </div>
        )}
        {activeTab === "mockPlans" && (
          <div>
            {/* Content for Mock Plans */}
            <MockPlanCard isMockPlanModalOpen={isMockPlanModalOpen} />
          </div>
        )}
        {activeTab === "resumePlans" && (
          <div>
            {/* Content for Resume Plans */}
            <ResumePlanCards resumePlanModal={resumePlanModal} />
          </div>
        )}
      </div>

      {isModalOpenCoursePlan && (
        <CreateCoursePlanModal
          onClose={() => setIsModalOpenCoursePlan(false)}
        />
      )}

      {isMockPlanModalOpen && (
        <CreateMockPlan setMockPlanModal={setMockPlanModal} />
      )}
      {resumePlanModal && (
        <CreateResumePlan setResumePlanModal={setResumePlanModal} />
      )}
    </div>
  );
};

export default Plans;
