import React, { useEffect, useState, useRef } from "react";
import WelcomeToCompany from "../../components/home/WelcomeToCompany";
import OurServices from "../../components/home/OurServices";
import AboutUs from "../../components/home/AboutUs";
import OurIndustry from "../../components/home/OurIndustry";
import CountContainer from "../../components/home/CountContainer";
import DigitalRevolution from "../../components/home/DigitalRevolution";
import ClientsTalk from "../../components/home/ClientsTalk";
import ContactUsForm from "../../components/home/ContactUsForm";
import BusinessCard from "../../components/common/BusinessCard";

const Home = () => {
  const [counts, setCounts] = useState({
    community: 0,
    clients: 0,
    projects: 0,
    industries: 0,
  });
  const sectionRef = useRef(null);
  const [hasAnimated, setHasAnimated] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = sectionRef.current;
      if (!section) return;

      const rect = section.getBoundingClientRect();
      const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;

      if (isVisible && !hasAnimated) {
        setHasAnimated(true);
        animateCounters();
      }
    };

    const animateCounters = () => {
      const targetCounts = {
        community: 130,
        clients: 60,
        projects: 2300,
        industries: 57,
      };
      const duration = 15000; // Animation duration in ms
      const increment = duration / 60; // Number of updates per second

      const interval = setInterval(() => {
        setCounts((prevCounts) => {
          let updatedCounts = { ...prevCounts };
          let finished = true;

          for (let key in targetCounts) {
            if (prevCounts[key] < targetCounts[key]) {
              updatedCounts[key] = Math.min(
                prevCounts[key] + Math.ceil(targetCounts[key] / increment),
                targetCounts[key]
              );
              finished = false;
            }
          }

          if (finished) clearInterval(interval);
          return updatedCounts;
        });
      }, 1000 / 60); // 60 updates per second
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [hasAnimated]);
  return (
    <div className="overflow-x-hidden overflow-y-hidden">
      <WelcomeToCompany />
      <div className="lg:px-20 md:px-10 px-5 py-10">
        <OurServices />
        <AboutUs />
        <OurIndustry />
      </div>
      <CountContainer sectionRef={sectionRef} counts={counts} />
      <div className="lg:px-20 md:px-10 px-5 py-10">
        <DigitalRevolution />
      </div>
      <ClientsTalk />
      <div className="lg:px-20 md:px-10 px-5 py-10">
        <ContactUsForm />
        <BusinessCard />
      </div>
    </div>
  );
};

export default Home;
