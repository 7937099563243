import { CiLocationOn } from "react-icons/ci";
import { CiStopwatch } from "react-icons/ci";
import { FiDollarSign } from "react-icons/fi";
import { useState, useEffect } from "react";
import jobLogo from "../../Assets/JobDetail/jobLogo.svg";

import encryptionModule from "../common/localStorageUtils";
import { useNavigate } from "react-router-dom";

const JobCards = ({
  userData,
  job,
  setApplyJobModal,
  JobApply,
  setCurrentJob,
  index,
  load,
  isApplied,
  setIsApplied,
}) => {
  const [hasApplied, setHasApplied] = useState(false);

  const navigate = useNavigate();
  //Prepare Payload Data...
  const payLoad = {
    fullName: userData?.user?.name,
    email: userData?.user?.email,
    phoneNumber: userData?.user?.phone,
  };
  useEffect(() => {
    if (
      job?.numbersOfApplicants &&
      job?.numbersOfApplicants?.some(
        (applicant) =>
          applicant?.email === userData?.user?.email ||
          applicant?.phoneNumber === userData?.user?.phone
      )
    ) {
      setHasApplied(true);
      setIsApplied(true);
    }
  }, [job?.numbersOfApplicants, userData?.user?.email, userData?.user?.phone]);

  return (
    <div className=" h-[15rem] bg-white relative rounded-lg shadow-sm mb-4 overflow-hidden border border-blue-500">
      <div className="p-4 lg:p-6 flex">
        <img
          src={jobLogo}
          alt={`${job.company} logo`}
          className="w-16 h-16 rounded-lg mr-4"
        />
        <div
          onClick={() => {
            navigate(`/jobs/details/${job._id}`);
          }}
          className="flex-1 cursor-pointer"
        >
          <div className="flex justify-between items-start mb-2">
            <div>
              <h3 className="text-lg text-left text-gray-500 mb-1">
                {job.company}
              </h3>
              <h2 className="text-lg text-left  font-semibold">
                {job.positionName}
              </h2>
            </div>
            {job.isNew && (
              <span className="bg-blue-100 text-blue-800 text-xs font-semibold px-2.5 py-0.5 rounded-full">
                New post
              </span>
            )}
          </div>
          <div className="text-sm text-gray-500 mb-2 flex flex-col lg:flex-row  gap-4 flex-wrap">
            <div className="flex">
              <div className="flex  items-center gap-1">
                <CiLocationOn className="text-gray-600" />
                <span className="mr-2 font-medium">{job.location}</span>
              </div>
              {/* <span className="mr-4 font-medium">•</span> */}
              <div className="flex  items-center gap-1">
                <CiStopwatch />
                <span className="mr-2 font-medium">{job.jobType}</span>
              </div>
            </div>
            {/* <span className="mr-4 font-medium">•</span> */}

            <div className="flex ">
              <div className="flex  items-center gap-1">
                <FiDollarSign />
                <span className="mr-2 font-medium">{job.salaryPackage}</span>
              </div>
              {/* <span className="mr-4 font-medium">•</span> */}

              {/* <div className="flex  items-center gap-1">
                <CiCalendar />
                <span className="font-medium">{job.posted}</span>
              </div> */}
            </div>
          </div>
          <p className="text-sm text-left text-gray-700">
            {job.jobDescription.slice(0, 300)}...
          </p>
        </div>
      </div>
      {hasApplied ? (
        <button
          onClick={() => {
            JobApply(job._id, "", payLoad, index);
          }}
          className="bg-green-300 text-white tracking-wide font-semibold px-4 py-2 w-[10rem] absolute bottom-2 right-2 rounded-md "
        >
          {load ? "Please, Wait..." : "Applied"}
        </button>
      ) : (
        <button
          onClick={() => {
            // if (!userData) {
            setApplyJobModal(true);

            setCurrentJob(job);
            // } else {
            //   JobApply(job._id, job.jobCreatedBy, payLoad, index);
            // }
          }}
          className="bg-green-500 text-white tracking-wide font-semibold px-4 py-2 w-[10rem] absolute bottom-2 right-2 rounded-md"
        >
          {load ? "Please, Wait..." : "Apply Now"}
        </button>
      )}
    </div>
  );
};

export default JobCards;
