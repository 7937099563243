import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import img from "../../../Assets/MockInterview/9384988_41713441.png";
import { FaEdit } from "react-icons/fa";
import { MdDeleteSweep } from "react-icons/md";
import EditMockPlannModal from "../PlansEdit/EditMockPlannModal";

const MockPlanCard = ({ isMockPlanModalOpen }) => {
  const [allMockPlans, setAllMockPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(1);
  const [editPlanModal, setEditPlanModal] = useState(false);
  const [currentPlan, setCurrentPlan] = useState({});
  // Fetch all mock plans
  const getAllMockPlans = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/mock-interview-plans/get-all-plans`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Response of getAllMockPlans: ", response.data);
        setAllMockPlans(response?.data?.packages);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Update plan's active status
  const updatePlanStatus = (planId, isActive) => {
    let config = {
      method: "put",
      url: `${process.env.REACT_APP_API_KEY}/mock-interview-plans/update/${planId}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        default_plan: isActive ? "true" : "false",
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response);
        console.log("Status updated successfully");
        getAllMockPlans();
      })
      .catch((error) => {
        console.log("Error updating status", error);
      });
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this plan ?")) {
      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_KEY}/mock-interview-plans/delete/${id}`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response.data);
          getAllMockPlans();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    getAllMockPlans();
  }, [editPlanModal, isMockPlanModalOpen]);

  const handleToggle = (plan) => {
    // Toggle the plan's active status locally
    const updatedPlans = allMockPlans.map((p) =>
      p.id === plan.id
        ? { ...p, isActive: p.default_plan === "true" ? "false" : "true" }
        : p
    );
    setAllMockPlans(updatedPlans);

    // Call the update API to persist the change (convert to boolean)
    updatePlanStatus(plan._id, plan.default_plan === "false");
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
      {editPlanModal ? (
        <EditMockPlannModal
          setEditPlanModal={setEditPlanModal}
          currentPlan={currentPlan}
        />
      ) : (
        ""
      )}

      {allMockPlans.map((plan) => (
        <div
          key={plan.id}
          className={`border rounded-lg p-4 shadow-md relative transition-transform transform hover:scale-105 cursor-pointer `}
          onClick={() => setSelectedPlan(plan.id)}
        >
          {/* Toggle Button */}
          <label className="inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={plan.default_plan === "true"} // Convert string to boolean
              onChange={() => handleToggle(plan)}
              className="sr-only peer"
            />
            <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-[#846CF9] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          </label>

          {/* Plan Image */}
          <div className="mb-4">
            <img src={img} alt="No Image" className="mx-auto" />
          </div>

          {/* Plan Title */}
          <h3 className="tracking-wide text-md text-center font-semibold mb-2">
            {plan?.packageName}
          </h3>

          {/* Pricing */}
          <div
            className={` text-gray-500 ${
              plan.discounted_price == "0" ? "" : "line-through"
            }  `}
          >
            <p className=" tracking-wide text-center">${plan.actual_price}</p>
          </div>
          <div className=" text-md font-bold tracking-wide mb-4">
            <p className="tracking-wide text-center">
              {plan.discounted_price == "0"
                ? "No Discounts Availabe 😞"
                : `$${plan.discounted_price}`}
            </p>
          </div>

          {/* Selected Plan Checkmark */}
          {/* {selectedPlan === plan.id && (
            <div className="absolute top-2 right-2 text-green-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
          )} */}
          <div className="flex justify-end items-center gap-2">
            <FaEdit
              onClick={() => {
                setEditPlanModal(true);
                setCurrentPlan(plan);
              }}
              size={25}
              className="cursor-pointer font-medium text-blue-600 dark:text-blue-500 hover:underline"
            />
            <MdDeleteSweep
              onClick={() => {
                handleDelete(plan._id);
              }}
              size={25}
              className="font-medium text-red-600 dark:text-red-500 hover:underline  cursor-pointer"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default MockPlanCard;
