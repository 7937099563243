import React from "react";
import { FaInfoCircle } from "react-icons/fa";
import { MdAddComment } from "react-icons/md";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import encryptionModule from "../../components/common/localStorageUtils";
import axios from "axios";
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
const CourseCreatePage = () => {
  const navigate = useNavigate();
  //   Cloudinary info
  const cloudName = "dnw1ttr02";
  const upload_preset = "wjs1zyo5";
  const adminData = encryptionModule.decryptData("adminInfo");
  const AdminId = adminData?.user?._id;

  //Variables declared here...
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [load, setload] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [keyPoints, setKeyPoints] = useState("");
  const [keyPointArr, setKeyPointArr] = useState([]);
  const [chooseCourse, setChooseCourse] = useState("");
  const [chooseCourseArr, setChooseCourseArr] = useState([]);
  const [courseImg, setCourseImg] = useState([]);
  const [arrOfCourseImg, setArrOfCourseImg] = useState([]);
  const [category, setCategory] = useState([]);
  const [courseBannerImg, setCourseBannerImg] = useState("");
  const [imageOne, setImageOne] = useState("");
  const [imageTwo, setImageTwo] = useState("");
  const [loadingBnr, setLoadingBnr] = useState(false);
  const [loadImageOne, setLoadImageOne] = useState(false);
  const [loadImageTwo, setLoadImageTwo] = useState(false);
  const [loaderOne, setloaderOne] = useState(false);
  const [loaderTwo, setloaderTwo] = useState(false);
  const [loaderThree, setloaderThree] = useState(false);

  //Validation Variables
  const [courseBannerVAl, setCourseBannerVal] = useState(true);
  // const [imageOneVal, setImageOneVal] = useState(true);
  // const [imageTwoVal, setImageTwoVal] = useState(true);
  const [keyPointVAl, setKeyPointVal] = useState(true);
  const [chooseVal, setChooseVal] = useState(true);

  //About Course Key Points are Handled...
  const handleKeyPointsChange = () => {
    let arr = [...keyPointArr];
    arr.push(keyPoints);
    setKeyPointArr(arr);
    setKeyPoints("");
  };
  //Why Choose this course Key Points are Handled...
  const handleChooseCourse = () => {
    let arr = [...chooseCourseArr];
    arr.push(chooseCourse);
    setChooseCourseArr(arr);
    setChooseCourse("");
  };

  //Course Image arr handled here...(temporary)
  const handleCourseImg = () => {
    if (arrOfCourseImg?.length < 2) {
      setArrOfCourseImg([...arrOfCourseImg, courseImg]);
      setCourseImg([]);
    } else {
      alert("Maximum 2 images can be added .");
    }
  };

  //Remove key points in about course section.....
  const removeKeyPoint = (each, itemIndex) => {
    const newArr = keyPointArr.filter((each, index) => {
      return index != itemIndex;
    });
    setKeyPointArr(newArr);
  };

  //Remove key points in Why choose this course section.....
  const removeCourseArr = (each, itemIndex) => {
    const newArr = chooseCourseArr.filter((each, index) => {
      return index != itemIndex;
    });
    setChooseCourseArr(newArr);
  };

  //remove course img .... (Temporary)
  const removeCourseImg = (each, itemIndex) => {
    const newArr = arrOfCourseImg.filter((each, index) => {
      return index != itemIndex;
    });
    setArrOfCourseImg(newArr);
  };

  //All Image uploads are handled here....
  const uploadImages = (eachfile, type) => {
    if (type === "Banner") {
      setloaderOne(true);
    } else if (type === "image1") {
      setloaderTwo(true);
    } else {
      setloaderThree(true);
    }
    const file = eachfile[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", upload_preset);
    formData.append("public_id", `KGS/${eachfile[0].name}`);
    axios
      .post(
        `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
        formData
      )
      .then((response) => {
        console.log(response);
        if (type === "Banner") {
          setCourseBannerImg(response?.data?.secure_url);
          setLoadingBnr(true);
          setloaderOne(false);
        } else if (type === "image1") {
          setImageOne(response?.data?.secure_url);
          setLoadImageOne(true);
          setloaderTwo(false);
        } else {
          setImageTwo(response?.data?.secure_url);
          setLoadImageTwo(true);
          setloaderThree(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //All catagory is fetched over here....
  console.log("-----cat--->", category);
  const getAllCatagory = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/categories/get-all-categories`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        setCategory(response?.data?.categories);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Form Validation Handled Over here...
  const validateForm = (data) => {
    for (const key in data) {
      if (!data[key]) {
        toast.error(`Please fill the ${key.replace("_", " ")} field`);
        return false;
      }
    }
    return true;
  };

  //Form submission handled here.....
  const createCourse = (data) => {
    if (courseBannerImg === "") {
      setCourseBannerVal(false);
      return;
    } else {
      setCourseBannerVal(true);
    }

    // if (imageOne === "") {
    //   setImageOneVal(false);
    //   return;
    // } else {
    //   setImageOneVal(true);
    // }

    // if (imageTwo === "") {
    //   setImageTwoVal(false);
    //   return;
    // } else {
    //   setImageTwoVal(true);
    // }

    if (keyPointArr.length === 0) {
      setKeyPointVal(false);
      return;
    } else {
      setKeyPointVal(true);
    }

    if (chooseCourseArr.length === 0) {
      setChooseVal(false);
      return;
    } else {
      setChooseVal(true);
    }

    if (!validateForm(data)) {
      return;
    }
    setload(true);
    const about_course = {
      title: data?.aboutCourseTitle,
      details: data?.detail,
      key_points: keyPointArr,
      // images: [imageOne, imageTwo],
      why_choose_this_course: chooseCourseArr,
      last_details: data?.least_details,
    };

    const myData = {
      ...data,
      courseBanner: courseBannerImg,
      description: data?.mydescription,
      published_by: AdminId,
      category: data?.category,
      about_course: about_course,
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/courses/create`,
      headers: {
        "Content-Type": "application/json",
      },
      data: myData,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        document.getElementById("course").reset();
        alert(
          "All images Uploaded and Course has been created successfully .."
        );
        setload(false);
        setTimeout(() => {
          navigate("/course");
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        alert("Course Couldn't be created..!");
        setload(false);
      });
  };

  useEffect(() => {
    getAllCatagory();
  }, []);

  //Components is returned over here...
  return (
    <form
      id="course"
      onSubmit={handleSubmit(createCourse)}
      className="container mx-auto p-6 mb-6"
    >
      {/* Hero Section */}
      <div className="relative bg-gray-100 p-6 rounded-lg shadow-lg mb-8">
        <h2 className="text-2xl font-bold mb-4 text-customPurple">
          Create Course
        </h2>
        <button
          onClick={() => {
            navigate("/course");
          }}
          className="h-[2rem] absolute top-6 right-6 w-[7rem] font-semibold tracking-wide rounded-md text-white bg-customPurple hover:bg-indigo-300"
        >
          Go Back
        </button>
        <div className="flex justify-between mb-4">
          <div className="w-1/2 pr-4">
            <label className="block text-sm font-semibold tracking-wide mb-2">
              Course Name
            </label>
            <input
              type="text"
              id="title"
              name="title"
              {...register("title", { required: true })}
              placeholder="Enter Course Name"
              className={`border p-2 w-full rounded ${
                errors.title ? "border-red-500" : ""
              }`}
            />
          </div>

          <div className="w-1/2 pl-4">
            <label className="block text-sm font-semibold tracking-wide mb-2">
              Course Category
            </label>
            <select
              id="category"
              name="category"
              {...register("category", { required: true })}
              className={`border p-2 w-full rounded ${
                errors.category ? "border-red-500" : ""
              }`}
            >
              <option value="">Select Category</option>
              {category.map((each, index) => {
                return (
                  <option key={index} value={each?._id}>
                    {each?.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <label className="block text-sm font-semibold tracking-wide mb-2">
          Course Description
        </label>
        <textarea
          id="mydescription"
          name="mydescription"
          {...register("mydescription", { required: true })}
          placeholder="Enter Course Description"
          className={`border p-2 w-full h-24 rounded mb-4 ${
            errors.mydescription ? "border-red-500" : ""
          }`}
        ></textarea>

        <div className="flex justify-between mb-4">
          <div className="w-1/2 pr-4">
            <label className="block text-sm font-semibold tracking-wide mb-2">
              Number of Reviews
            </label>
            <input
              type="number"
              id="reviews"
              name="reviews"
              {...register("reviews", { required: true })}
              placeholder="Enter number of reviews"
              className={`border ${
                errors.reviews ? "border-red-500" : ""
              } p-2 w-full rounded`}
            />
          </div>
          <div className="w-1/2 pl-4">
            <div className="">
              <label className="block text-sm font-semibold tracking-wide mb-2">
                Course Banner
              </label>
              <input
                onChange={(e) => {
                  setLoadingBnr(false);
                  uploadImages(e.target.files, "Banner");
                }}
                type="file"
                className={`border p-2 w-full rounded ${
                  !courseBannerVAl ? "border-red-500" : ""
                }`}
              />
            </div>
            {loadingBnr && (
              <div className="relative h-[5rem] mt-3 w-[5rem] bg-yellow-300 rounded-md">
                <img
                  className="h-full w-full object-cover rounded-md"
                  src={courseBannerImg}
                  alt="No Image"
                ></img>
                <IoCloseSharp
                  size={25}
                  color="white"
                  onClick={() => {
                    setLoadingBnr(false);
                  }}
                  className="absolute cursor-pointer  top-0 right-0"
                />
              </div>
            )}

            {loaderOne && (
              <div className="flex justify-center items-center h-[5rem] mt-3 w-[5rem] bg-yellow-300 rounded-md">
                <div className="loaderAdd"></div>
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-between mb-4">
          <div className="w-1/2 pr-4">
            <label className="block text-sm font-semibold tracking-wide mb-2">
              Rating
            </label>
            <select
              id="ratings"
              name="ratings"
              {...register("ratings", { required: true })}
              className={`border p-2 w-full rounded ${
                errors.ratings ? "border-red-500" : ""
              }`}
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>

          <p className="absolute bottom-2 right-2 block text-sm font-medium mr-4  tracking-wide mb-2">
            Published by: Admin
          </p>
        </div>
      </div>

      {/* Course Content */}
      <div className="bg-white p-6 rounded-lg shadow-lg mb-8">
        <h3 className="text-xl font-bold mb-4">Course Contents</h3>
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div className="w-full ">
            <label className="block text-sm font-semibold tracking-wide mb-2">
              Hours of Course
            </label>
            <input
              type="text"
              id="hours_of_course"
              name="hours_of_course"
              {...register("hours_of_course", { required: true })}
              placeholder="Hours of Course"
              className={`border p-2 rounded w-full ${
                errors.hours_of_course ? "border-red-500" : ""
              }`}
            />
          </div>
          <div className="w-full ">
            <label className="block text-sm font-semibold tracking-wide mb-2">
              Total Assignments
            </label>
            <input
              type="text"
              id="total_assignments"
              name="total_assignments"
              {...register("total_assignments", { required: true })}
              placeholder="Total Assignments"
              className={`border p-2 rounded w-full ${
                errors.total_assignments ? "border-red-500" : ""
              }`}
            />
          </div>
          <div className="w-full">
            <label className="block text-sm font-semibold tracking-wide mb-2">
              Live Classes
            </label>
            <input
              type="text"
              id="video_lessons"
              name="video_lessons"
              {...register("video_lessons", { required: true })}
              placeholder="Number of Videos"
              className={`border p-2 rounded w-full ${
                errors.video_lessons ? "border-red-500" : ""
              }`}
            />
          </div>
          <div className="w-full">
            <label className="block text-sm font-semibold tracking-wide mb-2">
              Number of Students Enrolled
            </label>
            <input
              type="text"
              id="students_enrolled"
              name="students_enrolled"
              {...register("students_enrolled", { required: true })}
              placeholder="Number of Students Enrolled"
              className={`border p-2 rounded w-full ${
                errors.students_enrolled ? "border-red-500" : ""
              }`}
            />
          </div>
        </div>
      </div>

      {/* About Course */}
      <div className="bg-gray-100 p-6 rounded-lg shadow-lg mb-8">
        <h3 className="text-xl font-bold mb-4">About Course</h3>
        <div className="mb-4">
          <label className="block text-sm font-semibold tracking-wide mb-2">
            Course Heading
          </label>
          <input
            type="text"
            id="aboutCourseTitle"
            name="aboutCourseTitle"
            {...register("aboutCourseTitle", { required: true })}
            placeholder="Course Heading"
            className={`border p-2 w-full rounded ${
              errors.aboutCourseTitle ? "border-red-500" : ""
            }`}
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-semibold tracking-wide mb-2">
            More Course Details
          </label>
          <textarea
            id="detail"
            name="detail"
            {...register("detail", { required: true })}
            placeholder="More Course Details"
            className={`border p-2 w-full h-24 rounded mb-4 ${
              errors.detail ? "border-red-500" : ""
            }`}
          ></textarea>
        </div>
        {/* <div className="flex justify-between mb-4">
          <div className="w-full">
            <div>
              <label className="block text-sm font-semibold tracking-wide mb-2">
                Image 1
              </label>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => {
                  setLoadImageOne(false);
                  uploadImages(e.target.files, "image1");
                }}
                className={`border p-2 rounded ${
                  !imageOneVal ? "border-red-500" : ""
                }`}
              />
            </div>
            {loadImageOne && (
              <div className="relative h-[5rem] mt-3 w-[5rem] bg-yellow-300 rounded-md">
                <img
                  className="h-full w-full object-cover rounded-md"
                  src={imageOne}
                  alt="No Image"
                ></img>
                <IoCloseSharp
                  size={25}
                  color="white"
                  onClick={() => {
                    setLoadImageOne(false);
                  }}
                  className="absolute cursor-pointer  top-0 right-0"
                />
              </div>
            )}

            {loaderTwo && (
              <div className="flex justify-center items-center h-[5rem] mt-3 w-[5rem] bg-yellow-300 rounded-md">
                <div className="loaderAdd"></div>
              </div>
            )}
          </div>

          <div className="w-full">
            <div>
              <label className="block text-sm font-semibold tracking-wide mb-2">
                Image 2
              </label>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => {
                  setLoadImageTwo(false);
                  uploadImages(e.target.files, "image2");
                }}
                className={`border p-2 rounded ${
                  !imageTwoVal ? "border-red-500" : ""
                }`}
              />
            </div>
            {loadImageTwo && (
              <div className="relative h-[5rem] mt-3 w-[5rem] bg-yellow-100 rounded-md">
                <img
                  className="h-full w-full object-cover rounded-md"
                  src={imageTwo}
                  alt="No Image"
                ></img>
                <IoCloseSharp
                  size={25}
                  color="white"
                  onClick={() => {
                    setLoadImageTwo(false);
                  }}
                  className="absolute cursor-pointer  top-0 right-0"
                />
              </div>
            )}

            {loaderThree && (
              <div className="flex justify-center items-center h-[5rem] mt-3 w-[5rem] bg-yellow-300 rounded-md">
                <div className="loaderAdd"></div>
              </div>
            )}
          </div>
        </div> */}
        <div className="w-full">
          <label className="block text-sm font-semibold tracking-wide mb-2">
            Key Points About Course
          </label>

          <div className="flex items-center justify-between border rounded">
            <input
              type="text"
              id="key_points"
              name="key_points"
              value={keyPoints}
              onChange={(e) => {
                setKeyPoints(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault(); // Prevent form submission if inside a form
                  handleKeyPointsChange();
                }
              }}
              placeholder="Key Points About Course"
              className={`border p-2 w-full rounded ${
                !keyPointVAl ? "border-red-500" : ""
              }`}
            />
            <MdAddComment
              onClick={() => {
                handleKeyPointsChange();
              }}
              size={30}
              className="text-blue-500 mr-2 cursor-pointer "
            />
          </div>
          <div className="mt-2 rounded-lg bg-gray-50 px-2 py-2 ">
            <ul className="flex flex-wrap items-center gap-1">
              {keyPointArr?.map((each, index) => {
                return (
                  <li
                    key={index}
                    className="flex items-center gap-1 rounded-full bg-customPurple px-2 py-1 text-sm text-white"
                  >
                    {each.substring(0, 30)}
                    <IoCloseSharp
                      onClick={() => {
                        removeKeyPoint(each, index);
                      }}
                      size={20}
                      className="font-semibold cursor-pointer"
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>

      {/* Why Choose This Course */}
      <div className="bg-white p-6 rounded-lg shadow-lg mb-8">
        <h3 className="text-xl font-bold mb-4">Why Choose This Course</h3>
        <div className="mb-4">
          <label className="block text-sm font-semibold tracking-wide mb-2">
            Key Points About Why Choose This Course
          </label>
          <div className="flex items-center justify-between border rounded">
            <input
              type="text"
              id="why_choose_this_course"
              name="why_choose_this_course"
              value={chooseCourse}
              onChange={(e) => {
                setChooseCourse(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault(); // Prevent form submission if inside a form
                  handleChooseCourse();
                }
              }}
              placeholder="Key Points About Why Choose This Course"
              className={`border p-2 w-full ${
                !chooseVal ? "border-red-500" : ""
              } rounded`}
            />
            <MdAddComment
              onClick={() => {
                handleChooseCourse();
              }}
              size={30}
              className="text-blue-500 mr-2 cursor-pointer"
            />
          </div>
          <div className="mt-2 rounded-lg bg-gray-50 px-2 py-2 ">
            <ul className="flex flex-wrap items-center gap-1">
              {chooseCourseArr?.map((each, index) => {
                return (
                  <li
                    key={index}
                    className="flex items-center gap-1 rounded-full bg-customPurple px-2 py-1 text-sm text-white"
                  >
                    {each.substring(0, 30)}
                    <IoCloseSharp
                      onClick={() => {
                        removeCourseArr(each, index);
                      }}
                      size={20}
                      className="font-semibold cursor-pointer"
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-semibold tracking-wide mb-2">
            Paragraph About Why Choose This Course
          </label>
          <textarea
            id="least_details"
            name="least_details"
            {...register("least_details", { required: true })}
            placeholder="Paragraph About Course"
            className={`border p-2 w-full h-24 rounded mb-4 ${
              errors.least_details ? "border-red-500" : ""
            }`}
          ></textarea>
        </div>
      </div>

      {/* submit button  */}
      <div className="col-span-2 flex justify-center">
        <button
          type="submit"
          className={`w-[9rem] px-4 py-2 ${
            load ? "bg-gray-400" : "bg-customPurple"
          }  text-white  rounded-md shadow-sm font-semibold hover:bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
        >
          {load ? "Creating..." : "Create Course"}
        </button>
      </div>
    </form>
  );
};

export default CourseCreatePage;
