import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { RiMenu4Line } from "react-icons/ri";
import { IoClose } from "react-icons/io5";
import logo from '../../Assets/Kgs_Logo.png'

const Sidebar = () => {
  const navigate = useNavigate();
  const path = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  console.log(path?.pathname);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <div
        className="lg:contents hidden relative flex-shrink-0"
        aria-label="Sidebar"
      >
        <div className="overflow-y-auto py-4 px-3 bg-white shadow-r shadow-2xl shadow-customPurple h-screen border-r border-gray-300">
          <ul className="space-y-2">
            <li>
              <div
                onClick={() => navigate("/dashboard")}
                className={`${
                  path?.pathname === "/dashboard" && "bg-customPurple text-white"
                } flex items-center cursor-pointer p-2 text-base font-normal  rounded-lg   mt-6 border border-customPurple hover:bg-customPurpleHover hover:text-white`}
              >
                <span className="ml-3">Dashboard</span>
              </div>
            </li>
            <li>
              <div
                onClick={() => navigate("/course")}
                className={`${
                  path?.pathname === "/course" && "bg-customPurple text-white"
                } flex items-center cursor-pointer p-2 text-base font-normal  rounded-lg   mt-6 border border-customPurple hover:bg-customPurpleHover hover:text-white`}
              >
                <span className="ml-3">Course</span>
              </div>
            </li>
            <li>
              <div
                onClick={() => navigate("/plans")}
                className={`${
                  path?.pathname === "/plans" && "bg-customPurple text-white"
                } flex items-center cursor-pointer p-2 text-base font-normal  rounded-lg   mt-6 border border-customPurple hover:bg-customPurpleHover hover:text-white`}
              >
                <span className="ml-3">Plans</span>
              </div>
            </li>
            <li>
              <div
                onClick={() => navigate("/admin-jobs")}
                className={`${
                  path?.pathname === "/admin-jobs" && "bg-customPurple text-white"
                } flex items-center cursor-pointer p-2 text-base font-normal  rounded-lg   mt-6 border border-customPurple hover:bg-customPurpleHover hover:text-white`}
              >
                <span className="ml-3">Jobs</span>
              </div>
            </li>
            <li>
              <div
                onClick={() => navigate("/issues")}
                className={`${
                  path?.pathname === "/issues" && "bg-customPurple text-white"
                } flex items-center cursor-pointer p-2 text-base font-normal  rounded-lg   mt-6 border border-customPurple hover:bg-customPurpleHover hover:text-white`}
              >
                <span className="ml-3">Issues</span>
              </div>
            </li>
            <li>
              <div
                onClick={() => navigate("/transactions")}
                className={`${
                  path?.pathname === "/transactions" && "bg-customPurple text-white"
                } flex items-center cursor-pointer p-2 text-base font-normal  rounded-lg   mt-6 border border-customPurple hover:bg-customPurpleHover hover:text-white`}
              >
                <span className="ml-3">Transactions</span>
              </div>
            </li>

            {/* <li>
              <div
                onClick={() => navigate("/settings")}
                className={`${
                  path?.pathname === "/settings" && "bg-customPurple text-white"
                } flex items-center cursor-pointer p-2 text-base font-normal  rounded-lg   mt-6 border border-customPurple hover:bg-customPurpleHover hover:text-white`}
              >
                <span className="ml-3">Settings</span>
              </div>
            </li> */}
          </ul>
        </div>
      </div>

      <div className="block lg:hidden">
        <button
          onClick={toggleSidebar}
          className="absolute ml-6 mt-[-50px] text-white"
        >
          {isSidebarOpen ? <IoClose size={30} /> : <RiMenu4Line size={30} />}
        </button>
        <AnimatePresence>
          {isSidebarOpen && (
            <motion.div
              className="h-full w-[50%] absolute"
              initial={{ opacity: 0, x: "-100%" }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: "-100%" }}
              transition={{ duration: 0.3 }}
            >
              <div className="overflow-y-auto py-4 px-3 relative z-20 bg-white shadow-r shadow-2xl shadow-customPurple h-screen border-r border-gray-300">
              <ul className="space-y-2">
            <li>
              <div
                onClick={() =>{
                  setIsSidebarOpen(false);
                  navigate("/dashboard")}}
                className={`${
                  path?.pathname === "/dashboard" && "bg-customPurple text-white"
                } flex items-center cursor-pointer p-2 text-base font-normal  rounded-lg   mt-6 border border-customPurple hover:bg-customPurpleHover hover:text-white`}
              >
                <span className="ml-3">Dashboard</span>
              </div>
            </li>
            <li>
              <div
                onClick={() => {
                  setIsSidebarOpen(false);
                  navigate("/course")}}
                className={`${
                  path?.pathname === "/course" && "bg-customPurple text-white"
                } flex items-center cursor-pointer p-2 text-base font-normal  rounded-lg   mt-6 border border-customPurple hover:bg-customPurpleHover hover:text-white`}
              >
                <span className="ml-3">Course</span>
              </div>
            </li>
            <li>
              <div
                onClick={() =>{
                   navigate("/plans")
                   setIsSidebarOpen(false);
                  }}
                className={`${
                  path?.pathname === "/plans" && "bg-customPurple text-white"
                } flex items-center cursor-pointer p-2 text-base font-normal  rounded-lg   mt-6 border border-customPurple hover:bg-customPurpleHover hover:text-white`}
              >
                <span className="ml-3">Plans</span>
              </div>
            </li>
            <li>
              <div
                onClick={() =>{
                  setIsSidebarOpen(false);
                  navigate("/admin-jobs")}}
                className={`${
                  path?.pathname === "/admin-jobs" && "bg-customPurple text-white"
                } flex items-center cursor-pointer p-2 text-base font-normal  rounded-lg   mt-6 border border-customPurple hover:bg-customPurpleHover hover:text-white`}
              >
                <span className="ml-3">Jobs</span>
              </div>
            </li>
            <li>
              <div
                onClick={() =>{
                  setIsSidebarOpen(false);
                  navigate("/issues")}}
                className={`${
                  path?.pathname === "/issues" && "bg-customPurple text-white"
                } flex items-center cursor-pointer p-2 text-base font-normal  rounded-lg   mt-6 border border-customPurple hover:bg-customPurpleHover hover:text-white`}
              >
                <span className="ml-3">Issues</span>
              </div>
            </li>
            <li>
              <div
                onClick={() =>{
                  setIsSidebarOpen(false);
                  navigate("/settings")}}
                className={`${
                  path?.pathname === "/settings" && "bg-customPurple text-white"
                } flex items-center cursor-pointer p-2 text-base font-normal  rounded-lg   mt-6 border border-customPurple hover:bg-customPurpleHover hover:text-white`}
              >
                <span className="ml-3">Settings</span>
              </div>
            </li>
          </ul>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export default Sidebar;
