import React from "react";
import img from "../../Assets/ApplicationDevelopment/image 253.png";
const ITStaffingLegacy = () => {
  return (
    <div className="px-5 md:px-20 gap-x-7 md:gap-y-11 flex flex-col lg:flex-row items-center justify-between w-[100%]">
      <div className="lg:w-[50%] w-[100%]">
        <h1 className="text-2xl md:text-4xl font-bold">
          Empower Your Legacy Systems with{" "}
          <span className="text-blue-500">Skilled IT Staffing</span>
        </h1>
        <p className="mt-5 text-lg md:text-xl text-gray-600">
          Navigating complex legacy systems can be challenging without the right
          expertise. Techmentee provides skilled IT professionals to manage and
          modernize your legacy applications efficiently. Our staffing solutions
          offer flexibility and cost-effectiveness for your business. Key
          offerings include:
        </p>
        <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-4 text-gray-600 text-lg md:text-xl montserrat">
          <div className="flex items-start">
            <span>Experienced IT Professionals</span>
          </div>
          <div className="flex items-start">
            <span>On-Demand Staffing</span>
          </div>
          <div className="flex items-start">
            <span>Flexible Engagement Models</span>
          </div>
          <div className="flex items-start">
            <span>Scalable Solutions</span>
          </div>
        </div>
        <p className="mt-5 text-lg md:text-xl text-gray-600">
          Whether you need to fill critical roles or enhance your team’s
          capabilities, our IT staffing services ensure your legacy systems are
          supported by the best talent. Let’s find the right fit for your
          business needs!
        </p>
      </div>

      <div className="w-[100%] lg:w-1/2 mt-10 md:mt-0 flex justify-center">
        <img
          src={img}
          alt="IT Staffing for Legacy Systems"
          className="rounded-lg w-full object-cover"
        />
      </div>
    </div>
  );
};

export default ITStaffingLegacy;
