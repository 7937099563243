import React from "react";
import SimpleBanner from "../../components/common/SimpleBanner";
import AboutUs_banner from "../../Assets/AboutUs/AboutUs_banner.png";
import HeadingWithDot from "../../components/common/HeadingWithDot";
import { FaMapMarkerAlt, FaEnvelope, FaPhone, FaClock } from "react-icons/fa"; // Icons for location, email, phone, and working hours
import { useEffect, useState } from "react";

const Contact = () => {
  const [phoneText, setPhoneText] = useState("KGS");
  const contactInfo = [
    {
      icon: (
        <FaMapMarkerAlt className="text-primaryColor text-4xl mb-4 mx-auto" />
      ),
      title: "Our Location",
      description:
        "2440 Old Milton Pkwy Suite 117, Alpharetta, GA 30009, United States",
    },
    {
      icon: <FaEnvelope className="text-primaryColor text-4xl mb-4 mx-auto" />,
      title: "Email Us",
      description: "info@kgstechnologygroup.com",
    },
    {
      icon: <FaPhone className="text-primaryColor text-4xl mb-4 mx-auto" />,
      title: "Call Us",
      description: (
        <div>
          <p>+1 (770) 8383-{phoneText}</p>
        </div>
      ),
    },
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      setPhoneText((prev) => (prev === "KGS" ? "547" : "KGS"));
    }, 2000); // Change every 2 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);
  return (
    <div className="overflow-x-hidden overflow-y-hidden">
      <SimpleBanner
        image={AboutUs_banner}
        name="Contact Us"
        path="Home / Contact Us"
      />
      <div className="lg:px-20 md:px-10 px-5 py-10">
        <div className="flex justify-center">
          <HeadingWithDot name="need any help" />
        </div>
        <h1 className="md:text-4xl text-2xl tracking-wider text-center mt-4">
          Contact Information
        </h1>
        <p className="text-TextSecondaryColor text-sm tracking-wider text-center m-auto xl:w-[60%] mt-4">
          Thank You for connecting with KGS Technology Group! We’d love to hear
          from you and discuss how our innovative solutions can help your
          business shine. Whether you have inquiries, need a consultation, or
          are seeking support, our team is just a click away.
        </p>

        {/* Contact Info Cards */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mt-8 px-4 xl:px-0">
          {contactInfo.map((info, index) => (
            <div
              key={index}
              className="border p-6 rounded-lg shadow-md text-center"
            >
              {info.icon}
              <h3 className="text-lg font-semibold tracking-wider">
                {info.title}
              </h3>
              <p className="text-sm text-TextSecondaryColor mt-2 leading-7">
                {info.description}
              </p>
            </div>
          ))}
        </div>

        {/* Google Maps Iframe */}
        <div className="mt-10 flex justify-center">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3313.3991027387333!2d-84.29187978478703!3d34.0698168256146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f571f88efacb9f%3A0x5d6ad0eb878db015!2s2440%20Old%20Milton%20Pkwy%20Suite%20117%2C%20Alpharetta%2C%20GA%2030009%2C%20United%20States!5e0!3m2!1sen!2sin!4v1724994185108!5m2!1sen!2sin"
            width="100%"
            height="650"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className="rounded-md shadow-lg"
          ></iframe>
        </div>

        <div className="mt-10">
          <div className="flex justify-center">
            <HeadingWithDot name="need any help" />
          </div>
          <h1 className="md:text-4xl text-2xl tracking-wider text-center mt-4">
            Send us a Message
          </h1>
          <p className="text-TextSecondaryColor text-sm tracking-wider text-center m-auto xl:w-[60%] mt-4">
            Please fill in your details, and we will get back to you as soon as
            possible.
          </p>

          {/* Contact Form */}
          <form className="mt-10 grid gap-6 grid-cols-1 md:grid-cols-2 md:mx-20 bg-gray-200 p-10 rounded-sm">
            <div className="flex flex-col">
              <input
                id="name"
                type="text"
                placeholder="Your Name"
                className="p-3 mt-2 border rounded-md"
              />
            </div>
            <div className="flex flex-col">
              <input
                id="email"
                type="email"
                placeholder="Email Address"
                className="p-3 mt-2 border rounded-md"
              />
            </div>
            <div className="flex flex-col">
              <input
                id="phone"
                type="tel"
                placeholder="Phone Number"
                className="p-3 mt-2 border rounded-md"
              />
            </div>
            <div className="flex flex-col">
              <input
                id="subject"
                type="text"
                placeholder="Subject"
                className="p-3 mt-2 border rounded-md"
              />
            </div>
            <div className="flex flex-col md:col-span-2">
              <textarea
                id="message"
                rows="5"
                placeholder="Your Message"
                className="p-3 mt-2 border rounded-md"
              />
            </div>
            <div className="md:col-span-2 text-center">
              <button
                type="submit"
                className="bg-[#ECAB06] hover:bg-[#CC8302] active:bg-[#A35C05] text-white px-12 py-3 rounded-md transition-colors"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
