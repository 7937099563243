import React from "react";
import BottomNavbar from "../userNavbar/BottomNavbar";
import TopNavbar from "../userNavbar/TopNavbar";

const UserNavbar = () => {
  return (
    <div className="sticky -top-14 w-full z-50">
      <TopNavbar />
      <BottomNavbar />
    </div>
  );
};

export default UserNavbar;
