import React, { useState } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import axios from "axios";
import toast from "react-hot-toast";
const CreateMockPlan = ({ setMockPlanModal }) => {
  const [Mock, setMocks] = useState({
    packageName: "",
    size: "",
    actualPrice: "",
    discountedPrice: "" || "0",
  });
  const [load, setLoad] = useState(false);
  const handleInputChange = (name, value) => {
    setMocks((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleMockSubmit = (e) => {
    e.preventDefault();
    console.log(Mock);
    setLoad(true);
    const myData = {
      packageName: Mock.packageName,
      size: Mock.size,
      actual_price: Mock.actualPrice,
      discounted_price: Mock.discountedPrice || 0,
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/mock-interview-plans/create`,
      headers: {
        "Content-Type": "application/json",
      },
      data: myData,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        setLoad(false);
        toast.success("Mock Created SuccessFully");
        setMockPlanModal(false);
        setMocks({});
      })
      .catch((error) => {
        console.log(error);
        setLoad(false);
        toast.error("Mock Couldn't be Created...!");
      });
  };

  return (
    <div className="fixed inset-0 z-50 bg-[#0000005d] h-screen w-screen flex justify-center items-center">
      <div className="w-[30rem] bg-customWhite rounded-lg relative ">
        <IoIosCloseCircle
          onClick={() => {
            setMockPlanModal(false);
          }}
          className="cursor-pointer absolute top-2 right-2"
          size={25}
          color="#846cf9"
        />
        <h1 className="text-center font-semibold tracking-wide my-4">
          Create Mock Plan
        </h1>
        <div>
          <form onSubmit={handleMockSubmit} class="w-full max-w-lg mx-auto p-4">
            <div class="mb-4">
              <label
                class="block text-gray-700 text-sm tracking-wide  mb-2"
                for="packageName"
              >
                Package name
              </label>
              <input
                className="shadow appearance-none border rounded tracking-wide w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="packageName"
                type="text"
                value={Mock?.packageName}
                onChange={(e) => {
                  handleInputChange("packageName", e.target.value);
                }}
                placeholder="Enter package name"
                required
              />
            </div>

            <div class="mb-4">
              <label
                class="block text-gray-700 text-sm tracking-wide  mb-2"
                for="size"
              >
                No. of mocks
              </label>
              <input
                class="shadow appearance-none border rounded tracking-wide w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="size"
                type="number"
                value={Mock?.size}
                onChange={(e) => {
                  handleInputChange("size", e.target.value);
                }}
                placeholder="Enter no. of mocks"
                required
              />
            </div>

            <div class="mb-4">
              <label
                class="block text-gray-700 text-sm tracking-wide  mb-2"
                for="actual_price"
              >
                Actual Price
              </label>
              <input
                class="shadow appearance-none border rounded tracking-wide w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="actual_price"
                type="number"
                value={Mock?.actualPrice}
                onChange={(e) => {
                  handleInputChange("actualPrice", e.target.value);
                }}
                placeholder="Enter actual price"
                required
              />
            </div>

            <div class="mb-4">
              <label
                class="block text-gray-700 text-sm tracking-wide  mb-2"
                for="discounted_price"
              >
                Discounted Price
              </label>
              <input
                class="shadow appearance-none tracking-wide border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="discounted_price"
                type="number"
                value={Mock?.discountedPrice}
                onChange={(e) => {
                  handleInputChange("discountedPrice", e.target.value);
                }}
                placeholder="Enter discounted price"
              />
            </div>

            <div class="flex items-center justify-between">
              <button
                class={`
                  
                  ${load ? "bg-blue-200" : "bg-[#846CF9]"}
                  
                  w-[7rem] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
                type="submit"
              >
                {load ? "Craeting..." : "Create"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateMockPlan;
