import React, { useState, useEffect } from "react";
import FaqImage from "../../Assets/AboutUs/FaqImage.png";
import HeadingWithDot from "../common/HeadingWithDot";
import { FaRegPlusSquare } from "react-icons/fa";
import { FaRegMinusSquare } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";

const faqData = [
  {
    question: "How to create an account?",
    answer:
      "To create an account, find the 'Sign up' or 'Create account' button, fill out the registration form with your personal information, and click 'Create account' or 'Sign up.' Verify your email address if needed, and then log in to start using the platform.",
  },
  {
    question: "How to reset your password?",
    answer:
      "Click on the 'Forgot password' link, enter your email address, and you will receive an email with instructions to reset your password.",
  },
  {
    question: "How to change your email address?",
    answer:
      "Go to your account settings, find the 'Email' section, and enter your new email address. Don't forget to save the changes.",
  },
  {
    question: "How to change your email address?",
    answer:
      "Go to your account settings, find the 'Email' section, and enter your new email address. Don't forget to save the changes.",
  },
];

const FaqSection = () => {
  const [openIndex, setOpenIndex] = useState(null);

  useEffect(() => {
    AOS.init({ duration: 1000, once: true }); // Initialize AOS
  }, []);

  const toggleFaq = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div
      className="mt-10 grid xl:grid-cols-2 grid-cols-1 gap-8"
      data-aos="fade-up" // AOS animation for the entire section
    >
      <div className="xl:pt-10">
        <HeadingWithDot name="faq" data-aos="fade-right" />
        <h1
          className="md:text-4xl text-2xl tracking-wider md:leading-[50px] leading-[30px] mt-2"
          data-aos="fade-right"
        >
          Creating
          <span className="text-primaryColor"> Digital </span>
          Masterpieces
        </h1>
        <p
          className="mt-2 text-sm font-regular text-TextSecondaryColor"
          data-aos="fade-right"
        >
          It is a long established fact that a reader will be distracted by the
          readable content of a page when looking at its layout.
        </p>

        <div className="accordion-group mt-8" data-aos="fade-up">
          {faqData.map((faq, index) => (
            <div
              key={index}
              className={`accordion pb-8 border-b border-solid border-gray-200 ${
                openIndex === index ? "active" : ""
              }`}
            >
              <button
                className="accordion-toggle group inline-flex items-center justify-between text-xl font-normal leading-8 text-gray-600 outline-none w-full transition duration-500 hover:text-indigo-600"
                onClick={() => toggleFaq(index)}
              >
                <div className="flex items-center gap-10">
                  {openIndex === index ? (
                    <FaRegMinusSquare className="text-primaryColor" />
                  ) : (
                    <FaRegPlusSquare className="text-primaryColor" />
                  )}
                  <h5
                    className={openIndex === index ? "text-primaryColor" : ""}
                  >
                    {faq.question}
                  </h5>
                </div>
              </button>
              <div
                className={`accordion-content overflow-hidden transition-all duration-500 ${
                  openIndex === index ? "max-h-screen" : "max-h-0"
                }`}
              >
                <p className="text-base font-normal text-gray-600 mt-2">
                  {faq.answer}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div>
        <img src={FaqImage} alt="FAQ" className="w-full" data-aos="zoom-in" />
      </div>
    </div>
  );
};

export default FaqSection;
