import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoMdMailOpen } from "react-icons/io";
import { RiWhatsappFill } from "react-icons/ri";
import { BiSolidPhoneCall } from "react-icons/bi";
import FooterBg from "../../Assets/Home/FooterBg.png";
import { IoLocationOutline } from "react-icons/io5";
import Logo from "../../Assets/logo/kgs.png";
import { FaYoutube } from "react-icons/fa";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa6";
import { useEffect, useState } from "react";

const UserFooter = () => {
  const navigate = useNavigate();
  const [phoneText, setPhoneText] = useState("KGS");

  const currentYear = new Date().getFullYear();

  const footerSections = [
    {
      title: "Discover KGS",
      img: Logo,
      links: [
        { text: "About us", path: "/about-us" },
        // { text: "Engagement Model", path: "#" },
        { text: "Privacy policy", path: "#" },
        { text: "Term and Conditions", path: "#" },
      ],
    },
    {
      title: "Programs",
      links: [
        { text: "Remote Boot Camp", path: "/program/remote-boot-camp" },
        {
          text: "Mock Interview & Caching",
          path: "/program/mock-interview-coaching",
        },
        { text: "Resume Review", path: "/program/ResumeReview" },
        {
          text: "Full-Time Job Assistance Program",
          path: "/program/full-time-job-assistance-program",
        },
      ],
    },
    {
      title: "Contact Us",
      links: [
        {
          text: "info@kgstechnologygroup.com",
          path: "mailto:info@example.com",
          icon: <IoMdMailOpen className="text-primaryColor mr-2" />,
        },
        {
          text: `+1 (770) 8383-${phoneText}`,
          path: "tel:+1770838383",
          icon: <BiSolidPhoneCall className="text-primaryColor mr-2" />,
        },
        {
          text: "Chat with us on WhatsApp",
          path: "https://wa.me/1770838383",
          icon: <RiWhatsappFill className="text-primaryColor mr-2" />,
        },
        {
          text: "2440 Old Milton Pkwy Suite 117, Alpharetta, GA 30009, United States",
          path: "#",
          icon: <IoLocationOutline className="text-primaryColor mr-2" />,
        },
      ],
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setPhoneText((prev) => (prev === "KGS" ? "547" : "KGS"));
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <React.Fragment>
      <footer
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 text-white lg:px-20 md:px-10 px-5 py-14"
        style={{
          backgroundImage: `url(${FooterBg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div>
          <img src={Logo} alt="img" className="w-28" />
          <p>
            KGS Technology delivers innovative, scalable tech solutions,
            empowering businesses with reliable, client-centric digital
            transformations.
          </p>
          <div className="flex space-x-4 mt-5">
            <FaYoutube />
            <FaFacebook />
            <FaLinkedin />
            <FaInstagram />
          </div>
        </div>
        {footerSections.map((section, index) => (
          <div key={index}>
            <h2 className="text-lg font-semibold mb-2">{section.title}</h2>
            <hr className="border-primaryColor border-[1px] mb-4 w-[140px]" />
            <ul className={section.title === "Contact Us" ? "space-y-2" : ""}>
              {section.links.map((link, linkIndex) => (
                <>
                  <li key={linkIndex} className="flex">
                    <div>{link.icon}</div>
                    <a href={link.path} className="hover:text-primaryColor">
                      {link.text}
                    </a>
                  </li>
                </>
              ))}
            </ul>
          </div>
        ))}
      </footer>

      <div className="flex flex-col md:flex-row justify-between items-center w-full py-4 px-6 text-sm bg-[#0F1425] text-white tracking-wider">
        <div className="text-center md:text-left mb-2 md:mb-0">
          © 2024 KGS. All Right Reserved
        </div>

        <div className="text-center md:text-right">
          Designed by <a href="https://www.webbocket.com/">web_bocket</a>{" "}
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserFooter;
