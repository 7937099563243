import React from "react";
import SimpleBanner from "../../components/common/SimpleBanner";
import AboutUs_banner from "../../Assets/AboutUs/AboutUs_banner.png";
import AboutUs from "../../components/aboutUs/AboutUs";
import SmallEnoughToCare from "../../components/aboutUs/SmallEnoughToCare";
import Team from "../../components/aboutUs/Team";
import Clients from "../../components/aboutUs/Clients";
import BusinessCard from "../../components/common/BusinessCard";
import FaqSection from "../../components/aboutUs/FaqSection";

const About = () => {
  return (
    <div className="overflow-x-hidden overflow-y-hidden">
      <SimpleBanner
        image={AboutUs_banner}
        name="About Us"
        path="Home / About us"
      />
      <div className="lg:px-20 md:px-10 px-5 py-10">
        <AboutUs />
      </div>
      <SmallEnoughToCare />
      {/* <div className="lg:px-20 md:px-10 px-5">
        <Team />
      </div> */}
      <Clients />
      <div className="lg:px-20 md:px-10 px-5">
        <FaqSection />
      </div>
      <div className="lg:px-10 m-10">
        <BusinessCard />
      </div>
    </div>
  );
};

export default About;
