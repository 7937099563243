import React from "react";
import img from "../../Assets/ApplicationDevelopment/image 253.png";
const LegacyApps = () => {
  return (
    <div className="px-5 md:px-20 gap-x-7 md:gap-y-11 flex flex-col lg:flex-row items-center justify-between w-[100%]">
      <div className="lg:w-[50%] w-[100%]">
        <h1 className="text-2xl md:text-4xl font-bold">
          Empower your Legacy Apps with{" "}
          <span className="text-blue-500">Microsoft Services</span>
        </h1>
        <p className="mt-5 text-lg md:text-xl text-gray-600">
          In today's fast-paced business world, modernizing your software is key
          to staying competitive. Partnering with a leading Microsoft services
          provider in Atlanta, GA, USA, can help you transform your legacy
          applications for the future. At Techmentee, we offer flexible and
          cost-effective solutions to modernize your business software with
          Microsoft technologies. Our key offerings include:
        </p>
        <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-4 text-gray-600 text-lg md:text-xl montserrat">
          <div className="flex items-start">
            <span>Azure Cloud Migration</span>
          </div>
          <div className="flex items-start">
            <span>Microsoft Power Platform</span>
          </div>
          <div className="flex items-start">
            <span>Dynamics 365 Integration</span>
          </div>
          <div className="flex items-start">
            <span>Continuous Updates & Support</span>
          </div>
        </div>
        <p className="mt-5 text-lg md:text-xl text-gray-600">
          Struggling with outdated business applications? Our Microsoft experts
          can help you leverage cutting-edge solutions to future-proof your
          infrastructure. With a focus on minimizing downtime and optimizing
          performance, we ensure your business is equipped to thrive in the
          digital era. Let's transform your ideas with Microsoft!
        </p>
      </div>

      <div className="w-[100%] lg:w-1/2 mt-10 md:mt-0 flex justify-center">
        <img
          src={img}
          alt="Microsoft Services Visualization"
          className="rounded-lg w-full object-cover"
        />
      </div>
    </div>
  );
};

export default LegacyApps;
