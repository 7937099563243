import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import bg from "../../Assets/Home/Background_orange_image.png";
import image1 from "../../Assets/Home/mobileIcon.png";
import image2 from "../../Assets/Home/laptoWifi_icon.png";
import image3 from "../../Assets/Home/dotSquere_icon.png";
import image4 from "../../Assets/Home/browser_icon.png";

const CountContainer = ({ sectionRef, counts }) => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with a 1000ms animation duration
  }, []);

  return (
    <div
      ref={sectionRef}
      className="h-auto py-10 mt-4 mb-8 lg:px-20 md:px-10 grid md:grid-cols-4 grid-cols-2 gap-8"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right",
      }}
    >
      <div
        className="flex flex-col justify-center items-center gap-2 tracking-wider"
        data-aos="fade-up"
      >
        <img src={image1} alt="images" className="w-[70px]" />
        <h1 className="text-white font-bold text-3xl">
          {counts.community.toLocaleString()}k+
        </h1>
        <p>Our Community</p>
      </div>
      <div
        className="flex flex-col justify-center items-center gap-2 tracking-wider"
        data-aos="fade-up"
        data-aos-delay="100"
      >
        <img src={image2} alt="images" className="w-[70px]" />
        <h1 className="text-white font-bold text-3xl">
          {counts.clients.toLocaleString()}k+
        </h1>
        <p>Job Aspirants</p>
      </div>
      <div
        className="flex flex-col justify-center items-center gap-2 tracking-wider"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        <img src={image3} alt="images" className="w-[70px]" />
        <h1 className="text-white font-bold text-3xl">
          {counts.projects.toLocaleString()}+
        </h1>
        <p>Projects</p>
      </div>
      <div
        className="flex flex-col justify-center items-center gap-2 tracking-wider"
        data-aos="fade-up"
        data-aos-delay="300"
      >
        <img src={image4} alt="images" className="w-[70px]" />
        <h1 className="text-white font-bold text-3xl">
          {counts.industries.toLocaleString()}+
        </h1>
        <p>Industries</p>
      </div>
    </div>
  );
};

export default CountContainer;
