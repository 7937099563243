import React from "react";
import careerBG from "../../Assets/Home/Evaluate-Career-bg.png";
import evaluateImg from "../../Assets/Home/EvaluateImg.png";
import communityImg from "../../Assets/Home/CommunityImg.png";
import { Link } from "react-router-dom";
import { BsFillOctagonFill } from "react-icons/bs";
import { FaArrowRightLong } from "react-icons/fa6";
import heroBG from "../../Assets/Home/HeroBG.png";
import boltIcon from "../../Assets/Home/BoltIcon.png";
import boxIcon from "../../Assets/Home/BoxIcon.png";
import featherIcon from "../../Assets/Home/featherdevIcon.png";
import globalIcon from "../../Assets/Home/GlobalIcon.png";

const WelcomeToCompany = () => {
  return (
    <div>
      <div
        className="h-[85vh] md:pt-10 mb-10 flex flex-col items-center justify-center text-center p-4"
        style={{
          backgroundImage: `url(${heroBG})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <p className="bg-[#3c3f31] outline-none text-white py-1 px-3 flex items-center gap-2 rounded-3xl shadow-[#F8B003] shadow text-xs md:text-base">
          Our journey begins since{" "}
          <span className="bg-[#F8B003] px-3 py-1 rounded-3xl">2008</span>
        </p>
        <h1 className="text-[#FFFFFF] text-xl md:text-3xl lg:text-5xl xl:text-7xl md:w-2/3 tracking-wider font-bold my-8 leading-[40px] lg:leading-[70px] xl:leading-[90px]">
          Bring Exellency With{" "}
          <span className="text-[#F8B003]">KGSTechnology</span> Group
        </h1>
        <p className="md:w-2/3 text-[#B6B7BA] text-sm md:text-base">
          Revolutionize your business with KGS Technology Group, your partner in
          innovation. Our team of experts leverages the latest technologies to
          thrive in the ever-evolving technological landscapes.
        </p>
        <p className="text-[#646464] mt-20 text-sm md:text-base">
          Trusted by 4,000+ companies
        </p>
        <div className="grid grid-cols-2 lg:grid-cols-4 mt-5 gap-8">
          <div className="flex items-center gap-2">
            <img src={boltIcon} alt="boltIcon" className="h-8" />
            <p className="text-[#BCBFC2] md:text-xl font-semibold">Boltshift</p>
          </div>
          <div className="flex items-center gap-2">
            <img src={boxIcon} alt="boltIcon" className="h-8" />
            <p className="text-[#BCBFC2] md:text-xl font-semibold">Lightbox</p>
          </div>
          <div className="flex items-center gap-2">
            <img src={featherIcon} alt="boltIcon" className="h-8" />
            <p className="text-[#BCBFC2] md:text-xl font-semibold">
              FeatherDev
            </p>
          </div>
          <div className="flex items-center gap-2">
            <img src={globalIcon} alt="boltIcon" className="h-8" />
            <p className="text-[#BCBFC2] md:text-xl font-semibold">
              GlobalBank
            </p>
          </div>
        </div>
      </div>
      <div
        className="w-full flex flex-col gap-10 items-center justify-between lg:px-20 md:px-10 px-5"
        style={{
          backgroundImage: `url(${careerBG})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Link
          to="/program/evaluate-your-career"
          data-aos="fade-up"
          className="w-full"
        >
          <div className="flex w-full items-center justify-between relative ">
            <img
              src={evaluateImg}
              alt="Evaluate Image"
              className="md:w-[65%] z-0"
            />
            <div className="bg-white shadow-lg rounded w-full md:w-auto xl:w-[40%] py-5 px-6 md:px-10 xl:p-10 z-10 absolute top-20 md:top-auto right-0">
              <h3 className="capitalize flex items-center justify-start gap-2 text-xs md:text-base">
                <BsFillOctagonFill className="text-[#f1a900] text-xs" />
                welcome to KGS Technology Group
              </h3>
              <h1 className="text-lg md:text-2xl xl:text-5xl text-[#263238] drop-shadow-lg tracking-wide my-1 lg:my-4">
                Evaluate Your Career
              </h1>
              <h3 className="text-[#F1A900] text-sm md: text-base">
                Enter for Job Opportunity
              </h3>
              <FaArrowRightLong
                className="text-[#F1A900] bg-[#FEF7EC] p-2 lg:p-3 h-8 lg:h-12 w-8 lg:w-12 rounded-full mt-2 lg:mt-4"
                size={20}
              />
            </div>
          </div>
        </Link>

        <Link
          to="/program/join-our-stem"
          className="w-full mt-20 mb-32 md:mb-0 md:mt-0"
          data-aos="fade-right"
        >
          <div className="flex flex-col-reverse md:flex-row w-full items-center justify-end relative ">
            <div className="bg-white shadow-lg rounded w-full md:w-auto xl:w-[40%] py-5 px-6 md:px-10 xl:p-10 z-10 absolute left-0 top-24 md:top-auto">
              <h3 className="capitalize flex items-center justify-start gap-2 text-xs md:text-base">
                <BsFillOctagonFill className="text-[#f1a900] text-xs" />
                welcome to KGS Technology Group
              </h3>
              <h1 className="text-lg md:text-2xl xl:text-5xl text-[#263238] drop-shadow-lg tracking-wide my-1 lg:my-4">
                Join Our Stem Community
              </h1>
              <h3 className="text-[#F1A900] text-sm md: text-base">
                Enter for Job Opportunity
              </h3>
              <FaArrowRightLong
                className="text-[#F1A900] bg-[#FEF7EC] p-2 lg:p-3 h-8 lg:h-12 w-8 lg:w-12 rounded-full mt-2 lg:mt-4"
                size={20}
              />
            </div>
            <img
              src={communityImg}
              alt="Evaluate Image"
              className="md:w-[65%] z-0 "
            />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default WelcomeToCompany;
