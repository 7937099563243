import React, { useState, useRef, useEffect } from "react";
import emailjs from "emailjs-com";
import ContactUs_image from "../../Assets/Home/ContactUs_image.png";
import Contact_us_bg_design from "../../Assets/Home/Contact_us_bg_design.png";
import HeadingWithDot from "../common/HeadingWithDot";
import AOS from "aos";
import "aos/dist/aos.css";
const ContactUsForm = () => {
  const formRef = useRef();
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with a duration of 1000ms
  }, []);

  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    if (!formValues.name) newErrors.name = "Name is required";
    if (!formValues.email) newErrors.email = "Email is required";
    if (!formValues.phone) newErrors.phone = "Phone Number is required";
    if (!formValues.subject) newErrors.subject = "Subject is required";
    if (!formValues.message) newErrors.message = "Message is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    emailjs
      .sendForm(
        "service_ah1g197",
        "template_8dbtkjv",
        formRef.current,
        "Dw0KD1VeRz0S58FGl"
      )
      .then((result) => {
        console.log(result.text);
        setLoading(false);
        formRef.current.reset();
        setFormValues({
          name: "",
          email: "",
          phone: "",
          subject: "",
          message: "",
        });
        setErrors({});
      })
      .catch((error) => {
        console.error(error.text);
        setLoading(false);
      });
  };

  return (
    // <div className="grid grid-cols-1 lg:grid-cols-2 p-4 pb-20">
    //   <div className="flex items-center justify-center">
    //     <img src={ContactUs_image} alt="Contact Us" className="w-full h-auto" />
    //   </div>
    //   <div className="">
    //     <HeadingWithDot name="Contact Us" />
    //     <h1 className="text-2xl md:text-4xl tracking-wider md:leading-[60px] leading-[30px] mt-2 w-full">
    //       Bringing Your
    //       <span className="text-primaryColor"> Vision </span>
    //       To Life
    //     </h1>
    //     <p className="text-TextSecondaryColor tracking-wider text-sm font-thin mt-2">
    //       Transforming your ideas into reality with creativity, dedication, and expertise, crafting unique solutions that bring your vision to life.
    //     </p>

    //     <form ref={formRef} onSubmit={handleSubmit} className="mt-8 space-y-6 shadow-lg bg-white p-6 md:p-10 relative z-10 border rounded-md">
    //       <div className="grid gap-4 md:grid-cols-2">
    //         <div>
    //           <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
    //           <input
    //             id="name"
    //             name="name"
    //             type="text"
    //             value={formValues.name}
    //             onChange={handleChange}
    //             className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primaryColor focus:border-primaryColor sm:text-sm"
    //             placeholder="Your Name"
    //           />
    //           {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
    //         </div>
    //         <div>
    //           <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
    //           <input
    //             id="email"
    //             name="email"
    //             type="email"
    //             value={formValues.email}
    //             onChange={handleChange}
    //             className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primaryColor focus:border-primaryColor sm:text-sm"
    //             placeholder="Your Email"
    //           />
    //           {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
    //         </div>
    //         <div className="md:col-span-2">
    //           <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Phone</label>
    //           <input
    //             id="phone"
    //             name="phone"
    //             type="tel"
    //             value={formValues.phone}
    //             onChange={handleChange}
    //             className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primaryColor focus:border-primaryColor sm:text-sm"
    //             placeholder="Your Phone Number"
    //           />
    //           {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
    //         </div>
    //         <div className="md:col-span-2">
    //           <label htmlFor="subject" className="block text-sm font-medium text-gray-700">Subject</label>
    //           <input
    //             id="subject"
    //             name="subject"
    //             type="text"
    //             value={formValues.subject}
    //             onChange={handleChange}
    //             className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primaryColor focus:border-primaryColor sm:text-sm"
    //             placeholder="Subject"
    //           />
    //           {errors.subject && <p className="text-red-500 text-sm">{errors.subject}</p>}
    //         </div>
    //         <div className="md:col-span-2">
    //           <label htmlFor="message" className="block text-sm font-medium text-gray-700">Message</label>
    //           <textarea
    //             id="message"
    //             name="message"
    //             rows="4"
    //             value={formValues.message}
    //             onChange={handleChange}
    //             className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primaryColor focus:border-primaryColor sm:text-sm"
    //             placeholder="Your Message"
    //           />
    //           {errors.message && <p className="text-red-500 text-sm">{errors.message}</p>}
    //         </div>
    //       </div>
    //       <div>
    //         <button
    //           type="submit"
    //           className="w-full bg-primaryColor text-white py-2 px-4 rounded-md shadow-sm hover:bg-primaryColorDark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primaryColor"
    //           disabled={loading}
    //         >
    //           {loading ? "Sending..." : "Submit Now"}
    //         </button>
    //       </div>
    //     </form>
    //     <img src={Contact_us_bg_design} alt="image" className="absolute right-0 mt-[-60px] z-0" />
    //   </div>
    // </div>
    <div className="grid grid-cols-1 lg:grid-cols-2 py-4 pb-20">
      <div className="flex items-center justify-center" data-aos="fade-right">
        <img src={ContactUs_image} alt="Contact Us" className="w-full h-auto" />
      </div>
      <div data-aos="fade-left">
        <HeadingWithDot name="Contact Us" />
        <h1 className="text-2xl md:text-4xl tracking-wider md:leading-[60px] leading-[30px] mt-2 w-full">
          Bringing Your
          <span className="text-primaryColor"> Vision </span>
          To Life
        </h1>
        <p className="text-TextSecondaryColor tracking-wider text-sm font-normal mt-2 text-justify">
          Transforming your ideas into reality with creativity, dedication, and
          expertise, crafting unique solutions that bring your vision to life.
        </p>

        <form
          ref={formRef}
          onSubmit={handleSubmit}
          className="mt-8 space-y-6 shadow-lg bg-white p-6 md:p-10 relative z-10 border rounded-md"
          data-aos="zoom-in"
        >
          <div className="grid gap-4 md:grid-cols-2">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <input
                id="name"
                name="name"
                type="text"
                value={formValues.name}
                onChange={handleChange}
                className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primaryColor focus:border-primaryColor sm:text-sm"
                placeholder="Your Name"
              />
              {errors.name && (
                <p className="text-red-500 text-sm">{errors.name}</p>
              )}
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                value={formValues.email}
                onChange={handleChange}
                className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primaryColor focus:border-primaryColor sm:text-sm"
                placeholder="Your Email"
              />
              {errors.email && (
                <p className="text-red-500 text-sm">{errors.email}</p>
              )}
            </div>
            <div className="md:col-span-2">
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-700"
              >
                Phone
              </label>
              <input
                id="phone"
                name="phone"
                type="tel"
                value={formValues.phone}
                onChange={handleChange}
                className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primaryColor focus:border-primaryColor sm:text-sm"
                placeholder="Your Phone Number"
              />
              {errors.phone && (
                <p className="text-red-500 text-sm">{errors.phone}</p>
              )}
            </div>
            <div className="md:col-span-2">
              <label
                htmlFor="subject"
                className="block text-sm font-medium text-gray-700"
              >
                Subject
              </label>
              <input
                id="subject"
                name="subject"
                type="text"
                value={formValues.subject}
                onChange={handleChange}
                className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primaryColor focus:border-primaryColor sm:text-sm"
                placeholder="Subject"
              />
              {errors.subject && (
                <p className="text-red-500 text-sm">{errors.subject}</p>
              )}
            </div>
            <div className="md:col-span-2">
              <label
                htmlFor="message"
                className="block text-sm font-medium text-gray-700"
              >
                Message
              </label>
              <textarea
                id="message"
                name="message"
                rows="4"
                value={formValues.message}
                onChange={handleChange}
                className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primaryColor focus:border-primaryColor sm:text-sm"
                placeholder="Your Message"
              />
              {errors.message && (
                <p className="text-red-500 text-sm">{errors.message}</p>
              )}
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="w-full bg-[#ECAB06] hover:bg-[#CC8302] active:bg-[#A35C05] text-white py-2 px-4 rounded-md shadow-sm hover:bg-primaryColorDark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primaryColor disabled:bg-[#FFF8C1]"
              disabled={loading}
            >
              {loading ? "Sending..." : "Submit Now"}
            </button>
          </div>
        </form>
        <img
          src={Contact_us_bg_design}
          alt="image"
          className="absolute right-0 mt-[-60px] z-0"
          data-aos="fade-up"
        />
      </div>
    </div>
  );
};

export default ContactUsForm;
