import React, { useEffect, useState } from "react";
import CreateCourseModal from "../../components/admin/CreateCourse/CreateCourseModal";
import CreateCategoryModal from "../../components/admin/CreateCourseCategory/CreateCategoryModal";
import axios from "axios";
import EditCourseModal from "../../components/admin/EditCourseModal/EditCourseModal";
import { FaEdit } from "react-icons/fa";
import {
  MdDeleteSweep,
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import EditCatgory from "../../components/admin/EditCataGoruModal/EditCatgory";

const Course = () => {
  const navigate = useNavigate();
  const [courseModal, setCourseModal] = useState(false);
  const [categoryModal, setCategoryModal] = useState(false);
  const [allCourse, setAllCourse] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [currentCourse, setCurrentCourse] = useState({});
  const [load, setload] = useState(false);
  const [loadCat, setloadCat] = useState(false);
  const [allCat, setAllCat] = useState([]);
  const [selectedTab, setSelectedTab] = useState("category");
  const [editCategoryModal, setEditCategoryModal] = useState(false);
  const [currentCategory, setCurrentCategory] = useState({});
  console.log(allCourse);

  const getAllCatagory = () => {
    setloadCat(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/categories/get-all-categories`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log("--->", response.data);
        setAllCat(response?.data?.categories);
        setloadCat(false);
      })
      .catch((error) => {
        console.log(error);
        setloadCat(false);
      });
  };

  const getAllCourse = (currentPage) => {
    setload(true);
    console.log("Load in getAllCourse: ", load);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/courses/get-all-course?page=${currentPage}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Response of getAllCourse", response?.data);
        setAllCourse(response?.data?.courses);
        setTotalPages(response?.data?.totalPages);
        setload(false);
      })
      .catch((error) => {
        console.log(error);
        setload(false);
      });
  };

  console.log("Total Pages: ", totalPages);

  const deleteCourse = (id) => {
    if (window.confirm("Are you sure you want to delete this course?")) {
      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_KEY}/courses/delete/${id}`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response);
          getAllCourse();
          alert("Course deleted successfully.");
        })
        .catch((error) => {
          console.log(error);
          alert("Course couldn't be deleted.");
        });
    }
  };

  const deleteCategory = (id) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_KEY}/categories/delete/${id}`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response);
          getAllCatagory(); // Refresh categories after deletion
          alert("Category deleted successfully.");
        })
        .catch((error) => {
          console.log(error);
          alert("Category couldn't be deleted.");
        });
    }
  };

  useEffect(() => {
    getAllCourse(currentPage);
    getAllCatagory();
  }, [refresh, categoryModal, courseModal, editCategoryModal, currentPage]);

  return (
    <div className="relativ">
      {courseModal ? (
        <CreateCourseModal
          refresh={refresh}
          setRefresh={setRefresh}
          courseModal={courseModal}
          setCourseModal={setCourseModal}
        />
      ) : (
        ""
      )}

      {categoryModal ? (
        <CreateCategoryModal
          categoryModal={categoryModal}
          setCategoryModal={setCategoryModal}
        />
      ) : (
        ""
      )}
      {editCategoryModal ? (
        <EditCatgory
          currentCategory={currentCategory}
          editCategoryModal={editCategoryModal}
          setEditCategoryModal={setEditCategoryModal}
        />
      ) : (
        ""
      )}

      {editModal ? (
        <EditCourseModal
          refresh={refresh}
          setRefresh={setRefresh}
          setEditModal={setEditModal}
          currentCourse={currentCourse}
        />
      ) : (
        ""
      )}

      {/* Tabs for switching between Course and Category */}
      <div className="flex justify-start mb-4">
        <button
          onClick={() => setSelectedTab("category")}
          className={`px-4 py-2 ${
            selectedTab === "category"
              ? "bg-customPurple text-white"
              : "bg-gray-200"
          } rounded-lg mx-2 font-semibold`}
        >
          All Categories
        </button>
        <button
          onClick={() => setSelectedTab("course")}
          className={`px-4 py-2 ${
            selectedTab === "course"
              ? "bg-customPurple text-white"
              : "bg-gray-200"
          } rounded-lg mx-2 font-semibold`}
        >
          All Courses
        </button>
      </div>
      <hr className="border-[4px] mt-2 mx-3 mb-4 border-customPurple rounded-full" />
      {selectedTab === "course" ? (
        <>
          {/* Create Course Button */}
          <div className="flex justify-between items-center pl-3 mb-4">
            <p className="text-xl tracking-wide font-bold ">
              Courses Available
            </p>
            <button
              onClick={() => {
                navigate("/createCourse");
              }}
              className="bg-customPurple h-[3rem] px-3 rounded-lg mx-3 font-semibold text-white"
            >
              Create Course
            </button>
          </div>

          {/* Course Table */}
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-4 mx-[12px]">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-customPurple dark:text-customWhite">
                <tr>
                  <th scope="col" className="p-4">
                    SL NO
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Course Title
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Category
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Ratings
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Reviews
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Published By
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Course Duration
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {allCourse?.length > 0 && !load ? (
                  allCourse.map((each, index) => (
                    <tr
                      key={each._id}
                      className="bg-white border-b dark:bg-custWhite hover:bg-gray-50 dark:hover:bg-gray-100"
                    >
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium whitespace-nowrap"
                      >
                        {(currentPage - 1) * 10 + (index + 1)}
                      </th>
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium whitespace-nowrap"
                      >
                        {each?.title}
                      </th>
                      <td className="px-6 py-4">{each?.category?.name}</td>
                      <td className="px-6 py-4">{each?.ratings}</td>
                      <td className="px-6 py-4">{each?.reviews}</td>
                      <td className="px-6 py-4">{each?.published_by?.name}</td>
                      <td className="px-6 py-4">{each?.hours_of_course} h</td>
                      <td className="flex items-center px-6 py-4">
                        <FaEdit
                          onClick={() => {
                            setEditModal(true);
                            setCurrentCourse(each);
                            navigate(`/editCourse/${each._id}`);
                          }}
                          size={20}
                          className="cursor-pointer font-medium text-blue-600 dark:text-blue-500 hover:underline"
                        />
                        <MdDeleteSweep
                          onClick={() => {
                            deleteCourse(each._id);
                          }}
                          size={20}
                          className="font-medium text-red-600 dark:text-red-500 hover:underline ms-3 cursor-pointer"
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    {load ? (
                      <td
                        colSpan="8"
                        className="px-6 py-8 text-center tracking-wide"
                      >
                        Fetching your data, Please wait...
                      </td>
                    ) : (
                      <td
                        colSpan="8"
                        className="px-6 py-8 text-center tracking-wide"
                      >
                        No courses available!
                      </td>
                    )}
                  </tr>
                )}
              </tbody>
            </table>
            <div></div>
            <div className="flex gap-4 float-right text-gray-500 text-sm items-center my-4">
              <p className=" text-gray-500 text-sm ">
                {currentPage} of {totalPages}
              </p>
              <button
                className=" flex items-center border border-gray-300 rounded-lg p-1 hover:bg-gray-300 "
                onClick={() => {
                  currentPage === 1
                    ? setCurrentPage(currentPage)
                    : setCurrentPage(currentPage - 1);
                }}
              >
                <MdOutlineKeyboardArrowLeft size={16} />
                prev
              </button>
              <button
                className="flex items-center border border-gray-300 rounded-lg p-1 hover:bg-gray-300"
                onClick={() => {
                  console.log("Loading: ", load);
                  currentPage === totalPages
                    ? setCurrentPage(currentPage)
                    : setCurrentPage(currentPage + 1);
                }}
              >
                next <MdOutlineKeyboardArrowRight size={16} />
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          {/* Create Category Button */}
          <div className="flex justify-between mb-4 items-center pl-3">
            <p className="text-xl tracking-wide font-bold ">
              Catagories Available
            </p>
            <button
              onClick={() => {
                setCategoryModal(true);
              }}
              className="bg-customPurple h-[3rem] px-3 rounded-lg mx-3 font-semibold text-white"
            >
              Create Category
            </button>
          </div>

          {/* Category Table */}
          <div className="container mx-auto p-3">
            {loadCat ? (
              // Show loading message while data is being fetched
              <p className="text-center text-gray-600">
                Fetching Categories...
              </p>
            ) : allCat.length === 0 ? (
              // Show message if no categories are available
              <p className="text-center text-gray-600">
                No category is available.
              </p>
            ) : (
              // Render categories if data is available
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                {allCat.map((category) => (
                  <div
                    key={category._id}
                    className="border-2 relative flex flex-col justify-between bg-white shadow-md rounded-xl p-4 hover:shadow-xl transition-shadow duration-200"
                  >
                    <div>
                      <h2 className="text-xl font-semibold text-gray-800 mb-2 capitalize">
                        {category?.name}
                      </h2>
                      {/* <p className="text-gray-600 mb-4 capitalize">
              {category?.description?.slice(0, 100)}...
            </p> */}
                    </div>
                    <div className="flex justify-between items-center">
                      <p className="text-sm text-gray-500">
                        Created At:{" "}
                        {new Date(category.createdAt).toLocaleDateString()}
                      </p>
                      <FaEdit
                        onClick={() => {
                          setEditCategoryModal(true);
                          setCurrentCategory(category);
                        }}
                        size={25}
                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline cursor-pointer"
                      />
                      <MdDeleteSweep
                        onClick={() => {
                          deleteCategory(category._id);
                        }}
                        size={25}
                        className="font-medium text-red-600 dark:text-red-500 hover:underline cursor-pointer"
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Course;
