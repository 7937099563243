import React, { useState } from "react";
import SimpleBanner from "../../../components/common/SimpleBanner";
import HealthCareBanner from "../../../Assets/Industry/HealthCareBanner.png"; // Reuse banner for now
import heathCare_image from "../../../Assets/Industry/heathCare_image.png"; // Reuse image
import HeadingWithDot from "../../../components/common/HeadingWithDot";
import ConsultingServices_image from "../../../Assets/Industry/ConsultingServices_image.png"; // Reuse image
import EHRImplementation_image from "../../../Assets/Industry/EHRImplementation_image.png"; // Reuse image
import Telemedicine_image from "../../../Assets/Industry/Telemedicine_image.png"; // Reuse image
import BusinessCard from "../../../components/common/BusinessCard"; // Reuse Business Card

// Updated content for Real Estate IT Solutions
const realEstateSolutions = [
  {
    image: ConsultingServices_image, // Reuse image
    title: "Property Management Software",
    description:
      "Our property management solutions streamline operations, from tenant management to lease tracking and maintenance requests, improving efficiency for real estate agencies and landlords.",
  },
  {
    image: EHRImplementation_image, // Reuse image
    title: "Smart Building Technology",
    description:
      "We provide smart building solutions, integrating IoT devices for energy management, security systems, and real-time monitoring, ensuring safer and more efficient properties.",
  },
  {
    image: Telemedicine_image, // Reuse image
    title: "Real Estate CRM Solutions",
    description:
      "Our CRM solutions are tailored to the real estate industry, helping agents track leads, manage relationships, and close deals faster with comprehensive client management tools.",
  },
];

const processSteps = [
  {
    index: 1,
    title: "Market Analysis & Research",
    description:
      "We begin by analyzing the real estate market and researching the latest technologies to identify the best digital solutions to meet the needs of property developers, managers, and buyers.",
  },
  {
    index: 2,
    title: "Legal & Compliance Assistance",
    description:
      "Our solutions ensure that real estate businesses comply with regulations, providing support for legal documentation, lease agreements, and financial transparency.",
  },
  {
    index: 3,
    title: "Customized Development",
    description:
      "We work closely with real estate companies to gather their specific requirements and develop tailored IT solutions that align with their business goals and market needs.",
  },
  {
    index: 4,
    title: "Design & Prototyping",
    description:
      "During the design phase, we create interactive prototypes to visualize smart building systems, CRM solutions, and property management platforms, ensuring user-friendly interfaces.",
  },
  {
    index: 5,
    title: "Launch & Deployment",
    description:
      "Our team ensures the smooth deployment of real estate platforms and technologies, providing training and support to ensure seamless integration with business processes.",
  },
  {
    index: 6,
    title: "Post-launch Support & Growth",
    description:
      "After launch, we offer continuous support and performance optimization, helping real estate businesses scale and adapt to changes in the market with our flexible solutions.",
  },
];

const RealEstate = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  return (
    <div>
      {/* Reuse the banner */}
      <SimpleBanner
        image={HealthCareBanner}
        name="Real Estate"
        path="Home / Industry / Real Estate"
      />

      <div className="lg:px-20 md:px-10 px-5 py-10">
        <div className="grid xl:grid-cols-2 grid-cols-1 gap-10">
          <div className="">
            {/* Reuse the image */}
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTN-oRglJ60dE6l1FpA2IgMP-iZ8sbdDjSOqA&s"
              alt="about us"
              className="w-full rounded-lg"
            />
          </div>
          <div className="flex justify-center items-center">
            <div>
              {/* Updated content for Real Estate */}
              <h1 className="md:text-4xl text-3xl tracking-wider md:leading-[55px] leading-[50px] mt-2">
                IT Solutions for
                <span className="text-primaryColor"> Real Estate</span>
              </h1>
              <p className="text-TextSecondaryColor mt-2 w-[90%] tracking-wide">
                Our IT solutions for the real estate industry simplify property
                management, enhance client relationships, and integrate
                cutting-edge smart building technologies. From CRM systems to
                IoT-enabled infrastructure, we help modernize the real estate
                industry.
              </p>
              <p className="text-TextSecondaryColor mt-2 w-[90%] tracking-wide">
                With a deep understanding of the real estate market, our team
                provides solutions that improve operational efficiency, enhance
                client engagement, and ensure compliance with legal and
                financial regulations.
              </p>
            </div>
          </div>
        </div>

        {/* Industry Solutions */}
        <div>
          <div className="flex justify-center mt-10">
            <HeadingWithDot name="Our Industry" />
          </div>
          <h1 className="md:text-4xl text-2xl tracking-wider text-center mt-4">
            Our <span className="text-primaryColor">Real Estate</span> IT
            Solutions
          </h1>
          <p className="text-TextSecondaryColor text-sm tracking-wider text-center m-auto xl:w-[60%] mt-4">
            We offer comprehensive IT services for the real estate sector, from
            smart building technologies to CRM systems and property management
            solutions, helping real estate businesses streamline operations and
            engage clients more effectively.
          </p>

          <div className="flex flex-wrap gap-8 mt-10 justify-center">
            {realEstateSolutions.map((solution, index) => (
              <div
                key={index}
                className="border p-6 rounded-lg shadow-md text-center flex flex-col items-center w-full sm:w-[calc(50%-16px)] lg:w-[calc(30.33%-16px)]"
              >
                <img
                  src={solution.image}
                  alt={solution.title}
                  className="w-20 mb-4"
                />
                <h3 className="text-lg font-semibold tracking-wider mb-2">
                  {solution.title}
                </h3>
                <p className="text-sm text-TextSecondaryColor tracking-wider">
                  {solution.description}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Process */}
        <div className="flex justify-center mt-10">
          <HeadingWithDot name="Our Process" />
        </div>
        <h1 className="md:text-4xl text-2xl tracking-wider text-center mt-4">
          Our <span className="text-primaryColor">Process</span>
        </h1>
        <p className="text-TextSecondaryColor text-sm tracking-wider text-center m-auto xl:w-[60%] mt-4">
          Our process ensures that real estate businesses benefit from
          customized, scalable solutions that streamline operations, enhance
          client relationships, and improve property management systems.
        </p>

        <div className="flex flex-wrap gap-8 mt-10 justify-center mb-10">
          {processSteps.map((step) => (
            <div
              onMouseEnter={() => setActiveIndex(step.index)}
              onMouseLeave={() => setActiveIndex(null)}
              key={step.index}
              className="relative border p-6 rounded-lg shadow-md flex flex-col w-full sm:w-[calc(50%-16px)] lg:w-[calc(50%-16px)]"
            >
              <div
                className={`absolute -top-8 right-0 m-2 w-12 text-2xl font-mono h-12 flex items-center justify-center text-white rounded-full transition-transform duration-300 ease-linear ${
                  activeIndex === step.index
                    ? "translate-x-4 bg-violet-500"
                    : "translate-x-0 bg-primaryColor"
                }`}
              >
                {step.index}
              </div>
              <h3 className="text-xl tracking-wider mb-2">{step.title}</h3>
              <p className="text-sm text-TextSecondaryColor tracking-wider leading-6">
                {step.description}
              </p>
            </div>
          ))}
        </div>

        {/* Reuse BusinessCard */}
        <BusinessCard />
      </div>
    </div>
  );
};

export default RealEstate;
