import React, { useState } from "react";
import SimpleBanner from "../../../components/common/SimpleBanner";
import HealthCareBanner from "../../../Assets/Industry/HealthCareBanner.png"; // Reuse the same banner for now
import heathCare_image from "../../../Assets/Industry/heathCare_image.png"; // Reuse image
import HeadingWithDot from "../../../components/common/HeadingWithDot";
import ConsultingServices_image from "../../../Assets/Industry/ConsultingServices_image.png"; // Reuse image
import EHRImplementation_image from "../../../Assets/Industry/EHRImplementation_image.png"; // Reuse image
import Telemedicine_image from "../../../Assets/Industry/Telemedicine_image.png"; // Reuse image
import BusinessCard from "../../../components/common/BusinessCard"; // Reuse Business Card

// Updated content for Government IT Solutions
const governmentSolutions = [
  {
    image: ConsultingServices_image, // Reuse image
    title: "Digital Government Services",
    description:
      "We provide digital transformation services that enable governments to offer seamless, secure, and efficient public services online, improving citizen engagement and reducing bureaucracy.",
  },
  {
    image: EHRImplementation_image, // Reuse image
    title: "Data Security & Compliance",
    description:
      "Our solutions ensure that government agencies adhere to strict data security standards and compliance regulations, safeguarding sensitive information and maintaining public trust.",
  },
  {
    image: Telemedicine_image, // Reuse image
    title: "Smart City Solutions",
    description:
      "We help governments adopt smart city technologies, from IoT implementations to automated traffic management systems, improving urban living and resource management.",
  },
];

const processSteps = [
  {
    index: 1,
    title: "Policy & Needs Assessment",
    description:
      "We begin by assessing the policy needs and challenges facing government agencies, identifying opportunities for digital transformation and operational improvement.",
  },
  {
    index: 2,
    title: "Security & Compliance Testing",
    description:
      "Our rigorous security and compliance testing ensures that all solutions meet government standards, safeguarding citizen data and maintaining system integrity.",
  },
  {
    index: 3,
    title: "Stakeholder Engagement",
    description:
      "We work closely with government officials, stakeholders, and citizens to gather input and ensure that our solutions address the needs of all parties involved.",
  },
  {
    index: 4,
    title: "Design & Prototyping",
    description:
      "Our design and prototyping phase helps government agencies visualize the final solution, incorporating feedback and optimizing user experience for citizens and officials alike.",
  },
  {
    index: 5,
    title: "Implementation & Integration",
    description:
      "We ensure a smooth integration of our solutions with existing government infrastructure, minimizing disruption and ensuring continuous service delivery.",
  },
  {
    index: 6,
    title: "Continuous Monitoring & Improvement",
    description:
      "Post-implementation, we provide continuous monitoring and support, ensuring that systems evolve to meet new policy demands and improve over time.",
  },
];

const Government = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  return (
    <div>
      {/* Reuse the banner */}
      <SimpleBanner
        image={HealthCareBanner}
        name="Government"
        path="Home / Industry / Government"
      />

      <div className="lg:px-20 md:px-10 px-5 py-10">
        <div className="grid xl:grid-cols-2 grid-cols-1 gap-10">
          <div className="">
            {/* Reuse the image */}
            <img
              src="https://pagecreative.co.uk/wp-content/uploads/2023/10/AdobeStock_223290240-1-2-scaled.jpeg"
              alt="about us"
              className="w-full rounded-lg"
            />
          </div>
          <div className="flex justify-center items-center">
            <div>
              {/* Updated content for government */}
              <h1 className="md:text-4xl text-3xl tracking-wider md:leading-[55px] leading-[50px] mt-2">
                Government IT Solutions for
                <span className="text-primaryColor"> Digital Governance</span>
              </h1>
              <p className="text-TextSecondaryColor mt-2 w-[90%] tracking-wide">
                Our IT solutions empower government agencies to digitize their
                operations, improve service delivery, and engage with citizens
                more effectively. From secure data management to smart city
                solutions, we ensure that governments stay ahead in the digital
                era.
              </p>
              <p className="text-TextSecondaryColor mt-2 w-[90%] tracking-wide">
                We work hand-in-hand with government agencies to modernize their
                IT infrastructure, enhance operational efficiency, and ensure
                data security and compliance across the board.
              </p>
            </div>
          </div>
        </div>

        {/* Industry Solutions */}
        <div>
          <div className="flex justify-center mt-10">
            <HeadingWithDot name="Our Industry" />
          </div>
          <h1 className="md:text-4xl text-2xl tracking-wider text-center mt-4">
            Our <span className="text-primaryColor">Government</span> IT
            Solutions
          </h1>
          <p className="text-TextSecondaryColor text-sm tracking-wider text-center m-auto xl:w-[60%] mt-4">
            We provide comprehensive IT services for government agencies,
            offering solutions that drive digital transformation, enhance
            service delivery, and ensure secure and compliant operations.
          </p>

          <div className="flex flex-wrap gap-8 mt-10 justify-center">
            {governmentSolutions.map((solution, index) => (
              <div
                key={index}
                className="border p-6 rounded-lg shadow-md text-center flex flex-col items-center w-full sm:w-[calc(50%-16px)] lg:w-[calc(30.33%-16px)]"
              >
                <img
                  src={solution.image}
                  alt={solution.title}
                  className="w-20 mb-4"
                />
                <h3 className="text-lg font-semibold tracking-wider mb-2">
                  {solution.title}
                </h3>
                <p className="text-sm text-TextSecondaryColor tracking-wider">
                  {solution.description}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Process */}
        <div className="flex justify-center mt-10">
          <HeadingWithDot name="Our Process" />
        </div>
        <h1 className="md:text-4xl text-2xl tracking-wider text-center mt-4">
          Our <span className="text-primaryColor">Process</span>
        </h1>
        <p className="text-TextSecondaryColor text-sm tracking-wider text-center m-auto xl:w-[60%] mt-4">
          Our process ensures that government agencies receive secure, scalable,
          and compliant IT solutions that transform their operations and improve
          public services.
        </p>

        <div className="flex flex-wrap gap-8 mt-10 justify-center mb-10">
          {processSteps.map((step) => (
            <div
              onMouseEnter={() => setActiveIndex(step.index)}
              onMouseLeave={() => setActiveIndex(null)}
              key={step.index}
              className="relative border p-6 rounded-lg shadow-md flex flex-col w-full sm:w-[calc(50%-16px)] lg:w-[calc(50%-16px)]"
            >
              <div
                className={`absolute -top-8 right-0 m-2 w-12 text-2xl font-mono h-12 flex items-center justify-center text-white rounded-full transition-transform duration-300 ease-linear ${
                  activeIndex === step.index
                    ? "translate-x-4 bg-violet-500"
                    : "translate-x-0 bg-primaryColor"
                }`}
              >
                {step.index}
              </div>
              <h3 className="text-xl tracking-wider mb-2">{step.title}</h3>
              <p className="text-sm text-TextSecondaryColor tracking-wider leading-6">
                {step.description}
              </p>
            </div>
          ))}
        </div>

        {/* Reuse BusinessCard */}
        <BusinessCard />
      </div>
    </div>
  );
};

export default Government;
