// import React from 'react'
// import AboutUs_banner from "../../../Assets/AboutUs/AboutUs_banner.png";
// import SimpleBanner from '../../../components/common/SimpleBanner';
// import { FaFacebook, FaLink, FaLinkedin, FaTwitter } from "react-icons/fa";
// import { FaMessage } from "react-icons/fa6";
// import BusinessCard from '../../../components/common/BusinessCard';
// import Calendar from '../../../Assets/JobDetail/CalendarBlank.png'
// import Timer from '../../../Assets/JobDetail/Timer.png'
// import Stack from '../../../Assets/JobDetail/Stack.png'
// import Wallet from '../../../Assets/JobDetail/Wallet.png'
// import Briefcase from '../../../Assets/JobDetail/briefcase.png'
// import MapTrifold from '../../../Assets/JobDetail/MapTrifold.png'
// import facebook from '../../../Assets/JobDetail/Rectangle.png'
// const JobDetail = () => {
//   return (
//     <div>
//       <SimpleBanner
//         image={AboutUs_banner}
//         name="Job Details"
//         path="Home / Evaluate Your Career / All Jobs / Job Details"
//       />
//       <div className="mx-auto px-12 bg-gray-50">
//         {/* Header Section */}
//         <div className="p-6 rounded-lg flex justify-between items-center">
//           <div className="flex items-center">
//             <img
//               src={facebook}
//               alt="Company Logo"
//               className="w-16 h-16 rounded-full mr-4"
//             />
//             <div>
//               <h1 className="text-2xl font-bold">Senior UX Designer</h1>
//               <span className="text-green-600 font-semibold">FULL-TIME</span>
//             </div>
//           </div>
//           <button className="bg-[#F8B003] text-white px-20 py-4 rounded-md">
//             Apply Now
//           </button>
//         </div>

//         <div className="mt-6 flex flex-col lg:flex-row gap-6">
//           <div className="flex-1 p-6 rounded-lg">
//             <div>
//               <h2 className="text-xl font-bold mb-4">Job Description</h2>
//               <p className="mb-6">
//                 Velstar is a Shopify Plus agency, and we partner with brands to help them grow, we also do the same with our people!
//               </p>
//               <p>
//                 Here at Velstar, we don't just make websites, we create exceptional digital experiences that consumers love. Our team of designers, developers, strategists, and creators work together to push brands to the next level. From Platform Migration, User Experience & User Interface Design, to Digital Marketing, we have a proven track record in delivering outstanding eCommerce solutions and driving sales for our clients.
//               </p>
//               <p>
//                 The role will involve translating project specifications into clean, test-driven, easily maintainable code. You will work with the Project and Development teams as well as with the Technical Director, adhering closely to project plans and delivering work that meets functional & non-functional requirements. You will have the opportunity to create new, innovative, secure and scalable features for our clients on the Shopify platform
//               </p>
//               <p>
//                 Want to work with us? You're in good company!
//               </p>
//             </div>
//             <div>
//               <h2 className="text-xl font-bold mb-4">Requirements</h2>
//               <ul className="list-disc list-inside space-y-2 mb-6">
//                 <li>Great troubleshooting and analytical skills combined with the desire to tackle challenges head-on</li>
//                 <li>3+ years of experience in back-end development working either with multiple smaller projects simultaneously or large-scale applications</li>
//                 <li>
//                   Experience with HTML, JavaScript, CSS, PHP, Symphony and/or Laravel
//                 </li>
//                 <li>
//                   Working regularly with APIs and Web Services (REST, GrapthQL, SOAP, etc)
//                 </li>
//                 <li>
//                   Have experience/awareness in Agile application development, commercial off-the-shelf software, middleware, servers and storage, and database management
//                 </li>
//                 <li>
//                   Familiarity with version control and project management systems (e.g., Github, Jira)
//                 </li>
//                 <li>
//                   Great troubleshooting and analytical skills combined with the desire to tackle challenges head-on
//                 </li>
//                 <li>
//                   Ambitious and hungry to grow your career in a fast-growing agency
//                 </li>
//               </ul>
//             </div>
//             <div>
//               <h2 className="text-xl font-bold mb-4">Desirable:</h2>
//               <ul className="list-disc list-inside space-y-2 mb-6">
//                 <li>
//                   Working knowledge of eCommerce platforms, ideally Shopify but also others e.g. Magento, WooCommerce, Visualsoft to enable seamless migrations.
//                 </li>
//                 <li>
//                   Working knowledge of payment gateways
//                 </li>
//                 <li>
//                   API platform experience / Building restful APIs
//                 </li>
//               </ul>
//             </div>
//             <div>
//               <h2 className="text-xl font-bold mb-4">Benefits</h2>
//               <ul className="list-disc list-inside space-y-2">
//                 <li>Early finish on Fridays for our end of week catch up (4:30 finish, and drink of your choice from the bar)</li>
//                 <li>
//                   28 days holiday (including bank holidays) rising by 1 day per year PLUS an additional day off on your birthday
//                 </li>
//                 <li>
//                   Generous annual bonus.
//                 </li>
//                 <li>
//                   Healthcare package
//                 </li>
//                 <li>
//                   Paid community days to volunteer for a charity of your choice
//                 </li>
//                 <li>
//                   £100 contribution for your own personal learning and development
//                 </li>
//                 <li>
//                   Free Breakfast on Mondays and free snacks in the office
//                 </li>
//                 <li>
//                   Access to Perkbox with numerous discounts plus free points from the company to spend as you wish
//                 </li>
//                 <li>
//                   Cycle 2 Work Scheme
//                 </li>
//                 <li>
//                   Brand new MacBook Pro
//                 </li>
//                 <li>Joining an agency on the cusp of exponential growth and being part of this exciting story.</li>
//               </ul>
//             </div>
//           </div>

//           {/* Sidebar */}
//           <div className="lg:w-1/3 p-6">
//             <div className="flex justify-between items-center border-b border-gray-200 pb-4 mb-4">
//               <div>
//                 <span className="text-sm text-gray-500">Salary (USD)</span>
//                 <p className="text-green-600 text-2xl font-bold">$100,000 - $120,000</p>
//                 <span className="text-sm text-gray-500">Yearly salary</span>
//               </div>
//               <div className="text-right">
//                 <img src={MapTrifold} alt='img' />
//                 <p className="text-blue-600 flex items-center space-x-2">
//                   <span>Job Location</span>
//                 </p>
//                 <p className="text-gray-500">Dhaka, Bangladesh</p>
//               </div>
//             </div>
//             <div className="grid grid-cols-3 gap-4 text-center">
//               <div>
//                 <div className="text-blue-500 text-2xl mb-2">
//                   <img src={Calendar} alt='img' />
//                 </div>
//                 <p className="text-sm font-semibold text-start">JOB POSTED:</p>
//                 <p className="text-sm text-start">14 Jun, 2021</p>
//               </div>
//               <div>
//                 <div className="text-blue-500 text-2xl mb-2">
//                   <img src={Timer} alt='img' />
//                 </div>
//                 <p className="text-sm font-semibold text-start">JOB EXPIRE IN:</p>
//                 <p className="text-sm text-start">14 Aug, 2021</p>
//               </div>
//               <div>
//                 <div className="text-blue-500 text-2xl mb-2">
//                   <img src={Stack} alt='img' />
//                 </div>
//                 <p className="text-sm font-semibold text-start">JOB LEVEL:</p>
//                 <p className="text-sm text-start">Entry Level</p>
//               </div>
//               <div>
//                 <div className="text-blue-500 text-2xl mb-2">
//                   <img src={Wallet} alt='img' />
//                 </div>
//                 <p className="text-sm font-semibold text-start">EXPERIENCE:</p>
//                 <p className="text-sm text-start">$50k-80k/month</p>
//               </div>
//               <div>
//                 <div className="text-blue-500 text-2xl mb-2">
//                   <img src={Briefcase} alt='img' />
//                 </div>
//                 <p className="text-sm font-semibold text-start">EDUCATION:</p>
//                 <p className="text-sm text-start">Graduation</p>
//               </div>
//             </div>
//             {/* Share Job Section */}
//             <div className="p-4">
//               <h2 className="text-lg font-medium mb-3">Share this job:</h2>
//               <div className="flex gap-3 text-[#0A65CC]">
//                 <button className="border border-gray-300 rounded-md px-4 py-2 flex items-center gap-2">
//                   <FaLink />
//                   <span>Copy Links</span>
//                 </button>
//                 <button className="border border-gray-300 rounded-md px-4 py-2 flex items-center justify-center">
//                   <FaLinkedin />
//                 </button>
//                 <button className="border border-gray-300 rounded-md px-4 py-2 flex items-center justify-center">
//                   <FaFacebook />
//                 </button>
//                 <button className="border border-gray-300 rounded-md px-4 py-2 flex items-center justify-center">
//                   <FaTwitter />
//                 </button>
//                 <button className="border border-gray-300 rounded-md px-4 py-2 flex items-center justify-center">
//                   <FaMessage />
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//         <BusinessCard />
//       </div>

//     </div>
//   )
// }
// export default JobDetail;

import React, { useEffect, useState } from "react";
import AboutUs_banner from "../../../Assets/AboutUs/AboutUs_banner.png";
import SimpleBanner from "../../../components/common/SimpleBanner";
import { FaFacebook, FaLink, FaLinkedin, FaTwitter } from "react-icons/fa";
import { FaMessage } from "react-icons/fa6";
import BusinessCard from "../../../components/common/BusinessCard";
import Calendar from "../../../Assets/JobDetail/CalendarBlank.png";
import Timer from "../../../Assets/JobDetail/Timer.png";
import Stack from "../../../Assets/JobDetail/Stack.png";
import Wallet from "../../../Assets/JobDetail/Wallet.png";
import Briefcase from "../../../Assets/JobDetail/briefcase.png";
import MapTrifold from "../../../Assets/JobDetail/MapTrifold.png";
import facebook from "../../../Assets/JobDetail/Rectangle.png";
import JobApplyModal from "../../../components/Modals/JobApplyModal";
import { useParams } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import jobLogo from "../../../Assets/JobDetail/jobLogo.svg";
import encryptionModule from "../../../components/common/localStorageUtils";
import ApplyJobModal from "../../../components/common/ApplyJobModal";

const JobDetail = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [jobDetails, setJobDetails] = useState();
  const [hasApplied, setHasApplied] = useState(false);
  const [applyJobModal, setApplyJobModal] = useState(false);

  const { id } = useParams();
  console.log(id);
  const [load, setLoad] = useState(false);

  const showModal = () => setIsModalVisible(true);
  const handleClose = () => setIsModalVisible(false);
  const [resumeFile, setResumeFile] = useState("");

  const userData = encryptionModule.decryptData("userInfo");
  console.log("userData------>", userData);

  let candidateData;
  if (userData) {
    candidateData = {
      fullName: userData?.user?.name,
      email: userData?.user?.email,
      phoneNumber: userData?.user?.phone,
    };
  }

  //Apply for the Job
  const JobApply = (id, jobOwnerId, payLoad) => {
    setLoad(true);
    //Prepare Payload Data...

    if (!resumeFile && !hasApplied) {
      toast.error("Please Provide your resume, while applying for any job !");
      return;
    }
    // Create FormData object
    const formattedData = new FormData();

    formattedData.append(
      "candidate",
      JSON.stringify({
        fullName: payLoad?.fullName,
        email: payLoad?.email,
        phoneNumber: payLoad?.phoneNumber,
      })
    );

    formattedData.append("file", resumeFile);
    console.log(formattedData);
    let config = {
      method: "Post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/job-posts/apply/${id}/${jobOwnerId}`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formattedData,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        toast.success(response.data.message);
        setLoad(false);
      })
      .catch((error) => {
        console.log(error);
        toast.success(
          "We have already received your application, Thanks for your interest !"
        );
        // toast.error(error?.response?.data?.message);
        setLoad(false);
      });
  };

  //Formatt Date Function..
  function formatDateToCustomString(isoDate) {
    const date = new Date(isoDate);
    return date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  }

  //Fetch Job Details....
  const getJobDetails = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/job-posts/${id}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        setJobDetails(response.data.data);
        // setLoad(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.message);
        // setLoad(false);
      });
  };

  useEffect(() => {
    getJobDetails();
  }, []);

  useEffect(() => {
    if (userData) {
      if (
        jobDetails?.numbersOfApplicants &&
        jobDetails?.numbersOfApplicants?.some(
          (applicant) =>
            applicant.email === userData.user.email ||
            applicant.phoneNumber === userData.user.phone
        )
      ) {
        setHasApplied(true);
      }
    }
  }, [
    jobDetails?.numbersOfApplicants,
    userData?.user?.email,
    userData?.user?.phone,
  ]);

  return (
    <div className="overflow-x-hidden overflow-y-hidden">
      <SimpleBanner
        image={AboutUs_banner}
        name="Job Details"
        path="Home / Evaluate Your Career / All Jobs / Job Details"
      />
      <div className="mx-auto px-4 lg:px-12 bg-gray-50">
        {/* Header Section */}
        <div className="p-6 rounded-lg flex flex-col lg:flex-row justify-between items-start lg:items-center">
          <div className="flex items-start lg:items-center">
            <img
              src={jobLogo}
              alt="Company Logo"
              className="w-12 h-12 lg:w-16 lg:h-16 rounded-full mr-4"
            />
            <div className="flex flex-col gap-2">
              <h1 className="text-xl lg:text-2xl font-bold">
                {jobDetails?.positionName}
              </h1>
              <div className="flex gap-4 items-center">
                <h1 className="text-TextSecondaryColor">
                  at {jobDetails?.company}
                </h1>
                <p className="text-white bg-green-600 text-sm px-4 py-0.5">
                  {jobDetails?.jobType}
                </p>
              </div>
            </div>
          </div>
          {hasApplied ? (
            <button
              className="mt-4 lg:mt-0 bg-[#ecd608cc] text-white font-semibold tracking-wide px-10 lg:px-20 py-3 lg:py-4 rounded-md"
              onClick={() => {
                JobApply(id, jobDetails?.jobCreatedBy, candidateData);
              }}
            >
              {load ? "Please, Wait..." : "Applied"}{" "}
            </button>
          ) : (
            <button
              className="mt-4 lg:mt-0 bg-[#F8B003] text-white font-semibold tracking-wide px-10 lg:px-20 py-3 lg:py-4 rounded-md"
              onClick={() => {
                // if (!userData) {
                setApplyJobModal(true);
                // } else {
                //   JobApply(id, jobDetails?.jobCreatedBy, candidateData);
                // }
              }}
            >
              {load ? "Please, Wait..." : "Apply Now"}{" "}
            </button>
          )}
        </div>

        <div className="flex flex-col lg:flex-row gap-6">
          <div className="flex-1 p-6 rounded-lg">
            <div className="text-TextSecondaryColor">
              <h2 className="text-xl font-bold mb-4 text-TextPrimaryColor">
                Job Description
              </h2>
              <p className="mb-6 ">{jobDetails?.jobDescription}</p>
            </div>
            {/* Additional sections for requirements and benefits */}
            <div>
              <h2 className="text-xl font-bold mb-4 mt-4">Requirements</h2>
              <ul className="list-disc list-inside space-y-2 mb-6 text-TextSecondaryColor">
                {jobDetails?.jobRequirements?.map((item) => {
                  return <li>{item}</li>;
                })}
              </ul>
            </div>
            <div>
              <h2 className="text-xl font-bold mb-4">Desirable:</h2>
              <ul className="list-disc list-inside space-y-2 mb-6 text-TextSecondaryColor">
                {jobDetails?.jobDesirables?.map((item) => {
                  return <li>{item}</li>;
                })}
              </ul>
            </div>
            <div>
              <h2 className="text-xl font-bold mb-4">Benefits</h2>
              <ul className="list-disc list-inside space-y-2 text-TextSecondaryColor">
                {jobDetails?.jobBenefits?.map((item) => {
                  return <li>{item}</li>;
                })}
              </ul>
            </div>
          </div>

          {/* Sidebar */}
          <div className="lg:w-1/3 p-6  rounded-lg">
            <div className="flex justify-between items-center border-b border-gray-200 pb-4 mb-4">
              <div>
                <span className="text-sm text-gray-500">Salary (USD)</span>
                <p className="text-green-600 text-2xl font-bold">
                  ${jobDetails?.salaryPackage}
                </p>
                <span className="text-sm text-gray-500">Yearly salary</span>
              </div>
              <div className="text-right">
                <img src={MapTrifold} alt="img" />
                <p className="text-blue-600 flex items-center space-x-2">
                  <span>Job Location</span>
                </p>
                <p className="text-gray-500">{jobDetails?.location}</p>
              </div>
            </div>
            <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 text-center">
              <div>
                <div className="text-blue-500 text-2xl mb-2">
                  <img src={Calendar} alt="img" />
                </div>
                <p className="text-sm font-semibold text-start">JOB POSTED:</p>
                <p className="text-sm text-start">
                  {formatDateToCustomString(jobDetails?.jobPostDate)}
                </p>
              </div>
              <div>
                <div className="text-blue-500 text-2xl mb-2">
                  <img src={Timer} alt="img" />
                </div>
                <p className="text-sm font-semibold text-start">
                  JOB EXPIRE IN:
                </p>
                <p className="text-sm text-start">
                  {formatDateToCustomString(jobDetails?.lastDayToApply)}
                </p>
              </div>
              <div>
                <div className="text-blue-500 text-2xl mb-2">
                  <img src={Stack} alt="img" />
                </div>
                <p className="text-sm font-semibold text-start">JOB LEVEL:</p>
                <p className="text-sm text-start">Entry Level</p>
              </div>
              <div>
                <div className="text-blue-500 text-2xl mb-2">
                  <img src={Wallet} alt="img" />
                </div>
                <p className="text-sm font-semibold text-start">EXPERIENCE:</p>
                <p className="text-sm text-start">
                  {jobDetails?.experience} Years
                </p>
              </div>
              {/* <div>
                <div className="text-blue-500 text-2xl mb-2">
                  <img src={Briefcase} alt="img" />
                </div>
                <p className="text-sm font-semibold text-start">EDUCATION:</p>
                <p className="text-sm text-start">Graduation</p>
              </div> */}
            </div>
            {/* Share Job Section */}
            <div className="p-4">
              <h2 className="text-lg font-medium mb-3">Share this job:</h2>
              <div className="flex gap-3 text-[#0A65CC] flex-wrap">
                <button className="border border-gray-300 rounded-md px-4 py-2 flex items-center gap-2">
                  <FaLink />
                  <span>Copy Links</span>
                </button>
                <button className="border border-gray-300 rounded-md px-4 py-2 flex items-center justify-center">
                  <FaLinkedin />
                </button>
                <button className="border border-gray-300 rounded-md px-4 py-2 flex items-center justify-center">
                  <FaFacebook />
                </button>
                <button className="border border-gray-300 rounded-md px-4 py-2 flex items-center justify-center">
                  <FaTwitter />
                </button>
                <button className="border border-gray-300 rounded-md px-4 py-2 flex items-center justify-center">
                  <FaMessage />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="my-10">
          <BusinessCard />
        </div>
      </div>

      {applyJobModal ? (
        <ApplyJobModal
          loading={load}
          setApplyJobModal={setApplyJobModal}
          JobApply={JobApply}
          currentJob={jobDetails}
          userData={userData}
          setResumeFile={setResumeFile}
          resumeFile={resumeFile}
        />
      ) : (
        ""
      )}
      {isModalVisible && <JobApplyModal jobId={id} handleClose={handleClose} />}
    </div>
  );
};

export default JobDetail;
