import React, { useState } from "react";
import SimpleBanner from "../../../components/common/SimpleBanner";
import HealthCareBanner from "../../../Assets/Industry/HealthCareBanner.png"; // Reuse the same banner for now
import heathCare_image from "../../../Assets/Industry/heathCare_image.png"; // Reuse image
import HeadingWithDot from "../../../components/common/HeadingWithDot";
import ConsultingServices_image from "../../../Assets/Industry/ConsultingServices_image.png"; // Reuse image
import EHRImplementation_image from "../../../Assets/Industry/EHRImplementation_image.png"; // Reuse image
import Telemedicine_image from "../../../Assets/Industry/Telemedicine_image.png"; // Reuse image
import BusinessCard from "../../../components/common/BusinessCard"; // Reuse Business Card

// Updated content for Infrastructure IT Solutions
const infrastructureSolutions = [
  {
    image: ConsultingServices_image, // Reuse image
    title: "Smart Infrastructure Solutions",
    description:
      "We offer advanced digital solutions to modernize infrastructure, from smart grid technology to intelligent transportation systems, improving efficiency and sustainability.",
  },
  {
    image: EHRImplementation_image, // Reuse image
    title: "Project Management & Monitoring",
    description:
      "Our project management solutions enable real-time monitoring of infrastructure projects, ensuring timely delivery, cost management, and risk mitigation.",
  },
  {
    image: Telemedicine_image, // Reuse image
    title: "Infrastructure Security & Compliance",
    description:
      "We provide cybersecurity and compliance solutions to protect critical infrastructure from threats and ensure regulatory adherence, safeguarding public assets and services.",
  },
];

const processSteps = [
  {
    index: 1,
    title: "Research & Feasibility Study",
    description:
      "We begin by analyzing infrastructure needs and challenges, conducting feasibility studies to understand opportunities for technological integration and improvement.",
  },
  {
    index: 2,
    title: "Risk Management & Compliance",
    description:
      "Our solutions are designed with security and compliance in mind, helping to mitigate risks and ensure that infrastructure systems comply with industry standards and regulations.",
  },
  {
    index: 3,
    title: "Collaborative Planning",
    description:
      "We collaborate with stakeholders, government bodies, and private entities to ensure that the solutions we propose are aligned with the broader infrastructure goals and future-proof.",
  },
  {
    index: 4,
    title: "Design & Prototyping",
    description:
      "During the design phase, we create detailed prototypes that visualize the impact of our smart infrastructure solutions, incorporating feedback to fine-tune the project scope.",
  },
  {
    index: 5,
    title: "Implementation & Monitoring",
    description:
      "We oversee the implementation of infrastructure projects, ensuring that every system is installed correctly and monitored for optimal performance.",
  },
  {
    index: 6,
    title: "Continuous Monitoring & Maintenance",
    description:
      "Post-launch, we provide ongoing maintenance and performance optimization, ensuring infrastructure systems operate efficiently and can scale with growing demand.",
  },
];

const Infrastructure = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  return (
    <div>
      {/* Reuse the banner */}
      <SimpleBanner
        image={HealthCareBanner}
        name="Infrastructure"
        path="Home / Industry / Infrastructure"
      />

      <div className="lg:px-20 md:px-10 px-5 py-10">
        <div className="grid xl:grid-cols-2 grid-cols-1 gap-10">
          <div className="">
            {/* Reuse the image */}
            <img
              src="https://cdn.prod.website-files.com/628905bae461d31c437ea344/64e6133d46ccec73573c1e39_Smart%20City%20Foundation.webp"
              alt="about us"
              className="w-full rounded-lg"
            />
          </div>
          <div className="flex justify-center items-center">
            <div>
              {/* Updated content for Infrastructure */}
              <h1 className="md:text-4xl text-3xl tracking-wider md:leading-[55px] leading-[50px] mt-2">
                Innovative IT Solutions for
                <span className="text-primaryColor"> Infrastructure</span>
              </h1>
              <p className="text-TextSecondaryColor mt-2 w-[90%] tracking-wide">
                Our IT solutions empower infrastructure development by offering
                cutting-edge technology for project management, smart systems,
                and security. Whether it's managing large-scale construction or
                deploying smart city technology, we help you build the
                infrastructure of the future.
              </p>
              <p className="text-TextSecondaryColor mt-2 w-[90%] tracking-wide">
                We work with stakeholders from both the public and private
                sectors to deliver smart, scalable infrastructure that improves
                efficiency, safety, and sustainability in urban and rural
                settings.
              </p>
            </div>
          </div>
        </div>

        {/* Industry Solutions */}
        <div>
          <div className="flex justify-center mt-10">
            <HeadingWithDot name="Our Industry" />
          </div>
          <h1 className="md:text-4xl text-2xl tracking-wider text-center mt-4">
            Our <span className="text-primaryColor">Infrastructure</span> IT
            Solutions
          </h1>
          <p className="text-TextSecondaryColor text-sm tracking-wider text-center m-auto xl:w-[60%] mt-4">
            We provide comprehensive IT services for the infrastructure sector,
            offering solutions that drive digital transformation, improve
            project management, and ensure security and compliance.
          </p>

          <div className="flex flex-wrap gap-8 mt-10 justify-center">
            {infrastructureSolutions.map((solution, index) => (
              <div
                key={index}
                className="border p-6 rounded-lg shadow-md text-center flex flex-col items-center w-full sm:w-[calc(50%-16px)] lg:w-[calc(30.33%-16px)]"
              >
                <img
                  src={solution.image}
                  alt={solution.title}
                  className="w-20 mb-4"
                />
                <h3 className="text-lg font-semibold tracking-wider mb-2">
                  {solution.title}
                </h3>
                <p className="text-sm text-TextSecondaryColor tracking-wider">
                  {solution.description}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Process */}
        <div className="flex justify-center mt-10">
          <HeadingWithDot name="Our Process" />
        </div>
        <h1 className="md:text-4xl text-2xl tracking-wider text-center mt-4">
          Our <span className="text-primaryColor">Process</span>
        </h1>
        <p className="text-TextSecondaryColor text-sm tracking-wider text-center m-auto xl:w-[60%] mt-4">
          Our process ensures that infrastructure projects are efficiently
          managed, secure, and compliant with industry standards, offering
          scalable solutions that meet the demands of modern infrastructure
          development.
        </p>

        <div className="flex flex-wrap gap-8 mt-10 justify-center mb-10">
          {processSteps.map((step) => (
            <div
              onMouseEnter={() => setActiveIndex(step.index)}
              onMouseLeave={() => setActiveIndex(null)}
              key={step.index}
              className="relative border p-6 rounded-lg shadow-md flex flex-col w-full sm:w-[calc(50%-16px)] lg:w-[calc(50%-16px)]"
            >
              <div
                className={`absolute -top-8 right-0 m-2 w-12 text-2xl font-mono h-12 flex items-center justify-center text-white rounded-full transition-transform duration-300 ease-linear ${
                  activeIndex === step.index
                    ? "translate-x-4 bg-violet-500"
                    : "translate-x-0 bg-primaryColor"
                }`}
              >
                {step.index}
              </div>
              <h3 className="text-xl tracking-wider mb-2">{step.title}</h3>
              <p className="text-sm text-TextSecondaryColor tracking-wider leading-6">
                {step.description}
              </p>
            </div>
          ))}
        </div>

        {/* Reuse BusinessCard */}
        <BusinessCard />
      </div>
    </div>
  );
};

export default Infrastructure;
