import React from 'react'
import AboutUs_banner from "../../../Assets/ApplicationDevelopment/istockphoto-1193278024-2048x2048.jpg";
import SimpleBanner from "../../../components/common/SimpleBanner";
import SCODDevelopment from "../../../components/SCODSpecialtyConsultantDemand/SCODDevelopment"
import SCODLegacy  from '../../../components/SCODSpecialtyConsultantDemand/SCODLegacy'
import SCODEfficiency from '../../../components/SCODSpecialtyConsultantDemand/SCODEfficiency'
const SCODSpecialtyConsultant = () => {
  return (
    <>
      <SimpleBanner
        image={AboutUs_banner}
        name="SCOD-Specialty Consultant on Demand"
        path="Home / Services / SCOD-Specialty Consultant on Demand"
      />
    <SCODDevelopment/>
    <SCODLegacy />
    <SCODEfficiency/>
    </>
  )
}

export default SCODSpecialtyConsultant