import React from 'react'
import SimpleBanner from "../../../components/common/SimpleBanner";
import AboutUs_banner from "../../../Assets/AboutUs/AboutUs_banner.png";
import KGSModernization from '../../../components/KGSProductSuite/KGSModernization';
import KGSProduct from '../../../components/KGSProductSuite/KGSProduct'
import Development from '../../../components/KGSProductSuite/Development';

const KGSProductSuite = () => {
  return (
    <>
      <SimpleBanner
        image={AboutUs_banner}
        name="KGS’s Product Suite"
        path="Home / Services / KGS’s Product Suite"
      />
      <Development/>
      <KGSModernization />
      <KGSProduct />

    </>
  )
}

export default KGSProductSuite