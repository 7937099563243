import React from "react";
import img from "../../Assets/ApplicationDevelopment/image 253.png";
const LegacyApps = () => {
  return (
    <div className="px-5 md:px-20 gap-x-7 md:gap-y-11 flex flex-col lg:flex-row items-center justify-between w-[100%]">
      <div className="lg:w-[50%] w-[100%]">
        <h1 className="text-2xl md:text-4xl font-bold">
          Transform Your Business for{" "}
          <span className="text-blue-500">the Future</span>
        </h1>
        <p className="mt-5 text-lg md:text-xl text-gray-600">
          In today's rapidly evolving business environment, staying ahead
          requires a deep understanding of your processes and systems.
          Techmentee offers comprehensive business analysis services that help
          you modernize and optimize your operations. Our key offerings include:
        </p>
        <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-4 text-gray-600 text-lg md:text-xl montserrat">
          <div className="flex items-start">
            <span>Process Optimization</span>
          </div>
          <div className="flex items-start">
            <span>Data-Driven Insights</span>
          </div>
          <div className="flex items-start">
            <span>Strategic Planning</span>
          </div>
          <div className="flex items-start">
            <span>Continuous Improvement</span>
          </div>
        </div>
        <p className="mt-5 text-lg md:text-xl text-gray-600">
          Is your business lagging due to outdated processes? Our experts can
          help you analyze and improve your operations, ensuring that your
          business is equipped for future success. Let's collaborate to bring
          your ideas to life and transform your business!
        </p>
      </div>

      <div className="w-[100%] lg:w-1/2 mt-10 md:mt-0 flex justify-center">
        <img
          src={img}
          alt="Business Analysis"
          className="rounded-lg w-full object-cover"
        />
      </div>
    </div>
  );
};

export default LegacyApps;
