import React, { useState, useEffect } from "react";
import bannerImage from "../../../Assets/Programs/RemoteBootCamp_banner.png";
import remote_boot_camp_image from "../../../Assets/Programs/remote_boot_camp_image.png";
import SimpleBanner from "../../../components/common/SimpleBanner";
import HeadingWithDot from "../../../components/common/HeadingWithDot";
import ComprehensiveCurriculum from "../../../Assets/Programs/ComprehensiveCurriculum.png";
import ExpertInstructors from "../../../Assets/Programs/ExpertInstructors.png";
import HandsOnProjects from "../../../Assets/Programs/HandsOnProjects.png";
import CollaborativeLearning from "../../../Assets/Programs/CollaborativeLearning.png";
import FlexibleSchedule from "../../../Assets/Programs/FlexibleSchedule.png";
import CareerSupport from "../../../Assets/Programs/CareerSupport.png";
import ContactUsForm from "../../../components/home/ContactUsForm";
import BusinessCard from "../../../components/common/BusinessCard";
import Courses from "../../../components/programs/remoteBootCamp/Courses";
import { Link } from "react-router-dom";
import axios from "axios";
import { BiSolidRightArrow } from "react-icons/bi";

const Features = [
  {
    image: ComprehensiveCurriculum,
    title: "Comprehensive Curriculum",
    description:
      "The program covers a wide range of topics, from foundational concepts to advanced techniques, depending on the field of study. Whether it's coding, data science, cybersecurity, or digital marketing, the curriculum is designed to provide practical, real-world skills.",
  },
  {
    image: ExpertInstructors,
    title: "Expert Instructors",
    description:
      "Participants learn from industry professionals with extensive experience in their respective fields. Instructors provide guidance, mentorship, and valuable insights into current industry practices.",
  },
  {
    image: HandsOnProjects,
    title: "Hands On Projects",
    description:
      "The program emphasizes experiential learning through hands-on projects and assignments. These projects simulate real-world scenarios, allowing participants to apply their knowledge and build a professional portfolio.",
  },
  {
    image: CollaborativeLearning,
    title: "Collaborative Learning",
    description:
      "Despite being remote, the BootCamp fosters a collaborative learning environment. Participants work in virtual teams, engage in peer reviews, and collaborate on group projects, mimicking a real-world work environment.",
  },
  {
    image: FlexibleSchedule,
    title: "Flexible Schedule",
    description:
      " Designed to accommodate the busy lives of participants, the BootCamp offers a flexible schedule with live sessions, recorded lectures, and asynchronous learning options. This allows learners to progress at their own pace.",
  },
  {
    image: CareerSupport,
    title: "Job Search Assistance",
    description:
      "Elevate your job search with our Professional Plan! Get exclusive 60-day access to our Application Assistance Plan, specifically designed to accelerate your job search and help you land the job you’ve always wanted.",
  },
];

const RemoteBootCamp = () => {
  const [courseData, setCourseData] = useState([]);
  const [loading, setLoading] = useState(true);
  console.log(courseData);
  const getAllCourse = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/courses/get-all-course`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response?.data?.courses);
        setCourseData(response?.data?.courses);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  useEffect(() => {
    getAllCourse();
  }, []);

  const formatTitleForUrl = (title) => {
    return title
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/&/g, "and") // Replace & with "and"
      .replace(/_/g, "-") // Replace underscores with hyphens
      .replace(/\//g, "-");
  };

  return (
    <div className="overflow-x-hidden overflow-y-hidden">
      <SimpleBanner
        image={bannerImage}
        name="Remote Boot Camp"
        path="Home / Programs / Remote Boot Camp"
      />
      <div className="lg:px-20 md:px-10 px-5 py-10">
        <div className="grid xl:grid-cols-2 grid-cols-1 gap-10 ">
          <div
            className="flex justify-center items-center"
            data-aos="fade-right"
          >
            <div>
              <h1 className="md:text-4xl text-3xl tracking-wider md:leading-[50px] leading-[40px] mt-2">
                A Brief Overview of the
                <span className="text-primaryColor"> Remote Boot Camp </span>
                Program
              </h1>
              <p className="text-TextSecondaryColor mt-2 w-[90%] font-light tracking-wide">
                The Remote BootCamp program is an intensive, online training
                course designed to equip participants with in-demand skills in a
                short period. Delivered entirely remotely, this program offers a
                flexible learning environment, allowing individuals to
                participate from anywhere in the world.
              </p>
              <p className="text-TextSecondaryColor mt-2 w-[90%] font-light tracking-wide">
                Transform your career with our Remote BootCamp program. Choose
                from a wide range of 60 courses designed to enhance your skills
                and advance your career. Whether you're a professional looking
                to upgrade your skills or a fresher entering the field, we have
                tailored plans for you.
              </p>
            </div>
          </div>
          <div className="" data-aos="fade-left">
            <img
              src={remote_boot_camp_image}
              alt="about us"
              className="w-full"
            />
          </div>
        </div>
        {/* Our Features */}
        <div>
          <div className="flex justify-center mt-10">
            <HeadingWithDot name="Our Features" />
          </div>
          <h1 className="md:text-4xl text-2xl tracking-wider text-center mt-4">
            Discover What Our <span className="text-primaryColor">Program</span>{" "}
            Offers
          </h1>
          {/* <p className="text-TextSecondaryColor text-sm tracking-wider text-center m-auto xl:w-[60%] mt-4">
            We offer comprehensive IT solutions to improve patient care and
            enhance healthcare delivery. Our solutions are tailored to meet the
            unique needs of healthcare providers, from EHR implementation to
            telemedicine platforms and practice management solutions.
          </p> */}
          <div className="mt-10 grid lg:grid-cols-2 grid-cols-1 gap-8">
            {Features.map((solution, index) => (
              <div
                key={index}
                className="border p-6 rounded-lg shadow-md flex flex-col"
                data-aos={index % 2 === 0 ? "fade-up" : "fade-right"}
                data-aos-delay={index * 100}
              >
                <img
                  src={solution.image}
                  alt={solution.title}
                  className="w-20 mb-4"
                />
                <h3 className="text-lg tracking-wider mb-2">
                  {solution.title}
                </h3>
                <p className="text-sm text-TextSecondaryColor tracking-wider">
                  {solution.description}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Courses */}
        <div className="mt-8">
          <div className="flex justify-center mt-10">
            <HeadingWithDot name="Courses" />
          </div>
          <h1 className="md:text-4xl text-2xl tracking-wider text-center mt-4">
            Top Notch
            <span className="text-primaryColor"> Courses </span>
            Provided by Us
          </h1>

          <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 mt-8">
            {loading
              ? [1, 2, 3].map((ele, index) => (
                  <div
                    key={index}
                    className="loading-skeleton w-full bg-gray-200 h-[300px] p-4 rounded-lg"
                    data-aos="fade-up" // Example: Add AOS effect here
                    data-aos-delay={index * 100} // Optional: Add delay based on index
                  >
                    <p className="bg-gray-300 w-full h-full rounded-md"></p>
                  </div>
                ))
              : courseData.slice(0, 3)?.map((ele, index) => (
                  <Link
                    key={ele?._id}
                    to={`/course/${formatTitleForUrl(ele?.title)}/${ele?._id}`}
                    className="border border-gray-300 p-3 rounded-xl"
                    data-aos="fade-up" // Example: Add AOS effect here
                    data-aos-delay={index * 100} // Optional: Add delay based on index
                  >
                    <img
                      src={ele?.courseBanner}
                      alt="Course Image"
                      className="rounded-md w-full h-[200px] object-cover"
                    />
                    <h1 className="font-bold mt-4 tracking-wide text-TextPrimaryColor">
                      {ele?.title}
                    </h1>
                    <p className="mt-3 tracking-wide text-sm leading-7 text-TextSecondaryColor truncate">
                      {ele?.description}
                    </p>
                    <div className="flex items-center justify-end gap-2 mt-4 cursor-pointer">
                      <button className="text-[#034D8E]">View Details </button>
                      <BiSolidRightArrow color="#034D8E" />
                    </div>
                  </Link>
                ))}
          </div>

          <div className="flex justify-center mt-4 hover:text-primaryColor underline">
            <Link to="/courses">View More</Link>
          </div>
        </div>

        {/* Contact Us Form */}
        <div className="mt-8">
          <ContactUsForm />
        </div>
        <div className="mt-8">
          <BusinessCard />
        </div>
      </div>
    </div>
  );
};

export default RemoteBootCamp;
