import React from 'react'
import SimpleBanner from "../../../components/common/SimpleBanner";
import AboutUs_banner from "../../../Assets/banner/microsoft.png";
import Development from '../../../components/Microsoftservices/Development';
import LegacyApps from '../../../components/Microsoftservices/LegacyApps';
import CostEfficiency from '../../../components/Microsoftservices/CostEfficiency';
const MicrosoftServices = () => {
  return (
    <>
      <SimpleBanner
        image={AboutUs_banner}
        name="Microsoft Services"
        path="Home / Services / Microsoft Services"
      />
      <Development/>
      <LegacyApps/>
      <CostEfficiency/>
    </>
  )
}

export default MicrosoftServices