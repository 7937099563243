import React from "react";
import img1 from "../../Assets/ApplicationDevelopment/image.png";
import img2 from "../../Assets/ApplicationDevelopment/image (1).png";
import img3 from "../../Assets/ApplicationDevelopment/image (2).png";
const Development = () => {
  return (
    <div className="bg-white py-10 px-5 md:px-20">
      <div className="text-center">
        <h1 className="text-3xl md:text-5xl font-bold">
          BUSINESS <span className="text-blue-500">ANALYSIS</span> SERVICE
        </h1>
        <p className="mt-5 text-lg md:text-xl text-gray-600">
          Drive growth by analyzing and optimizing your business processes with
          cutting-edge strategies and tools.
        </p>
      </div>

      <div className="mt-8 text-gray-600 text-center mx-auto">
        <p>
          Effective business analysis is key to achieving success in today's
          competitive environment. With changing market dynamics and increasing
          complexity, having the best business analysis services in Atlanta, GA,
          USA, is crucial. Techmentee recognizes the need to thoroughly analyze
          current systems and processes to ensure that your business is
          optimized for efficiency and growth. Our experts help you streamline
          operations, identify opportunities, and implement advanced strategies
          to stay ahead of the competition.
        </p>
      </div>

      <div className="mt-10 flex flex-col md:flex-row items-center justify-center gap-5">
        <img
          src={img1}
          alt="Data Analysis"
          className="rounded-lg w-full md:w-1/3 object-cover"
        />
        <img
          src={img2}
          alt="Strategy Planning"
          className="rounded-lg w-full md:w-1/3 object-cover"
        />
        <img
          src={img3}
          alt="Process Optimization"
          className="rounded-lg w-full md:w-1/3 object-cover"
        />
      </div>
    </div>
  );
};

export default Development;
