import React from "react";
import img1 from "../../Assets/ApplicationDevelopment/image.png";
import img2 from "../../Assets/ApplicationDevelopment/image (1).png";
import img3 from "../../Assets/ApplicationDevelopment/image (2).png";
const QAandTesting = () => {
  return (
    <div className="bg-white py-10 px-5 md:px-20">
      <div className="text-center">
        <h1 className="text-3xl md:text-5xl font-bold">
          QUALITY <span className="text-green-500">ASSURANCE</span> &amp;
          TESTING
        </h1>
        <p className="mt-5 text-lg md:text-xl text-gray-600">
          Ensure the highest quality and performance of your software with our
          comprehensive QA & Testing services.
        </p>
      </div>

      <div className="mt-8 text-gray-600 text-center mx-auto">
        <p>
          At Techmentee, we understand that quality is non-negotiable. Our QA &
          Testing services ensure that your software is robust, reliable, and
          ready to meet the demands of your business. From automated testing to
          performance validation, we cover all aspects of software quality to
          help you succeed.
        </p>
      </div>

      <div className="mt-10 flex flex-col md:flex-row items-center justify-center gap-5">
        <img
          src={img1}
          alt="Automated Testing"
          className="rounded-lg w-full md:w-1/3 object-cover"
        />
        <img
          src={img2}
          alt="Manual Testing"
          className="rounded-lg w-full md:w-1/3 object-cover"
        />
        <img
          src={img3}
          alt="Performance Testing"
          className="rounded-lg w-full md:w-1/3 object-cover"
        />
      </div>
    </div>
  );
};

export default QAandTesting;
