import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import axios from "axios";
import { toast } from "react-hot-toast";

const JobApplyModal = ({ handleClose, jobId }) => {
  const [formData, setFormData] = useState({
    job_reference: jobId,
    candidate_name: "",
    email: "",
    phone: "",
    current_location: "",
    experience: "",
    cover_letter: "",
    resume: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [resumeFile, setResumeFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const CLOUDINARY_URL =
    "https://api.cloudinary.com/v1_1/drijzhqfp/image/upload";
  const UPLOAD_PRESET = "manyavar";

  const validate = () => {
    const errors = {};
    if (!formData.candidate_name)
      errors.candidate_name = "Candidate name is required";
    if (!formData.email) errors.email = "Email is required";
    if (!formData.phone) errors.phone = "Phone number is required";
    if (!formData.current_location)
      errors.current_location = "Current location is required";
    if (!formData.experience) errors.experience = "Experience is required";
    if (!formData.cover_letter)
      errors.cover_letter = "Cover letter is required";
    if (!resumeFile) errors.resume = "Resume is required";
    return errors;
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setResumeFile(files[0]);
      setFormErrors((prevErrors) => ({ ...prevErrors, resume: "" }));
    } else {
      setFormData({ ...formData, [name]: value });
      setFormErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const handleUpload = async () => {
    if (!resumeFile) return;
    const formData = new FormData();
    formData.append("file", resumeFile);
    formData.append("upload_preset", UPLOAD_PRESET);
    try {
      const response = await axios.post(CLOUDINARY_URL, formData);
      return response.data.secure_url;
    } catch (error) {
      console.error("Error uploading file:", error);
      return "";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      try {
        const resumeUrl = await handleUpload();
        if (resumeUrl) {
          const submittedData = { ...formData, resume: resumeUrl };
          console.log("Form data submitted:", submittedData);

          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_KEY}/applied-jobs/create`,
            headers: {
              "Content-Type": "application/json",
            },
            data: submittedData,
          };

          axios
            .request(config)
            .then((response) => {
              console.log(response.data);
              toast.success(
                `Hey! ${response?.data?.appliedJob?.candidate_name} Your Application Submitted Successfully!`
              );
              handleClose();
            })
            .catch((error) => {
              console.log(error);
              toast.error("Something Went Wrong");
            });
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        toast.error("Failed to submit application. Please try again.");
      } finally {
        setIsLoading(false);
      }
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-bold">Apply for Job</h2>
          <button
            className="text-gray-500 hover:text-gray-700 transition-colors"
            onClick={handleClose}
          >
            <IoClose size={24} />
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="candidate_name"
              className={`block mb-1 font-medium ${
                formErrors.candidate_name ? "text-red-500" : "text-gray-700"
              }`}
            >
              Candidate Name
            </label>
            <input
              type="text"
              id="candidate_name"
              name="candidate_name"
              value={formData.candidate_name}
              onChange={handleChange}
              className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 ${
                formErrors.candidate_name
                  ? "border-red-500 focus:ring-red-500"
                  : "border-gray-300 focus:ring-blue-500"
              }`}
            />
            {formErrors.candidate_name && (
              <span className="text-red-500 text-sm">
                {formErrors.candidate_name}
              </span>
            )}
          </div>

          <div className="mb-4">
            <label
              htmlFor="email"
              className={`block mb-1 font-medium ${
                formErrors.email ? "text-red-500" : "text-gray-700"
              }`}
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 ${
                formErrors.email
                  ? "border-red-500 focus:ring-red-500"
                  : "border-gray-300 focus:ring-blue-500"
              }`}
            />
            {formErrors.email && (
              <span className="text-red-500 text-sm">{formErrors.email}</span>
            )}
          </div>

          <div className="mb-4">
            <label
              htmlFor="phone"
              className={`block mb-1 font-medium ${
                formErrors.phone ? "text-red-500" : "text-gray-700"
              }`}
            >
              Phone
            </label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 ${
                formErrors.phone
                  ? "border-red-500 focus:ring-red-500"
                  : "border-gray-300 focus:ring-blue-500"
              }`}
            />
            {formErrors.phone && (
              <span className="text-red-500 text-sm">{formErrors.phone}</span>
            )}
          </div>

          <div className="mb-4">
            <label
              htmlFor="current_location"
              className={`block mb-1 font-medium ${
                formErrors.current_location ? "text-red-500" : "text-gray-700"
              }`}
            >
              Current Location
            </label>
            <input
              type="text"
              id="current_location"
              name="current_location"
              value={formData.current_location}
              onChange={handleChange}
              className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 ${
                formErrors.current_location
                  ? "border-red-500 focus:ring-red-500"
                  : "border-gray-300 focus:ring-blue-500"
              }`}
            />
            {formErrors.current_location && (
              <span className="text-red-500 text-sm">
                {formErrors.current_location}
              </span>
            )}
          </div>

          <div className="mb-4">
            <label
              htmlFor="experience"
              className={`block mb-1 font-medium ${
                formErrors.experience ? "text-red-500" : "text-gray-700"
              }`}
            >
              Experience
            </label>
            <input
              type="text"
              id="experience"
              name="experience"
              value={formData.experience}
              onChange={handleChange}
              className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 ${
                formErrors.experience
                  ? "border-red-500 focus:ring-red-500"
                  : "border-gray-300 focus:ring-blue-500"
              }`}
            />
            {formErrors.experience && (
              <span className="text-red-500 text-sm">
                {formErrors.experience}
              </span>
            )}
          </div>

          <div className="mb-4">
            <label
              htmlFor="cover_letter"
              className={`block mb-1 font-medium ${
                formErrors.cover_letter ? "text-red-500" : "text-gray-700"
              }`}
            >
              Cover Letter
            </label>
            <textarea
              id="cover_letter"
              name="cover_letter"
              value={formData.cover_letter}
              onChange={handleChange}
              className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 ${
                formErrors.cover_letter
                  ? "border-red-500 focus:ring-red-500"
                  : "border-gray-300 focus:ring-blue-500"
              }`}
            />
            {formErrors.cover_letter && (
              <span className="text-red-500 text-sm">
                {formErrors.cover_letter}
              </span>
            )}
          </div>

          <div className="mb-4">
            <label
              htmlFor="resume"
              className={`block mb-1 font-medium ${
                formErrors.resume ? "text-red-500" : "text-gray-700"
              }`}
            >
              Resume (JPG format only)
            </label>
            <input
              type="file"
              id="resume"
              name="resume"
              accept=".jpg, .jpeg, .png"
              onChange={handleChange}
              className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 ${
                formErrors.resume
                  ? "border-red-500 focus:ring-red-500"
                  : "border-gray-300 focus:ring-blue-500"
              }`}
            />
            {formErrors.resume && (
              <span className="text-red-500 text-sm">{formErrors.resume}</span>
            )}
          </div>

          <button
            type="submit"
            disabled={isLoading}
            className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
              isLoading
                ? "bg-blue-400 cursor-not-allowed"
                : "bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            }`}
          >
            {isLoading ? (
              <>
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Submitting...
              </>
            ) : (
              "Apply"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default JobApplyModal;
