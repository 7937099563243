import React from 'react'
import SimpleBanner from "../../../components/common/SimpleBanner";
import AboutUs_banner from "../../../Assets/banner/ITSTAFFING.png";
import ITStaffingDevelopment from '../../../components/ITStaffingDeliveryModel/ITStaffingDevelopment';
import ITStaffingLegacy from '../../../components/ITStaffingDeliveryModel/ITStaffingLegacy';
import StaffingEfficiency from '../../../components/ITStaffingDeliveryModel/StaffingEfficiency';

const ITStaffingDeliveryModel = () => {
  return (
    <>
      <SimpleBanner
        image={AboutUs_banner}
        name="IT Staffing & Delivery Model"
        path="Home / Services / IT Staffing & Delivery Model"
      />
      <ITStaffingDevelopment />
      <ITStaffingLegacy/>
      <StaffingEfficiency/>
    </>
  )
}

export default ITStaffingDeliveryModel