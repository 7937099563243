import React from 'react';
import img1 from '../../Assets/ApplicationDevelopment/Mask group.png';
import img2 from '../../Assets/ApplicationDevelopment/Mask group (2).png';
import img3 from '../../Assets/ApplicationDevelopment/Mask group (3).png';

const SCODEfficiency = () => {
  return (
    <div className='flex flex-wrap w-[90%] mx-auto justify-between py-6'>
      <div className='w-full sm:w-[45%] md:w-[22%] p-4 text-center'>
        <img src={img1} alt='Cost Efficiency' className='mx-auto mb-4'/>
        <h1 className='text-xl font-semibold'>Cost Efficiency</h1>
      </div>
      <div className='w-full sm:w-[45%] md:w-[22%] p-4 text-center'>
        <img src={img2} alt='Scalability' className='mx-auto mb-4'/>
        <h1 className='text-xl font-semibold'>Scalability</h1>
      </div>
      <div className='w-full sm:w-[45%] md:w-[22%] p-4 text-center'>
        <img src={img3} alt='Expert Support' className='mx-auto mb-4'/>
        <h1 className='text-xl font-semibold'>Expert Support</h1>
      </div>
      <div className='w-full sm:w-[45%] md:w-[22%] p-4 text-center'>
        <img src={img1} alt='Customized Solutions' className='mx-auto mb-4'/>
        <h1 className='text-xl font-semibold'>Customized Solutions</h1>
      </div>
    </div>
  );
}

export default SCODEfficiency;