import React, { useState } from "react";
import SimpleBanner from "../../../components/common/SimpleBanner";
import HealthCareBanner from "../../../Assets/Industry/HealthCareBanner.png"; // Using the same banner
import heathCare_image from "../../../Assets/Industry/heathCare_image.png"; // Keeping the image same for now
import HeadingWithDot from "../../../components/common/HeadingWithDot";
import ConsultingServices_image from "../../../Assets/Industry/ConsultingServices_image.png"; // Reusing
import EHRImplementation_image from "../../../Assets/Industry/EHRImplementation_image.png"; // Reusing
import Telemedicine_image from "../../../Assets/Industry/Telemedicine_image.png"; // Reusing
import BusinessCard from "../../../components/common/BusinessCard"; // Reusing Business Card

// Update the hardcoded content for automotive industry
const automotiveSolutions = [
  {
    image: ConsultingServices_image, // Reuse image
    title: "Manufacturing Optimization",
    description:
      "We assist automotive manufacturers in optimizing their production lines with cutting-edge IT solutions to enhance efficiency and reduce costs.",
  },
  {
    image: EHRImplementation_image, // Reuse image
    title: "Electric Vehicle Technologies",
    description:
      "We offer innovative software solutions for electric vehicle battery management, charging infrastructure, and vehicle-to-grid integration.",
  },
  {
    image: Telemedicine_image, // Reuse image
    title: "Autonomous Driving Systems",
    description:
      "Our IT solutions support autonomous driving systems, ensuring safety and reliability for next-generation automotive innovations.",
  },
];

const processSteps = [
  {
    index: 1,
    title: "Industry Research",
    description:
      "We conduct in-depth research to understand the trends and challenges in the automotive industry, enabling us to create innovative solutions.",
  },
  {
    index: 2,
    title: "Testing & Quality Assurance",
    description:
      "We rigorously test our IT solutions to meet the automotive industry's high standards of quality and safety before deployment.",
  },
  {
    index: 3,
    title: "Requirement Gathering",
    description:
      "We collaborate closely with automotive manufacturers to gather detailed requirements, ensuring our solutions address their specific business needs.",
  },
  {
    index: 4,
    title: "Design & Prototyping",
    description:
      "Our team creates detailed prototypes to give manufacturers a clear understanding of how our solutions integrate into their systems.",
  },
  {
    index: 5,
    title: "Deployment",
    description:
      "We oversee the smooth deployment of our solutions, ensuring seamless integration into existing automotive systems and operations.",
  },
  {
    index: 6,
    title: "Ongoing Support",
    description:
      "After deployment, we provide continuous support and updates to ensure that the solutions evolve with the industry’s needs.",
  },
];

const Automotive = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  return (
    <div>
      {/* Reuse the banner, no changes */}
      <SimpleBanner
        image={HealthCareBanner}
        name="Automotive"
        path="Home / Industry / Automotive"
      />

      <div className="lg:px-20 md:px-10 px-5 py-10">
        <div className="grid xl:grid-cols-2 grid-cols-1 gap-10">
          <div className="">
            {/* Reuse the image */}
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyY-pyPj5WD4HYGEd0r5lHrk8gfc18CrmSS_WVi0O9oLOOH2aLiAi9K_qb0UiLZRNVbl8&usqp=CAU"
              alt="about us"
              className="w-full rounded-lg"
            />
          </div>
          <div className="flex justify-center items-center">
            <div>
              {/* Change the content to match automotive industry */}
              <h1 className="md:text-4xl text-3xl tracking-wider md:leading-[55px] leading-[50px] mt-2">
                Automotive IT Development
                <span className="text-primaryColor"> Company</span>
              </h1>
              <p className="text-TextSecondaryColor mt-2 w-[90%] tracking-wide">
                Our automotive IT solutions optimize manufacturing processes,
                support electric vehicle infrastructure, and advance autonomous
                vehicle technologies.
              </p>
              <p className="text-TextSecondaryColor mt-2 w-[90%] tracking-wide">
                Whether you're a manufacturer or a tech firm in the automotive
                space, we provide solutions to help you achieve operational
                excellence and sustainable growth.
              </p>
            </div>
          </div>
        </div>

        {/* Industry Solutions */}
        <div>
          <div className="flex justify-center mt-10">
            <HeadingWithDot name="Our Industry" />
          </div>
          <h1 className="md:text-4xl text-2xl tracking-wider text-center mt-4">
            Our <span className="text-primaryColor">Automotive</span> IT
            Solutions
          </h1>
          <p className="text-TextSecondaryColor text-sm tracking-wider text-center m-auto xl:w-[60%] mt-4">
            We offer comprehensive IT solutions to improve automotive
            manufacturing, support electric and autonomous vehicle technologies,
            and enhance overall operational efficiency.
          </p>

          <div className="flex flex-wrap gap-8 mt-10 justify-center">
            {automotiveSolutions.map((solution, index) => (
              <div
                key={index}
                className="border p-6 rounded-lg shadow-md text-center flex flex-col items-center w-full sm:w-[calc(50%-16px)] lg:w-[calc(30.33%-16px)]"
              >
                <img
                  src={solution.image}
                  alt={solution.title}
                  className="w-20 mb-4"
                />
                <h3 className="text-lg font-semibold tracking-wider mb-2">
                  {solution.title}
                </h3>
                <p className="text-sm text-TextSecondaryColor tracking-wider">
                  {solution.description}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Process */}
        <div className="flex justify-center mt-10">
          <HeadingWithDot name="Our Process" />
        </div>
        <h1 className="md:text-4xl text-2xl tracking-wider text-center mt-4">
          Our <span className="text-primaryColor">Process</span>
        </h1>
        <p className="text-TextSecondaryColor text-sm tracking-wider text-center m-auto xl:w-[60%] mt-4">
          Our process ensures seamless integration of IT solutions in the
          automotive industry, from research and testing to deployment and
          continuous improvement.
        </p>

        <div className="flex flex-wrap gap-8 mt-10 justify-center mb-10">
          {processSteps.map((step) => (
            <div
              onMouseEnter={() => setActiveIndex(step.index)}
              onMouseLeave={() => setActiveIndex(null)}
              key={step.index}
              className="relative border p-6 rounded-lg shadow-md flex flex-col w-full sm:w-[calc(50%-16px)] lg:w-[calc(50%-16px)]"
            >
              <div
                className={`absolute -top-8 right-0 m-2 w-12 text-2xl font-mono h-12 flex items-center justify-center text-white rounded-full transition-transform duration-300 ease-linear ${
                  activeIndex === step.index
                    ? "translate-x-4 bg-violet-500"
                    : "translate-x-0 bg-primaryColor"
                }`}
              >
                {step.index}
              </div>
              <h3 className="text-xl tracking-wider mb-2">{step.title}</h3>
              <p className="text-sm text-TextSecondaryColor tracking-wider leading-6">
                {step.description}
              </p>
            </div>
          ))}
        </div>

        {/* Reuse BusinessCard */}
        <BusinessCard />
      </div>
    </div>
  );
};

export default Automotive;
