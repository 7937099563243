import React, { useState } from "react";
import SimpleBanner from "../../../components/common/SimpleBanner";
import HealthCareBanner from "../../../Assets/Industry/HealthCareBanner.png"; // Reuse the same banner for now
import heathCare_image from "../../../Assets/Industry/heathCare_image.png"; // Reuse image
import HeadingWithDot from "../../../components/common/HeadingWithDot";
import ConsultingServices_image from "../../../Assets/Industry/ConsultingServices_image.png"; // Reuse image
import EHRImplementation_image from "../../../Assets/Industry/EHRImplementation_image.png"; // Reuse image
import Telemedicine_image from "../../../Assets/Industry/Telemedicine_image.png"; // Reuse image
import BusinessCard from "../../../components/common/BusinessCard"; // Reuse Business Card

// Update the hardcoded content for E-commerce industry
const ecommerceSolutions = [
  {
    image: ConsultingServices_image, // Reuse image
    title: "E-commerce Platform Development",
    description:
      "We build robust e-commerce platforms tailored to your business needs, providing a seamless shopping experience, secure payment systems, and easy product management.",
  },
  {
    image: EHRImplementation_image, // Reuse image
    title: "Payment Gateway Integration",
    description:
      "Our payment gateway solutions ensure secure, fast, and efficient payment processing, offering your customers a wide variety of payment options.",
  },
  {
    image: Telemedicine_image, // Reuse image
    title: "Inventory Management Systems",
    description:
      "We provide inventory management solutions that help you streamline product tracking, reduce manual errors, and ensure a smooth supply chain process.",
  },
];

const processSteps = [
  {
    index: 1,
    title: "Market Research & Competitor Analysis",
    description:
      "We start by understanding the e-commerce market and analyzing your competitors to identify trends, opportunities, and challenges in the online retail space.",
  },
  {
    index: 2,
    title: "Security & Performance Testing",
    description:
      "Security and performance are crucial for e-commerce. Our testing ensures that your platform runs smoothly, with secure payment gateways and optimized load times.",
  },
  {
    index: 3,
    title: "Requirement Gathering",
    description:
      "We work closely with your team to gather specific requirements, including product categories, customer journey mapping, and operational needs.",
  },
  {
    index: 4,
    title: "UI/UX Design & Prototyping",
    description:
      "Our design team creates intuitive, visually appealing designs that enhance user experience, making it easy for customers to navigate and purchase products on your platform.",
  },
  {
    index: 5,
    title: "Platform Launch & Integration",
    description:
      "We handle the smooth launch of your e-commerce platform, ensuring that it integrates with existing systems like inventory management and customer relationship management tools.",
  },
  {
    index: 6,
    title: "Ongoing Support & Optimization",
    description:
      "We provide ongoing support and platform optimization, ensuring that your e-commerce business scales and adapts to changing customer needs and market trends.",
  },
];

const ECommerce = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  return (
    <div>
      {/* Reuse the banner, no changes */}
      <SimpleBanner
        image={HealthCareBanner}
        name="E-commerce"
        path="Home / Industry / E-commerce"
      />

      <div className="lg:px-20 md:px-10 px-5 py-10">
        <div className="grid xl:grid-cols-2 grid-cols-1 gap-10">
          <div className="">
            {/* Reuse the image */}
            <img
              src="https://pagecreative.co.uk/wp-content/uploads/2023/10/AdobeStock_223290240-1-2-scaled.jpeg"
              alt="about us"
              className="w-full rounded-xl"
            />
          </div>
          <div className="flex justify-center items-center">
            <div>
              {/* Change the content to match e-commerce industry */}
              <h1 className="md:text-4xl text-3xl tracking-wider md:leading-[55px] leading-[50px] mt-2">
                Comprehensive E-commerce IT Solutions
                <span className="text-primaryColor"> Company</span>
              </h1>
              <p className="text-TextSecondaryColor mt-2 w-[90%] tracking-wide">
                Our e-commerce IT solutions are designed to help businesses
                thrive in the competitive online retail space by offering
                scalable platforms, secure payment processing, and seamless
                customer experiences.
              </p>
              <p className="text-TextSecondaryColor mt-2 w-[90%] tracking-wide">
                From platform development to inventory management and payment
                gateway integration, we provide tailored solutions that help you
                manage and grow your online store efficiently.
              </p>
            </div>
          </div>
        </div>

        {/* Industry Solutions */}
        <div>
          <div className="flex justify-center mt-10">
            <HeadingWithDot name="Our Industry" />
          </div>
          <h1 className="md:text-4xl text-2xl tracking-wider text-center mt-4">
            Our <span className="text-primaryColor">E-commerce</span> IT
            Solutions
          </h1>
          <p className="text-TextSecondaryColor text-sm tracking-wider text-center m-auto xl:w-[60%] mt-4">
            We offer end-to-end e-commerce IT solutions that include platform
            development, secure payment gateway integration, and inventory
            management systems tailored to your online business needs.
          </p>

          <div className="flex flex-wrap gap-8 mt-10 justify-center">
            {ecommerceSolutions.map((solution, index) => (
              <div
                key={index}
                className="border p-6 rounded-lg shadow-md text-center flex flex-col items-center w-full sm:w-[calc(50%-16px)] lg:w-[calc(30.33%-16px)]"
              >
                <img
                  src={solution.image}
                  alt={solution.title}
                  className="w-20 mb-4"
                />
                <h3 className="text-lg font-semibold tracking-wider mb-2">
                  {solution.title}
                </h3>
                <p className="text-sm text-TextSecondaryColor tracking-wider">
                  {solution.description}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Process */}
        <div className="flex justify-center mt-10">
          <HeadingWithDot name="Our Process" />
        </div>
        <h1 className="md:text-4xl text-2xl tracking-wider text-center mt-4">
          Our <span className="text-primaryColor">Process</span>
        </h1>
        <p className="text-TextSecondaryColor text-sm tracking-wider text-center m-auto xl:w-[60%] mt-4">
          Our e-commerce development process ensures the creation of secure,
          scalable, and intuitive platforms tailored to meet the demands of the
          ever-evolving online marketplace.
        </p>

        <div className="flex flex-wrap gap-8 mt-10 justify-center mb-10">
          {processSteps.map((step) => (
            <div
              onMouseEnter={() => setActiveIndex(step.index)}
              onMouseLeave={() => setActiveIndex(null)}
              key={step.index}
              className="relative border p-6 rounded-lg shadow-md flex flex-col w-full sm:w-[calc(50%-16px)] lg:w-[calc(50%-16px)]"
            >
              <div
                className={`absolute -top-8 right-0 m-2 w-12 text-2xl font-mono h-12 flex items-center justify-center text-white rounded-full transition-transform duration-300 ease-linear ${
                  activeIndex === step.index
                    ? "translate-x-4 bg-violet-500"
                    : "translate-x-0 bg-primaryColor"
                }`}
              >
                {step.index}
              </div>
              <h3 className="text-xl tracking-wider mb-2">{step.title}</h3>
              <p className="text-sm text-TextSecondaryColor tracking-wider leading-6">
                {step.description}
              </p>
            </div>
          ))}
        </div>

        {/* Reuse BusinessCard */}
        <BusinessCard />
      </div>
    </div>
  );
};

export default ECommerce;
