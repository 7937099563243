import React, { useState, useEffect } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import axios from "axios";

const EditJobModal = ({ selectedJob, onClose, fetchJobs }) => {
  const [formData, setFormData] = useState({
    job_title: "",
    job_type: "",
    salary: "",
    location: "",
    job_posted_date: "",
    job_expire: "",
    experience: "",
    job_level: "",
    education: "",
    job_description: [""],
    requirements: [""],
    desirable: [""],
    benefits: [""],
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  // Fetch job data when modal opens
  useEffect(() => {
    if (selectedJob) {
      setFormData({
        job_title: selectedJob.job_title || "",
        job_type: selectedJob.job_type || "",
        salary: selectedJob.salary || "",
        location: selectedJob.location || "",
        job_posted_date: selectedJob.job_posted_date || "",
        job_expire: selectedJob.job_expire || "",
        experience: selectedJob.experience || "",
        job_level: selectedJob.job_level || "",
        education: selectedJob.education || "",
        job_description: selectedJob.job_description || [""],
        requirements: selectedJob.requirements || [""],
        desirable: selectedJob.desirable || [""],
        benefits: selectedJob.benefits || [""],
      });
    }
  }, [selectedJob]);

  const clearForm = () => {
    setFormData({
      job_title: "",
      job_type: "",
      salary: "",
      location: "",
      job_posted_date: "",
      job_expire: "",
      experience: "",
      job_level: "",
      education: "",
      job_description: [""],
      requirements: [""],
      desirable: [""],
      benefits: [""],
    });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.job_title) newErrors.job_title = true;
    if (!formData.job_type) newErrors.job_type = true;
    if (!formData.salary || formData.salary <= 0) newErrors.salary = true;
    if (!formData.location) newErrors.location = true;
    if (!formData.job_expire) newErrors.job_expire = true;
    if (!formData.experience) newErrors.experience = true;
    if (!formData.job_level) newErrors.job_level = true;
    if (!formData.education) newErrors.education = true;
    if (formData.job_description.length === 0) newErrors.job_description = true;
    if (formData.requirements.length === 0) newErrors.requirements = true;
    if (formData.desirable.length === 0) newErrors.desirable = true;
    if (formData.benefits.length === 0) newErrors.benefits = true;

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    if (errors[id]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [id]: false,
      }));
    }
  };

  const handleArrayChange = (index, e, field) => {
    const newArray = [...formData[field]];
    newArray[index] = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      [field]: newArray,
    }));
    if (errors[field]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: false,
      }));
    }
  };

  const addArrayItem = (field) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: [...prevData[field], ""],
    }));
  };

  const removeArrayItem = (index, field) => {
    const newArray = formData[field].filter((_, i) => i !== index);
    setFormData((prevData) => ({
      ...prevData,
      [field]: newArray,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      axios
        .put(
          `${process.env.REACT_APP_API_KEY}/jobs/update/${selectedJob._id}`,
          formData
        )
        .then((response) => {
          console.log(response.data);
          clearForm();
          setLoading(false);
          fetchJobs();
          onClose();
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  return (
    <div className="fixed inset-0 z-50 bg-[#0000005d] h-screen w-screen flex justify-center items-center">
      <div className="w-[80%] h-[90vh] bg-customWhite p-10 rounded-lg relative overflow-y-scroll">
        <IoIosCloseCircle
          onClick={() => onClose()}
          className="cursor-pointer absolute top-2 right-2"
          size={25}
          color="#846cf9"
        />
        <h1 className="text-center font-semibold my-4">Edit Job</h1>

        <form onSubmit={handleSubmit}>
          {/* Job Title */}
          <div className="mb-4">
            <label
              htmlFor="job_title"
              className={`${
                errors.job_title ? "text-red-500" : "text-gray-700"
              } block text-sm font-medium`}
            >
              Job Title
            </label>
            <input
              type="text"
              id="job_title"
              value={formData.job_title}
              onChange={handleChange}
              className={`${
                errors.job_title ? "border-red-500" : "border-gray-300"
              } mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
            />
          </div>

          {/* Job Type */}
          <div className="mb-4">
            <label
              htmlFor="job_type"
              className={`${
                errors.job_type ? "text-red-500" : "text-gray-700"
              } block text-sm font-medium`}
            >
              Job Type
            </label>
            <select
              id="job_type"
              value={formData.job_type}
              onChange={handleChange}
              className={`${
                errors.job_type ? "border-red-500" : "border-gray-300"
              } mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
            >
              <option value="">Select job type</option>
              <option value="full-time">Full-Time</option>
              <option value="part-time">Part-Time</option>
              <option value="contract">Contract</option>
              <option value="internship">Internship</option>
            </select>
          </div>

          {/* Salary */}
          <div className="mb-4">
            <label
              htmlFor="salary"
              className={`${
                errors.salary ? "text-red-500" : "text-gray-700"
              } block text-sm font-medium`}
            >
              Salary
            </label>
            <input
              type="number"
              id="salary"
              value={formData.salary}
              onChange={handleChange}
              className={`${
                errors.salary ? "border-red-500" : "border-gray-300"
              } mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
            />
          </div>

          {/* Location */}
          <div className="mb-4">
            <label
              htmlFor="location"
              className={`${
                errors.location ? "text-red-500" : "text-gray-700"
              } block text-sm font-medium`}
            >
              Location
            </label>
            <input
              type="text"
              id="location"
              value={formData.location}
              onChange={handleChange}
              className={`${
                errors.location ? "border-red-500" : "border-gray-300"
              } mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
            />
          </div>

          {/* Job Expire */}
          <div className="mb-4">
            <label
              htmlFor="job_expire"
              className={`${
                errors.job_expire ? "text-red-500" : "text-gray-700"
              } block text-sm font-medium`}
            >
              Job Expiry Date
            </label>
            <input
              type="date"
              id="job_expire"
              value={formData.job_expire}
              onChange={handleChange}
              className={`${
                errors.job_expire ? "border-red-500" : "border-gray-300"
              } mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
            />
          </div>

          {/* Experience */}
          <div className="mb-4">
            <label
              htmlFor="experience"
              className={`${
                errors.experience ? "text-red-500" : "text-gray-700"
              } block text-sm font-medium`}
            >
              Experience
            </label>
            <input
              type="text"
              id="experience"
              value={formData.experience}
              onChange={handleChange}
              className={`${
                errors.experience ? "border-red-500" : "border-gray-300"
              } mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
            />
          </div>

          {/* Job Level */}
          <div className="mb-4">
            <label
              htmlFor="job_level"
              className={`${
                errors.job_level ? "text-red-500" : "text-gray-700"
              } block text-sm font-medium`}
            >
              Job Level
            </label>
            <input
              type="text"
              id="job_level"
              value={formData.job_level}
              onChange={handleChange}
              className={`${
                errors.job_level ? "border-red-500" : "border-gray-300"
              } mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
            />
          </div>

          {/* Education */}
          <div className="mb-4">
            <label
              htmlFor="education"
              className={`${
                errors.education ? "text-red-500" : "text-gray-700"
              } block text-sm font-medium`}
            >
              Education
            </label>
            <input
              type="text"
              id="education"
              value={formData.education}
              onChange={handleChange}
              className={`${
                errors.education ? "border-red-500" : "border-gray-300"
              } mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
            />
          </div>

          {/* Job Description */}
          <div className="mb-4">
            <label
              htmlFor="job_description"
              className={`${
                errors.job_description ? "text-red-500" : "text-gray-700"
              } block text-sm font-medium`}
            >
              Job Description
            </label>
            {formData.job_description.map((desc, index) => (
              <div key={index} className="mb-2">
                <textarea
                  id="job_description"
                  value={desc}
                  onChange={(e) =>
                    handleArrayChange(index, e, "job_description")
                  }
                  className={`${
                    errors.job_description
                      ? "border-red-500"
                      : "border-gray-300"
                  } mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                />
                <button
                  type="button"
                  onClick={() => removeArrayItem(index, "job_description")}
                  className="text-red-500 mt-2"
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => addArrayItem("job_description")}
              className="text-blue-500"
            >
              Add More
            </button>
          </div>

          {/* Requirements */}
          <div className="mb-4">
            <label
              htmlFor="requirements"
              className={`${
                errors.requirements ? "text-red-500" : "text-gray-700"
              } block text-sm font-medium`}
            >
              Requirements
            </label>
            {formData.requirements.map((req, index) => (
              <div key={index} className="mb-2">
                <textarea
                  id="requirements"
                  value={req}
                  onChange={(e) => handleArrayChange(index, e, "requirements")}
                  className={`${
                    errors.requirements ? "border-red-500" : "border-gray-300"
                  } mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                />
                <button
                  type="button"
                  onClick={() => removeArrayItem(index, "requirements")}
                  className="text-red-500 mt-2"
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => addArrayItem("requirements")}
              className="text-blue-500"
            >
              Add More
            </button>
          </div>

          {/* Desirable */}
          <div className="mb-4">
            <label
              htmlFor="desirable"
              className={`${
                errors.desirable ? "text-red-500" : "text-gray-700"
              } block text-sm font-medium`}
            >
              Desirable
            </label>
            {formData.desirable.map((des, index) => (
              <div key={index} className="mb-2">
                <textarea
                  id="desirable"
                  value={des}
                  onChange={(e) => handleArrayChange(index, e, "desirable")}
                  className={`${
                    errors.desirable ? "border-red-500" : "border-gray-300"
                  } mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                />
                <button
                  type="button"
                  onClick={() => removeArrayItem(index, "desirable")}
                  className="text-red-500 mt-2"
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => addArrayItem("desirable")}
              className="text-blue-500"
            >
              Add More
            </button>
          </div>

          {/* Benefits */}
          <div className="mb-4">
            <label
              htmlFor="benefits"
              className={`${
                errors.benefits ? "text-red-500" : "text-gray-700"
              } block text-sm font-medium`}
            >
              Benefits
            </label>
            {formData.benefits.map((ben, index) => (
              <div key={index} className="mb-2">
                <textarea
                  id="benefits"
                  value={ben}
                  onChange={(e) => handleArrayChange(index, e, "benefits")}
                  className={`${
                    errors.benefits ? "border-red-500" : "border-gray-300"
                  } mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                />
                <button
                  type="button"
                  onClick={() => removeArrayItem(index, "benefits")}
                  className="text-red-500 mt-2"
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => addArrayItem("benefits")}
              className="text-blue-500"
            >
              Add More
            </button>
          </div>

          {/* Submit Button */}
          <div className="flex justify-center">
            <button
              type="submit"
              className="w-[300px] py-2 px-4 bg-customPurple text-white rounded-md shadow-sm hover:bg-customPurpleHover"
              disabled={loading}
            >
              {loading ? "Saving..." : "Save"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditJobModal;
