import React, { useEffect, useState } from "react";
import HeadingWithDot from "../../common/HeadingWithDot";
import { Link } from "react-router-dom";
import { BiSolidRightArrow } from "react-icons/bi";
import axios from "axios";

const Courses = () => {
  const [courseCategories, setCourseCatagory] = useState([]);
  const [coursesData, setCourseData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [loading, setLoading] = useState(true);

  const handleCategoryClick = (categoryName) => {
    setSelectedCategory(categoryName);
  };

  // Filter courses based on selected category
  const filteredCourses = coursesData.filter(
    (course) => course?.category?.name === selectedCategory
  );

  const formatTitleForUrl = (title) => {
    return title
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/&/g, "and") // Replace & with "and"
      .replace(/_/g, "-"); // Replace underscores with hyphens
  };

  const getAllCatagory = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/categories/get-all-categories`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setCourseCatagory(response?.data?.categories);
        setSelectedCategory(response?.data?.categories[0]?.name);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllCourse = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/courses/get-all-course`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setCourseData(response?.data?.courses);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false after fetching
      });
  };

  useEffect(() => {
    getAllCatagory();
    getAllCourse();
  }, []);

  return (
    <div>
      <div className="flex justify-center mt-10">
        <HeadingWithDot name="Courses" />
      </div>
      <h1 className="md:text-4xl text-2xl tracking-wider text-center mt-4">
        Top Notch
        <span className="text-primaryColor"> Courses </span>
        Provided by Us
      </h1>

      <div className="lg:px-20 md:px-10 px-5 pb-20">
        <div className="mt-6">
          <div className="flex space-x-4 justify-start overflow-x-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-300">
            {courseCategories.map((category) => (
              <button
                key={category.name}
                onClick={() => handleCategoryClick(category.name)}
                className={`py-2 px-4 rounded-lg text-white flex-shrink-0 w-[300px] ${
                  selectedCategory === category.name
                    ? "bg-primaryColor"
                    : "bg-gray-400"
                }`}
              >
                <span className="text-xl">📊</span> {category.name}
              </button>
            ))}
          </div>
        </div>

        <div className="mt-10">
          {loading ? ( // Show skeleton while loading
            <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8">
              {Array.from({ length: 6 }).map(
                (
                  _,
                  index // Adjust length based on your requirement
                ) => (
                  <div
                    key={index}
                    className="border border-gray-300 p-3 rounded-xl animate-pulse"
                  >
                    <div className="rounded-md w-full h-[200px] bg-gray-300 mb-4"></div>
                    <div className="h-6 bg-gray-300 mb-2"></div>
                    <div className="h-4 bg-gray-300"></div>
                  </div>
                )
              )}
            </div>
          ) : filteredCourses.length > 0 ? (
            <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8">
              {filteredCourses.map((ele) => (
                <Link
                  key={ele?._id}
                  to={`/course/${formatTitleForUrl(ele?.title)}/${ele?._id}`}
                  className="border border-gray-300 p-3 rounded-xl"
                >
                  <img
                    src={ele?.courseBanner}
                    alt="Course Image"
                    className="rounded-md w-full h-[200px] object-cover"
                  />
                  <h1 className="font-bold mt-4 tracking-wide text-TextPrimaryColor">
                    {ele?.title}
                  </h1>
                  <p className="mt-3 tracking-wide text-sm leading-7 text-TextSecondaryColor truncate">
                    {ele?.description}
                  </p>
                  <div className="flex items-center justify-end gap-2 mt-4 cursor-pointer">
                    <button className="text-[#034D8E]">View Details </button>
                    <BiSolidRightArrow color="#034D8E" />
                  </div>
                </Link>
              ))}
            </div>
          ) : (
            <div className="h-[10rem] tracking-wide font-medium text-center flex justify-center items-center text-[black] mt-10">
              <p className="text-center">No courses available...!</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Courses;
