import React from "react";
import bgImg from "../../Assets/picimgs/bgLower.png";
import sqrImg from "../../Assets/picimgs/img.png";
import checkMark from "../../Assets/picimgs/checkMark.png";

const IndependentInternshipPlans = () => {
  const plans = [
    {
      id: 1,
      price: 300,
      days: 30,
      points: [
        "Opportunity to Build a Unique Portfolio",
        "Flexible Learning Experience",
        "Broader Industry Exposure",
      ],
    },
  ];
  return (
    <div>
      <div
        className="flex flex-col items-center my-6 md:my-16"
        style={{
          backgroundImage: `url(${bgImg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="p-8 md:p-16">
          <h2 className="flex text-sm gap-8 justify-center mx-6 md:mx-16">
            <img src={sqrImg} alt="" />
            PRICING
            <img src={sqrImg} alt="" />
          </h2>
          <h1 className="text-3xl md:text-4xl font-bold text-[#101A29] capitalize tracking-wide text-center mb-8">
            Select a <span className="text-[#F8B003]">Plan</span> Based On Your
            Needs
          </h1>
          <div className="flex flex-col lg:flex-row justify-center gap-6">
            {plans.map((plan) => (
              <div
                key={plan.id}
                className="bg-white flex flex-col  p-8 md:p-16 shadow-xl rounded"
              >
                <h1 className="text-4xl md:text-7xl lg:text-left text-center text-[#101A29] font-bold capitalize mb-12">
                  ${plan.price}{" "}
                  <span className="text-xl text-[#F8B003] ">
                    / {plan.days} days
                  </span>
                </h1>
                {plan.points.map((point, index) => (
                  <div key={index} className="flex flex-col items-start mb-4">
                    <div className="flex mb-2 items-center text-left gap-2">
                      <img src={checkMark} alt="Check Mark" />
                      <p className="tracking-wide ">{point}</p>
                    </div>
                  </div>
                ))}
                <div className="flex justify-center mt-8">
                  <button className="px-6 py-4 bg-[#ECAB06] hover:bg-[#CC8302] active:bg-[#A35C05] w-36 rounded-md text-white font-semibold">
                    Enroll Now
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndependentInternshipPlans;
