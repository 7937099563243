import React from "react";

const ViewOrderModal = ({ isOpen, onClose, items }) => {
    console.log("------>",items)
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 md:w-1/2 lg:w-1/3">
        <h2 className="text-2xl font-semibold mb-4">Purchased Items</h2>

        <div className="h-[400px] overflow-y-scroll mb-4">
        {items?.courses?.length > 0 && (
          <div className="mb-4">
            <h3 className="font-bold text-lg">Courses:</h3>
            <div>
              {items.courses.map((course, index) => (
               <div key={index} className="border-2 border-primaryColor mt-2 p-4 rounded">
                <h1>Course Name : {course?.courses?.course_reference?.title}</h1>
                <h1>Course Plan{course?.courses?.course_plan_reference?.name}</h1>
                <p>Plan Price : ${course?.courses?.course_plan_reference?.price}</p>
               </div>
              ))}
            </div>
          </div>
        )}

        {items?.mockInterviews?.length > 0 && (
          <div className="mb-4">
            <h3 className="font-bold text-lg">Mock Interviews:</h3>
            <div>
              {items.mockInterviews.map((mock, index) => (
               <div key={index} className="border-2 border-primaryColor mt-2 p-4 rounded">
               <h1>Number of Mock : {mock?.mockInterviews?.mockInterview_plan_reference?.number_of_mocks}</h1>
               <h1>Price : ${mock?.mockInterviews?.mockInterview_plan_reference?.discounted_price}</h1>
               {/* <h1>Course Plan{mock?.mockInterviews?.course_plan_reference?.name}</h1>
               <p>Plan Price : ${mock?.mockInterviews?.course_plan_reference?.price}</p> */}
              </div>
              ))}
            </div>
          </div>
        )}

        {items?.resumeReviews?.length > 0 && (
          <div className="mb-4">
            <h3 className="font-bold text-lg">Resume Reviews:</h3>
            <div>
              {items.resumeReviews.map((resume, index) => (
               <div key={index} className="border-2 border-primaryColor mt-2 p-4 rounded">
               <h1>Type : {resume?.resumeReviews?.resume_review_plan?.type}</h1>
               <h1>Price : ${resume?.resumeReviews?.resume_review_plan?.price}</h1>
              </div>
              ))}
            </div>
          </div>
        )}
        </div>

        <button
          onClick={onClose}
          className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ViewOrderModal;
