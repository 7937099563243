import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Skeleton Loader for loading states
const SkeletonLoader = () => {
  return (
    <tr className="border-b animate-pulse">
      <td className="p-4">
        <div className="h-4 bg-gray-200 rounded w-3/4"></div>
      </td>
      <td className="p-4">
        <div className="h-4 bg-gray-200 rounded w-1/2"></div>
      </td>
      <td className="p-4">
        <div className="h-4 bg-gray-200 rounded w-1/4"></div>
      </td>
      <td className="p-4">
        <div className="h-4 bg-gray-200 rounded w-1/4"></div>
      </td>
      <td className="p-4">
        <div className="h-4 bg-gray-200 rounded w-1/4"></div>
      </td>
    </tr>
  );
};

const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_KEY}/transactions/get-all-transactions`);
        setTransactions(response.data);
      } catch (error) {
        console.error('Error fetching transactions:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, []);

  // Filter transactions based on search query, selected status, and date range
  const filteredTransactions = transactions.filter((transaction) => {
    const userId = transaction.userId?.name || '';
    const transactionDate = new Date(transaction.createdAt);
    
    const matchesSearchQuery = userId.toLowerCase().includes(searchQuery.toLowerCase()) || 
                               transaction.amount.toString().includes(searchQuery) || 
                               transaction.status.toLowerCase().includes(searchQuery.toLowerCase());
                               
    const matchesStatus = selectedStatus ? transaction.status === selectedStatus : true;

    const matchesDateRange = (startDate ? transactionDate >= new Date(startDate) : true) &&
                             (endDate ? transactionDate <= new Date(endDate) : true);

    return matchesSearchQuery && matchesStatus && matchesDateRange;
  });

  // Clear all filters
  const clearFilters = () => {
    setSearchQuery('');
    setSelectedStatus('');
    setStartDate('');
    setEndDate('');
  };

  // Determine if any filter is applied
  const isFilterApplied = searchQuery || selectedStatus || startDate || endDate;

  return (
    <div className="p-4">
      <h1 className="text-xl font-bold mb-4">Transactions</h1>

     <div className=''>
         {/* Search Bar */}
      <div className="mb-4">
        <label className="mr-2">Search:</label>
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search by User Name, Amount, or Status"
          className="border-2 rounded p-2 border-customPurple w-full"
        />
      </div>

     <div className='flex flex-wrap items-center justify-between'>
         {/* Status Filter */}
      <div className="mb-4">
        <label className="mr-2">Filter by Status:</label>
        <select
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.target.value)}
          className="border-2 rounded p-2 border-customPurple"
        >
          <option value="">All</option>
          <option value="completed">Completed</option>
          <option value="pending">Pending</option>
          <option value="failed">Failed</option>
        </select>
      </div>

      {/* Date Range Filter */}
      <div className="mb-4 flex space-x-4">
        <div>
          <label className="mr-2">Start Date:</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="border-2 rounded p-2 border-customPurple"
          />
        </div>
        <div>
          <label className="mr-2">End Date:</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="border-2 rounded p-2 border-customPurple"
          />
        </div>
      </div>

      {/* Clear Filters Button with conditional styles */}
      <button
        onClick={clearFilters}
        className={`text-white px-4 py-2 rounded mb-4 ${isFilterApplied ? 'bg-red-500' : 'bg-gray-400'}`}
        disabled={!isFilterApplied} // Disable if no filters are applied
      >
        Clear Filters
      </button>
     </div>
     </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-4">
        <table className="min-w-full bg-white shadow-md rounded-lg">
          <thead>
            <tr className="bg-customPurple text-xs uppercase text-white text-left">
              <th className="p-4">User Name</th>
              <th className="p-4">Amount</th>
              <th className="p-4">Currency</th>
              <th className="p-4">Status</th>
              <th className="p-4">Date</th>
            </tr>
          </thead>
          <tbody className='bg-white border'>
            {loading ? (
              // Display multiple skeleton loaders while loading
              Array.from({ length: 5 }).map((_, index) => <SkeletonLoader key={index} />)
            ) : (
              filteredTransactions.length > 0 ? (
                filteredTransactions.map((transaction) => (
                  <tr key={transaction._id} className="border-b">
                    <td className="p-4">{transaction.userId?.name}</td>
                    <td className="p-4">{transaction.amount.toFixed(2)}</td>
                    <td className="p-4">{transaction.currency}</td>
                    <td className={`p-4 ${getStatusClass(transaction.status)}`}>{transaction.status}</td>
                    <td className="p-4">{new Date(transaction.createdAt).toLocaleString()}</td>
                  </tr>
                ))
              ) : (
                <tr className="border-b">
                  <td colSpan={5} className="p-4 text-center text-gray-600">
                    No matching transactions found.
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

// Helper function to get status class
const getStatusClass = (status) => {
  switch (status) {
    case "completed":
      return "text-green-600";
    case "pending":
      return "text-yellow-600";
    case "failed":
      return "text-red-600";
    default:
      return "text-gray-600";
  }
};

export default Transactions;
