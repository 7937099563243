import React from "react";
import LoghingGirlImage from "../../Assets/AboutUs/LoghingGirlImage.png";
import Approachable_image from "../../Assets/AboutUs/Approachable_image.png";
import SalivatingIdeas_Image from "../../Assets/AboutUs/SalivatingIdeas_Image.png";
import UnmatchedQuality_image from "../../Assets/AboutUs/UnmatchedQuality_image.png";
import TrueProfessionals_image from "../../Assets/AboutUs/TrueProfessionals_image.png";

const data = [
  {
    title: "Approachable",
    description:
      "Without concerning about location or time zone, feel free to connect with us to know the progress of your projects. We will keep you informed about the development processes, dealings, workflows, and delivery.",
    image: Approachable_image,
  },
  {
    title: "Salivating Ideas",
    description:
      "We understand the limitations your business is facing and the access to highly skilled global professionals, so that together we can make a difference and analyze the business era and drive future business growth.",
    image: SalivatingIdeas_Image,
  },
  {
    title: "Unmatched Quality",
    description:
      "Our high-quilty and global standards are what our clients vouch for. We deploy seamless software products for dynamic business needs, ensuring constant support, and maximum ROI.",
    image: UnmatchedQuality_image,
  },
  {
    title: "True Professionals",
    description:
      "Client satisfaction is our priority. Hence, we keep your business goals in mind and leave no stone unturned to achieve them. We maintain global standards while delivering software solutions.",
    image: TrueProfessionals_image,
  },
];

const SmallEnoughToCare = () => {
  return (
    <div>
      <div className="grid xl:grid-cols-2 grid-cols-1 lg:pl-20 md:px-10 md:gap-16 gap-0">
        <div className="flex flex-col justify-center">
          <div className="md:p-0 p-5">
            <h1 className="md:text-4xl text-2xl tracking-wider md:leading-[50px] leading-[30px] mt-2 w-[70%]">
              Innovating Today for <br />a Better
              <span className="text-primaryColor"> Tomorrow.</span>
            </h1>
            <div className="grid md:grid-cols-3 grid-cols-1 gap-8 mt-8 xl:relative xl:w-[140%] ">
              <div className=" bg-primaryColor text-white p-8 rounded-md">
                <div className="border-4 border-dotted h-24 w-24 flex items-center justify-center inline-block border-white rounded-full">
                  <h1 className="text-3xl font-bold text-white">87%</h1>
                </div>
                <h1 className="mt-2">Client Satisfaction</h1>
                <p className="mt-2 text-sm font-normal">
                  We prioritize our clients’ needs, ensuring personalized
                  attention and exceptional service at every stage.
                </p>
              </div>
              <div className=" bg-black text-white p-8 rounded-md">
                <div className="border-4 border-dotted h-24 w-24 flex items-center justify-center inline-block border-white rounded-full">
                  <h1 className="text-3xl font-bold text-white">75%</h1>
                </div>
                <h1 className="mt-2">IT Consultations</h1>
                <p className="mt-2 text-sm font-normal">
                  We provide expert IT consultations tailored to help businesses
                  optimize their technology and achieve success.
                </p>
              </div>
              <div className="bg-white text-black p-8 rounded-md border">
                <div className="border-4 border-dotted h-24 w-24 flex items-center justify-center inline-block border-black rounded-full">
                  <h1 className="text-3xl font-bold text-black">90%</h1>
                </div>
                <h1 className="mt-2">Proven Success Rate</h1>
                <p className="mt-2 text-sm font-normal">
                  With a track record of effective solutions, we help clients
                  navigate challenges and seize opportunities.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="md:p-0 xl:pr-10 p-5">
          <img
            src={LoghingGirlImage}
            alt="LoghingGirlImage"
            className="w-full  rounded-lg"
          />
        </div>
      </div>
      <div className="lg:px-20 md:px-10 px-5 grid grid-cols-1 lg:grid-cols-2 gap-6 py-10">
        {data.map((item, index) => (
          <div
            key={index}
            className="flex flex-col items-center text-center p-10 shadow-lg border border-primaryColor rounded-lg"
          >
            <img src={item.image} alt={item.title} className="mt-auto mb-4" />
            <h1 className="text-TextPrimaryColor font-bold">{item.title}</h1>
            <p className="mt-3 text-sm tracking-wider text-TextSecondaryColor">
              {item.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SmallEnoughToCare;

// import React, { useEffect } from "react";
// import AOS from "aos";
// import "aos/dist/aos.css";
// import LoghingGirlImage from "../../Assets/AboutUs/LoghingGirlImage.png";
// import Approachable_image from "../../Assets/AboutUs/Approachable_image.png";
// import SalivatingIdeas_Image from "../../Assets/AboutUs/SalivatingIdeas_Image.png";
// import UnmatchedQuality_image from "../../Assets/AboutUs/UnmatchedQuality_image.png";
// import TrueProfessionals_image from "../../Assets/AboutUs/TrueProfessionals_image.png";

// const data = [
//   {
//     title: "Approachable",
//     description:
//       "Without concerning about location or time zone, feel free to connect with us to know the progress of your projects. We will keep you informed about the development processes, dealings, workflows, and delivery.",
//     image: Approachable_image,
//     aos: "fade-up",
//   },
//   {
//     title: "Salivating Ideas",
//     description:
//       "We understand the limitations your business is facing and the access to highly skilled global professionals, so that together we can make a difference and analyze the business era and drive future business growth.",
//     image: SalivatingIdeas_Image,
//     aos: "fade-right",
//   },
//   {
//     title: "Unmatched Quality",
//     description:
//       "Our high-quality and global standards are what our clients vouch for. We deploy seamless software products for dynamic business needs, ensuring constant support, and maximum ROI.",
//     image: UnmatchedQuality_image,
//     aos: "zoom-in",
//   },
//   {
//     title: "True Professionals",
//     description:
//       "Client satisfaction is our priority. Hence, we keep your business goals in mind and leave no stone unturned to achieve them. We maintain global standards while delivering software solutions.",
//     image: TrueProfessionals_image,
//     aos: "fade-left",
//   },
// ];

// const SmallEnoughToCare = () => {
//   useEffect(() => {
//     AOS.init({ duration: 1000 }); // Initialize AOS with animation duration
//   }, []);

//   return (
//     <div>
//       <div
//         className="grid xl:grid-cols-2 grid-cols-1 lg:pl-20 md:pl-10 md:gap-16 gap-0"
//         data-aos="fade-up"
//       >
//         <div className="flex flex-col justify-center">
//           <div className="md:p-0 p-5">
//             <h1
//               className="md:text-4xl text-2xl tracking-wider md:leading-[50px] leading-[30px] mt-2 w-[70%]"
//               data-aos="fade-right"
//             >
//               Innovating Today for <br />a Better
//               <span className="text-primaryColor"> Tomorrow.</span>
//             </h1>
//             <div
//               className="grid md:grid-cols-3 grid-cols-1 gap-8 mt-8 xl:relative xl:w-[140%]"
//               data-aos="fade-up"
//             >
//               <div className=" bg-primaryColor text-white p-8 rounded-md" data-aos="zoom-in">
//                 <div className="border-4 border-dotted h-24 w-24 flex items-center justify-center inline-block border-white rounded-full">
//                   <h1 className="text-3xl font-bold text-white">87%</h1>
//                 </div>
//                 <h1 className="mt-2">Client Satisfaction</h1>
//                 <p className="mt-2 text-sm font-thin">
//                   We prioritize our clients’ needs, ensuring personalized
//                   attention and exceptional service at every stage.
//                 </p>
//               </div>
//               <div className=" bg-black text-white p-8 rounded-md" data-aos="fade-left">
//                 <div className="border-4 border-dotted h-24 w-24 flex items-center justify-center inline-block border-white rounded-full">
//                   <h1 className="text-3xl font-bold text-white">75%</h1>
//                 </div>
//                 <h1 className="mt-2">IT Consultations</h1>
//                 <p className="mt-2 text-sm font-thin">
//                   We provide expert IT consultations tailored to help businesses
//                   optimize their technology and achieve success.
//                 </p>
//               </div>
//               <div className="bg-white text-black p-8 rounded-md border" data-aos="flip-up">
//                 <div className="border-4 border-dotted h-24 w-24 flex items-center justify-center inline-block border-black rounded-full">
//                   <h1 className="text-3xl font-bold text-black">90%</h1>
//                 </div>
//                 <h1 className="mt-2">Proven Success Rate</h1>
//                 <p className="mt-2 text-sm font-thin">
//                   With a track record of effective solutions, we help clients
//                   navigate challenges and seize opportunities.
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="md:p-0 p-5">
//           <img
//             src={LoghingGirlImage}
//             alt="LoghingGirlImage"
//             className="w-full md:rounded-none rounded-lg"
//             data-aos="fade-left"
//           />
//         </div>
//       </div>
//       <div
//         className="lg:px-20 md:px-10 px-5 grid grid-cols-1 lg:grid-cols-2 gap-6 py-10"
//         data-aos="fade-up"
//       >
//         {data.map((item, index) => (
//           <div
//             key={index}
//             className="flex flex-col items-center text-center p-10 shadow-lg border border-primaryColor rounded-lg"
//             data-aos={item.aos}
//           >
//             <img src={item.image} alt={item.title} className="mt-auto mb-4" />
//             <h1 className="text-TextPrimaryColor font-bold">{item.title}</h1>
//             <p className="mt-3 text-sm tracking-wider text-TextSecondaryColor">
//               {item.description}
//             </p>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default SmallEnoughToCare;
