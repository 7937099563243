import React from 'react'
import SimpleBanner from "../../../components/common/SimpleBanner";
import AboutUs_banner from "../../../Assets/banner/Section.png";
import Development from '../../../components/Application/Development';
import LegacyApps from '../../../components/Application/LegacyApps';
import CostEfficiency from '../../../components/Application/CostEfficiency';
export const ApplicationDevelopment = () => {
  return (
    <>
     <SimpleBanner
        image={AboutUs_banner}
        name="Application Development"
        path="Home / Services / Application Development"
      />
      <Development/>
      <LegacyApps/>
      <CostEfficiency/>
    </>
  )
}
